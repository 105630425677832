import { Grid } from "@mui/material";
import { TabsContainer } from "./TabsContainer";
import { ResearchTabDetails } from "./TabWidgets/ResearchTabDetails";
import { RouteToContactUs } from "../../../../CodingRippler/CodingRipplerHome/ContactUs/ContactUs";

export const ResearchTabPanel = (props) => {
  const { value } = props;

  return (
    <TabsContainer value={value} index={2}>
      <Grid container spacing={2}>
        <Grid item xs={12} lg={4}>
          <ResearchTabDetails
            imageSrc="/airippler/ai-tools/research/human-queue.png"
            imageAlt="Description of the image"
            title="Identify Survey Biases with AI"
            description={`The "Identify Survey Biases" tool uses AI to detect and explain potential biases in survey questions. Designed for researchers, marketers, and operations teams, this tool ensures that your surveys are fair, reliable, and provide actionable insights by eliminating any inherent biases.`}
            handleButtonClick={RouteToContactUs}
            buttonText={"Contact Us"}
          />
        </Grid>

        <Grid item xs={12} lg={4}>
          <ResearchTabDetails
            imageSrc="/airippler/ai-tools/research/emoji-emotions.png"
            imageAlt="Description of the image"
            title="Extract Insights from Your Files with GPT"
            description={`The "Extract Insights from Your Files with GPT" tool enables you to get answers from various file types, such as PDFs, CSVs, and audio files. Using the power of GPT (Generative Pre-trained Transformer), it processes and analyzes your files to provide precise, relevant answers. Ideal for researchers, data analysts, and students, this tool simplifies information retrieval and boosts productivity.`}
            handleButtonClick={RouteToContactUs}
            buttonText={"Contact Us"}
          />
        </Grid>

        <Grid item xs={12} lg={4}>
          <ResearchTabDetails
            imageSrc="/airippler/ai-tools/research/chatgpt.png"
            imageAlt="Description of the image"
            title="Analyze Emotions in Text"
            description={`The "Analyze Emotions in Text" tool uses AI to detect specific emotions within written content. By identifying key emotional cues, it helps businesses and researchers gain valuable insights into sentiment and emotional undertones. Whether analyzing customer feedback, employee surveys, or other text-based interactions, this tool simplifies sentiment analysis at scale.`}
            handleButtonClick={RouteToContactUs}
            buttonText={"Contact Us"}
          />
        </Grid>
      </Grid>
    </TabsContainer>
  );
};
