import CloseIcon from "@mui/icons-material/Close";
import MenuIcon from "@mui/icons-material/Menu";
import { Card, CardMedia, useMediaQuery } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import { useTheme } from "@mui/material/styles";
import { Box } from "@mui/system";
import { useEffect, useState } from "react";
import { CRDrawerButton } from "./CRDrawerButton";

export const CRNavigationSmallerDevice = ({ children, ...props }) => {
  const theme = useTheme();
  const isLargeScreen = useMediaQuery(theme.breakpoints.up("lg"));

  const [drawerOpen, setDrawerOpen] = useState(false);

  useEffect(() => {
    setDrawerOpen(false);
  }, [isLargeScreen]);

  const handleDrawerToggle = () => {
    setDrawerOpen(!drawerOpen);
  };

  const clickHandler = () => {
    window.location.href = "/";
  };

  return (
    <>
      <Card
        sx={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "space-between",
          backgroundColor: "white",
          width: "100%",
          position: "fixed",
          zIndex: 1000,
          height: { xs: "64px", sm: "80px" },
          top: 0,
        }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            height: 100,
            width: "15rem",
            overflow: "hidden",
            transform: "translate(5%, 0)",
          }}
        >
          <CardMedia
            component="img"
            image={"/codingrippler/brand-logos/logo-main-light.svg"}
            alt="Coding Rippler"
            onClick={() => clickHandler()}
            sx={{
              objectFit: "contain",
              maxHeight: "100%",
              maxWidth: "100%",
            }}
          />
        </Box>
        <Box
          sx={{
            borderRadius: "24px",
            display: "flex", // Enables flexbox
            flexDirection: "row", // Arranges children horizontally
            alignItems: "center", // Vertically centers the items (optional)
            backgroundColor: "transparent", // Set background to transparent
          }}
        >
          <IconButton
            color="inherit"
            aria-label="menu"
            onClick={handleDrawerToggle}
            className="menuBox"
          >
            {drawerOpen ? <CloseIcon /> : <MenuIcon />}
          </IconButton>
        </Box>
      </Card>

      {drawerOpen && (
        <CRDrawerButton
          drawerOpen={drawerOpen}
          setDrawerOpen={setDrawerOpen}
          handleDrawerToggle={handleDrawerToggle}
        />
      )}
    </>
  );
};
