"use client";
import { Box, Typography } from "@mui/material";
// import { cn } from "@/lib/utils";
import { AnimatePresence, motion } from "framer-motion";
import React, { useCallback, useEffect, useState } from "react";

export const FlipWords = ({ words, duration = 3000, className, ...props }) => {
  const [currentWord, setCurrentWord] = useState(words[0]);
  const [isAnimating, setIsAnimating] = useState(false);
  const { isPhoneOrTab, isPhone } = props;

  const startAnimation = useCallback(() => {
    const word = words[words.indexOf(currentWord) + 1] || words[0];
    setCurrentWord(word);
    setIsAnimating(true);
  }, [currentWord, words]);

  useEffect(() => {
    if (!isAnimating)
      setTimeout(() => {
        startAnimation();
      }, duration);
  }, [isAnimating, duration, startAnimation]);

  return (
    <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
      width="100%"
      sx={{
        overflow: "hidden",
      }}
    >
      <AnimatePresence
        onExitComplete={() => {
          setIsAnimating(false);
        }}
      >
        <motion.div
          initial={{
            opacity: 0,
            y: 10,
          }}
          animate={{
            opacity: 1,
            y: 0,
          }}
          transition={{
            type: "spring",
            stiffness: 100,
            damping: 10,
          }}
          exit={{
            opacity: 0,
            y: -20,
            x: 20,
            filter: "blur(8px)",
            scale: isPhone ? 1.0 : 1.3,
            position: "absolute",
          }}
          key={currentWord}
          style={{ maxWidth: "100%", overflow: "hidden" }} // Ensure the div does not exceed the container width
        >
          {/* edit suggested by Sajal: https://x.com/DewanganSajal */}
          <Typography
            variant={isPhoneOrTab ? "h4" : "h1"}
            fontWeight="bold"
            className="typography-custom-css"
            sx={{
              textAlign: { xs: "center", sm: "center", lg: "center" },
            }}
          >
            {currentWord.split(" ").map((word, wordIndex) => (
              <motion.span
                key={word + wordIndex}
                initial={{ opacity: 0, y: 10, filter: "blur(8px)" }}
                animate={{ opacity: 1, y: 0, filter: "blur(0px)" }}
                transition={{
                  delay: wordIndex * 0.3,
                  duration: 0.3,
                }}
              >
                {word.split("").map((letter, letterIndex) => (
                  <motion.span
                    key={word + letterIndex}
                    initial={{ opacity: 0, y: 10, filter: "blur(8px)" }}
                    animate={{ opacity: 1, y: 0, filter: "blur(0px)" }}
                    transition={{
                      delay: wordIndex * 0.3 + letterIndex * 0.05,
                      duration: 0.2,
                    }}
                  >
                    <span className="theme-specfic-colored-text">{letter}</span>
                  </motion.span>
                ))}

                {wordIndex !== currentWord.split(" ").length - 1 && (
                  <span>&nbsp;</span>
                )}
              </motion.span>
            ))}
          </Typography>
        </motion.div>
      </AnimatePresence>
    </Box>
  );
};
