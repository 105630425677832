import React, { useState } from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  IconButton,
  Box,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { contactService } from '../../../../services/contactService';
import ModernLoader from '../../CRAnimations/ModernLoaders';


export const trackAndOpenDialog = (setDialogOpen) => {
  // Open the dialog
  setDialogOpen(true);

  // Google Analytics Event Tracking
  if (window.gtag) {
    window.gtag('event', 'lets_talk_clicked', {
      event_category: 'User Interaction',
      event_label: 'Lets Talk Dialog',
      value: 1,
    });
  }

};


const ContactDialog = ({ open, onClose }) => {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));
  const [loading, setLoading] = useState(false);
  
  // Add state for success notification
  const [showSuccess, setShowSuccess] = useState(false);
  const [successTimer, setSuccessTimer] = useState(null);
  
  // Add state to track if form has been modified
  const [formModified, setFormModified] = useState(false);
  
  // Add state for confirmation dialog
  const [showConfirmClose, setShowConfirmClose] = useState(false);
  
  const [formData, setFormData] = useState({
    fullName: '',
    email: '',
    subject: '',
    projectGoals: ''
  });
  
  const [formErrors, setFormErrors] = useState({});
  
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
    
    // Set form as modified if any field has been edited
    if (!formModified) {
      setFormModified(true);
    }
    
    // Clear error when field is edited
    if (formErrors[name]) {
      setFormErrors({ ...formErrors, [name]: null });
    }
  };
  
  const validateForm = () => {
    const errors = {};
    if (!formData.fullName) errors.fullName = 'Full Name is required';
    
    if (!formData.email) {
      errors.email = 'Email Address is required';
    } else if (!/\S+@\S+\.\S+/.test(formData.email)) {
      errors.email = 'Email is not valid';
    }
    
    if (!formData.subject) errors.subject = 'Please select an option';
    
    return errors;
  };
  
  const handleSubmit = async (e) => {
    // If the event was triggered by a form submission, prevent default behavior
    if (e) {
      e.preventDefault();
    }
    
    const errors = validateForm();
    if (Object.keys(errors).length === 0) {
      setLoading(true);
      setFormErrors({});
      
      try {
        const data = await contactService.sendContactForm(formData);

        if (data?.success ?? false) {
          setFormData({
            fullName: '',
            email: '',
            subject: '',
            projectGoals: ''
          });
          setLoading(false);
          setShowSuccess(true);
          setFormModified(false);
          
          // Auto-close the dialog after 3 seconds
          const timer = setTimeout(() => {
            onClose(true);
          }, 3000);
          setSuccessTimer(timer);
        } else {
          setFormErrors({ submit: "Failed to send contact form. Please try again." });
          setLoading(false);
        }
      } catch (error) {
        setFormErrors({ submit: error.message });
        setLoading(false);
      }
    } else {
      setFormErrors(errors);
    }
  };
  
  // Clear success timer on unmount or dialog close
  React.useEffect(() => {
    return () => {
      if (successTimer) {
        clearTimeout(successTimer);
      }
    };
  }, [successTimer]);
  
  // Define handleClear function to reset the form
  const handleClear = () => {
    setFormData({
      fullName: '',
      email: '',
      subject: '',
      projectGoals: ''
    });
    setFormErrors({});
    setFormModified(false);
  };
  
  // Handle dialog close attempt
  const handleCloseAttempt = () => {
    // Only show confirmation if form has been modified and not in loading or success state
    if (formModified && !loading && !showSuccess) {
      setShowConfirmClose(true);
    } else {
      onClose(false);
    }
  };
  
  // Handle confirmed close
  const handleConfirmedClose = () => {
    setShowConfirmClose(false);
    onClose(false);
  };
  
  // Reset form and success state when dialog opens/closes
  React.useEffect(() => {
    if (!open) {
      // Reset success state and form when dialog closes
      setShowSuccess(false);
      setFormData({
        fullName: '',
        email: '',
        subject: '',
        projectGoals: ''
      });
      setFormErrors({});
      setFormModified(false);
      
      // Clear any existing timers
      if (successTimer) {
        clearTimeout(successTimer);
        setSuccessTimer(null);
      }
    }
  }, [open, successTimer]);
  
  // We're removing the Escape key event listener to let the Dialog's disableEscapeKeyDown handle it
  
  // Confirmation dialog for closing
  const ConfirmCloseDialog = () => (
    <Dialog 
      open={showConfirmClose} 
      onClose={() => setShowConfirmClose(false)}
      maxWidth="xs"
      fullWidth
      PaperProps={{
        sx: {
          borderRadius: '16px',
          boxShadow: '0 10px 40px rgba(0, 0, 0, 0.2)',
        }
      }}
    >
      <DialogTitle sx={{ 
        fontWeight: 600,
        borderBottom: '1px solid rgba(0, 0, 0, 0.08)',
        p: 2.5
      }}>
        Discard changes?
      </DialogTitle>
      <DialogContent sx={{ p: 3, pt: 2.5 }}>
        <Typography variant="body1">
          You have unsaved changes. Are you sure you want to close this form? Your data will be lost.
        </Typography>
      </DialogContent>
      <DialogActions sx={{ p: 2, px: 3 }}>
        <Button 
          onClick={() => setShowConfirmClose(false)} 
          sx={{ 
            borderRadius: '10px',
            textTransform: 'none',
            fontWeight: 600
          }}
        >
          Continue editing
        </Button>
        <Button 
          onClick={handleConfirmedClose}
          sx={{ 
            backgroundColor: '#e53935',
            color: 'white',
            borderRadius: '10px',
            textTransform: 'none',
            fontWeight: 600,
            '&:hover': {
              backgroundColor: '#d32f2f',
            }
          }}
        >
          Discard
        </Button>
      </DialogActions>
    </Dialog>
  );

  return (
    <>
      <Dialog 
        open={open} 
        onClose={handleCloseAttempt}
        fullScreen={fullScreen}
        maxWidth="sm"
        fullWidth
        disableEscapeKeyDown={true} // Always disable escape key for main dialog
        PaperProps={{
          sx: {
            borderRadius: '16px',
            backgroundColor: '#f8f9ff',
            overflow: 'hidden',
            boxShadow: '0 10px 40px rgba(0, 0, 0, 0.15)'
          }
        }}
      >
        {(loading || showSuccess) && (
          <Box
            sx={{
              position: 'absolute',
              top: 0,
              left: 0,
              width: '100%',
              height: '100%',
              background: 'rgba(245, 247, 255, 0.92)',
              backdropFilter: 'blur(8px)',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
              zIndex: 9999,
              borderRadius: '16px',
              gap: 3,
              transition: 'all 0.3s ease'
            }}
          >
            {loading ? (
              <>
                <ModernLoader 
                  dotColor="linear-gradient(135deg, #7c4fe0 0%, #5f82ff 100%)"
                  barColor="linear-gradient(90deg, #7c4fe0, #5f82ff, #7c4fe0)"
                  barBgColor="rgba(124, 79, 224, 0.2)"
                />
                <Typography 
                  variant="body1" 
                  sx={{ 
                    mt: 2, 
                    fontWeight: 600,
                    fontSize: '1.1rem',
                    color: '#5f82ff',
                    letterSpacing: '0.4px',
                    animation: 'fadeInOut 2s infinite',
                    '@keyframes fadeInOut': {
                      '0%': { opacity: 0.7 },
                      '50%': { opacity: 1 },
                      '100%': { opacity: 0.7 }
                    }
                  }}
                >
                  Sending your message...
                </Typography>
              </>
            ) : (
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  justifyContent: 'center',
                  textAlign: 'center',
                  maxWidth: '80%',
                  animation: 'scaleUp 0.5s ease-out',
                  '@keyframes scaleUp': {
                    '0%': { transform: 'scale(0.8)', opacity: 0 },
                    '100%': { transform: 'scale(1)', opacity: 1 }
                  }
                }}
              >
                <Box 
                  sx={{
                    width: 80,
                    height: 80,
                    borderRadius: '50%',
                    backgroundColor: 'rgba(124, 79, 224, 0.1)',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    mb: 3
                  }}
                >
                  <Box
                    sx={{
                      width: 40,
                      height: 40,
                      borderRadius: '50%',
                      backgroundColor: '#53d86a',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      '&::after': {
                        content: '""',
                        display: 'block',
                        width: 15,
                        height: 8,
                        borderBottom: '3px solid white',
                        borderLeft: '3px solid white',
                        transform: 'rotate(-45deg) translate(1px, -2px)'
                      }
                    }}
                  />
                </Box>
                <Typography
                  variant="h5"
                  sx={{
                    fontWeight: 700,
                    color: '#7c4fe0',
                    mb: 2
                  }}
                >
                  Message Sent Successfully!
                </Typography>
                <Typography
                  variant="body1"
                  sx={{
                    color: '#4a4a4a',
                    mb: 3,
                    maxWidth: '400px'
                  }}
                >
                  Thank you for reaching out to us. We'll get back to you within 24-48 hours.
                </Typography>
                <Box
                  sx={{
                    width: '100%',
                    height: '4px',
                    backgroundColor: 'rgba(124, 79, 224, 0.2)',
                    borderRadius: '2px',
                    position: 'relative',
                    overflow: 'hidden',
                    mt: 1
                  }}
                >
                  <Box
                    sx={{
                      position: 'absolute',
                      top: 0,
                      left: 0,
                      height: '100%',
                      width: '100%',
                      backgroundColor: '#7c4fe0',
                      animation: 'progress 3s linear',
                      '@keyframes progress': {
                        '0%': { width: '100%' },
                        '100%': { width: '0%' }
                      }
                    }}
                  />
                </Box>
                <Typography
                  variant="caption"
                  sx={{ 
                    mt: 1,
                    color: 'rgba(74, 74, 74, 0.7)'
                  }}
                >
                  This window will close automatically in a few seconds
                </Typography>
              </Box>
            )}
          </Box>
        )}
        
        <DialogTitle
          sx={{
            background: 'linear-gradient(135deg, #7c4fe0 0%, #5f82ff 100%)',
            color: 'white',
            fontWeight: 600,
            p: 3,
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            position: 'relative',
            overflow: 'visible',
            '&::before': {
              content: '""',
              position: 'absolute',
              top: '-50%',
              right: '-50%',
              width: '100%',
              height: '200%',
              background: 'radial-gradient(circle, rgba(255,255,255,0.1) 0%, rgba(255,255,255,0) 70%)',
              transform: 'rotate(30deg)',
              pointerEvents: 'none',
              zIndex: 1,
            },
            '&::after': {
              content: '""',
              position: 'absolute',
              bottom: '-20px',
              right: '-20px',
              width: '120px',
              height: '120px',
              borderRadius: '40%',
              background: 'rgba(255, 255, 255, 0.05)',
              filter: 'blur(5px)',
              animation: 'floatAnimation 8s infinite ease-in-out',
              pointerEvents: 'none',
              zIndex: 1,
            },
            '@keyframes floatAnimation': {
              '0%, 100%': { transform: 'translate(0, 0) rotate(0deg)' },
              '33%': { transform: 'translate(-15px, 15px) rotate(-5deg)' },
              '66%': { transform: 'translate(15px, -5px) rotate(5deg)' },
            }
          }}
        >
          <Typography variant="h5" component="div" sx={{ 
            fontWeight: 700,
            fontSize: '1.5rem',
            letterSpacing: '0.5px',
            textShadow: '0 2px 4px rgba(0,0,0,0.1)',
            display: 'flex',
            alignItems: 'center',
            gap: 1
          }}>
            <Box sx={{
              display: 'inline-flex',
              alignItems: 'center',
              marginRight: 1.5
            }}>
              <Box 
                component="span" 
                sx={{
                  display: 'inline-block',
                  width: '30px',
                  height: '30px',
                  borderRadius: '50%',
                  background: 'rgba(255, 255, 255, 0.2)',
                  boxShadow: 'inset 0 0 0 2px rgba(255, 255, 255, 0.6)',
                  position: 'relative',
                  '&::before': {
                    content: '""',
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    width: '12px',
                    height: '12px',
                    borderRadius: '50%',
                    backgroundColor: 'white',
                  }
                }}
              />
            </Box>
            Let's Talk
          </Typography>
          <IconButton
            edge="end"
            color="inherit"
            onClick={handleCloseAttempt}
            aria-label="close"
            sx={{
              backgroundColor: 'rgba(255,255,255,0.1)',
              '&:hover': {
                backgroundColor: 'rgba(255,255,255,0.2)',
              },
              transition: 'all 0.2s ease',
              zIndex: 100
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        
        <DialogContent sx={{ p: 4, pt: 2 }}>
          <Typography variant="body1" sx={{ 
             mb: 4,
             mt: 4,
            fontSize: '1rem',
            lineHeight: 1.5,
            color: '#4a4a4a',
            position: 'relative',
            pl: 2
          }}>
            <Box sx={{
              position: 'absolute',
              left: 0,
              top: 0,
              bottom: 0,
              width: '3px',
              borderRadius: '4px',
              background: 'linear-gradient(to bottom, #7c4fe0, #5f82ff)',
            }} />
            Tell us what you need, and we'll get back to you within 24-48 hours.
          </Typography>
          
          <Box 
            component="form" 
            onSubmit={handleSubmit}
            noValidate
            sx={{ 
              display: 'flex', 
              flexDirection: 'column', 
              gap: 3.5,
              '& .MuiTextField-root, & .MuiFormControl-root': {
                transition: 'transform 0.2s ease',
                '&:hover': {
                  transform: 'translateY(-2px)'
                }
              }
            }}
          >
            <TextField
              required
              fullWidth
              label="Full Name"
              name="fullName"
              value={formData.fullName}
              onChange={handleChange}
              error={!!formErrors.fullName}
              helperText={formErrors.fullName}
              sx={{
                '& .MuiOutlinedInput-root': {
                  borderRadius: '12px',
                  transition: 'all 0.2s ease',
                  '&:hover fieldset': {
                    borderColor: '#7281ff',
                  },
                  '&.Mui-focused fieldset': {
                    borderColor: '#7281ff',
                    borderWidth: '2px',
                  },
                  '&.Mui-focused': {
                    boxShadow: '0 0 0 3px rgba(114, 129, 255, 0.1)',
                  },
                },
              }}
              InputLabelProps={{
                sx: {
                  '&.Mui-focused': {
                    color: '#7281ff',
                  },
                },
              }}
            />
            
            <TextField
              required
              fullWidth
              label="Email Address"
              name="email"
              type="email"
              value={formData.email}
              onChange={handleChange}
              error={!!formErrors.email}
              helperText={formErrors.email}
              sx={{
                '& .MuiOutlinedInput-root': {
                  borderRadius: '12px',
                  '&:hover fieldset': {
                    borderColor: '#7281ff',
                  },
                  '&.Mui-focused fieldset': {
                    borderColor: '#7281ff',
                    borderWidth: '2px',
                  },
                  '&.Mui-focused': {
                    boxShadow: '0 0 0 3px rgba(114, 129, 255, 0.1)',
                  },
                },
              }}
              InputLabelProps={{
                sx: {
                  '&.Mui-focused': {
                    color: '#7281ff',
                  },
                },
              }}
            />
            
            <FormControl
              required
              fullWidth
              error={!!formErrors.subject}
              sx={{
                '& .MuiOutlinedInput-root': {
                  borderRadius: '12px',
                  '&:hover fieldset': {
                    borderColor: '#7281ff',
                  },
                  '&.Mui-focused fieldset': {
                    borderColor: '#7281ff',
                    borderWidth: '2px',
                  },
                  '&.Mui-focused': {
                    boxShadow: '0 0 0 3px rgba(114, 129, 255, 0.1)',
                  },
                },
              }}
            >
              <InputLabel sx={{ '&.Mui-focused': { color: '#7281ff' } }}>
                I'm interested in
              </InputLabel>
              <Select
                name="subject"
                value={formData.subject}
                onChange={handleChange}
                label="I'm interested in"
                MenuProps={{
                  PaperProps: {
                    sx: {
                      borderRadius: '12px',
                      overflow: 'hidden',
                      mt: 1,
                      boxShadow: '0 10px 25px rgba(114, 129, 255, 0.15)',
                      '& .MuiMenuItem-root': {
                        transition: 'all 0.2s ease',
                        '&:hover': {
                          backgroundColor: 'rgba(114, 129, 255, 0.08)',
                        }
                      },
                      '& .MuiMenuItem-root.Mui-selected': {
                        backgroundColor: 'rgba(114, 129, 255, 0.15)',
                        '&:hover': {
                          backgroundColor: 'rgba(114, 129, 255, 0.2)',
                        }
                      },
                    },
                  },
                }}
              >
                <MenuItem value="AI Solutions">AI Solutions</MenuItem>
                <MenuItem value="Web Solutions">Web Solutions</MenuItem>
                <MenuItem value="Other">Other</MenuItem>
              </Select>
              {formErrors.subject && (
                <Typography color="error" variant="caption" sx={{ mt: 0.5, ml: 1.5 }}>
                  {formErrors.subject}
                </Typography>
              )}
            </FormControl>
            
            <TextField
              fullWidth
              label="Your Message (Optional)"
              name="projectGoals"
              multiline
              rows={3}
              value={formData.projectGoals}
              onChange={handleChange}
              placeholder="Tell us briefly about your project or question"
              sx={{
                '& .MuiOutlinedInput-root': {
                  borderRadius: '12px',
                  '&:hover fieldset': {
                    borderColor: '#7281ff',
                  },
                  '&.Mui-focused fieldset': {
                    borderColor: '#7281ff',
                    borderWidth: '2px',
                  },
                  '&.Mui-focused': {
                    boxShadow: '0 0 0 3px rgba(114, 129, 255, 0.1)',
                  },
                },
              }}
              InputLabelProps={{
                sx: {
                  '&.Mui-focused': {
                    color: '#7281ff',
                  },
                },
              }}
            />
            
            {formErrors.submit && (
              <Box 
                sx={{
                  backgroundColor: 'rgba(211, 47, 47, 0.04)',
                  borderLeft: '4px solid #d32f2f',
                  padding: 2,
                  borderRadius: '0 8px 8px 0'
                }}
              >
                <Typography color="error" variant="body2">
                  {formErrors.submit}
                </Typography>
              </Box>
            )}
            
            {/* Hidden submit button to capture Enter key press */}
            <Button type="submit" sx={{ display: 'none' }} />
          </Box>
        </DialogContent>
        
        <DialogActions sx={{ p: 4, pt: 2, justifyContent: 'center', gap: 2 }}>
          <Button
            onClick={handleClear}
            sx={{
              borderRadius: '12px',
              px: 4,
              py: 1.2,
              textTransform: 'none',
              fontWeight: 600,
              color: '#5f82ff',
              border: '1px solid rgba(95, 130, 255, 0.5)',
              '&:hover': {
                backgroundColor: 'rgba(95, 130, 255, 0.05)',
                border: '1px solid #5f82ff',
                transform: 'translateY(-2px)',
                transition: 'all 0.2s ease'
              },
            }}
            disabled={loading}
          >
            Clear
          </Button>
          <Button
            onClick={handleSubmit}
            className="primary-button"
            sx={{
              borderRadius: '12px',
              px: 4,
              py: 1.2,
            }}
            disabled={loading}
          >
            Submit
          </Button>
        </DialogActions>
      </Dialog>
      
      {/* Confirmation dialog */}
      <ConfirmCloseDialog />
    </>
  );
};

export default ContactDialog;