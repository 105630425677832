import React from 'react';
import { Box, Typography } from '@mui/material';

// Main section heading component with consistent styling
export const SectionHeading = ({ 
  title, 
  subtitle, 
  centered = false, 
  light = false, 
  gradient = false,
  afterWidth = 80,
  marginBottom = 6,
  label,
  ...props 
}) => {
  const textColor = light ? 'white' : '#23234c';
  const subTextColor = light ? 'rgba(255, 255, 255, 0.8)' : '#666';
  const accentColor = light ? '#00ff91' : '#6056ff';
  const gradientStart = light ? '#00ff91' : '#6056ff';
  const gradientEnd = light ? '#6c63ff' : '#943bff';
  
  return (
    <Box 
      sx={{ 
        textAlign: centered ? 'center' : 'left',
        mb: marginBottom,
        width: '100%'
      }}
      {...props}
    >
      {/* Optional label with dot indicator */}
      {label && (
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            background: light ? 'rgba(255, 255, 255, 0.1)' : 'rgba(96, 86, 255, 0.1)',
            backdropFilter: 'blur(8px)',
            py: 1,
            px: 3,
            borderRadius: '50px',
            mb: 4,
            mx: centered ? 'auto' : 0,
            justifyContent: centered ? 'center' : 'flex-start',
            width: 'fit-content',
          }}
        >
          <Box
            sx={{
              width: '8px',
              height: '8px',
              borderRadius: '50%',
              background: accentColor,
              mr: 1.5,
            }}
          />
          <Typography
            variant="subtitle2"
            sx={{
              color: textColor,
              fontWeight: 600,
              letterSpacing: '1px',
              fontSize: '0.875rem',
            }}
          >
            {label}
          </Typography>
        </Box>
      )}

      {/* Main heading */}
      <Typography
        variant="h2"
        align={centered ? "center" : "left"}
        sx={{
          color: textColor,
          fontWeight: 800,
          position: 'relative',
          mb: subtitle ? 2 : 0,
          fontSize: { xs: '2rem', sm: '2.5rem', md: '3rem' },
          textShadow: light ? '0 0 15px rgba(108, 99, 255, 0.4)' : 'none',
          ...(gradient && {
            background: `linear-gradient(90deg, ${gradientStart} 0%, ${gradientEnd} 100%)`,
            WebkitBackgroundClip: 'text',
            WebkitTextFillColor: 'transparent',
          }),
          '&::after': {
            content: '""',
            position: 'absolute',
            bottom: subtitle ? '-12px' : '-15px',
            left: centered ? '50%' : '0',
            transform: centered ? 'translateX(-50%)' : 'none',
            width: { xs: `${afterWidth * 0.75}px`, sm: `${afterWidth}px` },
            height: '4px',
            background: `linear-gradient(90deg, ${gradientStart}, ${gradientEnd})`,
            borderRadius: '2px',
          }
        }}
      >
        {title}
      </Typography>

      {/* Optional subtitle */}
      {subtitle && (
        <Typography
          variant="h6"
          align={centered ? "center" : "left"}
          sx={{
            color: subTextColor,
            fontWeight: 400,
            mt: 3,
            mb: 0,
            maxWidth: centered ? '700px' : '100%',
            mx: centered ? 'auto' : 0,
            fontSize: { xs: '1rem', md: '1.1rem' },
            lineHeight: 1.6,
          }}
        >
          {subtitle}
        </Typography>
      )}
    </Box>
  );
};

// Convenience components
export const DarkSectionHeading = (props) => (
  <SectionHeading light {...props} />
);

export const GradientSectionHeading = (props) => (
  <SectionHeading gradient {...props} />
);