import AutoAwesomeIcon from "@mui/icons-material/AutoAwesome";
import {
  Box,
  Button,
  Container,
  Grid,
  Typography,
  useMediaQuery,
  useTheme
} from "@mui/material";
import React, { useState } from 'react';
import {
  RouteToAboutUs
} from "../../CodingRipplerWidgets";
import ContactDialog, { trackAndOpenDialog } from "../ContactUs/ContactUsDialog";



export const FooterCTA = ({ pageContent }) => {

    const [open, setOpen] = useState(false);
    
    const handleContactClick = () => trackAndOpenDialog(setOpen);
    
    const handleClose = (success) => {
      setOpen(false);
      if (success) {
        // Handle successful submission (e.g. show a success toast)
      }
    };  
  
  const theme = useTheme();

  return (

    <>
    <ContactDialog open={open} onClose={handleClose} />
    <Box
      sx={{
        position: 'relative',
        overflow: 'hidden',
        py: { xs: 8, md: 12 },
        background: 'linear-gradient(180deg, rgba(240,242,255,0.6) 0%, rgba(255,255,255,1) 100%)',
      }}
      id="footer-cta-section"
    >
      {/* Decorative Background Elements */}
      <Box 
        sx={{ 
          position: 'absolute',
          top: 0,
          left: 0,
          width: '100%',
          height: '100%',
          opacity: 0.4,
          zIndex: 0,
          overflow: 'hidden',
          pointerEvents: 'none',
        }}
      >
        {/* Grid pattern */}
        <Box
          sx={{
            position: 'absolute',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            backgroundImage: 'radial-gradient(rgba(99, 102, 241, 0.1) 1px, transparent 1px)',
            backgroundSize: '30px 30px',
          }}
        />
        
        {/* Abstract shapes */}
        <Box
          sx={{
            position: 'absolute',
            top: '10%',
            right: '5%',
            width: '300px',
            height: '300px',
            borderRadius: '38% 62% 63% 37% / 41% 44% 56% 59%',
            background: 'linear-gradient(45deg, rgba(99, 102, 241, 0.1), rgba(139, 92, 246, 0.1))',
            transform: 'rotate(-10deg)',
            animation: 'float 12s ease-in-out infinite',
            '@keyframes float': {
              '0%': { transform: 'rotate(-10deg) translate(0px, 0px)' },
              '50%': { transform: 'rotate(-5deg) translate(15px, 15px)' },
              '100%': { transform: 'rotate(-10deg) translate(0px, 0px)' },
            }
          }}
        />
        
        <Box
          sx={{
            position: 'absolute',
            bottom: '15%',
            left: '10%',
            width: '250px',
            height: '250px',
            borderRadius: '58% 42% 33% 67% / 63% 30% 70% 37%',
            background: 'linear-gradient(135deg, rgba(99, 102, 241, 0.08), rgba(59, 130, 246, 0.08))',
            transform: 'rotate(15deg)',
            animation: 'float2 15s ease-in-out infinite',
            '@keyframes float2': {
              '0%': { transform: 'rotate(15deg) translate(0px, 0px)' },
              '50%': { transform: 'rotate(10deg) translate(-15px, 10px)' },
              '100%': { transform: 'rotate(15deg) translate(0px, 0px)' },
            }
          }}
        />
      </Box>
      
      <Container maxWidth="md" sx={{ position: 'relative', zIndex: 2 }}>
        <Grid container spacing={4} justifyContent="center" alignItems="center">
          <Grid item xs={12} textAlign="center">
            <Typography 
              variant={useMediaQuery(theme.breakpoints.down('md')) ? "h3" : "h2"} 
              component="h1"
              sx={{
                fontWeight: 800,
                mb: 2,
                '& .highlight': {
                  color: 'primary.main',
                  position: 'relative',
                  display: 'inline-block',
                  zIndex: 1,
                },
              }}
            >
              Get started with <span className="highlight">Coding Rippler</span> today
            </Typography>
            
            <Box
              sx={{
                background: theme.palette.primary.light,
                opacity: 0.1,
                width: '100%',
                height: '10px',
                borderRadius: '5px',
                mt: -7,
                mb: 7,
                maxWidth: '660px',
                mx: 'auto',
                position: 'relative',
                zIndex: 0
              }}
            />

            <Typography
              variant="h6"
              component="p"
              sx={{
                color: 'text.secondary',
                mb: 5,
                maxWidth: '700px',
                mx: 'auto',
                fontWeight: 'normal',
              }}
            >
              {pageContent?.subHeader || "Transform your ideas into reality with our expert development team"}
            </Typography>
            
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'center',
                flexDirection: { xs: 'column', sm: 'row' },
                gap: 2,
                mb: 6,
              }}
            >
              <Button
                variant="contained"
                size="large"
                className="theme-specific-bg-button"
                onClick={RouteToAboutUs}
                sx={{
                  borderRadius: '30px',
                  py: 1.5,
                  px: 4,
                  fontWeight: 600,
                  textTransform: 'none',
                  fontSize: '1rem',
                  boxShadow: '0 6px 15px rgba(99, 102, 241, 0.4)',
                  minWidth: { xs: '100%', sm: '180px' },
                  background: 'linear-gradient(90deg, #6366F1 0%, #8B5CF6 100%)',
                  '&:hover': {
                    boxShadow: '0 8px 25px rgba(99, 102, 241, 0.6)',
                    background: 'linear-gradient(90deg, #5661F0 0%, #7048F5 100%)',
                  },
                  position: 'relative',
                  overflow: 'hidden',
                  transition: 'all 0.3s ease',
                  '&::after': {
                    content: '""',
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    width: '100%',
                    height: '100%',
                    background: 'rgba(255, 255, 255, 0.1)',
                    transform: 'translateX(-100%)',
                    transition: 'transform 0.3s ease',
                  },
                  '&:hover::after': {
                    transform: 'translateX(0)',
                  }
                }}
                startIcon={<AutoAwesomeIcon />}
              >
                OUR STORY
              </Button>

              <Button
                variant="outlined"
                size="large"
                className="theme-specific-button"
                onClick={handleContactClick}
                sx={{
                  borderRadius: '30px',
                  py: 1.5,
                  px: 4,
                  fontWeight: 600,
                  textTransform: 'none',
                  fontSize: '1rem',
                  minWidth: { xs: '100%', sm: '180px' },
                  borderColor: theme.palette.primary.main,
                  borderWidth: '2px',
                  '&:hover': {
                    borderWidth: '2px',
                    backgroundColor: 'rgba(99, 102, 241, 0.04)',
                  }
                }}
              >
                LET'S TALK
              </Button>
            </Box>

            <Box
              sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                gap: 1.5,
                backgroundColor: 'rgba(99, 102, 241, 0.06)',
                borderRadius: '50px',
                py: 1.5,
                px: 3,
                width: 'fit-content',
                mx: 'auto',
                border: '1px dashed rgba(99, 102, 241, 0.3)',
                position: 'relative',
                overflow: 'hidden',
                '&::before': {
                  content: '""',
                  position: 'absolute',
                  top: 0,
                  left: '-100%',
                  width: '100%',
                  height: '100%',
                  background: 'linear-gradient(90deg, transparent, rgba(255,255,255,0.4), transparent)',
                  animation: 'shine 3s infinite',
                },
                '@keyframes shine': {
                  '0%': { left: '-100%' },
                  '100%': { left: '100%' }
                }
              }}
            >
              <AutoAwesomeIcon 
                sx={{ 
                  color: theme.palette.primary.main,
                  animation: 'sparkle 2s infinite alternate',
                  '@keyframes sparkle': {
                    '0%': { opacity: 0.6, transform: 'scale(0.9)' },
                    '100%': { opacity: 1, transform: 'scale(1.1)' }
                  }
                }} 
              />
              <Typography 
                variant="body1" 
                sx={{ 
                  fontWeight: 600,
                  color: theme.palette.primary.main
                }}
              >
                Start your project today!
              </Typography>
            </Box>
          </Grid>
        </Grid>
      </Container>
    </Box>

    </>
  );
};

export default FooterCTA;