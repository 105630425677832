import {
  Box,
  Fab
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { RouteToContactUs } from "../../../CodingRippler/CodingRipplerHome/ContactUs/ContactUs";
import ContactDialog, { trackAndOpenDialog } from "../../../CodingRippler/CodingRipplerHome/ContactUs/ContactUsDialog";
import { useState } from "react";

// Read the environment variable
const taskPlannerDemoUrl = process.env.REACT_APP_TASK_PLANNER_DEMO_URL;

export const Demo = (children, ...props) => {
  return (
    <Box display="flex" justifyContent="center">
      <Fab
        variant="extended"
        size="large"
        className="button"
        p={10}
        pointerEvents="none"
        color="primary"
        onClick={RouteToContactUs}
        sx={{ zIndex: 1 }}
      >
        Book a Demo
      </Fab>
    </Box>
  );
};

export const TryForFree = (children, ...props) => {
  
  const handleTryForFree = () => {
    // setOpen(true);
//    window.location.href = taskPlannerDemoUrl;
  window.open(taskPlannerDemoUrl, '_blank', 'noopener,noreferrer');

  };

  // const [open, setOpen] = useState(false);


  return (
    <Box display="flex" justifyContent="center">
      <Fab
        variant="extended"
        size="large"
        className="button"
        p={10}
        pointerEvents="none"
        color="primary"
        onClick={handleTryForFree}
        sx={{ zIndex: 1 }}
      >
        Try for Free
      </Fab>

      {/* <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="coming-soon-title"
        aria-describedby="coming-soon-description"
        sx={{
          "& .MuiDialog-paper": {
            borderRadius: "16px", // Curved borders
            boxShadow: "0px 8px 20px rgba(0, 0, 0, 0.2)", // Soft shadow
            padding: "20px",
            textAlign: "center",
          },
        }}
      >
        <DialogTitle id="coming-soon-title">Coming Soon!</DialogTitle>
        <DialogContent>
          <DialogContentText id="coming-soon-description">
            We’re preparing something special. <br />
            Try for Free - Coming Soon!
          </DialogContentText>
        </DialogContent>
        <DialogActions sx={{ justifyContent: "center" }}>
          <Button onClick={handleClose} className="button" variant="contained">
            Close
          </Button>
        </DialogActions>
      </Dialog> */}
    </Box>
  );
};

export const Documentation = (children, ...props) => {
  const handleDocumentationClick = () => {
    window.location.href = `/ai`;
  };

  return (
    <Box display="flex" justifyContent="center">
      <Fab
        variant="extended"
        size="large"
        className="button"
        p={10}
        pointerEvents="none"
        color="primary"
        onClick={handleDocumentationClick}
        sx={{ zIndex: 1 }}
      >
        Documentation
      </Fab>
    </Box>
  );
};

export const ScheduleACall = ({ children, ...props }) => {
  const theme = useTheme();

  const handleScheduleACallClick = () => {
    window.location.href = `/ai`;
  };

  return (
    <Box display="flex" justifyContent="center">
      <Fab
        variant="extended"
        size="large"
        p={10}
        pointerEvents="none"
        sx={{ backgroundColor: theme.palette.background.default, zIndex: 1 }}
        onClick={handleScheduleACallClick}
      >
        Schedule a Call
      </Fab>
    </Box>
  );
};

export const RequestDemo = (children, ...props) => {
  const theme = useTheme();

    const [open, setOpen] = useState(false);
        
    const handleContactClick = () => trackAndOpenDialog(setOpen);
  
    const handleClose = (success) => {
      setOpen(false);
      if (success) {
        // Handle successful submission (e.g. show a success toast)
      }
    };  
  
  return (
    <>

      <ContactDialog open={open} onClose={handleClose} />
      <Box display="flex" justifyContent="center">
        <Fab
          variant="extended"
          size="large"
          p={10}
          pointerEvents="none"
          sx={{ backgroundColor: theme.palette.background.default, zIndex: 1 }}
          onClick={handleContactClick}
        >
          Request Demo
        </Fab>
      </Box>
    </>
  );
};
