import {
  Box,
  Container,
  Typography
} from '@mui/material';
import { styled } from '@mui/material/styles';
import React from 'react';
import { RecognizedBrands } from '../CodingRipplerWidgets';
import GetInTouch from './ContactUs/GetInTouch';
import WhatWeOffer from './OurService/WhatWeOfferV3';
import SolutionsShowcasePage from './Testimonial/SolutionsShowcasePage';
import PricingPage from './Pricing/PricingPage';
import WhyWorkWithUs from './OurService/WhyWorkWithUs';
import FooterCTA from './OurService/FooterCTA';
import CarouselWorkflowComponent from '../CRAnimations/AIWorkflowStudioSection'; // Import our new component
import ScrollingQuestions from '../CRAnimations/Offers/ScrollingQuestions';
// import CardLayout from '../CRAnimations/Offers/TestCard';
// Primary color: #6056ff

// Styled components
// const HeroSection = styled(Box)(({ theme }) => ({
//   minHeight: '100vh',
//   display: 'flex',
//   flexDirection: 'column',
//   alignItems: 'center',
//   justifyContent:  'center',
//   position: 'relative',
//   overflow: 'hidden',
//   background: 'linear-gradient(135deg, #f0f2ff 0%, #e6e9ff 50%, #f5f7ff 100%)',
//   padding: theme.spacing(2),
//   '&::before': {
//     content: '""',
//     position: 'absolute',
//     width: '60vw',
//     height: '60vw',
//     borderRadius: '50%',
//     background: 'radial-gradient(circle, rgba(96, 86, 255, 0.15) 0%, rgba(255, 255, 255, 0) 60%)',
//     top: '-20vh',
//     right: '-20vw',
//     zIndex: 0
//   },
//   '&::after': {
//     content: '""',
//     position: 'absolute',
//     width: '50vw',
//     height: '50vw',
//     borderRadius: '50%',
//     background: 'radial-gradient(circle, rgba(96, 86, 255, 0.1) 0%, rgba(255, 255, 255, 0) 70%)',
//     bottom: '-15vh',
//     left: '-15vw',
//     zIndex: 0
//   }
// }));

const HeroSection = styled(Box)(({ theme }) => ({
  minHeight: '90vh', // Reduced from 100vh to prevent excessive whitespace
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  position: 'relative',
  overflow: 'hidden',
  background: 'linear-gradient(135deg, #f0f2ff 0%, #e6e9ff 50%, #f5f7ff 100%)',
  padding: theme.spacing(2),
  marginBottom: 0, // Ensure no bottom margin
  
  // Mobile adjustments
  [theme.breakpoints.down('sm')]: {
    minHeight: '85vh', // Even more compact for mobile
    paddingBottom: 0, // Remove bottom padding
  },
  
  '&::before': {
    content: '""',
    position: 'absolute',
    width: '60vw',
    height: '60vw',
    borderRadius: '50%',
    background: 'radial-gradient(circle, rgba(96, 86, 255, 0.15) 0%, rgba(255, 255, 255, 0) 60%)',
    top: '-20vh',
    right: '-20vw',
    zIndex: 0
  },
  '&::after': {
    content: '""',
    position: 'absolute',
    width: '50vw',
    height: '50vw',
    borderRadius: '50%',
    background: 'radial-gradient(circle, rgba(96, 86, 255, 0.1) 0%, rgba(255, 255, 255, 0) 70%)',
    bottom: '-15vh',
    left: '-15vw',
    zIndex: 0
  }
}));


const ShapeDecoration = styled(Box)(({ theme, position }) => ({
  
  position: 'absolute',
  width: position === 'small' ? '180px' : '350px',
  height: position === 'small' ? '180px' : '350px',
  borderRadius: position === 'small' ? '30px' : '60px',
  background: position === 'small' 
    ? 'linear-gradient(135deg, rgba(96, 86, 255, 0.15) 0%, rgba(96, 86, 255, 0.08) 100%)' 
    : 'linear-gradient(135deg, rgba(96, 86, 255, 0.12) 0%, rgba(96, 86, 255, 0.05) 100%)',
  boxShadow: position === 'small'
    ? '0 10px 30px rgba(96, 86, 255, 0.1)'
    : '0 15px 40px rgba(96, 86, 255, 0.08)',
  transform: `rotate(${position === 'small' ? '45deg' : '-15deg'})`,
  top: position === 'small' ? '15%' : '55%',
  [position === 'small' ? 'right' : 'left']: position === 'small' ? '5%' : '8%',
  zIndex: 0,
  '&::before': {
    content: '""',
    position: 'absolute',
    width: '100%',
    height: '100%',
    borderRadius: 'inherit',
    border: position === 'small' 
      ? '2px solid rgba(96, 86, 255, 0.2)' 
      : '3px solid rgba(96, 86, 255, 0.15)',
    top: position === 'small' ? '-10px' : '-15px',
    left: position === 'small' ? '-10px' : '-15px',
  },
  // Add this to make them gently float
animation: position === 'small' 
? 'float 8s ease-in-out infinite' 
: 'float 12s ease-in-out infinite reverse',
'@keyframes float': {
'0%, 100%': { transform: `rotate(${position === 'small' ? '45deg' : '-15deg'}) translateY(0)` },
'50%': { transform: `rotate(${position === 'small' ? '45deg' : '-15deg'}) translateY(-20px)` }
}


  
}));
;

const brandList = [
  {
    imageList1: [


      {

        image: "/recognization/startupindia.svg",
        backlink: "https://www.startupindia.gov.in/",
        alt: "Startup India",
      },
      {
                  image: "https://assets.goodfirms.co/badges/color-badge/top-software-development-companies.svg",          
                  backlink: "https://www.goodfirms.co/company/codingrippler-tech-private-ltd",
                  alt: "GoodFirms",
                 customHeight: "100px",
                 customHeightPhone: "75px",
        
      },
      {
        image: "/recognization/verifiedAgencyDesignRush.svg",
        backlink: "https://www.designrush.com/",
        alt: "DesignRush",
      },
      {
        image: "/recognization/goodfirm-blue.png",
        backlink: "https://www.goodfirms.co",
        alt: "GoodFirms",

      }
    ],
  },
  {
    imageList2: [],
  },
];



// Main component
const CRHomeV4 = ({ children, ...props }) => {

  const { isPhoneorTab, isPhone, isTab, deviceWithSmallHeight } = props;

  const contactCodingRippler = {
    header: (
      <>
        {" "}
        Get started with{" "}
        <span className="theme-specfic-colored-text">
          Coding Rippler
        </span> today{" "}
      </>
    ),
    subHeader: "Let’s Build Innovative Solutions Tailored to Your Needs",
    buttonText: "View Pages",
  };


  return (
    <>
      <Box sx={{minHeight: '100vh',  overflowX: "hidden" }} id="hero-section">
      <HeroSection>
        <ShapeDecoration position="large" />
        <ShapeDecoration position="small" />

        <Container
          maxWidth="lg"
          sx={{
            position: "relative",
            zIndex: 1,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            textAlign: "center",
            mt: { xs: 0, md: 0 },
          }}
        >
          <Typography
            variant="h1"
            sx={{
              fontWeight: 700,
              color: "#1a1a1a",
              fontSize: { xs: "2rem", sm: "3rem", md: "4rem" },
              lineHeight: 1.2,
              mb: 3,
              maxWidth: "900px",
            }}
          >
      Engineering AI Solutions to Think, Act & Evolve With Your Business Data
</Typography>

          {/* <Typography
            variant="h6"
            sx={{
              color: "rgba(0, 0, 0, 0.7)",
              mb: 5,
              maxWidth: "700px",
              fontSize: { xs: "1.1rem", md: "1.25rem" },
            }}
          >
            Connect. Chat. Discover.
          </Typography> */}

          <GetInTouch />
        </Container>
      </HeroSection>
      
      {/* Spacer between hero and dashboard */}
      <Box sx={{ height: { xs: 20, md: 40 } }} />
        
        <Box sx={{ py: 8, backgroundColor: "#fafafa" }}>
          <Container maxWidth="lg" sx={{ textAlign: "center" }}>
            <Typography
              variant="h5"
              sx={{
                color: "#333",
                fontWeight: 600,
                mb: 5,
              }}
            >
              Recognized by
            </Typography>

            <RecognizedBrands
              isPhone={isPhone}
              isTab={isTab}
              deviceWithSmallHeight={deviceWithSmallHeight}
              pageContent={brandList}
              isPhoneorTab={isPhoneorTab}
            />
          </Container>
        </Box>
      </Box>
      <CarouselWorkflowComponent/>
      <WhatWeOffer />
      <ScrollingQuestions/>
      <SolutionsShowcasePage />
      <PricingPage />
      <WhyWorkWithUs />

      <FooterCTA pageContent={contactCodingRippler} />
    </>
  );
};

export default CRHomeV4;