import { Grid } from "@mui/material";
import React from "react";
import {
  OfferCard,
  ShowBalanceSheet,
  ShowBestSalesMan,
  ShowLatestProduct,
  ShowNumbers,
  ShowNumbersUsingRadialBar,
  ShowSalesOverview,
  ShowYearlyNumbers,
  SliderContainer,
  WishCard,
} from "../../CodingRipplerWidgets";

export default function ECommerceDashboard({ children, ...props }) {
  const data = [
    {
      page1: {
        userName: "John Doe",
        description:
          "Best seller of the month you have done 57.6% more sales today.",
        wish: "Congratulations  🎉",
        backgroundImage: "/codingrippler/dashboards/dashboard_card_bg.jpg",
        welcomeImage: "/codingrippler/dashboards/welcome.webp",
        buttonText: "Go now",
        customStyle: {
          headerTextStyle: {
            textAlign: "left",
            fontSize: {
              xs: "1.5rem",
              sm: "2rem",
              lg: "2rem",
            },
            color: "white",
          },
          subHeaderTextStyle: {
            textAlign: "left",
            fontSize: { xs: "1.25rem", sm: "1.5rem" },
            color: "white",
          },
        },
      },

      buyNow: {
        buttonText: "Buy now",
        customStyle: {
          headerTextStyle: {
            textAlign: "left",
            fontSize: "1.5rem",
            color: "white",
          },
          subHeaderTextStyle: {
            textAlign: "left",
            fontSize: "1.25rem",
            color: "white",
          },
        },
        items: [
          {
            title: "Jumpman MVP",
            offer: "New",
            ratings: 4,
            price: "450",
            currency: "$",
            // image: "/codingrippler/e-commerce/products/item2.png",
            image: "/codingrippler/parallax/nike-shoes-2.jpg",
          },
          {
            title: "Men's Walking Shoes",
            offer: "New",
            ratings: 5,
            price: "600",
            currency: "$",
            // image: "/codingrippler/e-commerce/products/item4.png",
            image: "/codingrippler/parallax/nike-shoes-1.jpg",
          },
          {
            title: "Wireless headphones",
            offer: "New",
            ratings: 5,
            price: "300",
            currency: "$",
            image: "/codingrippler/e-commerce/products/item5.png",
          },
          {
            title: "Wired Headphones",
            offer: "New",
            ratings: 4,
            price: "450",
            currency: "$",
            image: "/codingrippler/e-commerce/products/item2.png",
          },
        ],
      },

      productSoldData: {
        topic: "Product sold",
        total: 745,
        frequency: "last week",
        changeInPercentage: "+2.6%",
        seriesName: "Sales",
        monthlyData: [90, 75, 65, 55, 85, 70, 90, 35, 50, 30, 75, 85],
        yAxisMin: 0,
        yAxisMax: 100,
        monthlyCategories: [
          "Jan",
          "Feb",
          "Mar",
          "Apr",
          "May",
          "Jun",
          "Jul",
          "Aug",
          "Sep",
          "Oct",
          "Nov",
          "Dec",
        ],
        customStyle: {
          headerTextStyle: {
            textAlign: "left",
            fontSize: "1.5rem",
          },
          subHeaderTextStyle: {
            textAlign: "left",
            fontSize: "1.25rem",
          },
          graphColor: ["#00A76F"],
        },
      },

      totalBalanace: {
        topic: "Total balance",
        total: "18,765",
        frequency: "last week",
        changeInPercentage: "-0.1%",
        seriesName: "Balance",
        monthlyData: [
          1885,
          1550,
          1700,
          1665,
          1775,
          1695,
          1780,
          1690,
          1680,
          1675,
          1670,
          1600,
        ],
        yAxisMin: 1500,
        yAxisMax: 1800,
        monthlyCategories: [
          "Jan",
          "Feb",
          "Mar",
          "Apr",
          "May",
          "Jun",
          "Jul",
          "Aug",
          "Sep",
          "Oct",
          "Nov",
          "Dec",
        ],

        customStyle: {
          headerTextStyle: {
            textAlign: "left",
            fontSize: "1.5rem",
          },
          subHeaderTextStyle: {
            textAlign: "left",
            fontSize: "1.25rem",
          },
          graphColor: ["#f9b934"],
        },
      },

      SalesProfit: {
        topic: "Sales profit",
        total: "5540",
        frequency: "last week",
        changeInPercentage: "+0.6%",
        seriesName: "Sales",
        monthlyData: [
          600,
          400,
          200,
          50,
          250,
          400,
          550,
          700,
          790,
          600,
          400,
          600,
        ],
        yAxisMin: 0,
        yAxisMax: 1000,
        monthlyCategories: [
          "Jan",
          "Feb",
          "Mar",
          "Apr",
          "May",
          "Jun",
          "Jul",
          "Aug",
          "Sep",
          "Oct",
          "Nov",
          "Dec",
        ],
        customStyle: {
          headerTextStyle: {
            textAlign: "left",
            fontSize: "1.5rem",
          },
          subHeaderTextStyle: {
            textAlign: "left",
            fontSize: "1.25rem",
          },
          graphColor: ["#FF5733"],
        },
      },

      SalesByGender: {
        topic: "Sale by gender",
        total: "2324",
        frequency: [],
        changeInPercentage: "",
        seriesName: "Sale by gender",
        seriesData: [25, 50, 75],
        labels: ["Mens", "Womens", "Kids"],
        colors: ["#00A76F", "#f9b934", "#FF5733"],
        customStyle: {
          headerTextStyle: {
            textAlign: "left",
            fontSize: "1.5rem",
          },
          subHeaderTextStyle: {
            textAlign: "left",
            fontSize: "1.25rem",
          },
          graphColor: ["#FF5733"],
        },
      },

      IncomeVsExpensesData: {
        topic: "Yearly sales",
        totalIncome: { "2024": "1.23k", "2023": "1.03k" },
        totalExpenses: { "2024": "6.79k", "2023": "3.49k" },
        frequency: "than last year",
        changeInPercentage: { "2024": "+43%", "2023": "+23%" },
        seriesName: ["Total income", "Total expenses"],
        seriesData: {
          "2024": [
            {
              name: "Total income",
              data: [10, 41, 35, 51, 49, 62, 69, 91, 148, 35, 51, 49], // Example data for Series 1
            },
            {
              name: "Total expenses",
              data: [10, 34, 13, 56, 77, 88, 99, 77, 45, 13, 56, 77], // Example data for Series 2
            },
          ],

          "2023": [
            {
              name: "Total income",
              data: [51, 35, 41, 10, 91, 69, 62, 148, 91, 69, 62, 49], // Example data for Series 1
            },
            {
              name: "Total expenses",
              data: [56, 13, 34, 10, 77, 99, 88, 45, 77, 99, 88, 77], // Example data for Series 2
            },
          ],
        },

        monthlyCategories: [
          "Jan",
          "Feb",
          "Mar",
          "Apr",
          "May",
          "Jun",
          "Jul",
          "Aug",
          "Sep",
          "Oct",
          "Nov",
          "Dec",
        ],
        colors: ["#00A76F", "#f9b934"],
        customStyle: {
          headerTextStyle: {
            textAlign: "left",
            fontSize: "1.5rem",
          },
          subHeaderTextStyle: {
            textAlign: "left",
            fontSize: "1.25rem",
          },
          graphColor: ["#00A76F", "#f9b934"],
        },
      },

      SalesOverview: {
        topic: "Sales Overview",
        TotalProfit: {
          header: "Total Profit",
          amount: "8,374",
          currency: "$",
          changeInPercentage: "+10.1%",
          progressValue: 10.1,
          progressColor: "#00A76F",
        },
        TotalIncome: {
          header: "Total Income",
          amount: "9,714",
          currency: "$",
          changeInPercentage: "+13.6%",
          progressValue: 13.6,
          progressColor: "#f9b934",
        },
        TotalExpenses: {
          header: "Total Expenses",
          amount: "6,871",
          currency: "$",
          changeInPercentage: "+28.2%",
          progressValue: 28.2,
          progressColor: "#FF5733",
        },
        customStyle: {
          headerTextStyle: {
            textAlign: "left",
            fontSize: "1.5rem",
          },
          subHeaderTextStyle: {
            textAlign: "left",
            fontSize: "1.25rem",
            color: "rgb(34, 43, 69)",
            fontWeight: "bold",
          },
          subHeaderTextStyle2: {
            textAlign: "left",
            fontSize: "1.25rem",
          },
        },
      },

      BalanceSheet: {
        topic: "Current balance",
        currentBalance: {
          header: "Current Balance",
          amount: "187,650",
          currency: "$",
        },
        orderTotal: {
          header: "Order Total",
          amount: "287,650",
          currency: "$",
        },
        earning: {
          header: "Earning",
          amount: "25,500",
          currency: "$",
        },
        refunded: {
          header: "Refunded",
          amount: "1,600",
          currency: "$",
        },

        customStyle: {
          headerTextStyle: {
            textAlign: "left",
            fontSize: "1.5rem",
          },
          subHeaderTextStyle: {
            textAlign: "left",
            fontSize: "1.25rem",
          },
          subHeaderTextStyle2: {
            textAlign: "left",
            fontSize: "1.25rem",
          },
        },
      },

      salesManData: {
        topic: "Best salesman",
        salesMan: [
          {
            name: "Jayvion Simon",
            sales: {
              amount: "1,450",
              currency: "$",
            },
            product: "CAP",
            country: "Germany",
            countryFlag: "/codingrippler/dashboards/country/germany-flag.png",
            avatar: "/codingrippler/dashboards/salesman/sales-man-1.webp",
            rank: 1,
          },
          {
            name: "Lucian Obrien",
            sales: {
              amount: "97.14",
              currency: "$",
            },
            product: "Branded shoes",
            country: "England",
            countryFlag: "/codingrippler/dashboards/country/england-flag.png",
            avatar: "/codingrippler/dashboards/salesman/sales-man-2.webp",
            rank: 2,
          },
          {
            name: "Deja Brady",
            sales: {
              amount: "67.14",
              currency: "$",
            },
            product: "Headphone",
            country: "France",
            countryFlag: "/codingrippler/dashboards/country/france-flag.png",
            avatar: "/codingrippler/dashboards/salesman/sales-man-3.webp",
            rank: 3,
          },
          {
            name: "Harrison Stein",
            sales: {
              amount: "85.21",
              currency: "$",
            },
            product: "Cell phone",
            country: "South Korea",
            countryFlag: "/codingrippler/dashboards/country/korea-flag.png",
            avatar: "/codingrippler/dashboards/salesman/sales-man-4.webp",
            rank: 4,
          },
          {
            name: "Reece Chung",
            sales: {
              amount: "52.17",
              currency: "$",
            },
            product: "Earings",
            country: "USA",
            countryFlag:
              "/codingrippler/dashboards/country/united-states-flag.png",
            avatar: "/codingrippler/dashboards/salesman/sales-man-5.webp",
            rank: 5,
          },
        ],

        customStyle: {
          headerTextStyle: {
            textAlign: "left",
            fontSize: "1.5rem",
          },
          subHeaderTextStyle: {
            textAlign: "left",
            fontSize: "1.25rem",
          },
        },
      },

      latestProduct: {
        topic: "Latest product",
        items: [
          {
            title: "Jumpman MVP",
            offer: "New",
            ratings: 4,
            price: {
              amount: "190",
              currency: "$",
              isDiscountAvailable: true,
              afterDiscount: "180",
            },
            // image: "/codingrippler/e-commerce/products/item2.png",
            image: "/codingrippler/parallax/nike-shoes-2.jpg",
            liveUsers: 5,
          },
          {
            title: "Men's Walking Shoes",
            offer: "New",
            ratings: 5,
            price: {
              amount: "150",
              currency: "$",
              isDiscountAvailable: false,
              afterDiscount: "",
            },
            // image: "/codingrippler/e-commerce/products/item4.png",
            image: "/codingrippler/parallax/nike-shoes-1.jpg",
            liveUsers: 3,
          },
          {
            title: "Wired Headphones",
            offer: "New",
            ratings: 5,
            price: {
              amount: "300",
              currency: "$",
              isDiscountAvailable: false,
              afterDiscount: "",
            },
            image: "/codingrippler/e-commerce/products/item15.png",
            liveUsers: 2,
          },
          {
            title: "Bridal Earrings",
            offer: "New",
            ratings: 4,
            price: {
              amount: "150",
              currency: "$",
              isDiscountAvailable: false,
              afterDiscount: "",
            },
            image: "/codingrippler/e-commerce/products/item16.png",
            liveUsers: 4,
          },
          {
            title: "iPhone 16 Pro Max",
            offer: "New",
            ratings: 4,
            price: {
              amount: "1722",
              currency: "$",
              isDiscountAvailable: true,
              afterDiscount: "1522",
            },
            image: "/codingrippler/e-commerce/products/item17.png",
            liveUsers: 4,
          },
        ],
        customStyle: {
          headerTextStyle: {
            textAlign: "left",
            fontSize: "1.5rem",
          },
          subHeaderTextStyle: {
            textAlign: "left",
            fontSize: "1.25rem",
          },
        },
      },
    },
  ];

  const handleGoNow = () => {};

  const handleBuyNow = () => {};

  return (
    <>
      <Grid container spacing={2} overflow="hidden">
        <Grid
          item
          xs={12}
          sx={{
            mt: {
              xs: 5, 
              // xs: `max(10vh, 64px)`,
              // sm: `max(10vh, 80px)`,
              // lg: `max(10vh, 80px)`,
            },
          }}
        >
          {/* <Box display="flex" height={dummyContainerHeight} /> */}
          <Grid container>
            <Grid item xs={12} lg={8}>
              <WishCard
                pageContent={data[0].page1}
                buttonHandler={handleGoNow}
              />
            </Grid>

            <Grid item xs={12} lg={4} sx={{ maxWidth: "100%" }}>
              <SliderContainer sliderButtonColors="theme-white-background-color">
                {data[0].buyNow.items.map((item, index) => (
                  <OfferCard
                    pageContent={data[0].buyNow}
                    itemToDisplay={item}
                    buttonHandler={handleBuyNow}
                    key={index}
                  />
                ))}
              </SliderContainer>
            </Grid>
          </Grid>
        </Grid>

        <Grid item xs={12}>
          <Grid container spacing={0}>
            <Grid item xs={12} lg={4}>
              <ShowNumbers pageContent={data[0].productSoldData} />
            </Grid>

            <Grid item xs={12} lg={4}>
              <ShowNumbers pageContent={data[0].totalBalanace} />
            </Grid>

            <Grid item xs={12} lg={4}>
              <ShowNumbers pageContent={data[0].SalesProfit} />
            </Grid>
          </Grid>
        </Grid>

        <Grid item xs={12}>
          <Grid container spacing={2}>
            <Grid item xs={12} lg={4}>
              <ShowNumbersUsingRadialBar pageContent={data[0].SalesByGender} />
            </Grid>

            <Grid item xs={12} lg={8}>
              <ShowYearlyNumbers pageContent={data[0].IncomeVsExpensesData} />
            </Grid>
          </Grid>

          <Grid item xs={12}>
            <Grid container spacing={2}>
              <Grid item xs={12} lg={8}>
                <ShowSalesOverview pageContent={data[0].SalesOverview} />
              </Grid>

              <Grid item xs={12} lg={4}>
                <ShowBalanceSheet pageContent={data[0].BalanceSheet} />
              </Grid>
            </Grid>
          </Grid>

          <Grid item xs={12}>
            <Grid container spacing={2}>
              <Grid item xs={12} lg={8}>
                <ShowBestSalesMan pageContent={data[0].salesManData} />
              </Grid>
              <Grid item xs={12} lg={4}>
                {<ShowLatestProduct pageContent={data[0].latestProduct} />}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
}
