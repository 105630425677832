import KeyboardArrowDownOutlinedIcon from "@mui/icons-material/KeyboardArrowDownOutlined";
import KeyboardArrowUpOutlinedIcon from "@mui/icons-material/KeyboardArrowUpOutlined";
import { Card, CardContent, CardMedia, Grid } from "@mui/material";
import { Box } from "@mui/system";
import { CommonContainer } from "../../CodingRippler/CodingRipplerWidgets";
import {
  AICommonBoxContainer,
  AICommonContainer,
  ResponsiveBoxCenteredChildren,
} from "../AIUtil/AIUtils";
import { FAQsCard, FAQsHeader, FAQsSubHeaderHome } from "./FAQ/FAQWidgets";
import {
  FeatureHeaderUseCases,
  FeatureSubHeaderHome,
  FeatureWidgets,
  FeaturesContainer,
} from "./Features/FeaturesWidgets";
import { RequestDemo, TryForFree } from "./HomePageWidgets/Buttons";
import {
  IntroHeaderHome,
  IntroSubHeaderHome,
  IntroSubHeadingButtons,
} from "./HomePageWidgets/IntroWidgets";
import { HorizontalCard } from "./UseCase/UseCaseTabs";
import { IntroHeaderUseCases, UseCaseWidgets } from "./UseCase/UseCaseWidgets";

export const AIHome = ({ children, ...props }) => {
  const { isPhoneOrTab, isPhone, isTab, deviceWithSmallHeight } = props;

  const FaqData = [
    {
      question: "What is AI Rippler?",
      answer:
        "AI Rippler is an AI-powered platform designed to help businesses automate workflows and streamline operations with intelligent agents. It empowers teams to scale efficiently, focusing on growth and productivity without the overhead of additional staffing.",
      icon: [
        <KeyboardArrowDownOutlinedIcon fontSize="large" />,

        <KeyboardArrowUpOutlinedIcon fontSize="large" />,
      ],
      //      icon: <KeyboardArrowDownOutlinedIcon fontSize="large" />,
    },

    {
      question: "What AI Rippler offers?",
      answer:
        "AI Rippler offers an all-in-one AI solution to streamline your business processes. From automated lead nurturing and customer support to insightful data analysis and efficient task management, AI Rippler provides a suite of AI-driven tools designed to improve productivity, personalize interactions, and reduce operational costs. With customizable AI agents, seamless integrations, and intuitive features, AI Rippler empowers your team to focus on growth while the AI handles the rest.",
      icon: [
        <KeyboardArrowDownOutlinedIcon fontSize="large" />,

        <KeyboardArrowUpOutlinedIcon fontSize="large" />,
      ],
      //      icon: <KeyboardArrowDownOutlinedIcon fontSize="large" />,
    },
    {
      question: "How can we contact you?",
      answer:
        "You can reach us at contact@codingrippler.com or by filling out the contact form on our website. We’ll get back to you promptly to discuss your project and explore how we can help. We look forward to connecting with you!",
      icon: [
        <KeyboardArrowDownOutlinedIcon fontSize="large" />,

        <KeyboardArrowUpOutlinedIcon fontSize="large" />,
      ],
    },
  ];

  return (
    <Grid id="AIHome" container spacing={10} mt={2}>
      <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
        <CommonContainer>
          <IntroHeaderHome
            isPhone={isPhone}
            isTab={isTab}
            deviceWithSmallHeight={deviceWithSmallHeight}
            isPhoneOrTab={isPhoneOrTab}
          />
          <Box m={2} />
          <IntroSubHeaderHome
            isPhone={isPhone}
            isTab={isTab}
            deviceWithSmallHeight={deviceWithSmallHeight}
            isPhoneOrTab={isPhoneOrTab}
          />
        </CommonContainer>
      </Grid>

      <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
        <ResponsiveBoxCenteredChildren>
          <TryForFree />
          <Box m={2} />
          <RequestDemo />
        </ResponsiveBoxCenteredChildren>
        <Box m={2} />
        <IntroSubHeadingButtons />
      </Grid>

      <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
        <AICommonContainer>
          <CardMedia
            component="img"
            image="/codingrippler/landings/item15.jpg"
            alt="Description of the image"
            width="100%"
            height="100%"
          />
        </AICommonContainer>
      </Grid>

      {/* Widgets page  */}
      <Grid item xs={12}>
        <UseCaseWidgets />
      </Grid>

      <Grid item xs={12}>
        <CommonContainer>
          <IntroHeaderUseCases />
        </CommonContainer>
      </Grid>

      <Grid item xs={12}>
        <HorizontalCard />
      </Grid>

      {/* Feature page  */}

      <Grid item xs={12}>
        <FeatureWidgets />
      </Grid>

      <Grid item xs={12}>
        <CommonContainer>
          <FeatureHeaderUseCases />
          <Box m={2} />
          <FeatureSubHeaderHome />
        </CommonContainer>
      </Grid>

      <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
        <AICommonBoxContainer>
          <FeaturesContainer />
        </AICommonBoxContainer>
      </Grid>

      <Grid item xs={12}>
        <CommonContainer>
          <FAQsHeader />
          <Box m={2} />
          <FAQsSubHeaderHome />
        </CommonContainer>
      </Grid>

      <Grid item xs={12}>
        <CommonContainer>
          <Grid container>
            <Grid
              item
              md={1}
              xl={3}
              sx={{
                display: {
                  xs: "none",
                  sm: "none",
                  md: "block",
                  lg: "block",
                },
              }}
            ></Grid>
            <Grid item xs={12} md={10} xl={6}>
              <Card
                sx={{ marginBottom: "1rem", borderRadius: "24px", p: 4 }}
                elevation={8}
              >
                <CardContent>
                  {FaqData.map((faq, index) => (
                    <Box key={index} mb={5}>
                      <FAQsCard
                        key={index}
                        question={faq.question}
                        answer={faq.answer}
                        openingIcon={faq.icon[0]}
                        closingIcon={faq.icon[1]}
                        isPhoneOrTab={isPhoneOrTab}
                      />
                    </Box>
                  ))}
                </CardContent>
              </Card>
            </Grid>
            <Grid
              item
              md={1}
              xl={3}
              sx={{
                display: {
                  xs: "none",
                  sm: "none",
                  md: "block",
                  lg: "block",
                },
              }}
            ></Grid>
          </Grid>
        </CommonContainer>
      </Grid>
    </Grid>
  );
};
