// import React from 'react';
// import { 
//   Box, 
//   Typography, 
//   Paper,
//   Grid,
//   ThemeProvider,
//   createTheme,
//   styled
// } from '@mui/material';
// import {
//     FileText as CatchUpIcon,
//     ListTodo as GatherIcon,
//     Calendar as PrepareIcon,
//   } from 'lucide-react';
  
// // Create a custom theme to match the design
// const theme = createTheme({
//   palette: {
//     background: {
//       default: '#f8f8f8',
//       paper: '#ffffff',
//     },
//     text: {
//       primary: '#121212',
//       secondary: '#505050',
//     },
//   },
//   typography: {
//     fontFamily: '"Inter", "Roboto", "Helvetica", "Arial", sans-serif',
//   },
//   shape: {
//     borderRadius: 8,
//   },
// });

// // ThemedActionCard component - this is the new card design
// const ThemedActionCard = ({
//   id,
//   title,
//   description,
//   customIcon,
//   iconBg,
//   gradient,
//   showSchedule = true,
//   hasCopilot = false,
// }) => {
//   return (
//     <Box 
//       sx={{
//         display: 'flex',
//         flexDirection: 'column',
//         height: '100%',
//         borderRadius: 2,
//         overflow: 'hidden',
//         border: '1px solid rgba(0, 0, 0, 0.15)',
//         transition: 'all 0.2s',
//         '&:hover': {
//           boxShadow: '0 4px 12px rgba(0, 0, 0, 0.1)'
//         }
//       }}
//     >
//       <Box 
//         sx={{
//           flex: 1,
//           p: 3,
//           // Convert tailwind gradient to CSS
//           background: `linear-gradient(to bottom right, ${gradient.includes('purple') ? '#E9D5FF, #FAF5FF' : gradient.includes('green') ? '#DCFCE7, #F0FDF4' : '#DBEAFE, #EFF6FF'})`,
//         }}
//       >
//         <Box 
//           sx={{
//             width: 48,
//             height: 48,
//             borderRadius: 2,
//             // Convert tailwind bg color to CSS
//             backgroundColor: iconBg.includes('purple') ? '#E9D5FF' : iconBg.includes('green') ? '#DCFCE7' : '#DBEAFE',
//             display: 'flex',
//             alignItems: 'center',
//             justifyContent: 'center',
//             mb: 2
//           }}
//         >
//           {customIcon}
//         </Box>
        
//         <Box sx={{ fontWeight: 600, fontSize: '1.125rem', mb: 0.75, color: 'text.primary' }}>
//           {title}
//         </Box>
//         <Box sx={{ fontSize: '0.875rem', color: 'text.secondary', mb: 1 }}>
//           {description}
//         </Box>
//       </Box>
      
//       <Box sx={{ px: 3, py: 1.5, bgcolor: 'white', borderTop: '1px solid rgba(0, 0, 0, 0.05)' }}>
//         {showSchedule ? (
//           <Box 
//             sx={{
//               display: 'flex',
//               alignItems: 'center',
//               gap: 0.75,
//               fontSize: '0.875rem',
//               color: 'text.secondary',
//               fontWeight: 500
//             }}
//           >
//             <PrepareIcon size={16} />
//             <Box>Schedule</Box>
//           </Box>
//         ) : (
//           <Box 
//             sx={{
//               display: 'flex',
//               alignItems: 'center',
//               gap: 0.75,
//               fontSize: '0.875rem',
//               color: 'text.secondary'
//             }}
//           >
//             <CatchUpIcon size={16} />
//             <Box>AI Studio will reach out</Box>
//           </Box>
//         )}
//       </Box>
//     </Box>
//   );
// };

// // Define action data - use this for the template cards
// const mainActions = [
//   {
//     id: 'catch-up',
//     title: 'Regularly summarize communications',
//     name: 'Catch Up',
//     type: 'Communication',
//     description: 'Stay on top of your communications with AI-powered summaries',
//     customIcon: <CatchUpIcon className="w-6 h-6 text-purple-600" />,
//     iconBg: 'bg-purple-100',
//     gradient: 'from-purple-100 to-purple-50',
//     showSchedule: true,
//     hasCopilot: false,
//     link: 'catch-up'
//   },
//   {
//     id: 'gather',
//     title: 'Ask people to complete a task by a deadline',
//     name: 'Gather',
//     type: 'Collaboration',
//     description: 'Send reminders and track task completion with AI',
//     customIcon: <GatherIcon className="w-6 h-6 text-green-600" />,
//     iconBg: 'bg-green-100',
//     gradient: 'from-green-100 to-green-50',
//     showSchedule: false,
//     hasCopilot: true,
//     link: 'gather'
//   },
//   {
//     id: 'prepare',
//     title: 'Help me prepare for my day',
//     name: 'Prepare',
//     type: 'Productivity',
//     description: 'Get an AI-generated brief of your day ahead',
//     customIcon: <PrepareIcon className="w-6 h-6 text-blue-600" />,
//     iconBg: 'bg-blue-100',
//     gradient: 'from-blue-100 to-blue-50',
//     showSchedule: true,
//     hasCopilot: false,
//     link: 'prepare'
//   }
// ];

// const TemplateCard = () => {
//   return (
//     <ThemeProvider theme={theme}>
//       <Paper
//         elevation={1}
//         sx={{
//           borderRadius: 2,
//           overflow: 'hidden',
//           boxShadow: '0px 4px 20px rgba(0, 0, 0, 0.08)',
//         }}
//       >
//         <Box sx={{ p: 2 }}>
//           <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2 }}>
//             <Typography variant="h6" sx={{ fontWeight: 600, fontSize: '1rem' }}>
//               Templates (103)
//             </Typography>
//             <Box sx={{ color: '#999', fontSize: '1rem' }}>×</Box>
//           </Box>
          
//           <Box sx={{ display: 'flex', overflowX: 'auto', mb: 2 }}>
//             <Box 
//               sx={{ 
//                 border: '1px solid #e0e0e0', 
//                 borderRadius: 1, 
//                 padding: '3px 12px',
//                 marginRight: 1,
//                 bgcolor: '#000',
//                 color: '#fff',
//               }}
//             >
//               <Typography variant="body2" sx={{ fontSize: '0.75rem' }}>All</Typography>
//             </Box>
//             {['Design', 'Engineering', 'Marketing & Sales', 'Product Management', 'Development'].map((tab) => (
//               <Box 
//                 key={tab} 
//                 sx={{ 
//                   border: '1px solid #e0e0e0', 
//                   borderRadius: 1, 
//                   padding: '3px 12px',
//                   marginRight: 1,
//                   color: 'text.secondary',
//                   whiteSpace: 'nowrap',
//                 }}
//               >
//                 <Typography variant="body2" sx={{ fontSize: '0.75rem' }}>{tab}</Typography>
//               </Box>
//             ))}
//             <Box 
//               sx={{ 
//                 border: '1px solid #e0e0e0', 
//                 borderRadius: 1, 
//                 padding: '3px 12px',
//                 color: 'text.secondary',
//               }}
//             >
//               <Typography variant="body2" sx={{ fontSize: '0.75rem' }}>+ 2 more</Typography>
//             </Box>
//           </Box>

//           <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2 }}>
//             <Box 
//               sx={{ 
//                 border: '1px solid #e0e0e0',
//                 borderRadius: 1,
//                 padding: '4px 8px',
//                 display: 'flex',
//                 alignItems: 'center',
//                 color: '#999',
//                 width: 180,
//               }}
//             >
//               <Typography variant="body2" sx={{ fontSize: '0.75rem', ml: 1 }}>search</Typography>
//             </Box>
            
//             <Box sx={{ display: 'flex', gap: 1 }}>
//               <Box 
//                 sx={{ 
//                   border: '1px solid #e0e0e0',
//                   borderRadius: 1,
//                   padding: '4px 8px',
//                   display: 'flex',
//                   alignItems: 'center',
//                   color: '#555',
//                 }}
//               >
//                 <Typography variant="body2" sx={{ fontSize: '0.75rem' }}>Company ▼</Typography>
//               </Box>
              
//               <Box 
//                 sx={{ 
//                   border: '1px solid #e0e0e0',
//                   borderRadius: 1,
//                   padding: '4px 8px',
//                   display: 'flex',
//                   alignItems: 'center',
//                   color: '#555',
//                 }}
//               >
//                 <Typography variant="body2" sx={{ fontSize: '0.75rem' }}>All apps ▼</Typography>
//               </Box>
              
//               <Box 
//                 sx={{ 
//                   border: '1px solid #e0e0e0',
//                   borderRadius: 1,
//                   padding: '4px 4px',
//                   display: 'flex',
//                   alignItems: 'center',
//                   justifyContent: 'center',
//                   color: '#555',
//                   width: 28,
//                   height: 24,
//                 }}
//               >
//                 <Typography variant="body2" sx={{ fontSize: '0.75rem' }}>≡</Typography>
//               </Box>
//             </Box>
//           </Box>

//           {/* Replace the template items with the new ThemedActionCard components */}
//           <Grid container spacing={2}>
//             {mainActions.map((action) => (
//               <Grid item xs={12} sm={4} key={action.id}>
//                 <ThemedActionCard {...action} />
//               </Grid>
//             ))}
//           </Grid>
//         </Box>
//       </Paper>
//     </ThemeProvider>
//   );
// };

// export default TemplateCard;

import React from 'react';
import { 
  Box, 
  Typography, 
  Paper,
  Grid,
  ThemeProvider,
  createTheme,
  styled,
  useMediaQuery
} from '@mui/material';
import {
    FileText as CatchUpIcon,
    ListTodo as GatherIcon,
    Calendar as PrepareIcon,
  } from 'lucide-react';
  
// Create a custom theme to match the design
const theme = createTheme({
  palette: {
    background: {
      default: '#f8f8f8',
      paper: '#ffffff',
    },
    text: {
      primary: '#121212',
      secondary: '#505050',
    },
  },
  typography: {
    fontFamily: '"Inter", "Roboto", "Helvetica", "Arial", sans-serif',
  },
  shape: {
    borderRadius: 8,
  },
});

// ThemedActionCard component - this is the new card design
const ThemedActionCard = ({
  id,
  title,
  description,
  customIcon,
  iconBg,
  gradient,
  showSchedule = true,
  hasCopilot = false,
}) => {
  return (
    <Box 
      sx={{
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
        borderRadius: 2,
        overflow: 'hidden',
        border: '1px solid rgba(0, 0, 0, 0.15)',
        transition: 'all 0.2s',
      }}
    >
      <Box 
        sx={{
          flex: 1,
          p: 3,
          // Convert tailwind gradient to CSS
          background: `linear-gradient(to bottom right, ${gradient.includes('purple') ? '#E9D5FF, #FAF5FF' : gradient.includes('green') ? '#DCFCE7, #F0FDF4' : '#DBEAFE, #EFF6FF'})`,
        }}
      >
        <Box 
          sx={{
            width: 48,
            height: 48,
            borderRadius: 2,
            // Convert tailwind bg color to CSS
            backgroundColor: iconBg.includes('purple') ? '#E9D5FF' : iconBg.includes('green') ? '#DCFCE7' : '#DBEAFE',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            mb: 2
          }}
        >
          {customIcon}
        </Box>
        
        <Box sx={{ fontWeight: 600, fontSize: '1.125rem', mb: 0.75, color: 'text.primary' }}>
          {title}
        </Box>
        <Box sx={{ fontSize: '0.875rem', color: 'text.secondary', mb: 1 }}>
          {description}
        </Box>
      </Box>
      
      <Box sx={{ px: 3, py: 1.5, bgcolor: 'white', borderTop: '1px solid rgba(0, 0, 0, 0.05)' }}>
        {showSchedule ? (
          <Box 
            sx={{
              display: 'flex',
              alignItems: 'center',
              gap: 0.75,
              fontSize: '0.875rem',
              color: 'text.secondary',
              fontWeight: 500,
              cursor: 'pointer',
              '&:hover': {
                color: 'text.primary'
              }
            }}
          >
            <PrepareIcon size={16} />
            <Box>Schedule</Box>
          </Box>
        ) : (
          <Box 
            sx={{
              display: 'flex',
              alignItems: 'center',
              gap: 0.75,
              fontSize: '0.875rem',
              color: 'text.secondary'
            }}
          >
            <CatchUpIcon size={16} />
            <Box>Catch Up</Box>
          </Box>
        )}
      </Box>
    </Box>
  );
};

// Define action data - use this for the template cards
const mainActions = [
  {
    id: 'catch-up',
    title: 'Regularly summarize communications',
    name: 'Catch Up',
    type: 'Communication',
    description: 'Stay on top of your communications with AI-powered summaries',
    customIcon: <CatchUpIcon className="w-6 h-6 text-purple-600" />,
    iconBg: 'bg-purple-100',
    gradient: 'from-purple-100 to-purple-50',
    showSchedule: true,
    hasCopilot: false,
    link: 'catch-up'
  },
  {
    id: 'gather',
    title: 'Ask people to complete a task by a deadline',
    name: 'Gather',
    type: 'Collaboration',
    description: 'Send reminders and track task completion with AI',
    customIcon: <GatherIcon className="w-6 h-6 text-green-600" />,
    iconBg: 'bg-green-100',
    gradient: 'from-green-100 to-green-50',
    showSchedule: false,
    hasCopilot: true,
    link: 'gather'
  },
  {
    id: 'prepare',
    title: 'Help me prepare for my day',
    name: 'Prepare',
    type: 'Productivity',
    description: 'Get an AI-generated brief of your day ahead',
    customIcon: <PrepareIcon className="w-6 h-6 text-blue-600" />,
    iconBg: 'bg-blue-100',
    gradient: 'from-blue-100 to-blue-50',
    showSchedule: true,
    hasCopilot: false,
    link: 'prepare'
  }
];

const TemplateCard = () => {
  // Add media query to detect mobile screens
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  
  // Categories to display - on mobile, show only "All"
  const categories = isMobile 
    ? [] 
    : ['Design', 'Engineering', 'Marketing & Sales', 'Product Management', 'Development'];

  return (
    <ThemeProvider theme={theme}>
      <Paper
        elevation={1}
        sx={{
          borderRadius: 2,
          overflow: 'hidden',
          boxShadow: '0px 4px 20px rgba(0, 0, 0, 0.08)',
          width: '100%' // Ensure full width on all screens
        }}
      >
        <Box sx={{ p: { xs: 1.5, sm: 2 } }}> {/* Reduced padding on mobile */}
          <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2 }}>
            <Typography variant="h6" sx={{ fontWeight: 600, fontSize: '1rem' }}>
              Templates (103)
            </Typography>
            <Box sx={{ color: '#999', fontSize: '1rem' }}>×</Box>
          </Box>
          
          <Box sx={{ display: 'flex', overflowX: 'auto', mb: 2, width: '100%' }}>
            <Box 
              sx={{ 
                border: '1px solid #e0e0e0', 
                borderRadius: 1, 
                padding: '3px 12px',
                marginRight: 1,
                bgcolor: '#000',
                color: '#fff',
              }}
            >
              <Typography variant="body2" sx={{ fontSize: '0.75rem' }}>All</Typography>
            </Box>
            
            {/* Show additional categories only on non-mobile screens */}
            {!isMobile && categories.map((tab) => (
              <Box 
                key={tab} 
                sx={{ 
                  border: '1px solid #e0e0e0', 
                  borderRadius: 1, 
                  padding: '3px 12px',
                  marginRight: 1,
                  color: 'text.secondary',
                  whiteSpace: 'nowrap',
                }}
              >
                <Typography variant="body2" sx={{ fontSize: '0.75rem' }}>{tab}</Typography>
              </Box>
            ))}
            
            {/* Show "+ 2 more" only on non-mobile screens */}
            {!isMobile && (
              <Box 
                sx={{ 
                  border: '1px solid #e0e0e0', 
                  borderRadius: 1, 
                  padding: '3px 12px',
                  color: 'text.secondary',
                }}
              >
                <Typography variant="body2" sx={{ fontSize: '0.75rem' }}>+ 2 more</Typography>
              </Box>
            )}
          </Box>

          <Box sx={{ 
            display: 'flex', 
            flexDirection: { xs: 'column', sm: 'row' }, // Stack on mobile
            justifyContent: 'space-between', 
            alignItems: { xs: 'stretch', sm: 'center' }, 
            mb: 2,
            gap: { xs: 1, sm: 0 } // Add gap on mobile
          }}>
            <Box 
              sx={{ 
                border: '1px solid #e0e0e0',
                borderRadius: 1,
                padding: '4px 8px',
                display: 'flex',
                alignItems: 'center',
                color: '#999',
                width: { xs: '100%', sm: 180 }, // Full width on mobile
              }}
            >
              <Typography variant="body2" sx={{ fontSize: '0.75rem', ml: 1 }}>search</Typography>
            </Box>
            
            <Box sx={{ 
              display: 'flex', 
              gap: 1,
              width: { xs: '100%', sm: 'auto' }, // Full width on mobile
              justifyContent: { xs: 'space-between', sm: 'flex-end' } // Space between on mobile
            }}>
              <Box 
                sx={{ 
                  border: '1px solid #e0e0e0',
                  borderRadius: 1,
                  padding: '4px 8px',
                  display: 'flex',
                  alignItems: 'center',
                  color: '#555',
                  flexGrow: { xs: 1, sm: 0 } // Grow on mobile
                }}
              >
                <Typography variant="body2" sx={{ fontSize: '0.75rem' }}>Company ▼</Typography>
              </Box>
              
              <Box 
                sx={{ 
                  border: '1px solid #e0e0e0',
                  borderRadius: 1,
                  padding: '4px 8px',
                  display: 'flex',
                  alignItems: 'center',
                  color: '#555',
                  flexGrow: { xs: 1, sm: 0 } // Grow on mobile
                }}
              >
                <Typography variant="body2" sx={{ fontSize: '0.75rem' }}>All apps ▼</Typography>
              </Box>
              
              <Box 
                sx={{ 
                  border: '1px solid #e0e0e0',
                  borderRadius: 1,
                  padding: '4px 4px',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  color: '#555',
                  width: 28,
                  height: 24,
                }}
              >
                <Typography variant="body2" sx={{ fontSize: '0.75rem' }}>≡</Typography>
              </Box>
            </Box>
          </Box>

          {/* Action cards grid - adjust columns for mobile */}
          <Grid container spacing={2}>
            {mainActions.map((action) => (
              <Grid item xs={12} sm={isMobile ? 12 : 4} key={action.id}>
                <ThemedActionCard {...action} />
              </Grid>
            ))}
          </Grid>
        </Box>
      </Paper>
    </ThemeProvider>
  );
};

export default TemplateCard;