import { Route, Routes } from "react-router-dom";
import { OurWebServices } from "./OurWebServices";
import { Parallax } from "../Parallax/Parallax";
import { Basic } from "../Basic/Basic";
import { GridContainer } from "../Grid/GridContainer";
import { Masanory } from "../Masanory/Masanory";
import { ECommerce } from "../ECommerce/ECommerce";
import { Dashboards } from "../../CodingRipplerHome/Dashboards/Dashboards";
import { OurWebPortfolio } from "./OurWebPortfolio";

export const Services = ({ children, ...props }) => {
  const { isPhoneorTab, isPhone, isTab, deviceWithSmallHeight, theme } = props;

  return (
    <>
      <Routes>
        <Route
          path="/*"
          element={
            <OurWebServices
              isPhone={isPhone}
              isTab={isTab}
              isPhoneorTab={isPhoneorTab}
              deviceWithSmallHeight={deviceWithSmallHeight}
              theme={theme}
            />
          }
        />

        <Route
          path="/parallax/*"
          element={
            <Parallax
              isPhone={isPhone}
              isTab={isTab}
              isPhoneorTab={isPhoneorTab}
              deviceWithSmallHeight={deviceWithSmallHeight}
            />
          }
        />

        <Route
          path="/basic/*"
          element={
            <Basic
              isPhone={isPhone}
              isTab={isTab}
              deviceWithSmallHeight={deviceWithSmallHeight}
              isPhoneorTab={isPhoneorTab}
            />
          }
        />

        <Route
          path="/grid/*"
          element={
            <GridContainer
              isPhone={isPhone}
              isTab={isTab}
              deviceWithSmallHeight={deviceWithSmallHeight}
              isPhoneorTab={isPhoneorTab}
            />
          }
        />

        <Route
          path="/masanory/*"
          element={
            <Masanory
              isPhone={isPhone}
              isTab={isTab}
              deviceWithSmallHeight={deviceWithSmallHeight}
              isPhoneorTab={isPhoneorTab}
            />
          }
        />

        <Route
          path="/e-commerce/*"
          element={
            <ECommerce
              isPhone={isPhone}
              isTab={isTab}
              deviceWithSmallHeight={deviceWithSmallHeight}
              isPhoneorTab={isPhoneorTab}
            />
          }
        />

        <Route
          path="/dashboards/*"
          element={
            <Dashboards
              isPhone={isPhone}
              isTab={isTab}
              deviceWithSmallHeight={deviceWithSmallHeight}
              isPhoneorTab={isPhoneorTab}
            />
          }
        />

        <Route
          path="/portfolio"
          element={
            <OurWebPortfolio
              isPhone={isPhone}
              isTab={isTab}
              isPhoneorTab={isPhoneorTab}
              deviceWithSmallHeight={deviceWithSmallHeight}
              theme={theme}
            />
          }
        />
      </Routes>
    </>
  );
};
