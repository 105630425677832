import GroupsIcon from "@mui/icons-material/Groups";
import HandshakeIcon from "@mui/icons-material/Handshake";
import PeopleIcon from "@mui/icons-material/People";
import RocketLaunchIcon from "@mui/icons-material/RocketLaunch";
import TipsAndUpdatesIcon from "@mui/icons-material/TipsAndUpdates";
import {
  Avatar,
  Box,
  Card,
  CardContent,
  Container,
  Divider,
  Grid,
  Typography
} from "@mui/material";
import { useState } from "react";
import { Helmet } from "react-helmet-async";
import { SlideInFromTopEffect } from "../../../BoilarPlateJs/MotionEffects";
import {
  HeroText
} from "../../CodingRipplerWidgets";
import ContactDialog from "../ContactUs/ContactUsDialog";
import FooterCTA from "../OurService/FooterCTA";
import { Services } from "../WhatWeDo/WhatWeDoWidgets";
import AboutUsHero from "./AboutUsHero";
import { DarkSectionHeading, SectionHeading } from "./SectionHeading";
import LightbulbOutlinedIcon from '@mui/icons-material/LightbulbOutlined';
import TrackChangesIcon from '@mui/icons-material/TrackChanges'; 
import StarOutlineIcon from '@mui/icons-material/StarOutline';
import SearchIcon from '@mui/icons-material/Search';
import TrendingUpIcon from '@mui/icons-material/TrendingUp';
import CheckIcon from '@mui/icons-material/Check';


export const AboutHome = ({ children, ...props }) => {
  const pageContent = {
    landingPAge: {
      title: "Driven by Excellence, Built for You",
      description:
        "As tech enthusiasts, we see every challenge as an opportunity to innovate and bring value to the people and businesses we serve.",
    },

    page2: [
      {
        title: "Our Commitment and Ambition",
        description:
          "Committed to innovation, driven to empower, and inspired to create impact",
      },
      {
        title: "Our core value: Quality and Excellence",
        description:
          "Delivering exceptional quality and relentless pursuit of excellence in everything we do",
      },
    ],

    page3: {
      title: "At Coding Rippler, we are dedicated to empowering success for",
      items: [
        {
          title: "Our Customers",
          description:
            "We create tailored solutions to help you grow and succeed",
          icon: <PeopleIcon />,
        },
        {
          title: "Our Team",
          description:
            "We empower our team to innovate, collaborate, and thrive together",
          icon: <GroupsIcon />,
        },
        {
          title: "Our Partners",
          description:
            "We build lasting relationships to drive shared success and innovation",
          icon: <HandshakeIcon />,
        },
      ],
    },

    page4: {
      ourApproach: {
        title: "Success starts with understanding",
        description:
          "We listen first to craft solutions that meet your goals and challenges.",

        introduction: [
          {
            title:
              "At Coding Rippler, we craft tailored solutions to transform challenges into milestones",
            icon: <RocketLaunchIcon />,
          },
          {
            title:
              "With collaboration and innovation, we make your vision a reality",
            icon: <TipsAndUpdatesIcon />,
          },
        ],
        steps: [
          {
            timeLine: "Discovery",
            description:
              "Mapping your processes and ingesting initial training data.",
            icon: "search",
            iconComponent: <SearchIcon />,
          },
          {
            timeLine: "Collaboration & Design",
            description:
              "We work together to craft a custom strategy that aligns with your vision and brings it to life.",
            icon: "handshake",
            iconComponent: <HandshakeIcon />,
          },
          {
            timeLine: "Implementation",
            description:
              "From concept to execution, we bring the plan to life with precision, delivering a seamless solution.",
            icon: "rocket",
            iconComponent: <RocketLaunchIcon />,
          },
          {
            timeLine: "Review & Optimize",
            description:
              "We continuously measure success and make adjustments to ensure maximum impact and growth.",
            icon: "trending_up",
            iconComponent: <TrendingUpIcon />,
          },
        ],
      },
    },

    page5: {
      title: "The Rippler Advantage",

      advantages: [
        {
          title: "Advanced Innovation",
          description:
            "We leverage the latest in AI and web technology to provide forward-thinking solutions that keep you ahead of the competition.",
          hoverColor: "#6056ff",
        },
        {
          title: "Tailored to You",
          description:
            "Every solution we craft aligns with your specific challenges, goals, and vision—because no two journeys are the same.",
          hoverColor: "#0d162f",
        },
        {
          title: "Commitment to Excellence",
          description:
            "With a focus on precision, quality, and continuous improvement, we ensure our solutions deliver lasting value and impact.",
          hoverColor: "#943bff",
        },
      ],
    },

    page6: {
      title: "The Minds Behind Coding Rippler",

      founders: [
        {
          name: "Harsh",
          designation: "Lead Tech Architect & Co-Founder",
          description:
            "Harsh, a Computer Science graduate from IIT and a Microsoft engineer, co-founded CodingRippler to drive AI-powered innovation. His expertise fuels intelligent automation and decision-making systems, helping businesses unlock the full potential of AI.",
          icon: "/codingrippler/ourteam/harsh.jpeg",
        },
        {
          name: "Ankit",
          designation: "Managing Director & Co-Founder",
          description:
            "Ankit, with 5+ years of experience in software automation, drives CodingRippler's strategic vision, leading innovation in AI-driven automation and intelligent systems.",
          icon: "/codingrippler/ourteam/ankit.jpg",
        },
        // {
        //   name: "Aanchal",
        //   designation: "Lead Designer & Co-Founder",
        //   description:
        //     "Aanchal leads the design vision at CodingRippler, blending modern aesthetics with seamless user experiences to craft visually stunning and highly functional digital products.",
        //   icon: "/codingrippler/ourteam/aanchal.jpeg",
        // },
      ],
      customStyle: {
        customHeader: {},
        customHeaderInfo: {
          textAlign: "left",
          fontWeight: "500",
        },
        customDescription: {
          textAlign: "left",
          fontWeight: "normal",
        },

        customSubHeader: {},
      },
    },

    page7: {
      contactCodingRippler: {
        header: (
          <>
            {" "}
            Get started with{" "}
            <span className="theme-specfic-colored-text">
              Coding Rippler
            </span>{" "}
            today{" "}
          </>
        ),
        subHeader: "Let's Build Innovative Solutions Tailored to Your Needs",
        buttonText: "View Pages",
      },
    },
  };

  const [open, setOpen] = useState(false);

  const handleClose = (success) => {
    setOpen(false);
    if (success) {
      // Handle successful submission (e.g. show a success toast)
    }
  };

  return (
    <>
      <Helmet>
        <title>About Us - Coding Rippler</title>
        <meta
          name="description"
          content="Learn more about Coding Rippler, Web Rippler, and AI Rippler, our mission, vision, and the team behind our success."
        />
        <meta property="og:title" content="About Us - Coding Rippler" />
        <meta
          property="og:description"
          content="Learn more about Coding Rippler, Web Rippler, and AI Rippler, our mission, vision, and the team behind our success."
        />
        <meta property="og:url" content="https://www.codingrippler.com/about" />
        <meta property="og:type" content="website" />
        <link rel="canonical" href="https://www.codingrippler.com/about" />
      </Helmet>

      <Grid container className="theme-gradient-background" id = "about-page">
        {/* Hero Section - Enhanced with gradient background */}

        {/* First Page - Landing Section */}

        <AboutUsHero />

        {/* Core Values Section - Enhanced with glass morphism */}
        <Grid item xs={12}>
          <Box
            sx={{
              position: "relative",
              py: 8,
              background:
                "linear-gradient(135deg, #0F0C29 0%, #302b63 50%, #24243e 100%)",
              overflow: "hidden",
              mt: -10, // Create overlap with previous section
              pt: 14, // Add padding to compensate for overlap
            }}
          >
            {/* Animated background elements */}
            <Box
              sx={{
                position: "absolute",
                top: 0,
                left: 0,
                width: "100%",
                height: "100%",
                opacity: 0.1,
                background:
                  'url(\'data:image/svg+xml,%3Csvg width="60" height="60" viewBox="0 0 60 60" xmlns="http://www.w3.org/2000/svg"%3E%3Cpath d="M54.627 0l.83.828-1.415 1.415L51.8 0h2.827zM5.373 0l-.83.828L5.96 2.243 8.2 0H5.374zM48.97 0l3.657 3.657-1.414 1.414L46.143 0h2.828zM11.03 0L7.372 3.657 8.787 5.07 13.857 0H11.03zm32.284 0L49.8 6.485 48.384 7.9l-7.9-7.9h2.83zM16.686 0L10.2 6.485 11.616 7.9l7.9-7.9h-2.83zm20.97 0l9.315 9.314-1.414 1.414L34.828 0h2.83zM22.344 0L13.03 9.314l1.414 1.414L25.172 0h-2.83zM32 0l12.142 12.142-1.414 1.414L30 1.828 17.272 14.556l-1.414-1.414L28 0h4zM.284 0l28 28-1.414 1.414L0 2.544V0h.284zM0 5.373l25.456 25.455-1.414 1.415L0 8.2V5.374zm0 5.656l22.627 22.627-1.414 1.414L0 13.86v-2.83zm0 5.656l19.8 19.8-1.415 1.413L0 19.514v-2.83zm0 5.657l16.97 16.97-1.414 1.415L0 25.172v-2.83zM0 28l14.142 14.142-1.414 1.414L0 30.828V28zm0 5.657L11.314 44.97l-1.414 1.415L0 36.485v-2.83zm0 5.657L8.485 47.8l-1.414 1.414L0 42.142v-2.83zm0 5.657l5.657 5.657-1.414 1.415L0 47.8v-2.83zM0 56.97l2.828 2.83-1.414 1.414L0 58.385v-1.414z" fill="%23ffffff" fill-rule="evenodd"/%3E%3C/svg%3E\')',
              }}
            />

            <Container maxWidth="lg" sx={{ position: "relative", zIndex: 2 }}>
              {/* REPLACED: Typography heading with DarkSectionHeading component */}
              <DarkSectionHeading
                title="Our Core Values"
                centered
                marginBottom={6}
                afterWidth={80}
              />

              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  flexWrap: "wrap",
                  gap: { xs: 6, md: 4 }, // Increased gap on mobile
                }}
              >
                {pageContent.page2.map((content, index) => (
                  <Box
                    key={index}
                    sx={{
                      position: "relative",
                      width: { xs: "280px", md: "320px" }, // Slightly smaller on mobile
                      height: { xs: "280px", md: "320px" },
                      clipPath:
                        "polygon(50% 0%, 100% 25%, 100% 75%, 50% 100%, 0% 75%, 0% 25%)",
                      background: "rgba(20, 20, 50, 0.5)",
                      backdropFilter: "blur(8px)",
                      border: "1px solid rgba(255, 255, 255, 0.1)",
                      transition: "all 0.4s ease",
                      "&:hover": {
                        transform: "scale(1.05)",
                        "& .hex-glow": {
                          opacity: 1,
                        },
                      },
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      padding: { xs: 3, md: 4 },
                      overflow: "hidden",
                    }}
                  >
                    {/* Glow effect */}
                    <Box
                      className="hex-glow"
                      sx={{
                        position: "absolute",
                        top: 0,
                        left: 0,
                        width: "100%",
                        height: "100%",
                        opacity: 0.3,
                        transition: "opacity 0.4s ease",
                        background:
                          index % 2 === 0
                            ? "radial-gradient(circle at center, rgba(0, 255, 145, 0.2) 0%, transparent 70%)"
                            : "radial-gradient(circle at center, rgba(108, 99, 255, 0.2) 0%, transparent 70%)",
                      }}
                    />

                    <Box sx={{ textAlign: "center", zIndex: 1 }}>
                      <Typography
                        variant="h4"
                        sx={{
                          color: "white",
                          mb: 2,
                          fontWeight: 700,
                          fontSize: { xs: "1.8rem", md: "2.2rem" }, // Responsive font size
                          background:
                            index % 2 === 0
                              ? "linear-gradient(90deg, #ffffff, #00ff91)"
                              : "linear-gradient(90deg, #ffffff, #6c63ff)",
                          WebkitBackgroundClip: "text",
                          WebkitTextFillColor: "transparent",
                        }}
                      >
                        {content.title.split(" ").slice(-1)[0]}
                      </Typography>

                      <Typography
                        variant="body1"
                        sx={{
                          color: "rgba(255, 255, 255, 0.9)",
                          fontWeight: 400,
                          fontSize: { xs: "0.9rem", md: "0.95rem" }, // Slightly smaller on mobile
                          lineHeight: 1.7,
                        }}
                      >
                        {content.description}
                      </Typography>
                    </Box>
                  </Box>
                ))}
              </Box>
            </Container>
          </Box>
        </Grid>

        <Grid item xs={12}>
          <SlideInFromTopEffect>
            <Box
              sx={{
                position: "relative",
                py: 10,
                background: "linear-gradient(180deg, #f4f5ff 0%, #ffffff 100%)",
                overflow: "hidden",
              }}
            >
              {/* Background pattern */}
              <Box
                sx={{
                  position: "absolute",
                  top: 0,
                  left: 0,
                  width: "100%",
                  height: "100%",
                  opacity: 0.04,
                  backgroundImage:
                    'url(\'data:image/svg+xml,%3Csvg width="60" height="60" viewBox="0 0 60 60" xmlns="http://www.w3.org/2000/svg"%3E%3Cpath d="M54.627 0l.83.828-1.415 1.415L51.8 0h2.827zM5.373 0l-.83.828L5.96 2.243 8.2 0H5.374zM48.97 0l3.657 3.657-1.414 1.414L46.143 0h2.828zM11.03 0L7.372 3.657 8.787 5.07 13.857 0H11.03z" fill="%236056ff" fill-rule="evenodd"/%3E%3C/svg%3E\')',
                  zIndex: 0,
                }}
              />

              <Container maxWidth="lg" sx={{ position: "relative", zIndex: 1 }}>
                {/* REPLACED: Box with Typography + underline + subtitle components */}
                <SectionHeading
                  title="The Rippler Advantage"
                  subtitle="Our unique approach combines cutting-edge technology with personalized solutions to deliver exceptional results."
                  centered
                  marginBottom={8}
                  afterWidth={80}
                />

                <Grid container spacing={4}>
                  {[
                    {
                      title: "Advanced Innovation",
                      description:
                        "We leverage the latest in AI and web technology to provide forward-thinking solutions that keep you ahead of the competition.",
                      icon: (
                        <LightbulbOutlinedIcon
                          sx={{ fontSize: 32, color: "#6056ff" }}
                        />
                      ),
                    },
                    {
                      title: "Tailored to You",
                      description:
                        "Every solution we craft aligns with your specific challenges, goals, and vision—because no two journeys are the same.",
                      icon: (
                        <TrackChangesIcon
                          sx={{ fontSize: 32, color: "#6056ff" }}
                        />
                      ),
                    },
                    {
                      title: "Commitment to Excellence",
                      description:
                        "With a focus on precision, quality, and continuous improvement, we ensure our solutions deliver lasting value and impact.",
                      icon: (
                        <StarOutlineIcon
                          sx={{ fontSize: 32, color: "#6056ff" }}
                        />
                      ),
                    },
                  ].map((advantage, index) => (
                    <Grid item xs={12} md={4} key={index}>
                      <Box
                        sx={{
                          height: "100%",
                          p: 4,
                          borderRadius: "16px",
                          background: "rgba(255, 255, 255, 0.8)",
                          backdropFilter: "blur(10px)",
                          boxShadow: "0 10px 30px rgba(0, 0, 0, 0.05)",
                          border: "1px solid rgba(240, 240, 255, 0.8)",
                          transition: "all 0.3s ease",
                          position: "relative",
                          overflow: "hidden",
                          "&:hover": {
                            transform: "translateY(-8px)",
                            boxShadow: "0 15px 40px rgba(96, 86, 255, 0.15)",
                            "& .advantage-icon": {
                              transform: "scale(1.1)",
                              background:
                                "linear-gradient(135deg, #6056ff 0%, #943bff 100%)",
                              "& svg": {
                                color: "#ffffff", // Change icon color to white on hover
                              },
                            },
                          },
                        }}
                      >
                        {/* Decorative corner accent */}
                        <Box
                          sx={{
                            position: "absolute",
                            top: 0,
                            right: 0,
                            width: "100px",
                            height: "100px",
                            background:
                              "linear-gradient(135deg, transparent 50%, rgba(96, 86, 255, 0.08) 50%)",
                            borderTopRightRadius: "16px",
                            zIndex: 0,
                          }}
                        />

                        {/* Icon */}
                        <Box
                          className="advantage-icon"
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            width: "60px",
                            height: "60px",
                            borderRadius: "12px",
                            background: "#f0f0ff",
                            mb: 3,
                            transition: "all 0.3s ease",
                          }}
                        >
                          {advantage.icon}
                        </Box>

                        <Typography
                          variant="h5"
                          sx={{
                            fontWeight: 700,
                            color: "#23234c",
                            mb: 2,
                            position: "relative",
                            zIndex: 1,
                          }}
                        >
                          {advantage.title}
                        </Typography>

                        <Typography
                          variant="body1"
                          sx={{
                            color: "#666",
                            lineHeight: 1.7,
                            position: "relative",
                            zIndex: 1,
                          }}
                        >
                          {advantage.description}
                        </Typography>
                      </Box>
                    </Grid>
                  ))}
                </Grid>
              </Container>
            </Box>
          </SlideInFromTopEffect>
        </Grid>

        {/* Process Section - Enhanced with modern timeline */}

        <Grid
          item
          xs={12}
          sx={{
            background: "linear-gradient(135deg, #2B2162 0%, #1E1A44 100%)",
            py: { xs: 6, md: 10 },
            position: "relative",
            overflow: "hidden",
            "&::before": {
              content: '""',
              position: "absolute",
              top: "-150px",
              right: "-150px",
              width: "300px",
              height: "300px",
              borderRadius: "50%",
              background: "rgba(108, 99, 255, 0.15)",
              zIndex: 1,
            },
            "&::after": {
              content: '""',
              position: "absolute",
              bottom: "-100px",
              left: "-100px",
              width: "200px",
              height: "200px",
              borderRadius: "50%",
              background: "rgba(148, 59, 255, 0.15)",
              zIndex: 1,
            },
          }}
        >
          <Box
            sx={{
              position: "relative",
              py: 10,
              background:
                "linear-gradient(135deg, #1E1A44 0%, #302b63 50%, #24243e 100%)",
              overflow: "hidden",
            }}
          >
            <Box
              sx={{
                position: "absolute",
                top: 0,
                left: 0,
                width: "100%",
                height: "100%",
                opacity: 0.05,
                backgroundImage:
                  'url(\'data:image/svg+xml,%3Csvg width="60" height="60" viewBox="0 0 60 60" xmlns="http://www.w3.org/2000/svg"%3E%3Cpath d="M54.627 0l.83.828-1.415 1.415L51.8 0h2.827zM5.373 0l-.83.828L5.96 2.243 8.2 0H5.374zM48.97 0l3.657 3.657-1.414 1.414L46.143 0h2.828zM11.03 0L7.372 3.657 8.787 5.07 13.857 0H11.03z" fill="%23ffffff" fill-rule="evenodd"/%3E%3C/svg%3E\')',
                zIndex: 0,
              }}
            />

            <Container maxWidth="lg" sx={{ position: "relative", zIndex: 2 }}>
              {/* REPLACED: Multiple components with DarkSectionHeading */}
              <DarkSectionHeading
                label="OUR APPROACH"
                title="Success starts with understanding"
                subtitle="We listen first to craft solutions that meet your goals and challenges."
                centered
                marginBottom={8}
                afterWidth={80}
              />

              <Box
                sx={{
                  position: "relative",
                  "&::before": {
                    content: '""',
                    position: "absolute",
                    width: { xs: "4px", md: "4px" },
                    height: "100%",
                    left: { xs: "18px", md: "50%" },
                    transform: { xs: "none", md: "translateX(-50%)" },
                    background: "linear-gradient(to bottom, #6056ff, #00ff91)",
                    borderRadius: "4px",
                    opacity: 0.7,
                    zIndex: 1,
                  },
                }}
              >
                {pageContent.page4.ourApproach.steps.map((step, index) => (
                  <Box
                    key={index}
                    sx={{
                      display: "flex",
                      flexDirection: {
                        xs: "row",
                        md: index % 2 === 0 ? "row" : "row-reverse",
                      },
                      mb: { xs: 5, md: 7 },
                      position: "relative",
                    }}
                  >
                    <Box
                      sx={{
                        position: "absolute",
                        width: { xs: "36px", md: "42px" },
                        height: { xs: "36px", md: "42px" },
                        borderRadius: "50%",
                        background:
                          "linear-gradient(135deg, #6056ff 0%, #943bff 100%)",
                        left: { xs: "0", md: "50%" },
                        transform: { xs: "none", md: "translateX(-50%)" },
                        top: { xs: "0", md: "16px" },
                        zIndex: 2,
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        boxShadow: "0 0 15px rgba(96, 86, 255, 0.5)",
                        border: "2px solid rgba(255, 255, 255, 0.2)",
                      }}
                    >
                      {/* <Box
                        component="span"
                        sx={{
                          color: "white",
                          fontSize: { xs: "1rem", md: "1.25rem" },
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        {step.icon === "search" && (
                          <SearchIcon fontSize="inherit" />
                        )}
                        {step.icon === "handshake" && (
                          <HandshakeIcon fontSize="inherit" />
                        )}
                        {step.icon === "rocket" && (
                          <RocketLaunchIcon fontSize="inherit" />
                        )}
                        {step.icon === "trending_up" && (
                          <TrendingUpIcon fontSize="inherit" />
                        )}
                      </Box> */}

                      <Box
                        component="span"
                        sx={{
                          color: "white",
                          fontSize: { xs: "1rem", md: "1.25rem" },
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        {step.iconComponent}
                      </Box>
                    </Box>

                    <Box
                      sx={{
                        width: { xs: "calc(100% - 50px)", md: "45%" },
                        ml: { xs: "50px", md: index % 2 === 0 ? "auto" : "0" },
                        mr: { xs: "0", md: index % 2 === 0 ? "0" : "auto" },
                        p: { xs: 3, md: 4 },
                        background: "rgba(255, 255, 255, 0.05)",
                        backdropFilter: "blur(10px)",
                        borderRadius: "12px",
                        border: "1px solid rgba(255, 255, 255, 0.1)",
                        transition: "all 0.3s ease",
                        position: "relative",
                        "&:hover": {
                          transform: "translateY(-5px)",
                          background: "rgba(255, 255, 255, 0.08)",
                          boxShadow: "0 10px 30px rgba(0, 0, 0, 0.2)",
                        },
                        // Arrow pointing to the timeline on desktop
                        "&::before": {
                          content: '""',
                          position: "absolute",
                          display: { xs: "none", md: "block" },
                          top: "24px",
                          [index % 2 === 0 ? "right" : "left"]: "-12px",
                          width: "24px",
                          height: "24px",
                          background: "rgba(255, 255, 255, 0.05)",
                          transform: "rotate(45deg)",
                          borderRadius: "2px",
                          zIndex: 0,
                        },
                      }}
                    >
                      <Typography
                        variant="h5"
                        sx={{
                          color: "#00ff91",
                          fontWeight: 700,
                          mb: 2,
                          fontSize: { xs: "1.5rem", md: "1.75rem" },
                        }}
                      >
                        {step.timeLine}
                      </Typography>

                      <Typography
                        variant="body1"
                        sx={{
                          color: "rgba(255, 255, 255, 0.9)",
                          lineHeight: 1.7,
                          fontSize: { xs: "0.95rem", md: "1rem" },
                        }}
                      >
                        {step.description}
                      </Typography>

                      <Box
                        sx={{
                          position: "absolute",
                          top: "-10px",
                          right: "-10px",
                          width: "30px",
                          height: "30px",
                          borderRadius: "50%",
                          background: "#00ff91",
                          color: "#24243e",
                          display: { xs: "flex", md: "none" },
                          alignItems: "center",
                          justifyContent: "center",
                          fontWeight: 700,
                          fontSize: "0.9rem",
                        }}
                      >
                        {index + 1}
                      </Box>
                    </Box>
                  </Box>
                ))}
              </Box>

              <Box
                sx={{
                  position: "relative",
                  zIndex: 2,
                  width: "80px",
                  height: "80px",
                  borderRadius: "50%",
                  background:
                    "linear-gradient(135deg, #00ff91 0%, #32C5FF 100%)",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  mx: "auto",
                  mt: -2,
                  boxShadow: "0 0 30px rgba(0, 255, 145, 0.4)",
                  "& svg": {
                    width: "40px",
                    height: "40px",
                    color: "white",
                  },
                }}
              >
                <CheckIcon fontSize="large" />
              </Box>
            </Container>
          </Box>
        </Grid>

        {/* Empowering Success For Section - Enhanced with card designs */}
        <Grid item xs={12}>
          <Container maxWidth="xl" sx={{ py: { xs: 6, md: 10 } }}>
            <SlideInFromTopEffect>
              <HeroText
                variant="h4"
                customStyle={{
                  textAlign: "center",
                  fontWeight: 700,
                  mb: 6,
                  position: "relative",
                  "&::after": {
                    content: '""',
                    position: "absolute",
                    bottom: "-12px",
                    left: "50%",
                    transform: "translateX(-50%)",
                    width: "80px",
                    height: "4px",
                    background:
                      "linear-gradient(90deg, #6056ff 0%, #943bff 100%)",
                    borderRadius: "2px",
                  },
                }}
              >
                {pageContent.page3.title}
              </HeroText>

              <Box sx={{ mt: 8 }}>
                <SlideInFromTopEffect>
                  <Services
                    WhatWeDoData={pageContent.page3.items}
                    customCardSx={{
                      borderRadius: "16px",
                      boxShadow: "0 10px 30px rgba(0,0,0,0.08)",
                      transition: "all 0.3s ease",
                      "&:hover": {
                        transform: "translateY(-5px)",
                        boxShadow: "0 15px 40px rgba(0,0,0,0.12)",
                      },
                    }}
                    customIconSx={{
                      color: "#6056ff",
                      background: "rgba(108, 99, 255, 0.1)",
                      p: 1.5,
                      borderRadius: "12px",
                    }}
                  />
                </SlideInFromTopEffect>
              </Box>
            </SlideInFromTopEffect>
          </Container>
        </Grid>

        {/* Team Section - Enhanced with modern cards */}
        <Grid
          item
          xs={12}
          sx={{
            background: "#f5f7ff",
            py: { xs: 6, md: 10 },
            borderRadius: "30px 30px 0 0",
          }}
        >
          <Container maxWidth="xl">
            {/* REPLACED: HeroText + Typography components with SectionHeading */}
            <SectionHeading
              title={pageContent.page6.title}
              subtitle="Meet our founding team of experts who drive innovation and excellence in everything we create."
              centered
              marginBottom={6}
              afterWidth={80}
            />

            {/* <Grid container spacing={4}>
              {pageContent.page6.founders.map((founder, index) => (
                <Grid item xs={12} sm={12} md={4} key={index}>
                  <Card
                    sx={{
                      borderRadius: "20px",
                      overflow: "hidden",
                      position: "relative",
                      height: { xs: "auto", lg: 450 },
                      background: "white",
                      transition: "transform 0.3s ease, box-shadow 0.3s ease",
                      boxShadow: "0 10px 30px rgba(0,0,0,0.08)",
                      "&:hover": {
                        transform: "translateY(-10px)",
                        boxShadow: "0 20px 40px rgba(0,0,0,0.12)",
                      },
                    }}
                  >
                    <Box
                      sx={{
                        position: "relative",
                        height: "100px",
                        background:
                          "linear-gradient(90deg, #6056ff 0%, #943bff 100%)",
                        mb: 6,
                        overflow: "visible",
                      }}
                    >
                      <Avatar
                        src={founder.icon}
                        alt={founder.name}
                        sx={{
                          width: 120,
                          height: 120,
                          border: "5px solid white",
                          boxShadow: "0 8px 20px rgba(0,0,0,0.1)",
                          position: "absolute",
                          left: "50%",
                          bottom: 0,
                          transform: "translate(-50%, 50%)",
                          zIndex: 2,
                          transition: "transform 0.3s ease",
                          "&:hover": {
                            transform: "translate(-50%, 50%) scale(1.05)",
                          },
                        }}
                      />
                    </Box>

                    <CardContent sx={{ textAlign: "center", pt: 6 }}>
                      <Typography
                        variant="h5"
                        fontWeight="bold"
                        gutterBottom
                        sx={{
                          background:
                            "linear-gradient(90deg, #6056ff 0%, #943bff 100%)",
                          WebkitBackgroundClip: "text",
                          WebkitTextFillColor: "transparent",
                        }}
                      >
                        {founder.name}
                      </Typography>

                      <Typography
                        variant="subtitle1"
                        color="text.secondary"
                        fontWeight="500"
                        gutterBottom
                      >
                        {founder.designation}
                      </Typography>

                      <Divider
                        sx={{
                          my: 2,
                          width: "50px",
                          mx: "auto",
                          borderColor: "#6056ff",
                        }}
                      />

                      <Typography
                        variant="body1"
                        color="text.secondary"
                        sx={{ px: 2, lineHeight: 1.7 }}
                      >
                        {founder.description}
                      </Typography>
                    </CardContent>
                  </Card>
                </Grid>
              ))}
            </Grid> */}

            <Grid container spacing={4} justifyContent="center">
              {pageContent.page6.founders.map((founder, index) => (
                <Grid item xs={12} sm={12} md={5} key={index}>
                  <Card
                    sx={{
                      borderRadius: "20px",
                      overflow: "hidden",
                      position: "relative",
                      height: { xs: "auto", lg: 450 },
                      background: "white",
                      transition: "transform 0.3s ease, box-shadow 0.3s ease",
                      boxShadow: "0 10px 30px rgba(0,0,0,0.08)",
                      "&:hover": {
                        transform: "translateY(-10px)",
                        boxShadow: "0 20px 40px rgba(0,0,0,0.12)",
                      },
                    }}
                  >
                    <Box
                      sx={{
                        position: "relative",
                        height: "100px",
                        background:
                          "linear-gradient(90deg, #6056ff 0%, #943bff 100%)",
                        mb: 6,
                        overflow: "visible",
                      }}
                    >
                      <Avatar
                        src={founder.icon}
                        alt={founder.name}
                        sx={{
                          width: 120,
                          height: 120,
                          border: "5px solid white",
                          boxShadow: "0 8px 20px rgba(0,0,0,0.1)",
                          position: "absolute",
                          left: "50%",
                          bottom: 0,
                          transform: "translate(-50%, 50%)",
                          zIndex: 2,
                          transition: "transform 0.3s ease",
                          "&:hover": {
                            transform: "translate(-50%, 50%) scale(1.05)",
                          },
                        }}
                      />
                    </Box>

                    <CardContent sx={{ textAlign: "center", pt: 6 }}>
                      <Typography
                        variant="h5"
                        fontWeight="bold"
                        gutterBottom
                        sx={{
                          background:
                            "linear-gradient(90deg, #6056ff 0%, #943bff 100%)",
                          WebkitBackgroundClip: "text",
                          WebkitTextFillColor: "transparent",
                        }}
                      >
                        {founder.name}
                      </Typography>

                      <Typography
                        variant="subtitle1"
                        color="text.secondary"
                        fontWeight="500"
                        gutterBottom
                      >
                        {founder.designation}
                      </Typography>

                      <Divider
                        sx={{
                          my: 2,
                          width: "50px",
                          mx: "auto",
                          borderColor: "#6056ff",
                        }}
                      />

                      <Typography
                        variant="body1"
                        color="text.secondary"
                        sx={{ px: 2, lineHeight: 1.7 }}
                      >
                        {founder.description}
                      </Typography>
                    </CardContent>
                  </Card>
                </Grid>
              ))}
            </Grid>
          </Container>
        </Grid>

        {/* CTA Section - Enhanced with gradient background */}
        <Grid item xs={12}>
          <Box
            sx={{
              position: "absolute",
              top: 0,
              right: 0,
              width: "300px",
              height: "300px",
              background:
                "radial-gradient(circle, rgba(255,255,255,0.1) 0%, rgba(255,255,255,0) 70%)",
              borderRadius: "50%",
              zIndex: 1,
            }}
          />

          <Box
            sx={{
              position: "absolute",
              bottom: -100,
              left: -100,
              width: "300px",
              height: "300px",
              background:
                "radial-gradient(circle, rgba(255,255,255,0.08) 0%, rgba(255,255,255,0) 70%)",
              borderRadius: "50%",
              zIndex: 1,
            }}
          />

          {/* <Container maxWidth="xl" sx={{ position: "relative", zIndex: 2 }}> */}
          {/* <ContactCodingRippler
            pageContent={pageContent.page7.contactCodingRippler}
            isPhone={isPhone}
            isTab={isTab}
            isPhoneorTab={isPhoneOrTab}
            deviceWithSmallHeight={deviceWithSmallHeight}
            customStyles={{
              container: {
                textAlign: "center",
              },
              header: {
                fontSize: { xs: "2rem", md: "2.5rem" },
                fontWeight: 800,
                color: "white",
                mb: 2
              },
              subheader: {
                fontSize: { xs: "1.1rem", md: "1.2rem" },
                color: "rgba(255, 255, 255, 0.9)",
                mb: 4,
                maxWidth: "700px",
                mx: "auto"
              },
              buttonContainer: {
                display: "flex",
                justifyContent: "center",
                gap: 2,
                flexWrap: "wrap"
              },
              primaryButton: {
                bgcolor: "white",
                color: "#6056ff",
                fontWeight: 600,
                px: 4,
                py: 1.5,
                borderRadius: "12px",
                boxShadow: "0 8px 20px rgba(0,0,0,0.15)",
                "&:hover": {
                  bgcolor: "white",
                  transform: "translateY(-3px)",
                  boxShadow: "0 12px 25px rgba(0,0,0,0.2)",
                },
                transition: "all 0.3s ease"
              },
              secondaryButton: {
                color: "white",
                border: "2px solid white",
                fontWeight: 600,
                px: 4,
                py: 1.4,
                borderRadius: "12px",
                "&:hover": {
                  bgcolor: "rgba(255,255,255,0.1)",
                  borderColor: "white",
                  transform: "translateY(-3px)"
                },
                transition: "all 0.3s ease"
              }
            }}
          /> */}

          <FooterCTA />
          {/* </Container> */}
        </Grid>
      </Grid>

      <ContactDialog open={open} onClose={handleClose} />
    </>
  );
};