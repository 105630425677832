import { Box, Collapse, IconButton, Typography } from "@mui/material";
import { useState } from "react";

export const FAQsHeader = (children, ...props) => {
  return (
    <Box display="flex" justifyContent="center" gap={2}>
      <Box>
        <Typography
          variant="h3"
          fontWeight="bold"
          className="typography-custom-css"
          sx={{
            textAlign: "center",
          }}
        >
          FAQs
        </Typography>
      </Box>
    </Box>
  );
};

export const FAQsSubHeaderHome = (children, ...props) => {
  return (
    <Box>
      <Typography
        variant="h6"
        fontWeight="normal"
        className="sub-header typography-custom-css"
        sx={{
          textAlign: { xs: "center", lg: "center" },
        }}
      >
        Can’t find the answer here?{" "}
        <span className="theme-specfic-colored-text">
          <a
            href="mailto:contact@codingrippler.com"
            style={{ color: "inherit", textDecoration: "none" }}
          >
            Contact our support team.
          </a>
        </span>
      </Typography>
    </Box>
  );
};

export const FAQsCard = ({ children, question, answer, ...props }) => {
  const [isOpen, setIsOpen] = useState(false);
  const { openingIcon, closingIcon, isPhoneOrTab } = props;

  return (
    <>
      <Box
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
        onClick={() => setIsOpen(!isOpen)}
      >
        <Typography
          variant={isPhoneOrTab ? "h5" : "h4"}
          fontWeight="bold"
          sx={{ fontFamily: "Roboto, sans-serif" }}
        >
          {question}
        </Typography>
        <IconButton
          onClick={() => {
            setIsOpen(!isOpen);
          }}
        >
          {isOpen ? closingIcon : openingIcon}
        </IconButton>
      </Box>

      <Collapse in={isOpen} timeout="auto" unmountOnExit>
        <Typography
          marginTop={2}
          variant="h6"
          className="sub-header"
          lineHeight={2}
          sx={{ fontFamily: "Roboto, sans-serif" }}
        >
          {answer}
        </Typography>
      </Collapse>
    </>
  );
};
