import { Box, useMediaQuery, useTheme } from "@mui/material";
import { useEffect, useState } from "react";

const ChatbotEmbed = ({ url }) => {
  const [isReachable, setIsReachable] = useState(false);
  const [isExpanded, setIsExpanded] = useState(false); // Control iframe size


  const theme = useTheme();
  const isPhone = useMediaQuery(theme.breakpoints.down("sm"));
  const isTab = useMediaQuery(theme.breakpoints.down("lg"));

  let chatbotWidth = "30%";
  let chatbotHeight = "80%";
  if (isPhone) {
    chatbotWidth = "100%";
    chatbotHeight = "100%";
  }else if(isTab){

    chatbotWidth = "60%";
  }

  useEffect(() => {
    const checkUrl = async () => {
      try {
        const response = await fetch(url, { method: "HEAD" });
        if (response.ok) {
          setIsReachable(true);
        }
      } catch (error) {
        setIsReachable(false);
      }
    };
    checkUrl();
  }, [url]);

  useEffect(() => {
    // Listen for messages from the chatbot iframe
    const handleMessage = (event) => {
      
      if (event.origin !== new URL(url).origin) return; // Ensure it's from the correct origin

      if (event.data.type === "CHATBOT_TOGGLE") {
        setIsExpanded(event.data.isOpen);
      }
    };

    window.addEventListener("message", handleMessage);
    return () => window.removeEventListener("message", handleMessage);
  }, [url]);

  if (!isReachable) {
    return null; // Render nothing if the URL is not reachable
  }

  return (
    <>
      {isReachable && (
        <Box
          sx={{
            position: "fixed",
            bottom: "0px",
            right: "5px",
            width: isExpanded ? chatbotWidth : "195px", // Expand on message
            height: isExpanded ? chatbotHeight : "75px", // Expand on message
            zIndex: isExpanded ? 2 : 2, // Always on top
            transition: "all 0.3s ease",
            p: 0, // Remove padding
            overflow: "hidden", // Hide scrollbar using CSS
            overflowX: "hidden", // Hide horizontal scrollbar
            backgroundColor: "transparent",
        }}
        >
          <iframe
            src={url}
            title="Chatbot"
            style={{
              width: "100%",
              height: "100%",
              border: "none", // Remove border
              overflow: "hidden", // Hide scrollbar using CSS
              overflowX: "hidden", // Hide horizontal scrollbar,
              background: "rgba(255, 255, 255, 0)" /* Ensures full transparency */

            }}
            scrolling="no" // Disable scrolling
            allowtransparency="true" // Allow transparency
          />
        </Box>
      )}
    </>
  );
};

export default ChatbotEmbed;
