import { Grid } from "@mui/material";
import { RouteToContactUs } from "../../../../CodingRippler/CodingRipplerHome/ContactUs/ContactUs";
import { TabsContainer } from "./TabsContainer";
import { FeaturedTabDetails } from "./TabWidgets/FeaturedTabDetails";

export const FeaturedTabPanel = (props) => {
  const { value } = props;

  // const handleUse = (id) => {
  //   window.location.href = `/ai`;
  // };

  return (
    <TabsContainer value={value} index={0}>
      <Grid container spacing={2}>
        <p></p>
        <Grid item xs={12} lg={4}>
          <FeaturedTabDetails
            imageSrc="/airippler/ai-tools/featured/youtube-icon.png"
            imageAlt="Description of the image"
            title="YouTube: Extract Video Files"
            description={`Quickly extract video files from YouTube with this simple tool. Perfect for content creators and digital marketers, this tool lets you download raw video content for editing, analysis, or repurposing. With just a YouTube URL, the tool retrieves the video file, streamlining the process and saving time when working with online video content.`}
            handleButtonClick={RouteToContactUs}
            buttonText={"Contact Us"}
          />
        </Grid>

        <Grid item xs={12} lg={4}>
          <FeaturedTabDetails
            imageSrc="/airippler/ai-tools/featured/slack.png"
            imageAlt="Description of the image"
            title="Slack: Retrieve Recent Messages"
            description={`Streamline team communication by effortlessly pulling recent messages from any Slack channel. This tool helps teams stay organized by automating the retrieval of key conversations. Whether for project management or tracking discussions, quickly access relevant messages and keep your team aligned with ease.`}
            handleButtonClick={RouteToContactUs}
            buttonText={"Contact Us"}
          />
        </Grid>

        <Grid item xs={12} lg={4}>
          <FeaturedTabDetails
            imageSrc="/airippler/ai-tools/featured/slack.png"
            imageAlt="Description of the image"
            title="Real-Time Analytics"
            description={`Gain immediate insights into your data with real-time analytics tools. Track key metrics, monitor trends, and make data-driven decisions faster than ever. Perfect for teams needing instant feedback to adapt and optimize strategies in real time.`}
            handleButtonClick={RouteToContactUs}
            buttonText={"Contact Us"}
          />
        </Grid>
      </Grid>
    </TabsContainer>
  );
};
