import { Box } from "@mui/system";

import { Grid, useMediaQuery, useTheme } from "@mui/material";
import { Helmet } from "react-helmet-async";
import { Route, Routes } from "react-router-dom";
import { AIAgentsHome } from "./AIAgents/AIAgentsHome";
import { AIHome } from "./AIHome/AIHome";
import { ProductsHome } from "./AIProducts/ProductsHome";

export const AI = ({ children, ...props }) => {
  //  const { toggleTheme } = useThemeMode();

  const theme = useTheme();

  const isPhone = useMediaQuery(theme.breakpoints.down("sm"));
  const isTab = useMediaQuery(theme.breakpoints.down("lg"));
  const deviceWithSmallHeight = useMediaQuery("(max-height: 750px)");
  const isPhoneOrTab = isPhone || isTab;

  return (
    <>
      <Helmet>
        <title>AI Rippler - Home</title>
        <meta
          name="description"
          content="Explore the wide range of AI services offered by AI Rippler."
        />
        <meta property="og:title" content="AI Rippler - Home" />
        <meta
          property="og:description"
          content="Explore the wide range of AI services offered by AI Rippler."
        />
        <meta property="og:url" content="https://www.codingrippler.com/ai" />
        <meta property="og:type" content="website" />
        <link rel="canonical" href="https://www.codingrippler.com/ai" />
      </Helmet>

      <Box className="ai-background">
        {/* Fixed elements that stay in viewport during scroll */}
        <Box className="bg-fixed-elements">
          <Box className="bg-grid"></Box>
          
          {/* Bottom corner elements that stay fixed */}
          <Box className="bottom-corner-elements">
              <Box className="corner-left"></Box>
              <Box className="corner-right"></Box>
              
              <Box className="bottom-corner-nodes">
                  <Box className="node node-1"></Box>
                  <Box className="node node-2"></Box>
                  <Box className="node node-3"></Box>
                  <Box className="node node-4"></Box>
                  <Box className="node node-5"></Box>
                  <Box className="node node-6"></Box>
                  
                  <Box className="connection connection-1"></Box>
                  <Box className="connection connection-2"></Box>
                  <Box className="connection connection-3"></Box>
                  <Box className="connection connection-4"></Box>
              </Box>
          </Box>
        </Box>

        {/* Elements that are positioned throughout the scroll */}
        <Box className="bg-scroll-elements">
          {/* Top section (first viewport) */}
          <Box className="top-decoration">
            <Box className="circle circle-1"></Box>
            <Box className="circle circle-2"></Box>
            <Box className="particle particle-brain"></Box>
            <Box className="particle particle-chip"></Box>

            <Box className="nodes top-nodes">
              <Box className="node node-1"></Box>
              <Box className="node node-2"></Box>
              <Box className="node node-3"></Box>
              <Box className="node node-4"></Box>
              <Box className="connection connection-1"></Box>
              <Box className="connection connection-2"></Box>
            </Box>

            <Box className="section-boundary top-boundary"></Box>
          </Box>

          {/* Middle section (second viewport) */}
          <Box className="middle-decoration">
            <Box className="circle circle-3"></Box>
            <Box className="particle particle-network"></Box>
            <Box className="particle particle-data"></Box>

            <Box className="nodes middle-nodes">
              <Box className="node node-1"></Box>
              <Box className="node node-2"></Box>
              <Box className="node node-3"></Box>
              <Box className="node node-4"></Box>
              <Box className="connection connection-1"></Box>
              <Box className="connection connection-2"></Box>
            </Box>

            <Box className="section-boundary middle-boundary"></Box>
          </Box>

          {/* Bottom section (third viewport) */}
          <Box className="bottom-decoration">
            <Box className="circle circle-4"></Box>
            <Box className="particle particle-code"></Box>
            <Box className="particle particle-cloud"></Box>

            <Box className="nodes bottom-nodes">
              <Box className="node node-1"></Box>
              <Box className="node node-2"></Box>
              <Box className="node node-3"></Box>
              <Box className="connection connection-1"></Box>
              <Box className="connection connection-2"></Box>
            </Box>

            <Box className="section-boundary bottom-boundary"></Box>
          </Box>

          {/* Additional section (fourth viewport) */}
          <Box className="additional-decoration">
            <Box className="circle circle-5"></Box>
          </Box>
        </Box>

        {/* Your content here */}
        <Box className="content-above-bg">
          <Grid
            id="AI"
            container
            sx={{
              overflow: "hidden",
            }}
          >
            <Routes>
              <Route
                path="/*"
                element={
                  <AIHome
                    isPhone={isPhone}
                    isTab={isTab}
                    deviceWithSmallHeight={deviceWithSmallHeight}
                    isPhoneOrTab={isPhoneOrTab}
                  />
                }
              />
              <Route
                path="/solutions/*"
                element={
                  <ProductsHome
                    isPhone={isPhone}
                    isTab={isTab}
                    deviceWithSmallHeight={deviceWithSmallHeight}
                    isPhoneOrTab={isPhoneOrTab}
                  />
                }
              />

              <Route
                path="/agents/*"
                element={
                  <AIAgentsHome
                    isPhone={isPhone}
                    isTab={isTab}
                    deviceWithSmallHeight={deviceWithSmallHeight}
                    isPhoneOrTab={isPhoneOrTab}
                  />
                }
              />
            </Routes>

            {/* Build your AI Hub today   */}
            {/* <Grid item xs={12} sx={{ mb: 10 }}>
              <AICommonBoxContainer>
                <CommonContainer>
                  <Card
                    sx={{
                      p: 2,
                      borderRadius: "24px",
                      minHeight: {
                        xs: deviceWithSmallHeight ? "100vh" : "75vh",
                        sm: "50vh",
                        lg: "50vh",
                      },
                      height: "100%",
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center",
                      alignItems: "center",
                      width: "100%",
                      backgroundImage:
                        "url(/airippler/home-page/common-cta-bg.jpg)",
                      backgroundSize: "cover",
                      backgroundPosition: "center",
                      backgroundRepeat: "no-repeat",
                    }}
                    elevation={3}
                  >
                    <CardContent>
                      <IntroHeaderHomeV2 />
                      <Box m={2} />
                      <IntroSubHeaderHome />

                      <Box m={2} />

                      <ResponsiveBoxCenteredChildren>
                        <TryForFree />
                        <Box m={2} />
                        <RequestDemo />
                      </ResponsiveBoxCenteredChildren>
                      <Box m={2} />
                      <IntroSubHeadingButtons />
                    </CardContent>
                  </Card>
                </CommonContainer>
              </AICommonBoxContainer>
            </Grid> */}
          </Grid>
        </Box>
      </Box>
    </>
  );
};