import AutoAwesomeIcon from "@mui/icons-material/AutoAwesome";
import { Box, Button, Grid } from "@mui/material";
import { useState } from "react";
import { HeroSubHeader, HeroText } from "../../CodingRipplerWidgets";
import ContactDialog, { trackAndOpenDialog } from "./ContactUsDialog";

export const ContactWebRippler = ({ children, ...props }) => {
  const {
    isPhoneorTab,
    isPhone,
    isTab,
    deviceWithSmallHeight,
    pageContent,
    viewPages,
  } = props;

  if (isPhone) {
    if (deviceWithSmallHeight) {
    }
  } else if (isTab) {
  }

    const [open, setOpen] = useState(false);
        
    const handleContactClick = () => trackAndOpenDialog(setOpen);
  
    const handleClose = (success) => {
      setOpen(false);
      if (success) {
        // Handle successful submission (e.g. show a success toast)
      }
    };  
  

  return (
    <>
      <ContactDialog open={open} onClose={handleClose} />
      <Grid container spacing={5}>
        <Grid
          item
          xs={12}
          minHeight={{
            xs: 400,
          }}
        >
          <Box
            display="flex"
            flexDirection="column"
            justifyContent="center"
            alignItems="center"
            width="100%"
            height="100%"
          >
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <HeroText variant={isPhoneorTab ? "h4" : "h3"}>
                  {pageContent.header}
                </HeroText>
              </Grid>

              <Grid item xs={12}>
                <HeroSubHeader>{pageContent.subHeader}</HeroSubHeader>
              </Grid>

              <Grid item xs={12}>
                {/* <Box display="flex" justifyContent="center" alignItems="center">
                <Box
                  display="flex"
                  justifyContent="flex-start"
                  alignItems="center"
                  gap={2}
                >
                  <Button
                    variant="contained"
                    size="large"
                    className="theme-specific-bg-button"
                    onClick={viewPages}
                  >
                    View Pages
                  </Button>

                  <Button
                    variant="outlined"
                    size="large"
                    className="theme-specific-button"
                    onClick={contactUs}
                  >
                    Let's Talk
                  </Button>
                </Box>
              </Box> */}

                <Box
                  display="flex"
                  justifyContent={isPhone ? "flex-start" : "center"}
                  alignItems="center"
                  flexDirection={isPhone ? "column" : "row"}
                  gap={2}
                  width="100%"
                  height="100%"
                >
                  <Button
                    fullWidth={isPhone ? true : false}
                    variant="contained"
                    size="large"
                    className="theme-specific-bg-button"
                    onClick={viewPages}
                  >
                    View Pages
                  </Button>

                  <Button
                    fullWidth={isPhone ? true : false}
                    variant="outlined"
                    size="large"
                    className="theme-specific-button"
                    onClick={handleContactClick}
                  >
                    Let's Talk
                  </Button>
                </Box>

                <Box m={{ xs: 0, sm: 2 }} />
                <Box
                  display="flex"
                  flexDirection="row"
                  justifyContent="center"
                  alignItems="center"
                  width="100%"
                  gap={2}
                >
                  <AutoAwesomeIcon />
                  <HeroSubHeader>Start your project today!</HeroSubHeader>
                </Box>
              </Grid>
              <Grid item xs={12} />
            </Grid>
          </Box>
        </Grid>
      </Grid>
    </>
  );
};
