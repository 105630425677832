import {
  Hub as HubIcon
} from "@mui/icons-material";
import {
  Box,
  Container,
  Typography
} from "@mui/material";
import React, { useState } from "react";
import CustomTabs from "../../boilerplate/CustomTabs";
import ContactSalesDialog, { trackAndOpenSalesDialog } from "../ContactUs/ContactSalesDialog";
import AIStudioPricing from "./AIStudioPricing";
import WebSolutionsPricing from "./WebSolutionsPricing";

// Addon services data remains the same

const PricingPage = () => {
  const [selectedTab, setSelectedTab] = useState(0);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [selectedProductType, setSelectedProductType] = useState('');
  const [selectedPricingTier, setSelectedPricingTier] = useState('');
  
  // Define the tabs for pricing section
  const pricingTabs = [
    { id: 0, label: 'AI STUDIO', icon: <HubIcon /> }
//     { id: 1, label: 'WEB SOLUTIONS', icon: <ComputerIcon /> },
  ];
  
  // Handler for dialog close
  const handleDialogClose = () => {
    setDialogOpen(false);    
  };
  
  // Example of opening the dialog with specific product
  const handleContactSales = (
    selectedProductType,
    selectedPricingTier
  ) => {
    setSelectedProductType(selectedProductType);
    setSelectedPricingTier(selectedPricingTier);
    trackAndOpenSalesDialog(setDialogOpen);
  };

  // Handle tab change
  const handleTabChange = (event, newValue) => {
    setSelectedTab(newValue);
  };

  return (
    <>
      <Box
        sx={{ bgcolor: "#f8f9ff", minHeight: "100vh", py: 8 }}
        id="pricing-section"
      >
        {/* Header Section */}
        <Container maxWidth="lg">
          <Box textAlign="center" mb={8}>
            <Typography
              variant="h1"
              component="h1"
              fontWeight={800}
              gutterBottom
              sx={{
                color: "#222",
                fontSize: { xs: "2.5rem", sm: "3rem", md: "4rem" },
                background: "linear-gradient(90deg, #333 0%, #6056ff 100%)",
                WebkitBackgroundClip: "text",
                WebkitTextFillColor: "transparent",
                letterSpacing: "-0.5px",
                mb: 2,
              }}
            >
              Simple, Transparent <Box component="span">Pricing</Box>
            </Typography>
            <Typography
              variant="h5"
              color="textSecondary"
              sx={{
                mb: 5,
                maxWidth: "750px",
                mx: "auto",
                fontWeight: 400,
                lineHeight: 1.5,
                fontSize: { xs: "1rem", sm: "1.2rem", md: "1.35rem" },
              }}
            >
              Choose the perfect plan for your needs with no hidden fees or
              surprise charges. All plans include dedicated support and regular
              updates.
            </Typography>

            {/* Product Type Tabs using the CustomTabs component */}
            <CustomTabs
              tabs={pricingTabs}
              activeTab={selectedTab}
              handleTabChange={handleTabChange}
              sx={{ 
                maxWidth: { xs: '100%', sm: 400 }, 
                mx: 'auto',
                boxShadow: '0 4px 12px rgba(0, 0, 0, 0.05)',
              }}
              showScrollButtons={false} // Hide scroll buttons since we only have 2 tabs
              variant="contained" // Use contained style for better visibility
              activeIndicator="background" // Use background instead of underline
            />
          </Box>

          {/* Conditional rendering of pricing components based on selected tab */}
          {selectedTab === 0 ? (
            <AIStudioPricing handleContactSales={handleContactSales} />
          ) : (
            <WebSolutionsPricing handleContactSales={handleContactSales} />
          )}
        </Container>
      </Box>
      <ContactSalesDialog
        open={dialogOpen}
        onClose={handleDialogClose}
        selectedProductType={selectedProductType}
        selectedPricingTier={selectedPricingTier}
      />
    </>
  );
};

// Function to scroll to the pricing section
// export const scrollToPricing = () => {
//   // Find the pricing section element
//   const pricingSection = document.getElementById('pricing-section');
  
//   // If the element exists, scroll to it with offset
//   if (pricingSection) {
//     // Get the navbar height to use as offset
//     const navbar = document.querySelector('header') || document.querySelector('nav');
//     const navbarHeight = navbar ? navbar.offsetHeight : 80; // Default to 80px if navbar not found
    
//     // Get the element's position relative to the viewport
//     const elementPosition = pricingSection.getBoundingClientRect().top;
//     // Get the current scroll position
//     const offsetPosition = elementPosition + window.pageYOffset - navbarHeight - 20; // Extra 20px for padding
    
//     // Scroll to the adjusted position
//     window.scrollTo({
//       top: offsetPosition,
//       behavior: 'smooth'
//     });
//   } else {
//     console.error('Pricing section element not found');
//   }
// };

export const scrollToPricing = () => {
  // Find the pricing section element
  const pricingSection = document.getElementById('pricing-section');
  
  // If the element exists, scroll to it with offset
  if (pricingSection) {
    // Get the navbar height to use as offset
    const navbar = document.querySelector('header') || document.querySelector('nav');
    const navbarHeight = navbar ? navbar.offsetHeight : 80; // Default to 80px if navbar not found
    
    // Get the element's position relative to the viewport
    const elementPosition = pricingSection.getBoundingClientRect().top;
    
    // Fix for deprecated pageYOffset - use window.scrollY instead
    const offsetPosition = elementPosition + window.scrollY - navbarHeight - 20; // Extra 20px for padding
    
    // Scroll to the adjusted position
    window.scrollTo({
      top: offsetPosition,
      behavior: 'smooth'
    });
    
    // Track the pricing button click with Google Analytics
    if (window.gtag) {
      window.gtag('event', 'pricing_button_clicked', {
        event_category: 'User Interaction',
        event_label: 'Pricing Section',
        value: 1
      });
    } else if (window.ga) {
      // Fallback for older Universal Analytics
      window.ga('send', 'event', 'User Interaction', 'Click', 'Pricing Section');
    } else {

      // do nothing as of now
    }
  } else {
    // do nothing as of now
  }
};


export default PricingPage;