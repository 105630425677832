import React, { useState, useEffect, createContext, useContext } from 'react';
import { 
  Box, 
  Typography, 
  IconButton,
  Menu,
  MenuItem,
  Tooltip
} from "@mui/material";
import CurrencyRupeeIcon from '@mui/icons-material/CurrencyRupee';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import SwapHorizIcon from '@mui/icons-material/SwapHoriz';

// Create a context to share currency state across components
export const CurrencyContext = createContext({
  currency: 'USD',
  setCurrency: () => {},
  conversionRate: 83.5,
  loading: true
});

// Provider component to wrap around pricing section
export const CurrencyProvider = ({ children }) => {
  const [currency, setCurrency] = useState('USD');
  const [conversionRate, setConversionRate] = useState(83.5); // Default fallback
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchConversionRate = async () => {
      try {
        const response = await fetch('https://open.er-api.com/v6/latest/USD');
        const data = await response.json();
        if (data && data.rates && data.rates.INR) {
          setConversionRate(data.rates.INR);
        }
      } catch (error) {
        console.log('Error fetching conversion rate:', error);
        // Using fallback rate
      } finally {
        setLoading(false);
      }
    };

    fetchConversionRate();
  }, []);

  return (
    <CurrencyContext.Provider value={{ currency, setCurrency, conversionRate, loading }}>
      {children}
    </CurrencyContext.Provider>
  );
};

// Currency Selector Component - to be placed at the top of pricing section
export const CurrencySelector = () => {
  const { currency, setCurrency } = useContext(CurrencyContext);
  const [anchorEl, setAnchorEl] = useState(null);
  
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  
  const handleClose = () => {
    setAnchorEl(null);
  };
  
  const handleCurrencyChange = (newCurrency) => {
    setCurrency(newCurrency);
    handleClose();
  };

  return (
    <Box sx={{ display: 'flex', justifyContent: 'flex-end', mb: 2 }}>
      <Tooltip title="Change Currency">
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <Typography 
            variant="body2" 
            sx={{ mr: 1, color: 'text.secondary' }}
          >
            Currency: {currency}
          </Typography>
          <IconButton 
            size="small" 
            onClick={handleClick}
            sx={{ color: 'primary.main' }}
          >
            {currency === 'USD' ? <AttachMoneyIcon fontSize="small" /> : <CurrencyRupeeIcon fontSize="small" />}
            <SwapHorizIcon fontSize="small" sx={{ ml: 0.5 }} />
          </IconButton>
        </Box>
      </Tooltip>
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <MenuItem onClick={() => handleCurrencyChange('USD')}>
          <AttachMoneyIcon fontSize="small" sx={{ mr: 1 }} />
          USD
        </MenuItem>
        <MenuItem onClick={() => handleCurrencyChange('INR')}>
          <CurrencyRupeeIcon fontSize="small" sx={{ mr: 1 }} />
          INR
        </MenuItem>
      </Menu>
    </Box>
  );
};

// Price formatter utility
export const formatPrice = (amount, currency, conversionRate) => {
  if (amount === null || amount === undefined) return null;
  
  const price = currency === 'USD' ? amount : Math.round(amount * conversionRate);
  const symbol = currency === 'USD' ? '$' : '₹';
  
  return { 
    value: price, 
    formatted: `${symbol}${price}` 
  };
};

// Price Display Component - direct replacement for pricing section
export const PriceDisplay = ({ 
  usdPrice, 
  discountedPrice = null, 
  yearlyPrice = null, 
  yearlyPerMonth = null, 
  billingType, 
  variant = "h3",
  isEnterprise = false,
  fontSize = { xs: "2.5rem", md: "3rem" }
}) => {
  const { currency, conversionRate } = useContext(CurrencyContext);
  
  // Get the appropriate price based on billing type
  const getPrice = () => {
    if (usdPrice === null) return null;
    
    let basePrice;
    if (billingType === "yearly") {
      basePrice = yearlyPerMonth;
    } else {
      basePrice = discountedPrice !== null ? discountedPrice : usdPrice;
    }
    
    return formatPrice(basePrice, currency, conversionRate);
  };
  
  // Get yearly total price if applicable
  const getYearlyTotal = () => {
    if (yearlyPrice === null || billingType !== "yearly") return null;
    return formatPrice(yearlyPrice, currency, conversionRate);
  };

  const price = getPrice();
  const yearlyTotal = getYearlyTotal();
  
  if (price === null) {
    return (
      <Typography
        variant={variant}
        sx={{
          fontWeight: 800,
          color: isEnterprise ? "common.white" : "text.primary",
          mb: 1,
          fontSize: fontSize
        }}
      >
        Custom
      </Typography>
    );
  }

  return (
    <>
      <Box
        sx={{
          display: "flex",
          alignItems: "flex-end",
          justifyContent: "center",
          mb: 1,
        }}
      >
        <Typography
          variant={variant}
          component="span"
          sx={{
            fontWeight: 800,
            lineHeight: 1,
            color: isEnterprise ? "common.white" : "text.primary",
            fontSize: fontSize
          }}
        >
          {price.formatted}
        </Typography>

        <Typography
          variant="h6"
          component="span"
          sx={{
            fontWeight: 500,
            color: isEnterprise ? "rgba(255, 255, 255, 0.7)" : "text.secondary",
            ml: 0.5,
            mb: 0.5,
            fontSize: { xs: '1rem', md: '1.25rem' }
          }}
        >
          /{billingType === "yearly" ? "mo" : "month"}
        </Typography>
      </Box>

      {/* Yearly total (if applicable) */}
      {yearlyTotal && (
        <Typography
          variant="body2"
          sx={{
            color: isEnterprise ? "rgba(255, 255, 255, 0.7)" : "text.secondary",
            mb: 1,
            fontSize: { xs: '0.75rem', md: '0.875rem' }
          }}
        >
          {yearlyTotal.formatted} billed annually
        </Typography>
      )}
    </>
  );
};

// Original Price Component with Strikethrough
export const OriginalPrice = ({ usdPrice, isEnterprise = false }) => {
  const { currency, conversionRate } = useContext(CurrencyContext);
  
  if (usdPrice === null) return null;
  
  const price = formatPrice(usdPrice, currency, conversionRate);
  
  return (
    <Typography
      variant="body1"
      sx={{
        textDecoration: "line-through",
        color: isEnterprise ? "rgba(255, 255, 255, 0.6)" : "text.secondary",
        mb: 0.5,
        fontSize: { xs: '0.875rem', md: '1rem' }
      }}
    >
      {price.formatted}/month
    </Typography>
  );
};

// Addon Price Component for the addon section
export const AddonPrice = ({ 
  usdPrice, 
  variant = "h6", 
  fontSize = { xs: "1rem", md: "1.25rem" } 
}) => {
  const { currency, conversionRate } = useContext(CurrencyContext);
  
  if (usdPrice === null) return "Custom";
  
  const price = formatPrice(usdPrice, currency, conversionRate);
  
  return (
    <Typography
      variant={variant}
      component="span"
      sx={{
        fontWeight: 700,
        fontSize: fontSize
      }}
    >
      {price.formatted}
    </Typography>
  );
};