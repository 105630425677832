import AutoAwesomeIcon from "@mui/icons-material/AutoAwesome";
import HeadphonesIcon from "@mui/icons-material/Headphones";
import LaptopMacIcon from "@mui/icons-material/LaptopMac";
import PhoneIphoneIcon from "@mui/icons-material/PhoneIphone";
import PhotoCameraIcon from "@mui/icons-material/PhotoCamera";
import SpeakerIcon from "@mui/icons-material/Speaker";
import WatchIcon from "@mui/icons-material/Watch";
import { Box, CardMedia, Grid, Stack } from "@mui/material";
import { Helmet } from "react-helmet-async";
import { SubmitYourEmail } from "../../CodingRipplerHome/ContactUs/SubmitYourEmail";
import { OurBlog } from "../../CodingRipplerHome/OurBlog/OurBlog";
import {
  BannerLayoutWithImage,
  CommonButtonV2,
  CommonContainer,
  EcommerceBanner,
  EcommerceCard,
  HeroSubHeader,
  HeroText,
  InfoCard,
  LayoutWithImage,
  PageIntroWithButtonContainer,
  ReviewCard,
  SliderContainer,
  TopicContainer,
} from "../../CodingRipplerWidgets";
import { ChooseByCategoryPage } from "./ECommerecWidgets/ChooseByCategoryPage";

export const ECommerceHome = ({ children, ...props }) => {
  const { isPhoneorTab, isPhone, isTab, deviceWithSmallHeight } = props;

  let slice = 3;

  if (isPhoneorTab) {
    slice = 4;
  }

  const pageContent = {
    imagePath: "/codingrippler/e-commerce/item1.png",
    //  imagePath: "/codingrippler/masonry/masonryLanding.svg",
    alt: "E-Commerce_1",
    header: "Immerse Yourself in Sound Like Never Before!",
    subHeader:
      "Web Rippler crafts modern, professional designs for your product—elevating its look while saving you valuable time!",
    offeredPrice: "$399.85",
    buttonText: "Discover The Offer",
    additionalOfferText:
      "Get a $150 Spotify Premium gift card with select Beats purchases!*",

    banner: {
      header: "Explore More Products",
      subHeader:
        "If we’re not the right fit anymore, you can export your data anytime—no questions asked.",
    },

    customStyle: {
      customBgColorCss: "theme-specific-background-color",
      customHeader: {
        color: "white",
      },
      customSubHeader: {
        color: "white",
      },
    },

    categoryData: [
      {
        topicText: "Categories",
        header: "Shop by Category",
        subHeader:
          "Shop Music Instruments & Accessories Online—Safely & Easily!",
        buttonText: "View All Categories",

        customStyle: {
          customHeader: {
            color: "rgb(34, 43, 69)",
            fontSize: {
              xs: "1.25rem",
              lg: "2.5rem",
              xl: "2.5rem",
            },
          },
          customSubHeader: {
            fontSize: {
              xs: "1.25rem",
              lg: "1.5rem",
              xl: "1.5rem",
            },
          },
        },

        // categoryTypes: [
        //   {
        //     title: "Headphones",
        //     icon: (
        //       <HeadphonesIcon
        //         fontSize="large"
        //         className="theme-background-color"
        //       />
        //     ),
        //   },

        //   {
        //     title: "Beats",
        //     icon: (
        //       <LibraryMusicIcon
        //         fontSize="large"
        //         className="theme-background-color"
        //       />
        //     ),
        //   },
        //   {
        //     title: "Notebooks",
        //     icon: (
        //       <ComputerIcon
        //         fontSize="large"
        //         className="theme-background-color"
        //       />
        //     ),
        //   },
        //   {
        //     title: "Cameras",
        //     icon: (
        //       <PhotoCameraIcon
        //         fontSize="large"
        //         className="theme-background-color"
        //       />
        //     ),
        //   },
        //   {
        //     title: "Gaming",
        //     icon: (
        //       <SportsEsportsIcon
        //         fontSize="large"
        //         className="theme-background-color"
        //       />
        //     ),
        //   },
        //   {
        //     title: "Video",
        //     icon: (
        //       <VideoCameraBackIcon
        //         fontSize="large"
        //         className="theme-background-color"
        //       />
        //     ),
        //   },
        // ],

        categoryTypes: [
          {
            title: "Headphones",
            icon: (
              <HeadphonesIcon
                fontSize="large"
                className="theme-background-color"
              />
            ),
          },
          {
            title: "Smartphones",
            icon: (
              <PhoneIphoneIcon
                fontSize="large"
                className="theme-background-color"
              />
            ),
          },
          {
            title: "Laptops",
            icon: (
              <LaptopMacIcon
                fontSize="large"
                className="theme-background-color"
              />
            ),
          },
          {
            title: "Cameras",
            icon: (
              <PhotoCameraIcon
                fontSize="large"
                className="theme-background-color"
              />
            ),
          },
          {
            title: "Home Audio",
            icon: (
              <SpeakerIcon
                fontSize="large"
                className="theme-background-color"
              />
            ),
          },
          {
            title: "Wearables",
            icon: (
              <WatchIcon fontSize="large" className="theme-background-color" />
            ),
          },
        ],
      },
    ],
    discoverTheOffer: {
      header: "Immerse Yourself in Sound Like Never Before!",
      subHeader:
        "Web Rippler crafts modern, professional designs for your product—elevating its look while saving you valuable time!",
      buttonText: "Discover The Offer",
      imagePath: "/codingrippler/e-commerce/item4.png",
      customStyle: {
        customHeader: {
          color: "rgb(34, 43, 69)",
          textAlign: "left",
          // fontSize: {
          //   xs: "1.25rem",
          //   lg: "1.5rem",
          //   xl: "2rem",
          // },
        },
        customSubHeader: {
          color: "rgb(34, 43, 69)",
          textAlign: "left",
          // fontSize: {
          //   xs: "1.25rem",
          //   lg: "1.5rem",
          //   xl: "1.5rem",
          // },
        },
      },
    },

    product: {
      topicText: "Products",
      header: "Featured products",
      subHeader: "Immerse Yourself in Sound Like Never Before!",
      buttonText: "View all",
      customStyle: {
        customHeader: {
          color: "rgb(34, 43, 69)",
          textAlign: "left",
        },
        customSubHeader: {
          color: "rgb(34, 43, 69)",

          customStylePricing: {
            textAlign: "left",
          },
        },
      },
      items: [
        {
          title: "Music player",
          ratings: 5,
          price: "399",
          currency: "$",
          image: "/codingrippler/e-commerce/products/item1.png",
        },

        {
          title: "Bluetooth headphones",
          ratings: 4,
          price: "450",
          currency: "$",
          image: "/codingrippler/e-commerce/products/item2.png",
        },
        {
          title: "Headphones",
          ratings: 5,
          price: "700",
          currency: "$",
          image: "/codingrippler/e-commerce/products/item3.png",
        },
        {
          title: "Headphones",
          ratings: 5,
          price: "600",
          currency: "$",
          image: "/codingrippler/e-commerce/products/item4.png",
        },
        {
          title: "Wireless headphones",
          ratings: 5,
          price: "300",
          currency: "$",
          image: "/codingrippler/e-commerce/products/item5.png",
        },
        {
          title: "Music player",
          ratings: 5,
          price: "200",
          currency: "$",
          image: "/codingrippler/e-commerce/products/item1.png",
        },
      ],
    },

    blogPage: {
      topicText: "Latest News",
      header: "Our latest news",
      subHeader: "Immerse Yourself in Sound Like Never Before!",
      buttonText: "Explore Our Blog",
      heroTextStyles: {
        customHeader: {
          color: "rgb(34, 43, 69)",
          textAlign: "center",
        },
        customSubHeader: {
          textAlign: "center",
        },
      },
      customStyle: {
        customHeader: {
          color: "rgb(34, 43, 69)",
          fontSize: {
            xs: "1.5rem",
            lg: "2.5rem",
            xl: "2.5rem",
          },
        },
        customSubHeader: {
          fontSize: {
            xs: "1.25rem",
            lg: "1.5rem",
            xl: "1.5rem",
          },
        },

        customBlogHeader: {
          color: "rgb(34, 43, 69)",
          textAlign: "center",
          fontSize: {
            xs: "1.25rem",
            lg: "1.25rem",
            xl: "1.25rem",
          },
        },

        customStylePricing: {
          textAlign: "left",
          fontSize: {
            xs: "1.25rem",
            lg: "1.5rem",
            xl: "1.5rem",
          },
        },
      },

      blogs: [
        {
          image: "/codingrippler/parallax/goby.jpg",
          title: "How to choose the right headphones",
          text:
            "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
          buttonText: "Read more",
        },
        {
          image: "/codingrippler/parallax/larq-bottles.jpg",
          title: "How to choose the right headphones",
          text:
            "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
          buttonText: "Read more",
        },
        {
          image: "/codingrippler/parallax/nike-shoes-1.jpg",
          title: "How to choose the right headphones",
          text:
            "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
          buttonText: "Read more",
        },
      ],
    },

    latestProducts: {
      topicText: "Products",
      header: "New Arrivals",
      subHeader: "Immerse Yourself in Sound Like Never Before!",
      buttonText: "View all",
      items: [
        {
          title: "Bluetooth headphones",
          ratings: 4,
          price: "450",
          currency: "$",
          image: "/codingrippler/e-commerce/products/item2.png",
        },
        {
          title: "Headphones",
          ratings: 5,
          price: "600",
          currency: "$",
          image: "/codingrippler/e-commerce/products/item4.png",
        },
        {
          title: "Wireless headphones",
          ratings: 5,
          price: "300",
          currency: "$",
          image: "/codingrippler/e-commerce/products/item5.png",
        },
        {
          title: "Bluetooth headphones",
          ratings: 4,
          price: "450",
          currency: "$",
          image: "/codingrippler/e-commerce/products/item2.png",
        },
      ],
      customStyle: {
        customHeader: {
          color: "rgb(34, 43, 69)",
          textAlign: "center",
        },
        customSubHeader: {
          textAlign: "center",
        },

        customStylePricing: {
          textAlign: "left",
          fontSize: {
            xs: "1.25rem",
            lg: "1.5rem",
            xl: "1.5rem",
          },
        },
      },
    },

    whyUs: {
      topicText: "Benefits",
      header: "Why You'll Love It",
      subHeader: "",
      buttonText: "",
      customStyle: {
        customBgColor: "#f7faff",
        customHeader: {
          color: "rgb(34, 43, 69)",
          fontSize: {
            xs: "1.5rem",
            lg: "2.5rem",
          },
          textAlign: "left",
          m: 2,
        },
        customHeaderInfo: {
          color: "rgb(34, 43, 69)",
          fontSize: {
            xs: "1.5rem",
          },
          textAlign: "left",
          fontWeight: "500",
        },
        customDescription: {
          fontSize: {
            xs: "1.25rem",
          },
          textAlign: "left",
          fontWeight: "normal",
        },

        customSubHeader: {
          fontSize: {
            xs: "1.25rem",
            lg: "1.5rem",
          },
          textAlign: "left",
        },
        justifyContent: "center",
        alignItems: "flex-start",
      },

      items: [
        {
          header: "High quality",
          description:
            "We provide you the chance to experience the instrument up close.",
        },
        {
          header: "Musical instruments",
          description:
            "Our team is always ready to assist you in choosing the perfect musical instrument, whether it's strings, keyboard, percussion, or any other type.",
        },
        {
          header: "Free assistance",
          description:
            "Receive expert advice on selecting musical instruments, equipment, and accessories every time.",
        },
      ],
    },

    testimonials: {
      topicText: "Testimonials",
      header: "Relied upon by innovative businesses of all sizes, worldwide.",
      subHeader:
        "Businesses worldwide have had exceptional experiences with Web Rippler. Here’s what they’re saying.",
      buttonText: "Read all reviews",
      customStyle: {
        customHeader: {
          color: "rgb(34, 43, 69)",
        },
        customHeaderInfo: {
          color: "rgb(34, 43, 69)",
          textAlign: "left",
          fontWeight: "500",
        },
        customDescription: {
          textAlign: "left",
          fontWeight: "normal",
        },

        customSubHeader: {},
      },

      items: [
        {
          name: "John Doe",
          image: "/codingrippler/e-commerce/reviewes/profilePic4.jpg",
          designation: "MUI lover",
          description:
            "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
        },
        {
          name: "Mary Jane",
          image: "/codingrippler/e-commerce/reviewes/profilePic4.jpg",
          designation: "Business owner",
          description:
            "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
        },
        {
          name: "Calvin Klein",
          image: "/codingrippler/e-commerce/reviewes/profilePic4.jpg",
          designation: "Designer",
          description:
            "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
        },
      ],
    },

    newsLetter: [
      {
        headerText: "Subscribe to our newsletter",
        subHeaderText:
          "Be the first to know about our latest news and updates—don’t miss out!",
        buttonText: "Subscribe",
      },
    ],
  };

  const handleSubmit = () => {};

  return (
    <>
      <Helmet>
        <title>Web Rippler - e-commerce</title>
        <meta
          name="description"
          content="Transform your e-commerce store with a custom design, crafted by Web Rippler and the Coding Rippler team."
        />
        <meta property="og:title" content="Web Rippler - Home" />
        <meta
          property="og:description"
          content="Transform your e-commerce store with a custom design, crafted by Web Rippler and the Coding Rippler team."
        />
        <meta
          property="og:url"
          content="https://www.codingrippler.com/web/e-commerce"
        />
        <meta property="og:type" content="website" />
        <link
          rel="canonical"
          href="https://www.codingrippler.com/web/e-commerce/e-commerce"
        />
      </Helmet>
      <Grid container spacing={2}>
        <Grid item xs={12} />
        <Grid
          item
          lg={1}
          sx={{
            display: { xs: "none", lg: "block" },
          }}
        ></Grid>
        <Grid
          item
          xs={12}
          md={6}
          lg={5}
          xl={5}
          mt={{
            xs: 5,            
            // xs: `max(10vh, 64px)`,
            // sm: `max(10vh, 80px)`,
            // lg: `max(20vh, 125px)`,
          }}
        >
          <CommonContainer>
            <LayoutWithImage
              isPhone={isPhone}
              isTab={isTab}
              deviceWithSmallHeight={deviceWithSmallHeight}
              isPhoneorTab={isPhoneorTab}
              pageContent={pageContent}
              textStyle={{
                minHeight: {
                  xs: 350,
                  sm: 350,
                  md: 450,
                },
                height: {
                  xs: "auto",
                },
              }}
              mediaStyle={{
                height: {
                  xs: "35vh",
                  md: deviceWithSmallHeight ? "70vh" : "50vh",
                  lg: "75vh",
                  xl: "60vh",
                },
              }}
            >
              <Stack spacing={2}>
                <HeroText
                  variant={isPhoneorTab ? "h4" : "h3"}
                  customStyle={{ textAlign: "left" }}
                >
                  <span className="theme-specfic-colored-text">
                    Immerse Yourself in Sound
                  </span>{" "}
                  Like Never Before!{" "}
                </HeroText>

                <Box m={2} />

                <HeroSubHeader customStyle={{ textAlign: "left" }}>
                  Web Rippler crafts modern, professional designs for your
                  product—elevating its look while saving you valuable time!
                </HeroSubHeader>

                <HeroText
                  variant={isPhoneorTab ? "h4" : "h3"}
                  customStyle={{ textAlign: "left" }}
                >
                  <span className="red-colored-text">$369.75</span>
                </HeroText>

                <Box>
                  <CommonButtonV2
                    buttonText={"Discover The Offer"}
                    onClick={handleSubmit}
                    customStyle={{ fontSize: "1rem" }}
                  />
                </Box>

                <Box className="white-bg">
                  <HeroSubHeader
                    customStyle={{
                      fontWeight: "normal",
                      padding: "1rem",
                      textAlign: "left",
                    }}
                  >
                    {pageContent.additionalOfferText}
                  </HeroSubHeader>
                </Box>
              </Stack>
            </LayoutWithImage>
          </CommonContainer>
        </Grid>
        <Grid
          item
          xs={12}
          md={6}
          lg={5}
          sx={{
            position: "relative",
            mt: {
              xs: 2,
              sm: 2,
              md: 5,
              // md: `max(10vh, 80px)`,
              // lg: `max(20vh, 125px)`,
            },
            mb: {
              xs: 2,
              sm: 2,
              lg: 0,
            },
            minHeight: {
              xs: 350,
              sm: 350,
              md: 450,
            },
          }}
        >
          <CommonContainer>
            <Box
              display="flex"
              justifyContent="center"
              alignItems={{ xs: "flex-start", md: "flex-start" }}
              width="100%"
              height="100%"
            >
              <Box
                sx={{
                  position: "absolute",
                  minHeight: {
                    xs: 350,
                    sm: 350,
                    md: 450,
                  },
                }}
              >
                <CardMedia
                  component="img"
                  image={pageContent.imagePath}
                  alt={pageContent.alt}
                  sx={{
                    objectFit: "contain",
                    width: "100%",
                    maxHeight: {
                      xs: 350,
                      sm: 350,
                      md: 450,
                    },
                  }}
                />
              </Box>
            </Box>
          </CommonContainer>
        </Grid>
        <Grid
          item
          lg={1}
          sx={{
            display: { xs: "none", lg: "block" },
          }}
        />
        {/* Banner section starts here  */}
        <Grid
          item
          xs={0}
          lg={1}
          sx={{
            display: {
              xs: "none",
              lg: "block",
            },
          }}
        />
        <Grid item xs={12} lg={10}>
          <BannerLayoutWithImage
            isPhone={isPhone}
            isTab={isTab}
            deviceWithSmallHeight={deviceWithSmallHeight}
            isPhoneorTab={isPhoneorTab}
            pageContent={pageContent.banner}
            customStyle={pageContent.customStyle}
          />
        </Grid>
        <Grid
          item
          xs={0}
          lg={1}
          sx={{
            display: {
              xs: "none",
              lg: "block",
            },
          }}
        />
        <Grid
          item
          lg={1}
          sx={{
            display: {
              xs: "none",
              sm: "none",
              lg: "block",
            },
          }}
        />
        <Grid item xs={12} lg={10}>
          <ChooseByCategoryPage
            isPhoneorTab={isPhoneorTab}
            isPhone={isPhone}
            isTab={isTab}
            deviceWithSmallHeight={deviceWithSmallHeight}
            pageContent={pageContent.categoryData}
            customStyle={pageContent.categoryData[0].customStyle}
          />
        </Grid>
        <Grid
          item
          lg={1}
          sx={{
            display: {
              xs: "none",
              sm: "none",
              lg: "block",
            },
          }}
        />
        <Grid item xs={12} className="yellow-background-color">
          <Grid container spacing={0}>
            <Grid
              item
              lg={1}
              sx={{
                display: {
                  xs: "none",
                  sm: "none",
                  lg: "block",
                },
              }}
            />

            <Grid item xs={12} lg={10}>
              <EcommerceBanner
                isPhone={isPhone}
                isTab={isTab}
                deviceWithSmallHeight={deviceWithSmallHeight}
                isPhoneorTab={isPhoneorTab}
                pageContent={pageContent.discoverTheOffer}
                customStyle={{
                  minHeight: {
                    xs: 350,
                    md: 400,
                  },
                  height: {
                    sm: "30vh",
                  },
                  width: "100%",
                }}
              >
                <CommonContainer>
                  <Stack spacing={2}>
                    <HeroText
                      variant="h4"
                      customStyle={
                        pageContent.discoverTheOffer.customStyle.customHeader
                      }
                    >
                      {pageContent.discoverTheOffer.header}
                    </HeroText>

                    <Box m={2} />

                    <HeroSubHeader
                      customStyle={
                        pageContent.discoverTheOffer.customStyle.customSubHeader
                      }
                    >
                      {pageContent.discoverTheOffer.subHeader}
                    </HeroSubHeader>

                    <Box>
                      <CommonButtonV2
                        buttonText={pageContent.discoverTheOffer.buttonText}
                        onClick={handleSubmit}
                        customStyle={{ fontSize: "1rem" }}
                      />
                    </Box>
                  </Stack>
                </CommonContainer>
              </EcommerceBanner>
            </Grid>
            <Grid
              item
              lg={1}
              sx={{
                display: {
                  xs: "none",
                  sm: "none",
                  lg: "block",
                },
              }}
            />
          </Grid>
        </Grid>
        <Grid
          item
          lg={1}
          sx={{
            display: {
              xs: "none",
              sm: "none",
              lg: "block",
            },
          }}
        />
        <Grid item xs={12} lg={10}>
          <CommonContainer>
            <Stack
              spacing={2}
              sx={{
                mt: {
                  xs: 5,
                  lg: 10,
                },
              }}
            >
              <TopicContainer topicText={"Featured"}>
                <AutoAwesomeIcon />
              </TopicContainer>

              <HeroText variant="h4" customStyle={{ textAlign: "center" }}>
                {pageContent.product.header}
              </HeroText>

              <Box m={2} />

              <HeroSubHeader customStyle={{ textAlign: "center" }}>
                {pageContent.product.subHeader}
              </HeroSubHeader>

              <Box
                display="flex"
                width="100%"
                justifyContent="center"
                alignItems="center"
              >
                <CommonButtonV2
                  buttonText={pageContent.product.buttonText}
                  onClick={handleSubmit}
                  customStyle={{ fontSize: "1rem" }}
                />
              </Box>
            </Stack>
          </CommonContainer>

          <Grid
            container
            spacing={0}
            sx={{
              mb: 10,
            }}
          >
            <Grid item xs={12} />
            {pageContent.product.items.map((item, index) => (
              <Grid item xs={12} sm={6} lg={4} key={index}>
                <EcommerceCard
                  isPhoneorTab={isPhoneorTab}
                  itemToDisplay={item}
                  deviceWithSmallHeight={deviceWithSmallHeight}
                  customStyle={pageContent.product.customStyle}
                />
              </Grid>
            ))}
          </Grid>
        </Grid>
        <Grid
          item
          lg={1}
          sx={{
            display: {
              xs: "none",
              sm: "none",
              lg: "block",
            },
          }}
        />
        <Grid item xs={12} className="white-bg">
          <Box
            sx={{
              mt: 10,
            }}
          >
            <CommonContainer>
              <PageIntroWithButtonContainer
                pageContent={pageContent.blogPage}
                isPhone={isPhone}
                isTab={isTab}
                deviceWithSmallHeight={deviceWithSmallHeight}
                isPhoneorTab={isPhoneorTab}
                customStyle={pageContent.blogPage.heroTextStyles}
              />
            </CommonContainer>
          </Box>
          <CommonContainer>
            <Box
              sx={{
                mb: { xs: 10, sm: 0 },
              }}
            >
              <SliderContainer>
                {pageContent.blogPage.blogs.map((blog, index) => (
                  <OurBlog
                    key={index}
                    isPhone={isPhone}
                    isTab={isTab}
                    deviceWithSmallHeight={deviceWithSmallHeight}
                    isPhoneorTab={isPhoneorTab}
                    pageContent={blog}
                    customStyle={pageContent.blogPage.customStyle}
                  />
                ))}
              </SliderContainer>
            </Box>
          </CommonContainer>
        </Grid>
        <Grid
          item
          lg={1}
          sx={{
            display: {
              xs: "none",
              sm: "none",
              lg: "block",
            },
          }}
        />
        <Grid item xs={12} lg={10}>
          <CommonContainer>
            <Box
              sx={{
                mt: { xs: 5, lg: 10 },
              }}
            >
              <PageIntroWithButtonContainer
                pageContent={pageContent.latestProducts}
                isPhone={isPhone}
                isTab={isTab}
                deviceWithSmallHeight={deviceWithSmallHeight}
                isPhoneorTab={isPhoneorTab}
                customStyle={pageContent.latestProducts.customStyle}
              />
            </Box>
          </CommonContainer>

          <Grid container spacing={0}>
            {pageContent.latestProducts.items
              .slice(0, slice)
              .map((item, index) => (
                <Grid item xs={12} sm={6} lg={4} xl={4} key={index}>
                  <EcommerceCard
                    itemToDisplay={item}
                    key={index}
                    deviceWithSmallHeight={deviceWithSmallHeight}
                    isPhoneorTab={isPhoneorTab}
                    customStyle={pageContent.latestProducts.customStyle}
                  />
                </Grid>
              ))}
          </Grid>
        </Grid>
        <Grid
          item
          lg={1}
          sx={{
            display: {
              xs: "none",
              sm: "none",
              lg: "block",
            },
          }}
        />

        <Grid item xs={12} sx={{}}>
          <CommonContainer>
            <Box
              sx={{
                mt: { xs: 5, lg: 10 },
              }}
            >
              <TopicContainer topicText={pageContent.whyUs.topicText}>
                <AutoAwesomeIcon />
              </TopicContainer>
              <Box m={2} />
              <HeroText variant="h4" customStyle={{ textAlign: "center" }}>
                {pageContent.whyUs.header}
              </HeroText>
            </Box>
          </CommonContainer>
        </Grid>
        <Grid
          item
          lg={1}
          sx={{
            display: {
              xs: "none",
              sm: "none",
              lg: "block",
            },
          }}
        />

        <Grid
          item
          xs={12}
          lg={10}
          sx={{
            mb: 10,
          }}
        >
          <Grid container spacing={0}>
            {pageContent.whyUs.items.map((item, index) => (
              <Grid item xs={12} sm={4} lg={4} key={index}>
                <InfoCard
                  customStyle={pageContent.whyUs.customStyle}
                  pageContent={item}
                  isPhone={isPhone}
                  isTab={isTab}
                  deviceWithSmallHeight={deviceWithSmallHeight}
                  isPhoneorTab={isPhoneorTab}
                />
              </Grid>
            ))}
          </Grid>
        </Grid>
        <Grid
          item
          lg={1}
          sx={{
            display: {
              xs: "none",
              sm: "none",
              lg: "block",
            },
          }}
        />
        <Grid item xs={12} className="white-bg">
          <Grid container spacing={0}>
            <Grid item xs={12}>
              <CommonContainer>
                <Box
                  sx={{
                    mt: { xs: 5, lg: 10 },
                  }}
                >
                  <PageIntroWithButtonContainer
                    pageContent={pageContent.testimonials}
                    isPhone={isPhone}
                    isTab={isTab}
                    deviceWithSmallHeight={deviceWithSmallHeight}
                    isPhoneorTab={isPhoneorTab}
                  />
                </Box>
              </CommonContainer>
            </Grid>

            <Grid
              item
              lg={1}
              sx={{
                display: {
                  xs: "none",
                  sm: "none",
                  lg: "block",
                },
              }}
            />
            <Grid item xs={12} lg={10}>
              <Grid
                container
                spacing={2}
                sx={{
                  mb: 10,
                }}
              >
                {pageContent.testimonials.items.map((item, index) => (
                  <Grid item xs={12} sm={12} md={4} lg={4} key={index}>
                    <ReviewCard
                      customStyle={pageContent.testimonials.customStyle}
                      pageContent={item}
                      isPhone={isPhone}
                      isTab={isTab}
                      deviceWithSmallHeight={deviceWithSmallHeight}
                      isPhoneorTab={isPhoneorTab}
                    />
                  </Grid>
                ))}
              </Grid>
            </Grid>
            <Grid
              item
              lg={1}
              sx={{
                display: {
                  xs: "none",
                  sm: "none",
                  lg: "block",
                },
              }}
            />
          </Grid>
        </Grid>
        <Grid item xs={12} />
        <Grid
          item
          lg={1}
          sx={{
            display: {
              xs: "none",
              sm: "none",
              lg: "block",
            },
          }}
        />
        <Grid item xs={12} lg={10}>
          <CommonContainer>
            <SubmitYourEmail
              isPhone={isPhone}
              isTab={isTab}
              deviceWithSmallHeight={deviceWithSmallHeight}
              isPhoneorTab={isPhoneorTab}
              pageContent={pageContent.newsLetter}
            />
          </CommonContainer>
        </Grid>
        <Grid
          item
          lg={1}
          sx={{
            display: {
              xs: "none",
              sm: "none",
              lg: "block",
            },
          }}
        />
      </Grid>
    </>
  );
};
