import "./App.css";

// import { useEffect } from "react";
// import { visitCount } from "./utils/apiService";
import { createTheme, ThemeProvider } from "@mui/material";
import { useEffect } from "react";
import { Route, BrowserRouter as Router, Routes } from "react-router-dom";
import ChatbotEmbed from "./components/bots/ChatbotEmbed";
import { CodingRippler } from "./components/CodingRippler/CodingRippler";
import { Contact } from "./components/CodingRippler/CodingRipplerHome/ContactUs/Contact";
import { initGA, initHomepageSectionTracking } from "./components/google-analytics/initGA";

const lightThemeOptions = createTheme({
  palette: {
    mode: "light",
    primary: {
      main: "#6056ff", // Primary color
    },
    text: {
      primary: "rgba(0, 0, 0, 0.87)", // Converted from rgba(0, 0, 0, 0.87),
      secondary: "rgba(0, 0, 0, 0.54)",
    },
    background: {
      default: "#f7faff", // Fallback background color
    },
  },
  typography: {
    fontFamily: "Inter, sans-serif",
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: "8px", // Rounded corners for buttons
          padding: "10px 20px",
        },
      },
    },
    MuiPaper: {
      styleOverrides: {
        root: {
          borderRadius: "8px", // Rounded corners for Paper components
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          borderRadius: "8px", // Rounded corners for input fields
        },
        notchedOutline: {
          borderRadius: "8px", // Rounded corners for the outline
        },
      },
    },
  },
});

function App() {
  const logoPathLight = "/codingrippler/brand-logos/logo-main-light.svg";

  const url = process.env.REACT_APP_CHATBOT_URL;

  useEffect(() => {
    initGA(); // Initialize GA
    initHomepageSectionTracking(); // Initialize section tracking
  }, []);

  return (
    <ThemeProvider theme={lightThemeOptions}>
      <Router>
        <Routes>
          {/* <Route path="/ai/*" element={<AI logoPath={aiLogoLight} />} /> */}
          <Route
            path="/*"
            element={<CodingRippler logoPath={logoPathLight} />}
          />

          {/* <Route path="/about/*" element={<About logoPath={logoPathLight} />} /> */}

          {/* <Route
            path="/web/*"
            element={<WebRippler logoPath={WebRipplerLogo} />}
          /> */}

          <Route
            path="/contact/*"
            element={<Contact logoPath={logoPathLight} />}
          />
        </Routes>
      </Router>

      <ChatbotEmbed url={url} />
    </ThemeProvider>
  );
}

export default App;
