import { Box, Button, Typography } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import React from "react";

export const About = ({ children, ...props }) => {
  const theme = useTheme();

  const handleAboutClick = (context) => {
    window.location.href = `${context}`;
  };

  const { context = "/", customTextColor } = props;

  return (
    <Button
      variant="text"
      style={{
        color: customTextColor ? "white" : theme.palette.text.primary,
      }}
      onClick={() => {
        handleAboutClick(context);
      }}
    >
      <Box display="flex" alignItems="center">
        <Typography
          variant="h6"
          fontFamily="Inter, sans-serif" // Add your desired font family here
          fontSize="1rem"
        >
          About
        </Typography>{" "}
      </Box>
    </Button>
  );
};
