import { CardMedia } from "@mui/material";
import { Box } from "@mui/system";
import { ButtonContainers } from "./ButtonContainers";

export const CRNavigationScrollView = ({ children, ...props }) => {
  const { visibility } = props;

  const logoPath = "/codingrippler/brand-logos/logo-main-light.svg";

  const clickHandler = () => {
    window.location.href = "/";
  };
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "space-between",
        backgroundColor: "white",
        width: "100%",
        position: "fixed",
        zIndex: 100,
        overflow: "visible", //To ensure that if the user hover over the components in the navigation bar, menu items are still visible
        visibility: visibility ? "visible" : "hidden",
        top: 0,
      }}
    >
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          // maxWidth: "100%",
          // maxHeight: "5vh",
          height: 100,
          width: "25rem",
          overflow: "hidden",
          transform: "translate(5%, 0)",
        }}
      >
        <CardMedia
          component="img"
          image={logoPath}
          alt="Coding Rippler"
          onClick={() => clickHandler()}
          sx={{
            objectFit: "contain",
            maxHeight: "100%",
            maxWidth: "100%",
            cursor: "pointer", // Add this line to change the cursor on hover
          }}
        />
      </Box>
      <Box
        sx={{
          borderRadius: "24px",
          display: "flex", // Enables flexbox
          flexDirection: "row", // Arranges children horizontally
          alignItems: "center", // Vertically centers the items (optional)
          backgroundColor: "transparent", // Set background to transparent
        }}
      >
        <ButtonContainers scrollView={visibility} />
      </Box>
    </Box>
  );
};
