import { Route, Routes } from "react-router-dom";
import { CatalystAgent } from "./BoshTheSalesAgent/CatalystAgent";

export const AIAgentsHome = ({ children, ...props }) => {
  const { isPhoneOrTab, isPhone, isTab, deviceWithSmallHeight } = props;

  return (
    <>
      <Routes>
        <Route
          path="/catalyst"
          element={
            <CatalystAgent
              isPhone={isPhone}
              isTab={isTab}
              deviceWithSmallHeight={deviceWithSmallHeight}
              isPhoneOrTab={isPhoneOrTab}
            />
          }
        />
        {/* <Route
          path="/catalyst-inbound-agent"
          element={
            <InboundHome
              isPhone={isPhone}
              isTab={isTab}
              deviceWithSmallHeight={deviceWithSmallHeight}
              isPhoneOrTab={isPhoneOrTab}
            />
          }
        />
        <Route
          path="/catalyst-outbound-agent"
          element={
            <OutboundHome
              isPhone={isPhone}
              isTab={isTab}
              deviceWithSmallHeight={deviceWithSmallHeight}
              isPhoneOrTab={isPhoneOrTab}
            />
          }
        /> */}
      </Routes>
    </>
  );
};
