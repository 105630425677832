import { TabsContainer } from "./TabsContainer";

import { Grid } from "@mui/material";
import { OperationsTabDetails } from "./TabWidgets/OperationsTabDetails";
import { RouteToContactUs } from "../../../../CodingRippler/CodingRipplerHome/ContactUs/ContactUs";

export const OperationsTabPanel = (props) => {
  const { value } = props;

  return (
    <TabsContainer value={value} index={4}>
      <Grid container spacing={2}>
        <Grid item xs={12} lg={4}>
          <OperationsTabDetails
            imageSrc="/airippler/research-tab-icons/pdf-icon.svg"
            imageAlt="Description of the image"
            title="PDF Data Extractor"
            description={`Automate data extraction from complex PDFs with cutting-edge OCR and LLM technology. This tool streamlines retrieving specific data points from PDF documents, making it ideal for professionals handling large volumes of information who need fast, accurate extraction.`}
            handleButtonClick={RouteToContactUs}
            buttonText={"Contact Us"}
          />
        </Grid>

        <Grid item xs={12} lg={4}>
          <OperationsTabDetails
            imageSrc="/airippler/research-tab-icons/excel.svg"
            imageAlt="Description of the image"
            title="CSV Data Analyzer"
            description={`Easily analyze CSV data by asking targeted questions. This tool simplifies data analysis tasks, enabling you to extract meaningful insights from your data without requiring advanced technical skills—ideal for operational efficiency and informed decision-making.`}
            handleButtonClick={RouteToContactUs}
            buttonText={"Contact Us"}
          />
        </Grid>

        <Grid item xs={12} lg={4}>
          <OperationsTabDetails
            imageSrc="/airippler/research-tab-icons/invoice-icon.svg"
            imageAlt="Description of the image"
            title="Invoice Data Extractor"
            description={`Automate data extraction from complex invoice PDFs using advanced OCR and LLM technologies. This tool quickly retrieves specific data points, making it invaluable for professionals handling large volumes of documents, ensuring fast and accurate information processing.`}
            handleButtonClick={RouteToContactUs}
            buttonText={"Contact Us"}
          />
        </Grid>
      </Grid>{" "}
    </TabsContainer>
  );
};
