import React, { useState, useEffect, useCallback } from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  IconButton,
  Box,
  Typography,
  useMediaQuery,
  useTheme,
  Grid,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { contactService } from '../../../../services/contactService'; // Reusing your existing service
import ModernLoader from '../../CRAnimations/ModernLoaders';

// Tracking function for analytics
export const trackAndOpenSalesDialog = (setDialogOpen, productType = '', pricingTier = '') => {
  // Open the dialog
  setDialogOpen(true);

  // Google Analytics Event Tracking
  if (window.gtag) {
    window.gtag('event', 'contact_sales_clicked', {
      event_category: 'Sales Funnel',
      event_label: 'Contact Sales Dialog',
      value: 1,
      product_type: productType,
      pricing_tier: pricingTier
    });
  }
};

const ContactSalesDialog = ({ open, onClose, selectedProductType = '', selectedPricingTier = '' }) => {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));
  const [loading, setLoading] = useState(false);
  
  // Add state for success notification
  const [showSuccess, setShowSuccess] = useState(false);
  const [successTimer, setSuccessTimer] = useState(null);
  
  // Add state to track if form has been modified
  const [formModified, setFormModified] = useState(false);
  
  // Add state for confirmation dialog
  const [showConfirmClose, setShowConfirmClose] = useState(false);
  
  // Store selected values for use in message, but don't include in formData
  const [selectedProduct, setSelectedProduct] = useState(selectedProductType || '');
  const [selectedTier, setSelectedTier] = useState(selectedPricingTier || '');
  
  const [formData, setFormData] = useState({
    email: '',
    fullName: '',
    message: '',
  });
  
  const [formErrors, setFormErrors] = useState({});
  
  // Update selected product and tier if initial values change
  useEffect(() => {
    if (selectedProductType && selectedProductType !== selectedProduct) {
      setSelectedProduct(selectedProductType);
    }
    
    if (selectedPricingTier && selectedPricingTier !== selectedTier) {
      setSelectedTier(selectedPricingTier);
    }
  }, [selectedProductType, selectedPricingTier, selectedProduct, selectedTier]);
  
  // Get placeholder text based on product type and pricing tier
  const getMessagePlaceholder = () => {
    let baseText = "Tell us about your specific needs and requirements.";
    
    if (selectedProduct && selectedTier) {
      return `You're interested in our ${selectedProduct} ${selectedTier} plan. ${baseText}`;
    } else if (selectedProduct) {
      return `You're interested in our ${selectedProduct} solutions. ${baseText}`;
    } else if (selectedTier) {
      return `You're interested in our ${selectedTier} plan. ${baseText}`;
    }
    
    return baseText;
  };
  
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
    
    // Set form as modified if any field has been edited
    if (!formModified) {
      setFormModified(true);
    }
    
    // Clear error when field is edited
    if (formErrors[name]) {
      setFormErrors({ ...formErrors, [name]: null });
    }
  };
  
  const validateForm = () => {
    const errors = {};
    
    if (!formData.email) {
      errors.email = 'Email Address is required';
    } else if (!/\S+@\S+\.\S+/.test(formData.email)) {
      errors.email = 'Email is not valid';
    }
    
    return errors;
  };
  
  const handleSubmit = async (e) => {
    // If the event was triggered by a form submission, prevent default behavior
    if (e) {
      e.preventDefault();
    }
    
    const errors = validateForm();
    if (Object.keys(errors).length === 0) {
      setLoading(true);
      setFormErrors({});
      
      try {
        // Prepare the full message with product and tier info if available
        let fullMessage = formData.message;
        
        if (selectedProduct || selectedTier) {
          fullMessage = `${selectedProduct ? 'Product: ' + selectedProduct : ''}${selectedProduct && selectedTier ? ', ' : ''}${selectedTier ? 'Plan: ' + selectedTier : ''}\n\n${formData.message}`;
        }
        
        // Track form submission
        window.gtag?.('event', 'sales_form_submitted', {
          event_category: 'Sales Funnel',
          event_label: `${selectedProduct} - ${selectedTier}`,
        });
        
        const data = await contactService.sendContactForm({
          ...formData,
          message: fullMessage,
          subject: `Sales Inquiry: ${selectedProduct || ''} ${selectedTier || ''}`.trim(),
          projectGoals: fullMessage,
          formType: 'sales',
        });

        if (data?.success ?? false) {
          resetForm();
          setLoading(false);
          setShowSuccess(true);
          setFormModified(false);
          
          // Auto-close the dialog after 5 seconds
          const timer = setTimeout(() => {
            onClose(true);
          }, 5000);
          setSuccessTimer(timer);
        } else {
          setFormErrors({ submit: "Failed to send sales inquiry. Please try again." });
          setLoading(false);
        }
      } catch (error) {
        setFormErrors({ submit: error.message });
        setLoading(false);
      }
    } else {
      setFormErrors(errors);
    }
  };
  
  // Clear success timer on unmount or dialog close
  useEffect(() => {
    return () => {
      if (successTimer) {
        clearTimeout(successTimer);
      }
    };
  }, [successTimer]);
  
  // Reset form function wrapped in useCallback
  const resetForm = useCallback(() => {
    setFormData({
      email: '',
      fullName: '',
      message: '',
    });
    setFormErrors({});
    setFormModified(false);
  }, []);
  
  // Handle dialog close attempt
  const handleCloseAttempt = () => {
    // Only show confirmation if form has been modified and not in loading or success state
    if (formModified && !loading && !showSuccess) {
      setShowConfirmClose(true);
    } else {
      onClose(false);
    }
  };
  
  // Handle confirmed close
  const handleConfirmedClose = () => {
    setShowConfirmClose(false);
    onClose(false);
  };
  
  // Reset form and success state when dialog opens/closes
  useEffect(() => {
    if (!open) {
      // Reset success state and form when dialog closes
      setShowSuccess(false);
      resetForm();
      
      // Clear any existing timers
      if (successTimer) {
        clearTimeout(successTimer);
        setSuccessTimer(null);
      }
    }
  }, [open, successTimer, resetForm]);
  
  // Styling variables for reuse
  const textFieldStyle = {
    '& .MuiOutlinedInput-root': {
      borderRadius: '12px',
      transition: 'all 0.2s ease',
      '&:hover fieldset': {
        borderColor: '#6366F1',
      },
      '&.Mui-focused fieldset': {
        borderColor: '#6366F1',
        borderWidth: '2px',
      },
      '&.Mui-focused': {
        boxShadow: '0 0 0 3px rgba(99, 102, 241, 0.1)',
      },
    },
    '& .MuiInputLabel-root.Mui-focused': {
      color: '#6366F1',
    },
  };
  
  // Confirmation dialog for closing
  const ConfirmCloseDialog = () => (
    <Dialog 
      open={showConfirmClose} 
      onClose={() => setShowConfirmClose(false)}
      maxWidth="xs"
      fullWidth
      PaperProps={{
        sx: {
          borderRadius: '16px',
          boxShadow: '0 10px 40px rgba(0, 0, 0, 0.2)',
        }
      }}
    >
      <DialogTitle sx={{ 
        fontWeight: 600,
        borderBottom: '1px solid rgba(0, 0, 0, 0.08)',
        p: 2.5
      }}>
        Discard changes?
      </DialogTitle>
      <DialogContent sx={{ p: 3, pt: 2.5 }}>
        <Typography variant="body1">
          You have unsaved changes. Are you sure you want to close this form? Your data will be lost.
        </Typography>
      </DialogContent>
      <DialogActions sx={{ p: 2, px: 3 }}>
        <Button 
          onClick={() => setShowConfirmClose(false)} 
          sx={{ 
            borderRadius: '10px',
            textTransform: 'none',
            fontWeight: 600
          }}
        >
          Continue editing
        </Button>
        <Button 
          onClick={handleConfirmedClose}
          sx={{ 
            backgroundColor: '#e53935',
            color: 'white',
            borderRadius: '10px',
            textTransform: 'none',
            fontWeight: 600,
            '&:hover': {
              backgroundColor: '#d32f2f',
            }
          }}
        >
          Discard
        </Button>
      </DialogActions>
    </Dialog>
  );

  // Get dialog title based on product type
  const getDialogTitle = () => {
    if (selectedProduct === 'Web Solutions') {
      return 'Contact Sales - Web Solutions';
    } else if (selectedProduct === 'AI Solutions') {
      return 'Contact Sales - AI Solutions';
    }
    return 'Contact Sales';
  };

  return (
    <>
      <Dialog
        open={open}
        onClose={handleCloseAttempt}
        fullScreen={fullScreen}
        maxWidth="sm"
        fullWidth
        disableEscapeKeyDown={true}
        PaperProps={{
          sx: {
            borderRadius: "16px",
            backgroundColor: "#f8f9ff",
            overflow: "hidden",
            boxShadow: "0 10px 40px rgba(0, 0, 0, 0.15)",
          },
        }}
      >
        {(loading || showSuccess) && (
          <Box
            sx={{
              position: "absolute",
              top: 0,
              left: 0,
              width: "100%",
              height: "100%",
              background: "rgba(245, 247, 255, 0.92)",
              backdropFilter: "blur(8px)",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              zIndex: 9999,
              borderRadius: "16px",
              gap: 3,
              transition: "all 0.3s ease",
            }}
          >
            {loading ? (
              <>
                <ModernLoader
                  dotColor="linear-gradient(135deg, #6366F1 0%, #818cf8 100%)"
                  barColor="linear-gradient(90deg, #6366F1, #818cf8, #6366F1)"
                  barBgColor="rgba(99, 102, 241, 0.2)"
                />
                <Typography
                  variant="body1"
                  sx={{
                    mt: 2,
                    fontWeight: 600,
                    fontSize: "1.1rem",
                    color: "#6366F1",
                    letterSpacing: "0.4px",
                    animation: "fadeInOut 2s infinite",
                    "@keyframes fadeInOut": {
                      "0%": { opacity: 0.7 },
                      "50%": { opacity: 1 },
                      "100%": { opacity: 0.7 },
                    },
                  }}
                >
                  Sending your inquiry...
                </Typography>
              </>
            ) : (
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center",
                  textAlign: "center",
                  maxWidth: "80%",
                  animation: "scaleUp 0.5s ease-out",
                  "@keyframes scaleUp": {
                    "0%": { transform: "scale(0.8)", opacity: 0 },
                    "100%": { transform: "scale(1)", opacity: 1 },
                  },
                }}
              >
                <Box
                  sx={{
                    width: 80,
                    height: 80,
                    borderRadius: "50%",
                    backgroundColor: "rgba(99, 102, 241, 0.1)",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    mb: 3,
                  }}
                >
                  <Box
                    sx={{
                      width: 40,
                      height: 40,
                      borderRadius: "50%",
                      backgroundColor: "#53d86a",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      "&::after": {
                        content: '""',
                        display: "block",
                        width: 15,
                        height: 8,
                        borderBottom: "3px solid white",
                        borderLeft: "3px solid white",
                        transform: "rotate(-45deg) translate(1px, -2px)",
                      },
                    }}
                  />
                </Box>
                <Typography
                  variant="h5"
                  sx={{
                    fontWeight: 700,
                    color: "#6366F1",
                    mb: 2,
                  }}
                >
                  Inquiry Sent Successfully!
                </Typography>
                <Typography
                  variant="body1"
                  sx={{
                    color: "#4a4a4a",
                    mb: 3,
                    maxWidth: "400px",
                  }}
                >
                  Thank you for reaching out to us. We'll get back to you within
                  24-48 hours.
                </Typography>
                <Box
                  sx={{
                    width: "100%",
                    height: "4px",
                    backgroundColor: "rgba(99, 102, 241, 0.2)",
                    borderRadius: "2px",
                    position: "relative",
                    overflow: "hidden",
                    mt: 1,
                  }}
                >
                  <Box
                    sx={{
                      position: "absolute",
                      top: 0,
                      left: 0,
                      height: "100%",
                      width: "100%",
                      backgroundColor: "#6366F1",
                      animation: "progress 5s linear",
                      "@keyframes progress": {
                        "0%": { width: "100%" },
                        "100%": { width: "0%" },
                      },
                    }}
                  />
                </Box>
                <Typography
                  variant="caption"
                  sx={{
                    mt: 1,
                    color: "rgba(74, 74, 74, 0.7)",
                  }}
                >
                  This window will close automatically in a few seconds
                </Typography>
              </Box>
            )}
          </Box>
        )}

        <DialogTitle
          sx={{
            background: "linear-gradient(135deg, #6366F1 0%, #818cf8 100%)",
            color: "white",
            fontWeight: 600,
            p: 3,
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            position: "relative",
            overflow: "visible",
            "&::before": {
              content: '""',
              position: "absolute",
              top: "-50%",
              right: "-50%",
              width: "100%",
              height: "200%",
              background:
                "radial-gradient(circle, rgba(255,255,255,0.1) 0%, rgba(255,255,255,0) 70%)",
              transform: "rotate(30deg)",
              pointerEvents: "none",
              zIndex: 1,
            },
          }}
        >
          <Typography
            variant="h5"
            component="div"
            sx={{
              fontWeight: 700,
              fontSize: "1.5rem",
              letterSpacing: "0.5px",
              textShadow: "0 2px 4px rgba(0,0,0,0.1)",
            }}
          >
            {getDialogTitle()}
          </Typography>
          <IconButton
            edge="end"
            color="inherit"
            onClick={handleCloseAttempt}
            aria-label="close"
            sx={{
              backgroundColor: "rgba(255,255,255,0.1)",
              "&:hover": {
                backgroundColor: "rgba(255,255,255,0.2)",
              },
              transition: "all 0.2s ease",
              zIndex: 100,
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>

        <DialogContent sx={{ p: 3 }}>
          {selectedTier && (
            <Box
              sx={{
                display: "inline-block",
                px: 2,
                py: 0.75,
                bgcolor: "rgba(99, 102, 241, 0.1)",
                color: "#6366F1",
                borderRadius: "20px",
                fontWeight: 600,
                fontSize: "0.875rem",
                mb: 3,
                mt: 2,
                border: "1px solid rgba(99, 102, 241, 0.2)",
              }}
            >
              {selectedTier} Plan
            </Box>
          )}

          <Typography
            variant="body1"
            sx={{
              mb: 3,
              mt: selectedTier ? 0 : 2,
              fontSize: "1rem",
              lineHeight: 1.5,
              color: "#4a4a4a",
              position: "relative",
              pl: 2,
            }}
          >
            <Box
              sx={{
                position: "absolute",
                left: 0,
                top: 0,
                bottom: 0,
                width: "3px",
                borderRadius: "4px",
                background: "linear-gradient(to bottom, #6366F1, #818cf8)",
              }}
            />
            Tell us about your business needs, and we'll help you find the right
            solution for your requirements.
          </Typography>

          <Box
            component="form"
            onSubmit={handleSubmit}
            noValidate
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: 3,
            }}
          >
            <Grid container spacing={2.5}>
              <Grid item xs={12}>
                <TextField
                  required
                  fullWidth
                  label="Email Address"
                  name="email"
                  type="email"
                  value={formData.email}
                  onChange={handleChange}
                  error={!!formErrors.email}
                  helperText={formErrors.email}
                  sx={textFieldStyle}
                />
              </Grid>

              <Grid item xs={12}>
                <TextField
                  fullWidth
                  label="Company Name (Optional)"
                  name="fullName"
                  value={formData.fullName}
                  onChange={handleChange}
                  error={!!formErrors.fullName}
                  helperText={formErrors.fullName}
                  sx={textFieldStyle}
                />
              </Grid>

              <Grid item xs={12}>
                <TextField
                  fullWidth
                  label="Tell us about your needs"
                  name="message"
                  multiline
                  rows={4}
                  value={formData.message}
                  onChange={handleChange}
                  placeholder={getMessagePlaceholder()}
                  sx={textFieldStyle}
                />
              </Grid>
            </Grid>

            {formErrors.submit && (
              <Box
                sx={{
                  backgroundColor: "rgba(211, 47, 47, 0.04)",
                  borderLeft: "4px solid #d32f2f",
                  padding: 2,
                  borderRadius: "0 8px 8px 0",
                }}
              >
                <Typography color="error" variant="body2">
                  {formErrors.submit}
                </Typography>
              </Box>
            )}
            {/* Hidden submit button to capture Enter key press */}
            <Button type="submit" sx={{ display: "none" }} />
          </Box>
        </DialogContent>

        <DialogActions sx={{ p: 4, justifyContent: "center", gap: 2 }}>
          <Button
            onClick={resetForm}
            sx={{
              borderRadius: "12px",
              px: 4,
              py: 1.2,
              textTransform: "none",
              fontWeight: 600,
              color: "#5f82ff",
              border: "1px solid rgba(95, 130, 255, 0.5)",
              "&:hover": {
                backgroundColor: "rgba(95, 130, 255, 0.05)",
                border: "1px solid #5f82ff",
                transform: "translateY(-2px)",
                transition: "all 0.2s ease",
              },
            }}
            disabled={loading}
          >
            Clear
          </Button>
          <Button
            onClick={handleSubmit}
            variant="contained"
            className="primary-button"
            disableElevation
            sx={{
              borderRadius: "12px",
              px: 4,
              py: 1.2,
            }}
            disabled={loading}
          >
            Submit
          </Button>
        </DialogActions>
      </Dialog>

      {/* Confirmation dialog */}
      <ConfirmCloseDialog />
    </>
  );
};

export default ContactSalesDialog;