import AutoAwesomeIcon from "@mui/icons-material/AutoAwesome";
import DataObjectOutlinedIcon from "@mui/icons-material/DataObjectOutlined";
import KeyboardArrowDownOutlinedIcon from "@mui/icons-material/KeyboardArrowDownOutlined";
import KeyboardArrowUpOutlinedIcon from "@mui/icons-material/KeyboardArrowUpOutlined";
import { Card, CardContent, Grid } from "@mui/material";
import { Box } from "@mui/system";
import { CommonContainer } from "../../../CodingRippler/CodingRipplerWidgets";
import { FeaturesContainer } from "../../AIHome/Features/FeaturesWidgets";
import { RequestDemo, TryForFree } from "../../AIHome/HomePageWidgets/Buttons";
import { IntroSubHeadingButtons } from "../../AIHome/HomePageWidgets/IntroWidgets";
import {
  AICommonBoxContainer,
  CommonHeaderTypography,
  CommonSubHeaderTypography,
  ResponsiveBoxCenteredChildren,
} from "../../AIUtil/AIUtils";
import {
  FAQsCard,
  ProductsPageHeader,
  ProductsPageSubHeader,
  TopicContainer,
} from "../ProductsWidget";
import { Helmet } from "react-helmet-async";

export const AIAgentsHome = ({ children, ...props }) => {
  const { isPhoneOrTab } = props;

  const faqData = [
    {
      question: "What is an AI Agent?",
      answer:
        "An AI agent is intelligent software designed to understand language, learn from data, and take actions to achieve specific goals automatically. For example, it can act as an assistant that comprehends natural language requests and performs tasks for you. Key capabilities of AI agents include:",
      points: [
        "Natural Language Processing: Understanding both text and voice requests.",
        "Continuous Learning: Improving over time as more data is provided.",
        "Task Automation: Handling repetitive tasks to save time and effort.",
        "Information Retrieval: Accessing data sources to provide accurate answers.",
      ],
      icon: [
        <KeyboardArrowDownOutlinedIcon fontSize="large" />,
        <KeyboardArrowUpOutlinedIcon fontSize="large" />,
      ],
    },
    {
      question: "Why Build Your Own AI Agent?",
      answer:
        "Discover how building your own AI agent can transform your business and boost productivity:",
      points: [
        "Automate Routine Tasks: Save time and reduce manual effort by having AI agents handle repetitive processes like form completion and report generation.",
        "Instant Access to Information: Quickly retrieve relevant information from multiple sources, enabling your team to focus on strategic priorities.",
        "Enhance Customer Service: Boost support efficiency with AI that can resolve common queries, direct requests, and escalate issues as needed.",
        "Enable Data-Driven Decisions: Leverage AI to analyze trends, predict outcomes, and provide actionable insights, empowering better decision-making.",
      ],
      icon: [
        <KeyboardArrowDownOutlinedIcon fontSize="large" />,
        <KeyboardArrowUpOutlinedIcon fontSize="large" />,
      ],
    },
    {
      question: "Get Started with Your Own AI Agent in Minutes",
      answer:
        "AI Rippler makes building your own AI agent effortless with no coding required. Just follow these simple steps:",
      points: [
        "Creating an Agent: Start by naming your agent, setting an avatar, and adding a description. This forms the unique identity of your AI assistant.",
        "Adding Skills and Tools: Choose from a library of pre-built skills like API calls and data processing, then drag and drop to equip your agent with the tools it needs to perform tasks.",
        "Setting Triggers: Define conditions to activate skills, such as initiating specific actions when receiving certain queries.",
        "Talk to Your Agent: Start interacting with your agent using natural language. The agent will improve as it gets more conversations.",
      ],
      icon: [
        <KeyboardArrowDownOutlinedIcon fontSize="large" />,
        <KeyboardArrowUpOutlinedIcon fontSize="large" />,
      ],
    },
    {
      question: "Next Steps",
      answer:
        "AI Rippler makes it easy for anyone to create AI agents tailored to your business needs.",
      points: [
        "Add more skills and integrate additional data sources to tackle a broader range of tasks and use cases.",
        "Easily connect your agent to popular tools like Slack, Twitter, and more to automate workflows across your platforms.",
        "Monitor conversations and interactions to keep improving your agent’s performance and accuracy.",
        "Share your AI agent with teammates to collaborate, optimize workflows, and boost overall productivity.",
      ],
      icon: [
        <KeyboardArrowDownOutlinedIcon fontSize="large" />,
        <KeyboardArrowUpOutlinedIcon fontSize="large" />,
      ],
    },
  ];

  return (
    <>
      <Helmet>
        <title>AI Rippler - AI Agents</title>
        <meta
          name="description"
          content="Discover AI agents from AI Rippler. Learn how AI agents can help automate tasks, enhance customer service, and provide data-driven insights."
        />
        <meta property="og:title" content="AI Rippler - AI Agents" />
        <meta
          property="og:description"
          content="Discover AI agents from AI Rippler. Learn how AI agents can help automate tasks, enhance customer service, and provide data-driven insights."
        />
        <meta
          property="og:url"
          content="https://www.codingrippler.com/ai/ai-agents"
        />
        <meta property="og:type" content="website" />
        <link
          rel="canonical"
          href="https://www.codingrippler.com/ai/ai-agents"
        />
      </Helmet>
      <Grid container spacing={10} mt={2}>
        <Grid item xs={12}>
          <TopicContainer topicText="AI Agent">
            <AutoAwesomeIcon />
          </TopicContainer>
        </Grid>

        <Grid item xs={12}>
          <CommonContainer>
            <ProductsPageHeader
              headerText={
                <CommonHeaderTypography variant={isPhoneOrTab ? "h4" : "h2"}>
                  <span className="theme-specfic-colored-text">
                    Recruit AI Agents to Grow Your Business
                  </span>
                </CommonHeaderTypography>
              }
            />

            <Box m={2} />
            <ProductsPageSubHeader
              headerText={
                <CommonSubHeaderTypography>
                  Expand your capabilities without expanding your workforce.
                </CommonSubHeaderTypography>
              }
            />
          </CommonContainer>
        </Grid>

        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
          <ResponsiveBoxCenteredChildren>
            <TryForFree />
            <Box m={2} />
            <RequestDemo />
          </ResponsiveBoxCenteredChildren>
          <Box m={2} />
          <IntroSubHeadingButtons />
        </Grid>

        {/* <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
        <Container sx={{ mt: 2, mb: 2 }}>
          <AnimatedBox>
            <YouTubeBox />
          </AnimatedBox>
        </Container>
      </Grid> */}

        {/* Feature page  */}

        <Grid item xs={12}>
          <TopicContainer topicText="Features">
            <DataObjectOutlinedIcon />
          </TopicContainer>
        </Grid>

        <Grid item xs={12}>
          <CommonContainer>
            <ProductsPageHeader
              headerText={
                <CommonHeaderTypography>
                  Put Your Business on{" "}
                  <span className="theme-specfic-colored-text">Autopilot</span>{" "}
                  for Better Results
                </CommonHeaderTypography>
              }
            />

            <Box m={2} />
            <ProductsPageSubHeader
              headerText={
                <CommonSubHeaderTypography>
                  Streamline your operations and let AI handle the rest.
                </CommonSubHeaderTypography>
              }
            />
          </CommonContainer>
        </Grid>

        <Grid item xs={12}>
          <AICommonBoxContainer>
            <FeaturesContainer />
          </AICommonBoxContainer>
        </Grid>

        <Grid item xs={12}>
          <CommonContainer>
            <ProductsPageHeader
              headerText={
                <CommonHeaderTypography>
                  <span>Everything You Need to Know About AI Agents</span>
                </CommonHeaderTypography>
              }
            />
          </CommonContainer>
        </Grid>

        {/* <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
        <CommonContainer>
          {faqData.map((faq, index) => (
            <FAQsCard key={index} question={faq.question}>
              {faq.answer}
              <ul>
                {faq.points.map((point, idx) => (
                  <li key={idx}>{point}</li>
                ))}
              </ul>
            </FAQsCard>
          ))}
        </CommonContainer>
      </Grid> */}

        <Grid item xs={12}>
          <CommonContainer>
            <Grid container>
              <Grid
                item
                md={1}
                xl={3}
                sx={{
                  display: {
                    xs: "none",
                    sm: "none",
                    md: "block",
                    lg: "block",
                  },
                }}
              ></Grid>
              <Grid item xs={12} md={10} xl={6}>
                <Card
                  sx={{ marginBottom: "1rem", borderRadius: "24px", p: 4 }}
                  elevation={8}
                >
                  <CardContent>
                    {faqData.map((faq, index) => (
                      <Box key={index} mb={5}>
                        <FAQsCard
                          question={faq.question}
                          answer={faq.answer}
                          points={faq.points}
                          openingIcon={faq.icon[0]}
                          closingIcon={faq.icon[1]}
                          isPhoneOrTab={isPhoneOrTab}
                        />
                      </Box>
                    ))}
                  </CardContent>
                </Card>
              </Grid>
              <Grid
                item
                md={1}
                xl={3}
                sx={{
                  display: {
                    xs: "none",
                    sm: "none",
                    md: "block",
                    lg: "block",
                  },
                }}
              ></Grid>
            </Grid>
          </CommonContainer>
        </Grid>
      </Grid>
    </>
  );
};
