// import React from 'react';
// import {
//   Box,
//   Container,
//   Typography
// } from '@mui/material';
// import { styled } from '@mui/material/styles';
// import CardLayout from '../../CRAnimations/Offers/AIStudioCards'; // Import CardLayout component

// // Simple styled component for the section
// const FeatureSection = styled(Box)(({ theme }) => ({
//   padding: theme.spacing(12, 0),
//   background: 'linear-gradient(135deg, #fcfcff 0%, #f8f9ff 100%)',
//   position: 'relative',
//   overflow: 'hidden',
// }));

// const WhatWeOffer = () => {
//   return (
//     <FeatureSection>
//       <Container sx={{ maxWidth: '1500px !important', width: '95%', mx: 'auto', px: { xs: 2, md: 3, lg: 4 } }}>
//         {/* Simple header section */}
//         <Box sx={{ 
//           textAlign: 'center', 
//           mb: 6
//         }}>
//           <Typography 
//             variant="h6" 
//             component="p" 
//             sx={{ 
//               color: 'primary.main', 
//               fontWeight: 700,
//               textTransform: 'uppercase',
//               letterSpacing: '1.5px',
//               mb: 2
//             }}
//           >
//             WHAT WE OFFER
//           </Typography>
          
//           <Typography 
//             variant="h2" 
//             component="h2" 
//             sx={{ 
//               fontWeight: 700, 
//               mb: 3,
//               fontSize: { xs: '2rem', md: '3rem' },
//               lineHeight: 1.2,
//               maxWidth: '900px',
//               mx: 'auto'
//             }}
//           >
//             Unlock the full potential of AI Studio
//           </Typography>
          
//           <Typography 
//             variant="body1" 
//             sx={{ 
//               color: 'rgba(0, 0, 0, 0.7)',
//               fontSize: { xs: '1rem', md: '1.15rem' },
//               lineHeight: 1.7,
//               fontWeight: 400,
//               maxWidth: '850px',
//               mx: 'auto',
//               px: { xs: 2, md: 0 }
//             }}
//           >
//             AI Studio enhances your workflow with intelligent assistants that generate content directly in your documents, 
//             provide answers to complex business questions, and connect seamlessly with your data for comprehensive insights.
//           </Typography>
//         </Box>

//         {/* CardLayout component */}
//         <CardLayout />
//       </Container>
//     </FeatureSection>
//   );
// };

// export default WhatWeOffer;
import React from 'react';
import {
  Box,
  Typography
} from '@mui/material';
import { styled } from '@mui/material/styles';
import CardLayout from '../../CRAnimations/Offers/AIStudioCards'; // Import CardLayout component
import { SectionHeading, SectionTitle } from '../../CodingRipplerWidgets';

// Updated styled component with responsive padding
const FeatureSection = styled(Box)(({ theme }) => ({
  padding: theme.spacing(6, 0),
  [theme.breakpoints.up('md')]: {
    padding: theme.spacing(12, 0),
  },
  // background: 'linear-gradient(135deg, #fcfcff 0%, #f8f9ff 100%)',
  position: 'relative',
  overflow: 'hidden',
  width: '100%',
}));

const WhatWeOffer = () => {
  return (
    <FeatureSection>
      {/* Replaced Container with Box that has no padding on mobile */}
      <Box
        sx={{
          width: "100%",
          maxWidth: { xs: "100%", md: "1500px" },
          mx: "auto",
          px: { xs: 1, sm: 2, md: 3, lg: 4 },
          overflow: "hidden",
        }}
        id="features-section"
      >
        {/* Simple header section */}
        <Box
          sx={{
            textAlign: "center",
            mb: { xs: 4, md: 6 },
            px: { xs: 2, md: 0 }, // Add padding only to the header on mobile
          }}
        >
          {/* <Typography 
          
            variant="h6" 
            component="p" 
            sx={{ 
              color: 'primary.main', 
              fontWeight: 700,
              textTransform: 'uppercase',
              letterSpacing: '1.5px',
              mb: 2
            }}
          >
            WHAT WE OFFER
          </Typography> */}

          <SectionTitle variant="subtitle1">WHAT WE OFFER</SectionTitle>

          {/* <Typography
            variant="h2"
            component="h2"
            sx={{
              fontWeight: 700,
              mb: 3,
              fontSize: { xs: "2rem", md: "3rem" },
              lineHeight: 1.2,
              maxWidth: "900px",
              mx: "auto",
            }}
          >
            Unified AI Platform
          </Typography> */}

          <SectionHeading
            variant="h2"
            sx={{ fontSize: { xs: "2rem", md: "3rem" } }}
          >
            Unified AI Platform
          </SectionHeading>

          <Typography
            // variant="body1"
            // sx={{
            //   color: "rgba(0, 0, 0, 0.7)",
            //   fontSize: { xs: "1rem", md: "1.15rem" },
            //   lineHeight: 1.7,
            //   fontWeight: 400,
            //   maxWidth: "850px",
            //   mx: "auto",
            // }}

            variant="h5"
            component="p"
            align="center"
            color="text.secondary"
            sx={{
              maxWidth: "800px",
              mx: "auto",
              mb: 6,
            }}
          >
            A single, adaptable AI ecosystem that seamlessly connects, thinks, and acts—eliminating fragmented tools and vendor lock-in for your business.          </Typography>
        </Box>

        {/* CardLayout component - with no wrapping container to add padding */}
        <CardLayout />
      </Box>
    </FeatureSection>
  );
};

export default WhatWeOffer;