import BarChartIcon from "@mui/icons-material/BarChart";
import DataObjectOutlinedIcon from "@mui/icons-material/DataObjectOutlined";
import DescriptionIcon from "@mui/icons-material/Description";
import DesignServicesIcon from "@mui/icons-material/DesignServices";
import HubIcon from "@mui/icons-material/Hub";
import IntegrationInstructionsIcon from "@mui/icons-material/IntegrationInstructions";
import LightModeOutlinedIcon from "@mui/icons-material/LightModeOutlined";
import MapIcon from "@mui/icons-material/Map";
import PersonIcon from "@mui/icons-material/Person";
import SchoolIcon from "@mui/icons-material/School";
import SearchIcon from "@mui/icons-material/Search";
import SupportAgentIcon from "@mui/icons-material/SupportAgent";
import { Box, Grid } from "@mui/material";
import { CommonContainer } from "../../../CodingRippler/CodingRipplerWidgets";
import { FlipWords } from "../../../CodingRippler/CRAnimations/FlipWords";
import { Demo } from "../../AIHome/HomePageWidgets/Buttons";
import { MarqueeEffect } from "../../AIProducts/ProductsWidget";
import {
  CommonHeaderTypography,
  CommonSubHeaderTypography,
  ResponsiveBoxCenteredChildren,
} from "../../AIUtil/AIUtils";
import { PageHeader, PageSubHeader, TopicContainer } from "../../AIWidgets";
import {
  AIAgentsFeatures,
  GettingStartedContainer,
  HowInboundWorks,
} from "../AIAgentsWidgets";
import { Helmet } from "react-helmet-async";

export const CatalystAgent = ({ children, ...props }) => {
  const { isPhoneOrTab, isPhone, isTab, deviceWithSmallHeight } = props;

  const steps = [
    {
      title: "Understand the Challenge",
      description:
        "Catalyst starts by clearly understanding your unique problem, ensuring we’re aligned with your goals.",
      icon: <PersonIcon fontSize="small" />,
    },
    {
      title: "Tailored Research",
      description:
        "We gather critical insights, using both internal and external data, to inform the best solution for you.",
      icon: <SearchIcon fontSize="small" />,
    },
    {
      title: "Custom Solution Design",
      description:
        "Catalyst creates a solution specifically for your needs, drawing from our research and expertise.",
      icon: <DesignServicesIcon fontSize="small" />,
    },
    {
      title: "Personalized Strategy",
      description:
        "A clear, actionable strategy is developed, outlining key milestones and timelines for success.",
      icon: <MapIcon fontSize="small" />,
    },
    {
      title: "Solution Implementation",
      description:
        "Catalyst seamlessly integrates the solution into your systems, ensuring smooth deployment.",
      icon: <IntegrationInstructionsIcon fontSize="small" />,
    },
    {
      title: "Ongoing Monitoring",
      description:
        "We track the solution’s performance and adjust it to keep it aligned with your evolving needs.",
      icon: <BarChartIcon fontSize="small" />,
    },
    {
      title: "User Empowerment",
      description:
        "Catalyst provides tools and training, so you're fully equipped to manage and optimize the solution.",
      icon: <SchoolIcon fontSize="small" />,
    },
    {
      title: "Continuous Support",
      description:
        "We offer ongoing support and evolve the solution as your business grows, ensuring it remains effective.",
      icon: <SupportAgentIcon fontSize="small" />,
    },
  ];

  const words = ["Smart Choices", "Quick Execution", "Endless Potential"];

  return (
    <>
      <Helmet>
        <title>AI Rippler - Catalyst Agent</title>
        <meta
          name="description"
          content="Meet Catalyst, your AI-powered agent from AI Rippler. Discover how Catalyst can help you make smarter decisions, execute faster, and unlock limitless possibilities."
        />
        <meta property="og:title" content="AI Rippler - Catalyst Agent" />
        <meta
          property="og:description"
          content="Meet Catalyst, your AI-powered agent from AI Rippler. Discover how Catalyst can help you make smarter decisions, execute faster, and unlock limitless possibilities."
        />
        <meta
          property="og:url"
          content="https://www.codingrippler.com/ai/agents/catalyst"
        />
        <meta property="og:type" content="website" />
        <link
          rel="canonical"
          href="https://www.codingrippler.com/ai/agents/catalyst"
        />
      </Helmet>
      <Grid container spacing={10} mt={2}>
        <Grid item xs={12}>
          <TopicContainer topicText="Introducing Catalyst">
            <HubIcon />
          </TopicContainer>
        </Grid>

        <Grid item xs={12}>
          <CommonContainer>
            {/* <PageHeader
            headerText={
              <CommonHeaderTypography variant={isPhoneOrTab ? "h4" : "h2"}>
                Meet{" "}
                <span className="theme-specfic-colored-text">Catalyst</span>,
                Your Sales Agent
              </CommonHeaderTypography>
            }
          /> */}

            <FlipWords
              words={words}
              duration={3000}
              isPhoneOrTab={isPhoneOrTab}
              isPhone={isPhone}
            />

            <Box m={2} />
            <PageSubHeader
              headerText={
                <CommonSubHeaderTypography>
                  Built for Your Challenges.
                </CommonSubHeaderTypography>
              }
            />
          </CommonContainer>
        </Grid>

        <Grid item xs={12}>
          <ResponsiveBoxCenteredChildren>
            <Demo />
            <Box m={2} />
          </ResponsiveBoxCenteredChildren>
          <Box m={2} />
        </Grid>

        <Grid item xs={12}>
          {/* <Container sx={{ mt: 2, mb: 2 }}>
          <AnimatedBox>
            <YouTubeBox />
          </AnimatedBox>
        </Container> */}

          <MarqueeEffect />
        </Grid>

        {/* Advertise page   */}

        {/* <Grid item xs={12}>
        <AICommonContainer>
          <CommonSubHeaderTypography customStyle={{ opacity: 0.8 }}>
            Companies of all sizes trust AI Rippler
          </CommonSubHeaderTypography>

          <Box m={2} />
          <InLineMediaContainer data={ListOfOurCustomers} />
        </AICommonContainer>
      </Grid> */}

        {/* <Grid item xs={12}>
        <CommonContainer>
          <AdvertiseLeadGenerationTool
            isPhone={isPhone}
            isTab={isTab}
            deviceWithSmallHeight={deviceWithSmallHeight}
            isPhoneOrTab={isPhoneOrTab}
          />
        </CommonContainer>
      </Grid> */}

        {/* Example Use Case Page  */}
        {/*

       Not required for now

      <Grid item xs={12}>
        <TopicContainer topicText="Customers">
          <PeopleAltOutlinedIcon />
        </TopicContainer>
      </Grid>
                
      <Grid item xs={12}>
        <AICommonBoxContainer
          customStyle={{
            //     border: "1px solid #868a97",
            boxShadow: "0px 0px 6px 3px rgba(0, 0, 0, 0.2)", // Custom shadow
            borderRadius: "24px",
          }}
        >
          <Container>
            <BlogContainer />
          </Container>
        </AICommonBoxContainer>
      </Grid> */}

        <Grid item xs={12}>
          <TopicContainer topicText="Features">
            <DataObjectOutlinedIcon />
          </TopicContainer>
        </Grid>

        {/* <Grid item xs={12}>
        <CommonContainer>
          <PageHeader
            headerText={
              <CommonHeaderTypography>
                <span className="theme-specfic-colored-text">
                  Features That Supercharge Your Sales
                </span>
              </CommonHeaderTypography>
            }
          />

          <Box m={2} />
          <PageSubHeader
            headerText={
              <CommonSubHeaderTypography>
                Maximize Every Sales Interaction with Catalyst AI
              </CommonSubHeaderTypography>
            }
          />
        </CommonContainer>
      </Grid> */}

        <Grid item xs={12}>
          <CommonContainer>
            <PageHeader
              headerText={
                <CommonHeaderTypography>
                  Onboard{" "}
                  <span className="theme-specfic-colored-text">Catalyst</span>
                </CommonHeaderTypography>
              }
            />

            <Box m={2} />
            <PageSubHeader
              headerText={
                <CommonSubHeaderTypography>
                  Designed in collaboration with industry experts to elevate
                  your game.
                </CommonSubHeaderTypography>
              }
            />
          </CommonContainer>
        </Grid>

        <Grid item xs={12}>
          <Grid container spacing={2}>
            <Grid
              item
              xs={1}
              sx={{
                display: { xs: "none", sm: "block" },
              }}
            />

            <Grid item xs={12} sm={10}>
              <CommonContainer>
                <AIAgentsFeatures />
              </CommonContainer>
            </Grid>
            <Grid
              item
              xs={1}
              sx={{
                display: { xs: "none", sm: "block" },
              }}
            />
          </Grid>
        </Grid>

        <Grid item xs={12}>
          <TopicContainer topicText="How it works">
            <DescriptionIcon />
          </TopicContainer>
        </Grid>

        <Grid item xs={12}>
          {/* <Box
            sx={{
              position: "relative",
              width: "100%",
              height: "100%",
              "&::before": {
                content: '""',
                position: "absolute",
                top: { xs: "0%", lg: "0%" },
                left: { xs: "20px", lg: "0%" },
                transform: {
                  xs: "translate(0%, 0%)",
                  lg: "translate(0%, 0%)",
                  xl: "translate(0%, 10%)",
                },
                width: { lg: "100%" },
                height: { lg: "100%" },
                backgroundImage: {
                  //      lg: "url(/airippler/ai-agents/inbound/curv-line.svg)",
                },
                backgroundSize: { xs: "cover", lg: "cover" },
                backgroundPosition: "center",
                backgroundRepeat: "no-repeat",
                opacity: 0.3,
                zIndex: 1,
              },
            }}
          > */}
          <HowInboundWorks steps={steps} />
          {/* </Box> */}
        </Grid>
        {/* <Grid item xs={12}>
        <HowItWorkContainer />
      </Grid> */}

        <Grid item xs={12}>
          <TopicContainer topicText="Getting Started">
            <LightModeOutlinedIcon />
          </TopicContainer>
        </Grid>

        <Grid item xs={12}>
          <CommonContainer>
            <PageHeader
              headerText={
                <CommonHeaderTypography>
                  Start in Just{" "}
                  <span className="theme-specfic-colored-text">one week</span>–
                  Fast, Easy, and Ready
                </CommonHeaderTypography>
              }
            />
            <Box m={2} />
            <PageSubHeader
              headerText={
                <CommonSubHeaderTypography>
                  Our Proven, Tailored Process Gets You Up and Running Quickly
                </CommonSubHeaderTypography>
              }
            />
          </CommonContainer>
        </Grid>

        <Grid item xs={12}>
          <GettingStartedContainer
            isPhone={isPhone}
            isTab={isTab}
            deviceWithSmallHeight={deviceWithSmallHeight}
            isPhoneOrTab={isPhoneOrTab}
          />
        </Grid>
      </Grid>
    </>
  );
};
