'use client';

import React from 'react';
import { Box, Typography } from '@mui/material';
import { motion } from 'framer-motion';
// Icon placeholder component - we'll use icons from assets
const IconPlaceholder = ({ service }) => {
  // Different background colors based on the service
  let bgColor = 'white'; // Default blue
  
//   if (service === 'jira') bgColor = '#0052CC';
//   if (service === 'slack') bgColor = '#4A154B';
//   if (service === 'hubspot') bgColor = '#FF7A59';
//   if (service === 'figma') bgColor = '#F24E1E';
  
  return (
    <Box 
    component="img"
    src={`/airippler/Icons/${service}.svg`}
      sx={{ 
        width: 24, 
        height: 24, 
        borderRadius: '4px',
        backgroundColor: bgColor,
        mr: 2,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
      }}
    >
    </Box>
  );
};

// Question card component without hover effects
const QuestionCard = ({ question, service }) => {
  return (
    <Box
      sx={{
        borderRadius: '12px',
        padding: '12px 30px',
        height: '56px',
        mx: 1.5,
        display: 'inline-flex',
        alignItems: 'center',
        whiteSpace: 'nowrap',
        backgroundColor: '#f0f4fc', // Light blue background
        boxSizing: 'border-box'
      }}
    >
      {service && <IconPlaceholder service={service} />}
      <Typography 
        variant="body1" 
        sx={{ 
          fontSize: '16px', 
          fontWeight: 500, 
          color: '#333',
          fontFamily: '"Inter", "Segoe UI", sans-serif' // Using Inter or Segoe UI for better match
        }}
      >
        {question}
      </Typography>
    </Box>
  );
};

// Question data
const row1Questions = [
  { text: "What's the status of the assigned tasks in the sprint?", service: "jira" },
  { text: "Has Client responded to my Mail?", service: "gmail" },
  { text: "Are there any unread messages in the #marketing channel?", service: "slack" }
];

const row2Questions = [
  { text: "What's the latest brainstorming session?", service: null },
  { text: "How many frames have been designed?", service: "figma" },
  { text: "Which folder contains the most recent assets?", service: "dropbox" }
];

const row3Questions = [
  { text: "What's the current draft status", service: "docs" },
  { text: "What are the latest sales projections in the CRM?", service: "hubspot" },
  { text: "When is the next sync meeting?", service: "calendar" }
];

// Main component
const ScrollingQuestions = () => {
  // Animation variants
  const row1Animation = {
    animate: {
      x: [0, -1000],
      transition: {
        x: {
          repeat: Infinity,
          repeatType: "loop",
          duration: 35,
          ease: "linear"
        }
      }
    }
  };

  const row2Animation = {
    animate: {
      x: [-1000, 0],
      transition: {
        x: {
          repeat: Infinity,
          repeatType: "loop",
          duration: 40,
          ease: "linear"
        }
      }
    }
  };

  const row3Animation = {
    animate: {
      x: [0, -1000],
      transition: {
        x: {
          repeat: Infinity,
          repeatType: "loop",
          duration: 30,
          ease: "linear"
        }
      }
    }
  };

  return (
    <Box sx={{ 
      // mt: 4, 
      mb: 5, 
      overflow: 'hidden',
      width: '100%', 
      maxWidth: '1400px',
      mx: 'auto'
    }}>
      {/* Row 1: right to left */}
      <Box 
        sx={{ 
          mb: 2.5, // Adjusted spacing between rows
          position: 'relative',
          overflow: 'hidden',
          height: '70px',
          display: 'flex',
          alignItems: 'center',
          // Fade effect mask
          '&::before': {
            content: '""',
            position: 'absolute',
            top: 0,
            left: 0,
            width: '80px',
            height: '100%',
            background: 'linear-gradient(to right, #fff, transparent)',
            zIndex: 1,
            pointerEvents: 'none'
          },
          '&::after': {
            content: '""',
            position: 'absolute',
            top: 0,
            right: 0,
            width: '80px',
            height: '100%',
            background: 'linear-gradient(to left, #fff, transparent)',
            zIndex: 1,
            pointerEvents: 'none'
          }
        }}
      >
        <motion.div
          variants={row1Animation}
          animate="animate"
          style={{
            display: 'inline-flex',
            whiteSpace: 'nowrap',
            paddingRight: '1000px'
          }}
        >
          {[...row1Questions, ...row1Questions, ...row1Questions].map((item, index) => (
            <QuestionCard 
              key={`row1-${index}`} 
              question={item.text} 
              service={item.service} 
            />
          ))}
        </motion.div>
      </Box>

      {/* Row 2: left to right */}
      <Box 
        sx={{ 
          mb: 2.5, // Adjusted spacing between rows
          position: 'relative',
          overflow: 'hidden',
          height: '70px',
          display: 'flex',
          alignItems: 'center',
          // Fade effect mask
          '&::before': {
            content: '""',
            position: 'absolute',
            top: 0,
            left: 0,
            width: '80px',
            height: '100%',
            background: 'linear-gradient(to right, #fff, transparent)',
            zIndex: 1,
            pointerEvents: 'none'
          },
          '&::after': {
            content: '""',
            position: 'absolute',
            top: 0,
            right: 0,
            width: '80px',
            height: '100%',
            background: 'linear-gradient(to left, #fff, transparent)',
            zIndex: 1,
            pointerEvents: 'none'
          }
        }}
      >
        <motion.div
          variants={row2Animation}
          animate="animate"
          style={{
            display: 'inline-flex',
            whiteSpace: 'nowrap',
            paddingRight: '1000px'
          }}
        >
          {[...row2Questions, ...row2Questions, ...row2Questions].map((item, index) => (
            <QuestionCard 
              key={`row2-${index}`} 
              question={item.text} 
              service={item.service} 
            />
          ))}
        </motion.div>
      </Box>

      {/* Row 3: right to left */}
      <Box 
        sx={{ 
          position: 'relative',
          overflow: 'hidden',
          height: '70px',
          display: 'flex',
          alignItems: 'center',
          // Fade effect mask
          '&::before': {
            content: '""',
            position: 'absolute',
            top: 0,
            left: 0,
            width: '80px',
            height: '100%',
            background: 'linear-gradient(to right, #fff, transparent)',
            zIndex: 1,
            pointerEvents: 'none'
          },
          '&::after': {
            content: '""',
            position: 'absolute',
            top: 0,
            right: 0,
            width: '80px',
            height: '100%',
            background: 'linear-gradient(to left, #fff, transparent)',
            zIndex: 1,
            pointerEvents: 'none'
          }
        }}
      >
        <motion.div
          variants={row3Animation}
          animate="animate"
          style={{
            display: 'inline-flex',
            whiteSpace: 'nowrap',
            paddingRight: '1000px'
          }}
        >
          {[...row3Questions, ...row3Questions, ...row3Questions].map((item, index) => (
            <QuestionCard 
              key={`row3-${index}`} 
              question={item.text} 
              service={item.service} 
            />
          ))}
        </motion.div>
      </Box>
    </Box>
  );
};

export default ScrollingQuestions;