import AutoAwesomeIcon from "@mui/icons-material/AutoAwesome";
import EmailIcon from "@mui/icons-material/Email";
import { Box } from "@mui/material";
import {
  CommonContainer,
  HeroSubHeader,
  HeroText,
  TopicContainer,
} from "../../CodingRipplerWidgets";
import { ButtonWithPrefix } from "../../CRAnimations/Animations";

export const RouteToContactUs = () => {
  window.location.href = `/contact`;
};

export const ContactUs = ({ children, ...props }) => {
  const { key, data, isPhoneorTab } = props;

  return (
    <Box
      display="flex"
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
      height="100vh"
      width="100%"
      gap={2}
      key={key}
      ref={data.reference}
      minHeight={{
        xs: 650,
        sm: 600,
      }}
      className="white-bg"
    >
      <TopicContainer topicText="Get Started">
        <AutoAwesomeIcon />
      </TopicContainer>

      <Box m={2} />

      <CommonContainer>
        <HeroText variant={isPhoneorTab ? "h4" : "h3"}>
          Get started with{" "}
          <span className="theme-specfic-colored-text">Web Rippler</span> today{" "}
        </HeroText>

        <Box m={2} />

        <HeroSubHeader>
          Create a stunning, modern website with fully customizable, flexible
          atomic MUI components.
        </HeroSubHeader>
      </CommonContainer>

      <ButtonWithPrefix
        text="Contact Us"
        prefix={<EmailIcon />}
        clickHandler={RouteToContactUs}
      />

      <Box
        display="flex"
        flexDirection="row"
        justifyContent="center"
        alignItems="center"
        width="100%"
        gap={2}
      >
        <AutoAwesomeIcon />
        <HeroSubHeader>Start your project today!</HeroSubHeader>
      </Box>
    </Box>
  );
};
