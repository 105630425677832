import DataObjectOutlinedIcon from "@mui/icons-material/DataObjectOutlined";
import {
  Box,
  Card,
  CardContent,
  CardMedia,
  Fab,
  Grid,
  Typography,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { Cards } from "../../AIWidgets";

export const FeatureWidgets = (children, ...props) => {
  const theme = useTheme();

  return (
    <Box display="flex" justifyContent="center">
      <Fab
        variant="extended"
        size="large"
        sx={{ backgroundColor: theme.palette.background.default, zIndex: 1 }}
        p={10}
        pointerEvents="none"
      >
        <DataObjectOutlinedIcon />

        <Typography ml={1}>Features</Typography>
      </Fab>
    </Box>
  );
};

export const FeatureHeaderUseCases = (children, ...props) => {
  return (
    <Box>
      <Typography
        variant="h4"
        fontWeight="bold"
        className="typography-custom-css"
        sx={{
          textAlign: { xs: "center", lg: "center" },
        }}
      >
        Put Your Business on{" "}
        <span className="theme-specfic-colored-text">Autopilot</span> for Better
        Results
      </Typography>
    </Box>
  );
};

export const FeatureSubHeaderHome = (children, ...props) => {
  return (
    <Box>
      <Typography
        variant="h6"
        fontWeight="normal"
        className="sub-header typography-custom-css"
        sx={{
          textAlign: { xs: "center", lg: "center" },
        }}
      >
        Streamline your operations and let AI handle the rest.
      </Typography>
    </Box>
  );
};

export const FeatureCard = ({ image, title, description, ...props }) => {
  return (
    <Card
      sx={{
        borderRadius: "24px",
        position: "relative",
        display: "flex",
        flexDirection: "column",
        justifyContent: "flex-start",
        minHeight: { xs: "450px", xl: "450px" }, // Set a minimum height for consistency
        height: { xs: "450px", xl: "450px" }, // Set a minimum height for consistency
        maxHeight: { xs: "450px", xl: "450px" }, // Set a maximum height for consistency
        m: 2,
        elevation: 4,
      }}
    >
      <CardMedia
        component="img"
        image={image}
        alt={title}
        sx={{
          height: "60%",
          width: "100%",
          objectFit: "cover",
        }}
      />

      <CardContent sx={{ mt: 5 }} height="30%">
        <Typography
          variant="h5"
          className="typography-custom-css"
          fontWeight="bold"
        >
          {title}
        </Typography>
        <Box m={2} />
        <Typography
          variant="body1"
          className="sub-header, typography-custom-css"
        >
          {description}
        </Typography>
      </CardContent>
    </Card>
  );
};

export const FeaturesContainer = ({ children, ...props }) => {
  const cardData = [
    {
      image: "/airippler/features/llmChoice.png",
      title: "Flexible LLM Choices",
      description:
        "Easily switch between leading LLM providers like OpenAI, Google, Meta, and Anthropic.",
    },
    {
      image: "/airippler/features/templatesToGetStarted.png",
      title: "Instant Templates",
      description:
        "Start fast with our growing library of customizable AI Agent templates.",
    },
    {
      image: "/airippler/features/no-coding-required.png",
      title: "Zero coding required",
      description:
        "No coding required—empower your business with customizable AI teammates.",
    },
  ];

  return (
    <Grid container spacing={2}>
      {cardData.map((card, index) => (
        <Grid item xs={12} sm={12} md={4} lg={4} xl={4} key={index}>
          <Cards
            image={card.image}
            title={card.title}
            description={card.description}
            customStyle={{
              opacity: 0.5,
              transition: "opacity 0.3s ease-in-out",
              "&:hover": {
                opacity: 1,
              },
            }}
          />
        </Grid>
      ))}
    </Grid>
  );
};
