import React, { createContext, useContext, useMemo, useState } from "react";
import { hydrate, render } from "react-dom"; // Changed this line
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import CssBaseline from "@mui/material/CssBaseline";
import { createTheme } from "@mui/material/styles";
import { ThemeProvider } from "@mui/material/styles";
import { HelmetProvider } from "react-helmet-async";

// Step 1: Create a context for theme mode
const ThemeModeContext = createContext();

// Step 2: Create a custom hook to use the theme mode context
export function useThemeMode() {
  return useContext(ThemeModeContext);
}

// The root element that we'll either hydrate or render to
const rootElement = document.getElementById("root");

const AppWithTheme = () => {
  // Step 3: Use state to manage theme mode
  const [mode, setMode] = useState("light");

  const toggleTheme = (newMode) => {
    setMode(newMode);
  };

  // Create theme with dynamic mode
  const theme = createTheme({
    palette: {
      mode: mode,
      constantColors: {
        white: "#ffffff",
        black: "#000000",
        hawaiianOcean: "#429cb5",
        dimGray: "#696969",
        pink: "#ff47a0",
      },
    },
    tourAndTravel: {
      headingTextSize: { sm: "1.0rem", md: "1.5rem" },
      bodyTextSize: { sm: "0.8rem", md: "1.0rem" },
    },
    AI: {
      paddingParent: { sm: 2, md: 2, lg: 2 },
      marginParent: { sm: 2, md: 2, lg: 2 },
    },
  });

  //Use useMemo to prevent unnecessary re-renders
  const contextValue = useMemo(() => ({ toggleTheme }), []);

  // Step 4: Provide the theme mode and toggle function via context
  return (
    <ThemeModeContext.Provider value={{ contextValue }}>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <HelmetProvider>
          <App />
        </HelmetProvider>
      </ThemeProvider>
    </ThemeModeContext.Provider>
  );
};

// Check if the HTML content was prerendered by react-snap
if (rootElement.hasChildNodes()) {
  // If it has child nodes, it means the HTML was prerendered
  hydrate(<AppWithTheme />, rootElement);
} else {
  // Normal client-side render for development or non-prerendered paths
  render(<AppWithTheme />, rootElement);
}

reportWebVitals();