import React from "react";
import {
  Box,
  Button,
  Card,
  CardContent,
  Chip,
  Divider,
  Grid,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography
} from "@mui/material";
import { styled } from "@mui/material/styles";
import {
  CheckCircle as CheckCircleIcon
} from "@mui/icons-material";

// Styled components
const StyledCard = styled(Card)(({ theme, premium, enterprise }) => ({
  height: "100%",
  display: "flex",
  flexDirection: "column",
  transition: "all 0.3s ease",
  position: "relative",
  overflow: "hidden",
  borderRadius: "16px",
  border: premium
    ? `2px solid ${theme.palette.primary.main}`
    : "1px solid rgba(0,0,0,0.08)",
  backgroundColor: enterprise
    ? theme.palette.primary.main
    : theme.palette.background.paper,
  color: enterprise ? theme.palette.common.white : "inherit",
  boxShadow: "0 6px 20px rgba(0,0,0,0.05)",
  "&:hover": {
    transform: "translateY(-8px)",
    boxShadow: "0 12px 28px rgba(0,0,0,0.1)"
  },
}));

const PopularBanner = styled(Box)(({ theme }) => ({
  position: "absolute",
  width: "100%",
  top: 0,
  left: 0,
  right: 0,
  padding: "12px",
  textAlign: "center",
  backgroundColor: theme.palette.primary.main,
  color: theme.palette.common.white,
  fontWeight: 700,
  fontSize: "14px",
  letterSpacing: "1px",
  zIndex: 1,
  textTransform: "uppercase",
  marginBottom: "24px",
}));

export const webSolutionsPlans = [
  {
      name: 'Essential',
      category: 'Web Solutions',
      audience: 'Startups & small businesses needing an online presence',
      oneTimePrice: "Starting at $99",
      originalPrice: 199,
      discountedPrice: 99,
      discountPercentage: 50,
      support: 'Standard support',
      description: "Perfect for startups and small businesses looking for a simple yet professional online presence.",
      features: [
          'Professional design',
          'Optimized for mobile & SEO',
          'Fast & secure website',
          'Basic contact form',
          'Quick turnaround'
      ],
      popular: false,
      enterprise: false
  },
  {
      name: 'Growth',
      category: 'Web Solutions',
      audience: 'Growing businesses needing a scalable website or web application',
      oneTimePrice: "Starting at $2,499",
      originalPrice: 3499,
      discountedPrice: 2499,
      discountPercentage: 28.58,
      support: 'Priority support',
      description: "Best for businesses that need a custom, scalable, and feature-rich website or web application.",
      features: [
          'Custom design & development',
          'Integration with third-party tools',
          'Admin dashboard (if required)',
          'Scalable architecture',
          'AI-powered features (optional)'
      ],
      popular: true,
      enterprise: false
  },
  {
      name: 'Enterprise',
      category: 'Web Solutions',
      audience: 'Companies needing advanced web applications, SaaS platforms, or high-performance solutions',
      oneTimePrice: "Custom Pricing (Get a Quote)",
      originalPrice: null,
      discountedPrice: null,
      discountPercentage: null,
      support: 'Dedicated project manager',
      description: "Designed for enterprises needing high-performance web applications, SaaS platforms, or complex systems.",
      features: [
          'Custom-built enterprise solutions',
          'High-security infrastructure',
          'Scalable cloud-based architecture',
          'Advanced API development',
          'End-to-end support & maintenance'
      ],
      popular: false,
      enterprise: true
  }
];
const WebSolutionsPricing = ({ handleContactSales }) => {
  return (
    <Grid container spacing={4} justifyContent="center" id = "webSolution-pricing">
      {webSolutionsPlans.map((plan, index) => (
        <Grid
          item
          xs={12}
          md={4}
          key={index}
          sx={{
            transform: plan.popular ? { md: "scale(1.03)" } : "none",
            zIndex: plan.popular ? 10 : 1,
          }}
        >
          <StyledCard
            elevation={0}
            premium={plan.popular}
            enterprise={plan.enterprise}
          >
            {plan.popular && (
              <Box sx={{ position: "relative", height: "50px", width: "100%" }}>
                <PopularBanner>MOST POPULAR</PopularBanner>
              </Box>
            )}

            <CardContent
              sx={{
                p: { xs: 3, md: 4 },
                pt: plan.popular ? 0 : { xs: 3, md: 4 },
              }}
            >
              {/* Card Header */}
              <Box textAlign="center" mb={4}>
                <Typography
                  variant="h3"
                  component="h2"
                  fontWeight={700}
                  sx={{
                    mb: 1.5,
                    color: plan.enterprise
                      ? "common.white"
                      : plan.popular
                      ? "primary.main"
                      : "text.primary",
                    fontSize: { xs: "2rem", md: "2.5rem" },
                    textAlign: "center"
                  }}
                >
                  {plan.name}
                </Typography>

                <Typography
                  variant="body1"
                  sx={{
                    color: plan.enterprise
                      ? "rgba(255, 255, 255, 0.8)"
                      : "text.secondary",
                    mb: 1.5,
                    textAlign: "center",
                    lineHeight: 1.4
                  }}
                >
                  {plan.audience}
                </Typography>
              </Box>

              {/* Pricing */}
              <Box textAlign="center" mb={3}>
                {/* Original price with strikethrough */}
                {plan.originalPrice !== null && (
                  <Typography
                    variant="body1"
                    sx={{
                      textDecoration: "line-through",
                      color: plan.enterprise
                        ? "rgba(255, 255, 255, 0.6)"
                        : "text.secondary",
                      mb: 0.5,
                    }}
                  >
                    ${plan.originalPrice}
                  </Typography>
                )}

                {/* Current price */}
                {!plan.enterprise ? (
                  <Box>
                    <Typography
                      variant="h3"
                      component="div"
                      sx={{
                        fontWeight: 800,
                        color: plan.enterprise
                          ? "common.white"
                          : "text.primary",
                        fontSize: { xs: "2.5rem", md: "3rem" },
                        lineHeight: 1,
                        mb: 0.5
                      }}
                    >
                      ${plan.discountedPrice}<sup>*</sup>
                    </Typography>
                    <Typography
                      variant="body2"
                      sx={{
                        color: plan.enterprise
                          ? "rgba(255, 255, 255, 0.7)"
                          : "text.secondary",
                        fontSize: "0.9rem",
                        fontWeight: 500
                      }}
                    >
                      One-time payment • Starting price
                    </Typography>
                  </Box>
                ) : (
                  <Box>
                    <Typography
                      variant="h3"
                      component="div"
                      sx={{
                        fontWeight: 800,
                        color: "common.white",
                        fontSize: { xs: "2.5rem", md: "3rem" },
                        lineHeight: 1,
                        mb: 0.5
                      }}
                    >
                      Custom
                    </Typography>
                    <Typography
                      variant="body2"
                      sx={{
                        color: "rgba(255, 255, 255, 0.7)",
                        fontSize: "0.9rem",
                        fontWeight: 500
                      }}
                    >
                      One-time payment • Based on requirements
                    </Typography>
                  </Box>
                )}
                
                {/* Discount badge */}
                {plan.discountPercentage && (
                  <Chip
                    label={`${Math.round(plan.discountPercentage)}% OFF`}
                    size="small"
                    sx={{
                      fontWeight: 700,
                      bgcolor: "rgba(46, 204, 113, 0.15)",
                      color: plan.enterprise ? "white" : "#2ecc71",
                      border: "none",
                      mt: 1
                    }}
                  />
                )}
              </Box>

              <Divider
                sx={{
                  my: 2,
                  borderColor: plan.enterprise
                    ? "rgba(255, 255, 255, 0.15)"
                    : "divider",
                }}
              />

              <Box textAlign="center" mb={2}>
                <Typography
                  variant="body2"
                  sx={{
                    color: plan.enterprise
                      ? "rgba(255, 255, 255, 0.8)"
                      : "text.secondary",
                    fontWeight: 600,
                  }}
                >
                  {plan.support}
                </Typography>
                <Box 
                  sx={{
                    mt: 1.5,
                    p: 1.5,
                    borderRadius: 1,
                    backgroundColor: plan.enterprise 
                      ? "rgba(255, 255, 255, 0.1)" 
                      : "rgba(0, 0, 0, 0.03)",
                    border: plan.enterprise
                      ? "1px solid rgba(255, 255, 255, 0.15)"
                      : "1px solid rgba(0, 0, 0, 0.08)"
                  }}
                >
                  <Typography
                    variant="body2"
                    sx={{
                      color: plan.enterprise
                        ? "rgba(255, 255, 255, 0.9)"
                        : "text.primary",
                      fontSize: "0.85rem",
                      fontWeight: 600,
                      mb: 0.5
                    }}
                  >
                    Maintenance & Support
                  </Typography>
                  <Typography
                    variant="body2"
                    sx={{
                      color: plan.enterprise
                        ? "rgba(255, 255, 255, 0.7)"
                        : "text.secondary",
                      fontSize: "0.85rem",
                    }}
                  >
                    {plan.enterprise 
                      ? "Customized plans based on requirements" 
                      : plan.popular 
                        ? "Plans available starting at $49/month" 
                        : "Plans available starting at $29/month"}
                  </Typography>
                </Box>
              </Box>

              {/* Features */}
              <List disablePadding sx={{ mb: 4 }}>
                {plan.features.map((feature, featureIndex) => (
                  <ListItem
                    key={featureIndex}
                    disablePadding
                    disableGutters
                    sx={{ mb: 1 }}
                  >
                    <ListItemIcon sx={{ minWidth: 30 }}>
                      <CheckCircleIcon
                        fontSize="small"
                        sx={{
                          color: plan.enterprise
                            ? "common.white"
                            : "#2ecc71"
                        }}
                      />
                    </ListItemIcon>
                    <ListItemText
                      primary={feature}
                      primaryTypographyProps={{
                        variant: "body2",
                        color: plan.enterprise
                          ? "common.white"
                          : "text.primary",
                        fontWeight: 500,
                      }}
                    />
                  </ListItem>
                ))}
              </List>

              {/* CTA Button */}
              <Box>
                <Button
                  variant={plan.enterprise ? "outlined" : "contained"}
                  fullWidth
                  size="large"
                  onClick={() => handleContactSales(plan.category, plan.name)}
                  sx={{
                    py: 1.5,
                    fontSize: "16px",
                    fontWeight: 600,
                    borderRadius: 40,
                    bgcolor: plan.enterprise
                      ? "common.white"
                      : "primary.main",
                    color: plan.enterprise
                      ? "primary.main"
                      : "common.white",
                    borderColor: plan.enterprise ? "white" : undefined,
                    textTransform: "none",
                    "&:hover": {
                      bgcolor: plan.enterprise
                        ? "rgba(255, 255, 255, 0.9)"
                        : "primary.dark",
                    },
                  }}
                >
                  {plan.enterprise ? "Contact Sales" : "Contact Sales"}
                </Button>
                <Typography
                  variant="caption"
                  component="div"
                  sx={{
                    mt: 1.5,
                    color: plan.enterprise ? "rgba(255, 255, 255, 0.7)" : "text.secondary",
                    fontSize: "0.75rem",
                    textAlign: "center"
                  }}
                >
                  * One-time development cost. Maintenance plans are optional.
                </Typography>
              </Box>
            </CardContent>
          </StyledCard>
        </Grid>
      ))}
    </Grid>
  );
};

export default WebSolutionsPricing;