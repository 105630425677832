// import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
// import PaymentsIcon from '@mui/icons-material/Payments';
// import TuneIcon from '@mui/icons-material/Tune';
// import {
//   Box,
//   Button,
//   Chip,
//   Divider,
//   Grid,
//   Typography
// } from '@mui/material';
// import React from 'react';

// // Sample data for add-on services
// // const addonServices = [
// //   {
// //     id: 1,
// //     title: "Fine-Tuning Add-On",
// //     icon: "tune",
// //     description: "Train AI models on your specific data for unparalleled accuracy and personalization. Fine-tuning allows your models to understand your unique business context.",
// //     standardPrice: "$2,500 one-time setup + $0.04 per training token",
// //     discountedPrice: "$1,500 setup fee + $0.02 per training token",
// //     discountPercentage: "40",
// //     ctaText: "Learn More",
// //     features: [
// //       "Custom model training",
// //       "Unparalleled accuracy",
// //       "Business-specific context",
// //       "Secure data handling"
// //     ]
// //   },
// //   {
// //     id: 2,
// //     title: "Usage-Based Pricing",
// //     icon: "payments",
// //     description: "Flexible pricing for businesses with variable usage needs. Only pay for what you use beyond your plan limits, with automatic volume discounts.",
// //     standardPrice: "$0.02 per extra API call",
// //     discountedPrice: "$0.015 per extra API call",
// //     additionalInfo: "Bulk pricing discounts available for large-volume users",
// //     ctaText: "Contact for Details",
// //     features: [
// //       "Pay only for what you use",
// //       "Automatic volume discounts",
// //       "Flexible billing options",
// //       "No hidden fees"
// //     ]
// //   }
// // ];

// const AddonSection = ({ ...props }) => {

//   const { addonServices, handleContactSales } = props;

//   return (
//     <>
//         <Box mt={10} mb={4}>
//           <Typography
//             variant="h3"
//             textAlign="center"
//             gutterBottom
//             fontWeight={800}
//             sx={{
//               mb: 1,
//               background: "linear-gradient(90deg, #333 30%, #6056ff 100%)",
//               WebkitBackgroundClip: "text",
//               WebkitTextFillColor: "transparent",
//             }}
//           >
//             Additional Services
//           </Typography>
//           <Typography
//             variant="body1"
//             textAlign="center"
//             color="textSecondary"
//             mb={5}
//             sx={{
//               maxWidth: "700px",
//               mx: "auto",
//               fontSize: "1.1rem",
//             }}
//           >
//             Enhance your AI capabilities with these powerful add-ons
//           </Typography>

//           {/* Creative alternative to traditional cards */}
//           <Box 
//             sx={{ 
//               position: 'relative',
//               maxWidth: '1100px',
//               mx: 'auto',
//               borderRadius: 6,
//               overflow: 'hidden',
//               bgcolor: '#fff',
//               boxShadow: (theme) => `0 10px 40px ${theme.palette.mode === 'dark' ? 'rgba(0,0,0,0.2)' : 'rgba(96, 86, 255, 0.15)'}`,
//               border: '1px solid',
//               borderColor: 'divider',
//               zIndex: 1,
//               '&::before': {
//                 content: '""',
//                 position: 'absolute',
//                 top: 0,
//                 left: 0,
//                 right: 0,
//                 height: '6px',
//                 background: 'linear-gradient(90deg, #6056ff 0%, #8075ff 100%)',
//                 zIndex: 2
//               }
//             }}
//           >
//             {addonServices.map((addon, index) => (
//               <Box 
//                 key={addon.id}
//                 sx={{
//                   position: 'relative',
//                   p: { xs: 3, md: 5 },
//                   ...(index < addonServices.length - 1 && {
//                     borderBottom: '1px dashed',
//                     borderColor: 'divider',
//                   })
//                 }}
//               >
//                 <Grid container spacing={3}>
//                   {/* Left Column - Icon and Title */}
//                   <Grid item xs={12} md={5}>
//                     <Box sx={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
//                       <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
//                         <Box
//                           sx={{
//                             display: 'flex',
//                             alignItems: 'center',
//                             justifyContent: 'center',
//                             width: 52,
//                             height: 52,
//                             borderRadius: '14px',
//                             background: (theme) => `linear-gradient(135deg, ${theme.palette.primary.main} 0%, ${theme.palette.primary.dark} 100%)`,
//                             color: 'white',
//                             mr: 2,
//                             boxShadow: '0 4px 12px rgba(96, 86, 255, 0.25)',
//                           }}
//                         >
//                           {addon.icon === "tune" ? (
//                             <TuneIcon sx={{ fontSize: 28 }} />
//                           ) : (
//                             <PaymentsIcon sx={{ fontSize: 28 }} />
//                           )}
//                         </Box>
//                         <Typography 
//                           variant="h5" 
//                           fontWeight={700}
//                           sx={{ letterSpacing: '-0.5px' }}
//                         >
//                           {addon.title}
//                         </Typography>
//                       </Box>
                      
//                       <Typography 
//                         variant="body1"
//                         sx={{
//                           color: 'text.secondary',
//                           lineHeight: 1.6,
//                           mb: 3
//                         }}
//                       >
//                         {addon.description}
//                       </Typography>

//                       {/* Feature list */}
//                       <Box sx={{ mt: 'auto' }}>
//                         <Typography 
//                           variant="subtitle2"
//                           fontWeight={600}
//                           sx={{ mb: 1, color: 'text.primary' }}
//                         >
//                           Key Benefits:
//                         </Typography>
//                         {addon.features.map((feature, idx) => (
//                           <Box 
//                             key={idx} 
//                             sx={{ 
//                               display: 'flex', 
//                               alignItems: 'center',
//                               mb: 1
//                             }}
//                           >
//                             <CheckCircleOutlineIcon 
//                               sx={{ 
//                                 fontSize: 18, 
//                                 mr: 1, 
//                                 color: 'primary.main' 
//                               }} 
//                             />
//                             <Typography variant="body2" color="text.secondary">
//                               {feature}
//                             </Typography>
//                           </Box>
//                         ))}
//                       </Box>
//                     </Box>
//                   </Grid>

//                   {/* Vertical Divider for desktop */}
//                   <Grid 
//                     item 
//                     sx={{ 
//                       display: { xs: 'none', md: 'flex' },
//                       alignItems: 'center',
//                       justifyContent: 'center'
//                     }}
//                   >
//                     <Divider 
//                       orientation="vertical" 
//                       flexItem 
//                       sx={{ 
//                         height: '80%', 
//                         my: 'auto',
//                         borderStyle: 'dashed'
//                       }} 
//                     />
//                   </Grid>

//                   {/* Right Column - Pricing */}
//                   <Grid item xs={12} md={6}>
//                     <Box 
//                       sx={{ 
//                         display: 'flex', 
//                         flexDirection: 'column',
//                         height: '100%',
//                         justifyContent: 'center'
//                       }}
//                     >
//                       <Box
//                         sx={{
//                           position: 'relative',
//                           bgcolor: (theme) => theme.palette.mode === 'dark' ? 'rgba(96, 86, 255, 0.08)' : 'rgba(96, 86, 255, 0.04)',
//                           borderRadius: 4,
//                           p: 3,
//                           mb: 3,
//                           overflow: 'hidden',
//                           border: '1px solid',
//                           borderColor: 'primary.light',
//                           transition: 'all 0.2s ease',
//                           '&:hover': {
//                             boxShadow: '0 6px 20px rgba(96, 86, 255, 0.1)',
//                             transform: 'translateY(-4px)',
//                           },
//                           '&::after': {
//                             content: '""',
//                             position: 'absolute',
//                             bottom: 0,
//                             right: 0,
//                             width: '30%',
//                             height: '30%',
//                             background: 'radial-gradient(circle, rgba(96, 86, 255, 0.1) 0%, rgba(255, 255, 255, 0) 70%)',
//                             zIndex: 0
//                           }
//                         }}
//                       >
//                         {addon.discountPercentage && (
//                           <Chip
//                             label={`Save ${addon.discountPercentage}%`}
//                             size="small"
//                             sx={{
//                               position: 'absolute',
//                               top: 16,
//                               right: 16,
//                               fontWeight: 700,
//                               bgcolor: 'rgba(46, 204, 113, 0.15)',
//                               color: '#2ecc71',
//                               border: '1px solid rgba(46, 204, 113, 0.3)',
//                               '& .MuiChip-label': {
//                                 px: 1,
//                               }
//                             }}
//                           />
//                         )}
                        
//                         <Typography variant="subtitle2" fontWeight={600} sx={{ mb: 0.5 }}>
//                           Standard Price
//                         </Typography>
//                         <Typography
//                           variant="body2"
//                           sx={{ 
//                             textDecoration: "line-through",
//                             mb: 2
//                           }}
//                           color="text.secondary"
//                         >
//                           {addon.standardPrice}
//                         </Typography>
                        
//                         <Typography variant="subtitle2" fontWeight={600} color="primary" sx={{ mb: 0.5 }}>
//                           Limited Time Offer
//                         </Typography>
//                         <Typography
//                           variant="h5"
//                           fontWeight={700}
//                           color="primary"
//                           sx={{ mb: 1.5 }}
//                         >
//                           {addon.discountedPrice}
//                         </Typography>
                        
//                         {!addon.discountPercentage && addon.additionalInfo && (
//                           <Typography 
//                             variant="body2" 
//                             sx={{ 
//                               mt: 1, 
//                               fontStyle: 'italic',
//                               color: 'text.secondary'
//                             }}
//                           >
//                             {addon.additionalInfo}
//                           </Typography>
//                         )}
//                       </Box>

//                       <Button                        
//                         variant="contained"
//                         className = "secondary-button"
//                         onClick={() => {handleContactSales(addon.category, addon.title)}}
//                         disableElevation
//                         sx={{
//                           py: 1.5,
//                           px: 3,
//                           borderRadius: 2.5,
//                           textTransform: "none",
//                           fontWeight: 600,
//                           fontSize: '0.95rem',
//                         }}
//                       >
//                         {addon.ctaText}
//                       </Button>
//                     </Box>
//                   </Grid>
//                 </Grid>
//               </Box>
//             ))}
//           </Box>
//         </Box>
//     </>
//   );
// };

// export default AddonSection;



import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import PaymentsIcon from '@mui/icons-material/Payments';
import TuneIcon from '@mui/icons-material/Tune';
import {
  Box,
  Button,
  Chip,
  Divider,
  Grid,
  Typography
} from '@mui/material';
import React, { useContext } from 'react';
// Import the CurrencyContext
import { CurrencyContext } from './PriceConverter';

const AddonSection = ({ ...props }) => {
  const { addonServices, handleContactSales } = props;
  // Use the CurrencyContext to access currency and conversion rate
  const { currency, conversionRate } = useContext(CurrencyContext);

  // Helper function to format price strings
  const formatPrice = (priceString) => {
    if (!priceString) return priceString;

    // Extract numerical values and currency symbols from the price string
    const regex = /\$([0-9,]+(?:\.[0-9]+)?)/g;
    let match;
    let formattedString = priceString;

    while ((match = regex.exec(priceString)) !== null) {
      const extractedPrice = match[1].replace(/,/g, '');
      const numericPrice = parseFloat(extractedPrice);
      
      // Convert to INR if needed
      const convertedPrice = currency === 'USD' 
        ? numericPrice 
        : Math.round(numericPrice * conversionRate);
      
      // Format the number with commas
      const formattedNumber = convertedPrice.toLocaleString('en-IN');
      
      // Replace the USD price with the converted price
      const currencySymbol = currency === 'USD' ? '$' : '₹';
      formattedString = formattedString.replace(
        match[0], 
        `${currencySymbol}${formattedNumber}`
      );
    }

    return formattedString;
  };

  return (
    <>
        <Box mt={10} mb={4}>
          <Typography
            variant="h3"
            textAlign="center"
            gutterBottom
            fontWeight={800}
            sx={{
              mb: 1,
              background: "linear-gradient(90deg, #333 30%, #6056ff 100%)",
              WebkitBackgroundClip: "text",
              WebkitTextFillColor: "transparent",
            }}
          >
            Additional Services
          </Typography>
          <Typography
            variant="body1"
            textAlign="center"
            color="textSecondary"
            mb={5}
            sx={{
              maxWidth: "700px",
              mx: "auto",
              fontSize: "1.1rem",
            }}
          >
            Enhance your AI capabilities with these powerful add-ons
          </Typography>

          {/* Creative alternative to traditional cards */}
          <Box 
            sx={{ 
              position: 'relative',
              maxWidth: '1100px',
              mx: 'auto',
              borderRadius: 6,
              overflow: 'hidden',
              bgcolor: '#fff',
              boxShadow: (theme) => `0 10px 40px ${theme.palette.mode === 'dark' ? 'rgba(0,0,0,0.2)' : 'rgba(96, 86, 255, 0.15)'}`,
              border: '1px solid',
              borderColor: 'divider',
              zIndex: 1,
              '&::before': {
                content: '""',
                position: 'absolute',
                top: 0,
                left: 0,
                right: 0,
                height: '6px',
                background: 'linear-gradient(90deg, #6056ff 0%, #8075ff 100%)',
                zIndex: 2
              }
            }}
          >
            {addonServices.map((addon, index) => (
              <Box 
                key={addon.id}
                sx={{
                  position: 'relative',
                  p: { xs: 3, md: 5 },
                  ...(index < addonServices.length - 1 && {
                    borderBottom: '1px dashed',
                    borderColor: 'divider',
                  })
                }}
              >
                <Grid container spacing={3}>
                  {/* Left Column - Icon and Title */}
                  <Grid item xs={12} md={5}>
                    <Box sx={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
                      <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
                        <Box
                          sx={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            width: 52,
                            height: 52,
                            borderRadius: '14px',
                            background: (theme) => `linear-gradient(135deg, ${theme.palette.primary.main} 0%, ${theme.palette.primary.dark} 100%)`,
                            color: 'white',
                            mr: 2,
                            boxShadow: '0 4px 12px rgba(96, 86, 255, 0.25)',
                          }}
                        >
                          {addon.icon === "tune" ? (
                            <TuneIcon sx={{ fontSize: 28 }} />
                          ) : (
                            <PaymentsIcon sx={{ fontSize: 28 }} />
                          )}
                        </Box>
                        <Typography 
                          variant="h5" 
                          fontWeight={700}
                          sx={{ letterSpacing: '-0.5px' }}
                        >
                          {addon.title}
                        </Typography>
                      </Box>
                      
                      <Typography 
                        variant="body1"
                        sx={{
                          color: 'text.secondary',
                          lineHeight: 1.6,
                          mb: 3
                        }}
                      >
                        {addon.description}
                      </Typography>

                      {/* Feature list */}
                      <Box sx={{ mt: 'auto' }}>
                        <Typography 
                          variant="subtitle2"
                          fontWeight={600}
                          sx={{ mb: 1, color: 'text.primary' }}
                        >
                          Key Benefits:
                        </Typography>
                        {addon.features.map((feature, idx) => (
                          <Box 
                            key={idx} 
                            sx={{ 
                              display: 'flex', 
                              alignItems: 'center',
                              mb: 1
                            }}
                          >
                            <CheckCircleOutlineIcon 
                              sx={{ 
                                fontSize: 18, 
                                mr: 1, 
                                color: 'primary.main' 
                              }} 
                            />
                            <Typography variant="body2" color="text.secondary">
                              {feature}
                            </Typography>
                          </Box>
                        ))}
                      </Box>
                    </Box>
                  </Grid>

                  {/* Vertical Divider for desktop */}
                  <Grid 
                    item 
                    sx={{ 
                      display: { xs: 'none', md: 'flex' },
                      alignItems: 'center',
                      justifyContent: 'center'
                    }}
                  >
                    <Divider 
                      orientation="vertical" 
                      flexItem 
                      sx={{ 
                        height: '80%', 
                        my: 'auto',
                        borderStyle: 'dashed'
                      }} 
                    />
                  </Grid>

                  {/* Right Column - Pricing - Updated with currency conversion */}
                  <Grid item xs={12} md={6}>
                    <Box 
                      sx={{ 
                        display: 'flex', 
                        flexDirection: 'column',
                        height: '100%',
                        justifyContent: 'center'
                      }}
                    >
                      <Box
                        sx={{
                          position: 'relative',
                          bgcolor: (theme) => theme.palette.mode === 'dark' ? 'rgba(96, 86, 255, 0.08)' : 'rgba(96, 86, 255, 0.04)',
                          borderRadius: 4,
                          p: 3,
                          mb: 3,
                          overflow: 'hidden',
                          border: '1px solid',
                          borderColor: 'primary.light',
                          transition: 'all 0.2s ease',
                          '&:hover': {
                            boxShadow: '0 6px 20px rgba(96, 86, 255, 0.1)',
                            transform: 'translateY(-4px)',
                          },
                          '&::after': {
                            content: '""',
                            position: 'absolute',
                            bottom: 0,
                            right: 0,
                            width: '30%',
                            height: '30%',
                            background: 'radial-gradient(circle, rgba(96, 86, 255, 0.1) 0%, rgba(255, 255, 255, 0) 70%)',
                            zIndex: 0
                          }
                        }}
                      >
                        {addon.discountPercentage && (
                          <Chip
                            label={`Save ${addon.discountPercentage}%`}
                            size="small"
                            sx={{
                              position: 'absolute',
                              top: 16,
                              right: 16,
                              fontWeight: 700,
                              bgcolor: 'rgba(46, 204, 113, 0.15)',
                              color: '#2ecc71',
                              border: '1px solid rgba(46, 204, 113, 0.3)',
                              '& .MuiChip-label': {
                                px: 1,
                              }
                            }}
                          />
                        )}
                        
                        <Typography variant="subtitle2" fontWeight={600} sx={{ mb: 0.5 }}>
                          Standard Price
                        </Typography>
                        <Typography
                          variant="body2"
                          sx={{ 
                            textDecoration: "line-through",
                            mb: 2
                          }}
                          color="text.secondary"
                        >
                          {/* Apply currency conversion to standard price */}
                          {formatPrice(addon.standardPrice)}
                        </Typography>
                        
                        <Typography variant="subtitle2" fontWeight={600} color="primary" sx={{ mb: 0.5 }}>
                          Limited Time Offer
                        </Typography>
                        <Typography
                          variant="h5"
                          fontWeight={700}
                          color="primary"
                          sx={{ mb: 1.5 }}
                        >
                          {/* Apply currency conversion to discounted price */}
                          {formatPrice(addon.discountedPrice)}
                        </Typography>
                        
                        {!addon.discountPercentage && addon.additionalInfo && (
                          <Typography 
                            variant="body2" 
                            sx={{ 
                              mt: 1, 
                              fontStyle: 'italic',
                              color: 'text.secondary'
                            }}
                          >
                            {addon.additionalInfo}
                          </Typography>
                        )}
                      </Box>

                      <Button                        
                        variant="contained"
                        className="secondary-button"
                        onClick={() => {handleContactSales(addon.category, addon.title)}}
                        disableElevation
                        sx={{
                          py: 1.5,
                          px: 3,
                          borderRadius: 2.5,
                          textTransform: "none",
                          fontWeight: 600,
                          fontSize: '0.95rem',
                        }}
                      >
                        {addon.ctaText}
                      </Button>
                    </Box>
                  </Grid>
                </Grid>
              </Box>
            ))}
          </Box>
        </Box>
    </>
  );
};

export default AddonSection;