import { Routes, Route } from "react-router-dom";
import ECommerceDashboard from "./ECommerceDashboard";
import { Helmet } from "react-helmet-async";

export const Dashboards = ({ children, ...props }) => {
  const { isPhone, isTab, deviceWithSmallHeight } = props;

  return (
    <>
      <Helmet>
        <title>Web Rippler - dashboards</title>
        <meta
          name="description"
          content="Transform your website with a custom design, crafted by Web Rippler and the Coding Rippler team."
        />
        <meta property="og:title" content="Web Rippler - dashboards" />
        <meta
          property="og:description"
          content="Transform your website with a custom design, crafted by Web Rippler and the Coding Rippler team."
        />
        <meta
          property="og:url"
          content="https://www.codingrippler.com/web/dashboards"
        />
        <meta property="og:type" content="website" />
        <link
          rel="canonical"
          href="https://www.codingrippler.com/web/dashboards"
        />
      </Helmet>
      <Routes>
        <Route
          path="/*"
          element={
            <ECommerceDashboard
              isPhone={isPhone}
              isTab={isTab}
              deviceWithSmallHeight={deviceWithSmallHeight}
            />
          }
        />
      </Routes>
    </>
  );
};
