// Pricing data - included inline to avoid import errors
export const aiStudioPlans = [
    {
        name: 'Startup',
        category: 'AI Solutions',
        audience: 'Small teams, early adopters',
        monthlyPrice: 150,
        discountedMonthlyPrice: 99,
        discountPercentage: 34,
        yearlySavingsBadge: 'Save 2 Months!',
        yearlyPrice: 990,
        yearlyPerMonth: 82.5,
        usageLimits: '1,500 API calls/month',
        support: 'Email support',
        // features: [
        //     "AI-powered knowledge retrieval",
        //     "Custom knowledge base",
        //     "1 data source connection",
        //     "1 workflow customization"
        //       ],
        features: [
            { "name": "AI-powered knowledge retrieval", "active": true },
            { "name": "Custom knowledge base", "active": true },
            { "name": "1 data source connection", "active": true },
            { "name": "1 workflow customization", "active": true },
            { "name": "Dedicated training sessions", "active": false },
            { "name": "Custom workflow automation", "active": false },
            { "name": "Custom SLAs & uptime guarantees" , "active": false  }
          ],
      
        popular: false,
        enterprise: false
    },
    {
        name: 'Growth',
        category: 'AI Solutions',
        audience: 'Scaling businesses, teams with AI needs',
        monthlyPrice: 249,
        discountedMonthlyPrice: 199,
        discountPercentage: 20,
        yearlySavingsBadge: 'Save 2 Months!',
        yearlyPrice: 1990,
        yearlyPerMonth: 165.83,
        usageLimits: '3,000 API calls/month',
        support: 'Priority email & chat support',
        // features: [
        //             "Everything in Startup plan",
        //             "Up to 3 data source connections",
        //             "Up to 3 workflow customizations",
        //             "Dedicated training sessions",
        //       ],

        features: [
            { "name": "AI-powered knowledge retrieval", "active": true },
            { "name": "Custom knowledge base", "active": true },
            { "name": "Up to 3 data source connections", "active": true },
            { "name": "Up to 3 workflow customizations", "active": true },
            { "name": "Dedicated training sessions", "active": true },
            { "name": "Custom workflow automation", "active": false },
            { "name": "Custom SLAs & uptime guarantees" , "active": false  }
          ],

        popular: true,
        enterprise: false
    },
    {
        name: 'Enterprise',
        category: 'AI Solutions',
        audience: 'High-volume AI usage, large teams',
        monthlyPrice: null,
        discountedMonthlyPrice: null,
        discountPercentage: null,
        yearlySavingsBadge: null,
        yearlyPrice: null,
        yearlyPerMonth: null,
        usageLimits: 'Custom API call limits',
        support: 'Dedicated account manager, SLA',
        // features: [
        //     "Everything in Growth plan",
        //     "Custom SLAs & uptime guarantees",
        //     "Custom data source connections",
        //     "Custom workflow automation"
        //       ],
        features: [
            { "name": "AI-powered knowledge retrieval", "active": true },
            { "name": "Custom knowledge base", "active": true },
            { "name": "Custom data source connections", "active": true },
            { "name": "Custom workflow customizations" , "active": true  },
            { "name": "Dedicated training sessions", "active": true },
            { "name": "Custom workflow automation" , "active": true  },
            { "name": "Custom SLAs & uptime guarantees", "active": true },
          ],

        popular: false,
        enterprise: true
    }
  ];
  
export const webSolutionsPlans = [
    {
        name: 'Essential',
        category: 'Web Solutions',
        audience: 'Startups, small businesses needing a basic website',
        monthlyPrice: 199,
        discountedMonthlyPrice: 99,
        discountPercentage: 50,
        yearlySavingsBadge: null,
        yearlyPrice: null,
        yearlyPerMonth: null,
        support: 'Standard support',
        features: [
            'Landing page or 5-page website',
            'Mobile-friendly design',
            'Basic SEO setup',
            'Contact form integration',
            'Google Analytics setup',
            '14-day post-launch support'
        ],
        popular: false,
        enterprise: false
    },
    {
        name: 'Growth',
        category: 'Web Solutions',
        audience: 'Businesses needing a scalable custom website or web application',
        monthlyPrice: 2999,
        discountedMonthlyPrice: 1999,
        discountPercentage: 33,
        yearlySavingsBadge: null,
        yearlyPrice: null,
        yearlyPerMonth: null,
        support: 'Priority support',
        features: [
            'Everything in Essential plan',
            'Advanced custom development',
            'Third-party API integrations',
            'Admin dashboard',
            'Custom design elements',
            'Optional AI-powered features via AI Studio',
            '60-day post-launch support'
        ],
        popular: true,
        enterprise: false
    },
    {
        name: 'Enterprise',
        category: 'Web Solutions',
        audience: 'Businesses needing complex web applications, SaaS solutions, or enterprise systems',
        monthlyPrice: 9999,
        discountedMonthlyPrice: 6999,
        discountPercentage: 30,
        yearlySavingsBadge: null,
        yearlyPrice: null,
        yearlyPerMonth: null,
        support: 'Dedicated project manager',
        features: [
            'Everything in Growth plan',
            'Custom web portal development',
            'SaaS-ready architecture',
            'Advanced API integration & automation',
            'Database setup & performance tuning',
            '6-month support & maintenance'
        ],
        popular: false,
        enterprise: true
    }
  ];
  
export const addonServices = [
    {
        id: 'fine-tuning',
        category: 'AI Solutions',
        title: 'Fine-Tuning Add-On',
        icon: 'settings',
        description: 'Train AI models on your specific data for improved accuracy and personalization. Fine-tuning allows your models to better understand your business context.',
        standardPrice: '$5,000 one-time setup + $0.04 per training token',
        discountedPrice: '$2,500 setup fee + $0.02 per training token',
        discountPercentage: 50,
        ctaText: 'Discuss with Sales',
        features: [
            "Custom model training",
            "Business-specific context",
            "Data privacy protection"
        ]  
    },
    {
        id: 'usage-pricing',
        category: 'AI Solutions',
        title: 'Usage-Based Pricing',
        icon: 'speed',
        description: 'Flexible pricing for businesses with variable usage needs. Only pay for what you use beyond your plan limits.',
        standardPrice: '$0.02 per extra API call',
        discountedPrice: '$0.015 per extra API call',
        additionalInfo: 'Bulk pricing discounts available for high-volume users upon request',
        ctaText: 'Discuss with Sales',
        features: [
            "Pay only for what you use",
            "Flexible billing options",
            "Transparent pricing"
        ]
    }
  ];
  