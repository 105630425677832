import { Box, Grid } from "@mui/material";
import React from "react";
import { SubmitYourEmail } from "../../CodingRipplerHome/ContactUs/SubmitYourEmail";
import {
  AdvertiseBrands,
  CardWithTextAndButton,
  CommonContainer,
  HeroSubHeader,
  HeroText,
} from "../../CodingRipplerWidgets";

export const GridHome = ({ children, ...props }) => {
  const { isPhoneorTab, isPhone, isTab, deviceWithSmallHeight } = props;

  const brandList = [
    {
      imageList1: [
        {
          image: "/codingrippler/brandIconsblack/logos_slack.svg",
        },
        {
          image: "/codingrippler/brandIconsblack/icons_amazon.svg",
        },
        {
          image: "/codingrippler/brandIconsblack/logos_google.svg",
        },
        {
          image: "/codingrippler/brandIconsblack/logos_microsoft.svg",
        },
        {
          image: "/codingrippler/brandIconsblack/icons_zara.svg",
        },
        {
          image: "/codingrippler/brandIconsblack/logos_netflix.svg",
        },
      ],
    },
    {
      imageList2: [
        {
          image: "/codingrippler/brandIconsblack/logos_youtube.svg",
        },
        {
          image: "/codingrippler/brandIconsblack/logos_airbnb.svg",
        },
        {
          image: "/codingrippler/brandIconsblack/logos_hubspot.svg",
        },
      ],
    },
  ];

  const productsInfo = [
    {
      image: "/codingrippler/masonry/img1.jpeg",
      title: "Lorem ipsum dolor",
      description: "Lorem ipsum dolor sit amet.",
      buttonText: "Learn More",
    },
    {
      image: "/codingrippler/masonry/img2.jpeg",
      title: "Consectetur adipiscing",
      description: "Consectetur adipiscing elit.",
    },
    {
      image: "/codingrippler/masonry/img3.jpeg",
      title: "Sed do eiusmod",
      description: "Sed do eiusmod tempor incididunt.",
      buttonText: "Learn More",
    },
    {
      image: "/codingrippler/masonry/img4.jpeg",
      title: "Tempor incididunt",
      description: "Tempor incididunt ut labore.",
    },
    {
      image: "/codingrippler/masonry/img5.jpeg",
      title: "Ut enim ad minim",
      description: "Ut enim ad minim veniam.",
      buttonText: "Learn More",
    },
    {
      image: "/codingrippler/masonry/img6.jpeg",
      title: "Quis nostrud",
      description: "Quis nostrud exercitation ullamco.",
    },
    {
      image: "/codingrippler/masonry/img7.jpeg",
      title: "Laboris nisi",
      description: "Laboris nisi ut aliquip ex ea.",
    },
    {
      image: "/codingrippler/masonry/img8.jpeg",
      title: "Duis aute irure",
      description: "Duis aute irure dolor in reprehenderit.",
      buttonText: "Learn More",
    },
    {
      image: "/codingrippler/masonry/img9.jpeg",
      title: "In voluptate velit",
      description: "In voluptate velit esse cillum.",
    },
    {
      image: "/codingrippler/masonry/img3.jpeg",
      title: "Sed do eiusmod",
      description: "Sed do eiusmod tempor incididunt.",
      buttonText: "Learn More",
    },
    {
      image: "/codingrippler/masonry/img5.jpeg",
      title: "Ut enim ad minim",
      description: "Ut enim ad minim veniam.",
      buttonText: "Learn More",
    },
    {
      image: "/codingrippler/masonry/img8.jpeg",
      title: "Duis aute irure",
      description: "Duis aute irure dolor in reprehenderit.",
      buttonText: "Learn More",
    },
  ];

  const SubmitYourPageContent = [
    {
      headerText: "Ready to scale your business?",
      subHeaderText:
        "Your website is fully responsive, ensuring visitors can access your content seamlessly from any device.",
      buttonText: "Hire Us",
      context: "HireUs",
    },
  ];

  return (
    <Grid
      container
      spacing={4}
      sx={{
        overflow: "hidden",
        height: "100%",
      }}
    >
      <Grid
        item
        xs={12}
        mt={{
          xs: "70px",
          sm: "85px",
          lg: "100px",
        }}
      >
        <CommonContainer>
          <HeroSubHeader variant="h6">
            Web Rippler enhances your product with a modern, professional
            design, saving you precious time.
          </HeroSubHeader>

          <Box m={2} />
          <HeroText variant={isPhoneorTab ? "h4" : "h3"}>
            We specialize in designing and developing web apps
          </HeroText>
        </CommonContainer>
      </Grid>
      <Grid item xs={12} />

      <Grid item xs={12}>
        <CommonContainer>
          <AdvertiseBrands
            isPhone={isPhone}
            isTab={isTab}
            deviceWithSmallHeight={deviceWithSmallHeight}
            pageContent={brandList}
            isPhoneorTab={isPhoneorTab}
          />
        </CommonContainer>
      </Grid>

      <Grid
        item
        lg={1}
        sx={{
          display: {
            xs: "none",
            sm: "none",
            lg: "block",
          },
        }}
      />
      <Grid item xs={12} lg={10}>
        <Grid container>
          {productsInfo.map((data, index) => (
            <Grid item xs={12} sm={6} lg={4} key={index}>
              <CardWithTextAndButton
                image={data.image}
                title={data.title}
                description={data.description}
                buttonText={data.buttonText}
                isPhone={isPhone}
                isTab={isTab}
                deviceWithSmallHeight={deviceWithSmallHeight}
                isPhoneorTab={isPhoneorTab}
              />
            </Grid>
          ))}
        </Grid>
      </Grid>
      <Grid
        item
        lg={1}
        sx={{
          display: {
            xs: "none",
            sm: "none",
            lg: "block",
          },
        }}
      />

      <Grid item xs={12}>
        <Box
          component="svg"
          sx={{
            position: "relative",
            width: "100%",
            height: "auto",
            transform: {
              xs: "translateY(50%)",
              sm: "translateY(50%)",
              lg: "translateY(25%)",
            },
          }}
          preserveAspectRatio="none"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 1920 100.1"
        >
          <path
            fill="#f7faff"
            //       fill="black"
            d="M0,0c0,0,934.4,93.4,1920,0v100.1H0L0,0z"
          ></path>
        </Box>
      </Grid>
      <Grid item xs={12} className="white-bg">
        <CommonContainer>
          <SubmitYourEmail
            isPhone={isPhone}
            isTab={isTab}
            deviceWithSmallHeight={deviceWithSmallHeight}
            isPhoneorTab={isPhoneorTab}
            pageContent={SubmitYourPageContent}
          />
        </CommonContainer>
      </Grid>
    </Grid>
  );
};
