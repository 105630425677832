import EmailIcon from '@mui/icons-material/Email';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  styled,
  TextField
} from '@mui/material';
import React, { useState } from 'react';
import { contactService } from "../../../../services/contactService";
import ModernLoader from '../../CRAnimations/ModernLoaders';

const FormCard = styled(Box)(({ theme }) => ({
  backgroundColor: 'white',
  borderRadius: '16px',
  padding: theme.spacing(3, 3, 3, 3),
  maxWidth: '700px',
  width: '100%',
  margin: '0 auto',
  boxShadow: '0 15px 50px rgba(96, 86, 255, 0.15)',
  border: '1px solid rgba(96, 86, 255, 0.08)',
}));

// Loading button component that uses the ModernLoader
const LoadingButton = ({ loading, children, ...props }) => {
  return (
    <Button {...props}>
      {loading ? (
        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '24px' }}>
          <ModernLoader
            dotSize={8}
            dotCount={3}
            barWidth={60}
            barHeight={3}
            gap={1}
            dotColor="linear-gradient(135deg, #ffffff 0%, #e0e0ff 100%)"
            barColor="linear-gradient(90deg, #ffffff, #e0e0ff, #ffffff)"
            barBgColor="rgba(255, 255, 255, 0.3)"
          />
        </Box>
      ) : (
        children
      )}
    </Button>
  );
};

const GetInTouch = () => {
  const [email, setEmail] = useState("");
  const [loading, setLoading] = useState(false);
  const [emailError, setEmailError] = useState("");
  const [dialogOpen, setDialogOpen] = useState(false);
  const [dialogTitle, setDialogTitle] = useState("");
  const [dialogMessage, setDialogMessage] = useState("");

  const validateEmail = (email) => {
    const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return re.test(String(email).toLowerCase());
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setLoading(true);

    if (!email) {
      setEmailError("Email is required");
      setDialogTitle("Validation Error");
      setDialogMessage("Email is required.");
      setDialogOpen(true);
      setLoading(false);
      return;
    } else if (!validateEmail(email)) {
      setEmailError("Invalid email format");
      setDialogTitle("Validation Error");
      setDialogMessage("Invalid email format.");
      setDialogOpen(true);
      setLoading(false);
      return;
    } else {
      setEmailError("");
    }

    try {
      const data = await contactService.sendContactForm({
        email: email,
        subject: "Free Consultancy Request " + email,
      });

      if (data?.success ?? false) {
        setEmail("");
        setDialogTitle("Success");
        setDialogMessage("Your request has been sent successfully.");
      } else {
        setDialogTitle("Error");
        setDialogMessage("Failed to send contact form. Please try again.");
      }
    } catch (error) {
      setDialogTitle("Error");
      setDialogMessage(error.message);
    } finally {
      setLoading(false);
      setDialogOpen(true);
    }
  };

  const handleCloseDialog = () => {
    setDialogOpen(false);
  };

  return (
    <>
      <FormCard>
        <Box
          component="form"
          onSubmit={handleSubmit}
          sx={{
            display: "flex",
            flexDirection: { xs: "column", sm: "row" },
            gap: 3,
            alignItems: { xs: "stretch", sm: "flex-start" },
            width: "100%",
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              flexGrow: 1,
              width: "100%",
            }}
          >
            <TextField
              fullWidth
              variant="outlined"
              placeholder="Enter your email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              error={!!emailError}
              helperText={emailError}
              InputProps={{
                startAdornment: (
                  <EmailIcon
                    sx={{
                      color: "#7c4fe0",
                      mr: 1.5,
                      fontSize: "1.3rem",
                      transition: "all 0.2s ease",
                      opacity: 0.8,
                      ".MuiOutlinedInput-root:hover &, .MuiOutlinedInput-root.Mui-focused &": {
                        opacity: 1,
                        transform: "scale(1.05)",
                      },
                    }}
                  />
                ),
                sx: {
                  borderRadius: "8px",
                  height: "56px",
                  "& fieldset": {
                    borderColor: "rgba(0, 0, 0, 0.1)",
                  },
                },
              }}
              sx={{
                flexGrow: 1,
                "& .MuiOutlinedInput-root": {
                  "&:hover fieldset": {
                    borderColor: "rgba(0, 0, 0, 0.2)",
                  },
                  "&.Mui-focused fieldset": {
                    borderColor: "#6056ff",
                  },
                  "&.Mui-error fieldset": {
                    borderColor: "error.main",
                  },
                },
                // We don't need negative margin here anymore
              }}
            />
          </Box>

          <LoadingButton
            loading={loading}
            variant="contained"
            type="submit"
            disabled={loading}
            className="primary-button"
            sx={{
              height: "56px",
              width: { xs: "100%", sm: "auto" },
              flexShrink: 0,
              minWidth: { sm: "150px" },
              // Align the button to the top when on desktop view
              marginTop: { sm: "0" },
              alignSelf: { sm: "flex-start" }
            }}
          >
            GET IN TOUCH
          </LoadingButton>
        </Box>
      </FormCard>

      <Dialog open={dialogOpen} onClose={handleCloseDialog}>
        <DialogTitle>{dialogTitle}</DialogTitle>
        <DialogContent>
          <DialogContentText>{dialogMessage}</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default GetInTouch;