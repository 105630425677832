import HubIcon from "@mui/icons-material/Hub";
import { Grid, useMediaQuery, useTheme } from "@mui/material";
import { useState } from "react";
import { Helmet } from "react-helmet-async";
import { Route, Routes } from "react-router-dom";
import { AI } from "../AI/AI";
import { SlideInFromTopEffect } from "../BoilarPlateJs/MotionEffects";
import PrivacyPolicy from '../privacy/PrivacyPolicy';
import { AboutHome } from "./CodingRipplerHome/AboutUs/AboutUsV2";
import ContactDialog, { trackAndOpenDialog } from "./CodingRipplerHome/ContactUs/ContactUsDialog";
import CRHomeV4 from "./CodingRipplerHome/CRHomeV4";
import { scrollToPricing } from './CodingRipplerHome/Pricing/PricingPage';
import { Services } from "./CRDemo/OurServices/Services";
import Footer from "./Footer/CRFooterV2";
import NavigationBar from "./Navigations/CommonNavigation/NavigationBar";


export const CodingRippler = ({ children, ...props }) => {
  const theme = useTheme();
  const isPhone = useMediaQuery(theme.breakpoints.down("sm"));
  const isTab = useMediaQuery(theme.breakpoints.down("lg"));
  const deviceWithSmallHeight = useMediaQuery("(max-height: 750px)");

  const isPhoneOrTab = isPhone || isTab;

  const { logoPath } = props;

  const [open, setOpen] = useState(false);
    
  const handleContactClick = () => trackAndOpenDialog(setOpen);

  const handleClose = (success) => {
    setOpen(false);
    if (success) {
      // Handle successful submission (e.g. show a success toast)
    }
  };  


  const menuItems = [
    {
      id: "home",
      label: "HOME",
      onClick: () => (window.location.href = "/"),
    },
    {
      "id": "teams",
      "label": "TEAMS",
      "subMenu": [
        {
          "id": "ai",
          "label": "AI Rippler",
          "description": "A dedicated team specializing in AI automation, intelligent chatbots, and data-driven solutions for businesses.",
          "icon": <HubIcon fontSize="small" />,
          "onClick": () => window.location.href = '/ai'
        },
        // {
        //   "id": "web",
        //   "label": "Web Rippler",
        //   "description": "A dedicated team focused on building scalable, high-performance web applications using React, MUI, and modern frameworks.",
        //   "icon": <ComputerIcon fontSize="small" />,
        //   "onClick": () => window.location.href = '/web'
        // }
      ]
    },
    {
      id: "pricing",
      label: "PRICING",
      onClick: scrollToPricing,
    },

    {
      id: "about",
      label: "ABOUT",
      onClick: () => (window.location.href = "/about"),
    },
  ];
  return (
    <>
      <Helmet>
        <title>Coding Rippler - Home</title>
        <meta
          name="description"
          content="Welcome to Coding Rippler, your go-to source for coding solutions."
        />
        <meta property="og:title" content="Coding Rippler - Home" />
        <meta
          property="og:description"
          content="Welcome to Coding Rippler, your go-to source for coding solutions."
        />
        <meta property="og:url" content="https://www.codingrippler.com/" />
        <meta property="og:type" content="website" />
        <link rel="canonical" href="https://www.codingrippler.com/" />

        <script type="application/ld+json">
          {`
      {
        "@context": "http://schema.org",
        "@type": "WebSite",
        "url": "https://www.codingrippler.com/",
        "name": "Coding Rippler",
        "potentialAction": {
          "@type": "SearchAction",
          "target": "https://www.codingrippler.com/search?q={search_term_string}",
          "query-input": "required name=search_term_string"
        }
      }
    `}
        </script>
      </Helmet>
      <Grid
        container
        sx={{
          maxWidth: "100%",
        }}
      >
        <Grid item xs={12}>
          <NavigationBar
            // Pass logo image source directly
            logoSrc={logoPath}
            logoAlt="CodingRippler Logo"
            // Fallback title if no logo
            title="CodingRippler"
            menuItems={menuItems}
            actionButtonText="LET'S TALK"
            actionButtonIcon="→"
            onActionButtonClick={handleContactClick}
          />
          <ContactDialog open={open} onClose={handleClose} />
        </Grid>

        <Routes>
          <Route
            path="/*"
            element={
              <Grid item xs={12}>
                {/* <CRHomeV3
                  isPhone={isPhone}
                  isTab={isTab}
                  isPhoneorTab={isPhoneOrTab}
                  deviceWithSmallHeight={deviceWithSmallHeight}
                /> */}

                <CRHomeV4
                  isPhone={isPhone}
                  isTab={isTab}
                  isPhoneorTab={isPhoneOrTab}
                  deviceWithSmallHeight={deviceWithSmallHeight}
                />
              </Grid>
            }
          />

          <Route path="/about/*" element={<AboutHome />} />

          <Route
            path="/web/*"
            element={
              <Services
                isPhone={isPhone}
                isTab={isTab}
                deviceWithSmallHeight={deviceWithSmallHeight}
                isPhoneorTab={isPhoneOrTab}
                theme={theme}
              />
            }
          />

          <Route path="/ai/*" element={<AI />} />

          <Route path="/privacy-policy/*" element={<PrivacyPolicy />} />
        </Routes>

        <Grid item xs={12} className="white-bg">
          <SlideInFromTopEffect>
            {/* <CRFooter
              isPhone={isPhone}
              isPhoneorTab={isPhoneOrTab}
              isTab={isTab}
              deviceWithSmallHeight={deviceWithSmallHeight}
            /> */}

            <Footer
              isPhone={isPhone}
              isPhoneorTab={isPhoneOrTab}
              isTab={isTab}
              deviceWithSmallHeight={deviceWithSmallHeight}
            />
          </SlideInFromTopEffect>
        </Grid>
      </Grid>
    </>
  );
};
