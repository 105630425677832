import BuildIcon from '@mui/icons-material/Build';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import {
  Box,
  Button,
  Card,
  CardContent,
  CardMedia,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  Tooltip,
  Typography
} from '@mui/material';
import React, { useEffect, useRef, useState } from 'react';

// const SolutionCard = ({ solution }) => {
//   // State for the popup dialog
//   const [openDialog, setOpenDialog] = useState(false);

//   // Generate a color based on tag name for variety
//   const getTagColor = (tag) => {
//     const colors = ['primary', 'secondary', 'success', 'info', 'warning'];
//     const hash = tag.split('').reduce((acc, char) => char.charCodeAt(0) + acc, 0);
//     return colors[hash % colors.length];
//   };



//   /**
//  * Opens a URL either in a new tab or the current tab
//  * @param {string} url - The URL to open
//  * @param {boolean} openInNewTab - Whether to open in new tab (true) or current tab (false)
//  */
// const openUrl = (url, openInNewTab) => {

//   console.log("url.."+url);
//   if (openInNewTab) {
//     // Open in new tab
//     window.open(url, '_blank', 'noopener,noreferrer');
//   } else {
//     // Open in current tab
//     window.location.href = url;
//   }
// };
//   // Handle button click based on readiness
//   const handleDemoClick = () => {
//     if (solution.readyToUse !== 100) {
//       setOpenDialog(true);
//     }else{
//       openUrl(solution.demoUrl, solution.openInNewTab);
//     }
//     // If 100% ready, the button's link behavior will handle it
//   };

//   return (
//     <Card
//       sx={{
//         height: "100%",
//         display: "flex",
//         flexDirection: "column",
//         borderRadius: 3,
//         overflow: "hidden",
//         boxShadow: "0 10px 25px rgba(0,0,0,0.05)",
//         position: "relative",
//         transition: "transform 0.3s ease, box-shadow 0.3s ease",
//         "&:hover": {
//           transform: "translateY(-5px)",
//           boxShadow: "0 15px 30px rgba(99, 102, 241, 0.2)",
//         },
//       }}
//     >
//       {/* Completion Badge - Top Right if 100% complete */}
//       {solution.readyToUse === 100 && (
//         <Chip
//           icon={<CheckCircleOutlineIcon />}
//           label="Ready"
//           color="success"
//           size="small"
//           sx={{
//             position: "absolute",
//             top: 16,
//             right: 16,
//             fontSize: "0.75rem",
//             fontWeight: 600,
//             zIndex: 10,
//             boxShadow: "0 4px 8px rgba(0,0,0,0.15)",
//           }}
//         />
//       )}

//       {/* Hero Image with Gradient Overlay */}
//       <Box sx={{ position: "relative" }}>
//         <CardMedia
//           component="img"
//           image={solution.image}
//           alt={solution.title}
//           sx={{
//             height: 220,
//             objectFit: "cover",
//           }}
//         />
//         <Box
//           sx={{
//             position: "absolute",
//             bottom: 0,
//             left: 0,
//             right: 0,
//             height: "50%",
//             background: "linear-gradient(to top, rgba(0,0,0,0.7) 0%, rgba(0,0,0,0) 100%)",
//           }}
//         />
        
//         {/* Category Chip - Bottom of CardMedia */}
//         <Chip
//           label={solution.category}
//           color="primary"
//           size="small"
//           sx={{
//             position: "absolute",
//             bottom: 0,
//             left: "16px",
//             transform: "translateY(50%)",
//             fontSize: "0.75rem",
//             fontWeight: 600,
//             zIndex: 10,
//             boxShadow: "0 4px 8px rgba(0,0,0,0.15)",
//           }}
//         />
//       </Box>

//       <CardContent sx={{ flexGrow: 1, p: 3 }}>
//         <Typography
//           variant="h5"
//           component="h2"
//           gutterBottom
//           sx={{ 
//             fontWeight: 700,
//             fontSize: { xs: "1.5rem", md: "1.75rem" },
//             mb: 2,
//             mt: 1
//           }}
//         >
//           {solution.title}
//         </Typography>

//         <Typography 
//           variant="body1" 
//           sx={{ 
//             color: "text.secondary",
//             mb: 3,
//             lineHeight: 1.6,
//             height: 80, // Fixed height for description
//             overflow: "hidden",
//             display: "-webkit-box",
//             WebkitLineClamp: 3,
//             WebkitBoxOrient: "vertical"
//           }}
//         >
//           {solution.description}
//         </Typography>

//         {/* Features List */}
//         <Box sx={{ mb: 3 }}>
//           <Typography
//             variant="subtitle2"
//             gutterBottom
//             sx={{
//               fontWeight: 600,
//               display: "flex",
//               alignItems: "center",
//               color: "primary.main",
//               mb: 2
//             }}
//           >
//             <BuildIcon fontSize="small" sx={{ mr: 1 }} />
//             KEY FEATURES
//           </Typography>
          
//           <Grid container spacing={1}>
//             {solution.features.map((feature, index) => (
//               <Grid item xs={12} key={index}>
//                 <Box 
//                   sx={{ 
//                     display: "flex", 
//                     alignItems: "flex-start",
//                     mb: 1
//                   }}
//                 >
//                   <CheckCircleOutlineIcon 
//                     fontSize="small" 
//                     color="primary" 
//                     sx={{ 
//                       mr: 1,
//                       mt: 0.3,
//                       opacity: 0.8
//                     }} 
//                   />
//                   <Typography variant="body2">
//                     {feature}
//                   </Typography>
//                 </Box>
//               </Grid>
//             ))}
//           </Grid>
//         </Box>

//         {/* Tags - With varying colors for better aesthetics */}
//         <Stack 
//           direction="row" 
//           spacing={1} 
//           flexWrap="wrap"
//           sx={{ mb: 3, minHeight: 40 }}
//         >
//           {solution.tags.map((tag) => (
//             <Chip
//               key={tag}
//               label={tag}
//               size="small"
//               color={getTagColor(tag)}
//               variant="outlined"
//               sx={{ 
//                 mb: 1,
//                 borderRadius: "6px",
//                 fontWeight: 500,
//                 "& .MuiChip-label": {
//                   color: (theme) => theme.palette[getTagColor(tag)].main
//                 }
//               }}
//             />
//           ))}
//         </Stack>

//         {/* Demo Button - Using marginTop:auto with consistent height */}
//         <Box sx={{ 
//           display: "flex", 
//           justifyContent: "center",
//           mt: 'auto',
//           pt: 2,
//           height: 60 // Consistent height for button container
//         }}>
//             <Button
//               variant="contained"
//               startIcon={<PlayArrowIcon />}
//               size="large"
//               className="primary-button"
//               onClick={handleDemoClick}
//               sx={{ 
//                 borderRadius: "30px",
//                 py: 1.25,
//                 px: 4,
//                 fontWeight: 600,
//                 textTransform: "none",
//                 fontSize: "0.95rem",
//                 letterSpacing: "0.5px",
//               }}
//             >
//               Try Live Demo
//             </Button>
//         </Box>
//       </CardContent>

//       {/* Dialog for not-ready solutions */}
//       <Dialog
//         open={openDialog}
//         onClose={() => setOpenDialog(false)}
//         aria-labelledby="solution-dialog-title"
//         aria-describedby="solution-dialog-description"
//       >
//         <DialogTitle id="solution-dialog-title">
//           {solution.title} - In Development
//         </DialogTitle>
//         <DialogContent>
//           <DialogContentText id="solution-dialog-description">
//             This solution is currently {solution.readyToUse}% complete and still in development. 
//             The demo will be available once development is finished. Would you like to be notified when it's ready?
//           </DialogContentText>
//         </DialogContent>
//         <DialogActions>
//           <Button onClick={() => setOpenDialog(false)} color="primary">
//             No, thanks
//           </Button>
//           <Button 
//             onClick={() => setOpenDialog(false)} 
//             color="primary" 
//             variant="contained"
//             autoFocus
//           >
//             Notify me
//           </Button>
//         </DialogActions>
//       </Dialog>
//     </Card>
//   );
// };

const SolutionCard = ({ solution }) => {
  // State for the popup dialog
  const [openDialog, setOpenDialog] = useState(false);
  
  // Added ref for the description element to check if it's truncated
  const descriptionRef = useRef(null);
  // State to track if description is truncated and needs tooltip
  const [isDescriptionTruncated, setIsDescriptionTruncated] = useState(false);

  // Check if the description is truncated on mount and window resize
  useEffect(() => {
    const checkTruncation = () => {
      if (descriptionRef.current) {
        const element = descriptionRef.current;
        // If the scroll height is greater than the client height, text is truncated
        setIsDescriptionTruncated(element.scrollHeight > element.clientHeight);
      }
    };

    // Initial check
    checkTruncation();
    
    // Add resize listener
    window.addEventListener('resize', checkTruncation);
    
    // Cleanup
    return () => {
      window.removeEventListener('resize', checkTruncation);
    };
  }, [solution.description]);

  // Generate a color based on tag name for variety
  const getTagColor = (tag) => {
    const colors = ['primary', 'secondary', 'success', 'info', 'warning'];
    const hash = tag.split('').reduce((acc, char) => char.charCodeAt(0) + acc, 0);
    return colors[hash % colors.length];
  };

  /**
   * Opens a URL either in a new tab or the current tab
   * @param {string} url - The URL to open
   * @param {boolean} openInNewTab - Whether to open in new tab (true) or current tab (false)
   */
  const openUrl = (url, openInNewTab) => {
    console.log("url.."+url);
    if (openInNewTab) {
      // Open in new tab
      window.open(url, '_blank', 'noopener,noreferrer');
    } else {
      // Open in current tab
      window.location.href = url;
    }
  };

  // Handle button click based on readiness
  const handleDemoClick = () => {
    if (solution.readyToUse !== 100) {
      setOpenDialog(true);
    } else {
      openUrl(solution.demoUrl, solution.openInNewTab);
    }
    // If 100% ready, the button's link behavior will handle it
  };

  return (
    <Card
      sx={{
        height: "100%",
        display: "flex",
        flexDirection: "column",
        borderRadius: 3,
        overflow: "hidden",
        boxShadow: "0 10px 25px rgba(0,0,0,0.05)",
        position: "relative",
        transition: "transform 0.3s ease, box-shadow 0.3s ease",
        "&:hover": {
          transform: "translateY(-5px)",
          boxShadow: "0 15px 30px rgba(99, 102, 241, 0.2)",
        },
      }}
    >
      {/* Completion Badge - Top Right if 100% complete */}
      {solution.readyToUse === 100 && (
        <Chip
          icon={<CheckCircleOutlineIcon />}
          label="Ready"
          color="success"
          size="small"
          sx={{
            position: "absolute",
            top: 16,
            right: 16,
            fontSize: "0.75rem",
            fontWeight: 600,
            zIndex: 10,
            boxShadow: "0 4px 8px rgba(0,0,0,0.15)",
          }}
        />
      )}

      {/* Hero Image with Gradient Overlay */}
      <Box sx={{ position: "relative" }}>
        <CardMedia
          component="img"
          image={solution.image}
          alt={solution.title}
          sx={{
            height: 220,
            objectFit: "cover",
          }}
        />
        <Box
          sx={{
            position: "absolute",
            bottom: 0,
            left: 0,
            right: 0,
            height: "50%",
            background: "linear-gradient(to top, rgba(0,0,0,0.7) 0%, rgba(0,0,0,0) 100%)",
          }}
        />
        
        {/* Category Chip - Bottom of CardMedia */}
        <Chip
          label={solution.category}
          color="primary"
          size="small"
          sx={{
            position: "absolute",
            bottom: 0,
            left: "16px",
            transform: "translateY(50%)",
            fontSize: "0.75rem",
            fontWeight: 600,
            zIndex: 10,
            boxShadow: "0 4px 8px rgba(0,0,0,0.15)",
          }}
        />
      </Box>

      <CardContent sx={{ flexGrow: 1, p: 3 }}>
        <Typography
          variant="h5"
          component="h2"
          gutterBottom
          sx={{ 
            fontWeight: 700,
            fontSize: { xs: "1.5rem", md: "1.75rem" },
            mb: 2,
            mt: 1
          }}
        >
          {solution.title}
        </Typography>

        {/* Description with Tooltip - Only shows tooltip if text is truncated */}
        <Tooltip 
          title={isDescriptionTruncated ? solution.description : ""} 
          arrow
          placement="top"
          enterDelay={500}
          leaveDelay={200}
        >
          <Typography 
            ref={descriptionRef}
            variant="body1" 
            sx={{ 
              color: "text.secondary",
              mb: 3,
              lineHeight: 1.6,
              height: 80, // Keeping the original fixed height
              overflow: "hidden",
              display: "-webkit-box",
              WebkitLineClamp: 3,
              WebkitBoxOrient: "vertical",
              textOverflow: "ellipsis", // Ensure proper ellipsis
              cursor: isDescriptionTruncated ? 'help' : 'default' // Change cursor to indicate hoverable text
            }}
          >
            {solution.description}
          </Typography>
        </Tooltip>

        {/* Features List */}
        <Box sx={{ mb: 3 }}>
          <Typography
            variant="subtitle2"
            gutterBottom
            sx={{
              fontWeight: 600,
              display: "flex",
              alignItems: "center",
              color: "primary.main",
              mb: 2
            }}
          >
            <BuildIcon fontSize="small" sx={{ mr: 1 }} />
            KEY FEATURES
          </Typography>
          
          <Grid container spacing={1}>
            {solution.features.map((feature, index) => (
              <Grid item xs={12} key={index}>
                <Box 
                  sx={{ 
                    display: "flex", 
                    alignItems: "flex-start",
                    mb: 1
                  }}
                >
                  <CheckCircleOutlineIcon 
                    fontSize="small" 
                    color="primary" 
                    sx={{ 
                      mr: 1,
                      mt: 0.3,
                      opacity: 0.8,
                      flexShrink: 0 // Prevent icon from shrinking
                    }} 
                  />
                  <Typography variant="body2">
                    {feature}
                  </Typography>
                </Box>
              </Grid>
            ))}
          </Grid>
        </Box>

        {/* Tags - Fixed for proper wrapping */}
        <Box 
          sx={{ 
            display: "flex", 
            flexWrap: "wrap",
            gap: 1, // Add gap between chips for proper spacing
            mb: 3,
            minHeight: 40 // Maintain original minimum height
          }}
        >
          {solution.tags.map((tag) => (
            <Chip
              key={tag}
              label={tag}
              size="small"
              color={getTagColor(tag)}
              variant="outlined"
              sx={{ 
                mb: 1, // Keep original bottom margin
                borderRadius: "6px",
                fontWeight: 500,
                "& .MuiChip-label": {
                  color: (theme) => theme.palette[getTagColor(tag)].main
                }
              }}
            />
          ))}
        </Box>

        {/* Demo Button - Using marginTop:auto with consistent height */}
        <Box sx={{ 
          display: "flex", 
          justifyContent: "center",
          mt: 'auto',
          pt: 2,
          height: 60 // Keep original height
        }}>
            <Button
              variant="contained"
              startIcon={<PlayArrowIcon />}
              size="large"
              className="primary-button"
              onClick={handleDemoClick}
              sx={{ 
                borderRadius: "30px",
                py: 1.25,
                px: 4,
                fontWeight: 600,
                textTransform: "none",
                fontSize: "0.95rem",
                letterSpacing: "0.5px",
              }}
            >
              Try Live Demo
            </Button>
        </Box>
      </CardContent>

      {/* Dialog for not-ready solutions */}
      <Dialog
        open={openDialog}
        onClose={() => setOpenDialog(false)}
        aria-labelledby="solution-dialog-title"
        aria-describedby="solution-dialog-description"
      >
        <DialogTitle id="solution-dialog-title">
          {solution.title} - In Development
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="solution-dialog-description">
            This solution is currently {solution.readyToUse}% complete and still in development. 
            The demo will be available once development is finished. Would you like to be notified when it's ready?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenDialog(false)} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </Card>
  );
};

export default SolutionCard;