import { Route, Routes } from "react-router-dom";
import { ParallaxHome } from "./ParallaxHome";
import { Helmet } from "react-helmet-async";

export const Parallax = ({ children, ...props }) => {
  const { isPhoneorTab, isPhone, isTab, deviceWithSmallHeight } = props;

  return (
    <>
      <Helmet>
        <title>Web Rippler - parallax</title>
        <meta
          name="description"
          content="Transform your website with a custom design, crafted by Web Rippler and the Coding Rippler team."
        />
        <meta property="og:title" content="Web Rippler - Home" />
        <meta
          property="og:description"
          content="Transform your website with a custom design, crafted by Web Rippler and the Coding Rippler team."
        />
        <meta
          property="og:url"
          content="https://www.codingrippler.com/web/parallax"
        />
        <meta property="og:type" content="website" />
        <link
          rel="canonical"
          href="https://www.codingrippler.com/web/parallax"
        />
      </Helmet>
      <Routes>
        <Route
          path="/*"
          element={
            <ParallaxHome
              isPhone={isPhone}
              isTab={isTab}
              isPhoneorTab={isPhoneorTab}
              deviceWithSmallHeight={deviceWithSmallHeight}
            />
          }
        />
      </Routes>
    </>
  );
};
