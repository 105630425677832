import React, { useState } from "react";
import "../CodingRippler.css";
import { Button, Box, Typography, Card, CardContent } from "@mui/material";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { useTheme } from "@mui/material/styles";
import { KeyboardArrowUp } from "@mui/icons-material";
import LanguageIcon from "@mui/icons-material/Language";
import HubIcon from "@mui/icons-material/Hub";

export const Services = ({ children, ...props }) => {
  const [isHovered, setIsHovered] = useState(false);
  const theme = useTheme();
  const { customTextColor } = props;

  const handleWebClick = (context) => {
    window.location.href = `${context}`;
  };

  const handleAIClick = (context) => {
    window.location.href = `${context}`;
  };

  return (
    <Box sx={{}}>
      <Button
        variant="text"
        style={{
          color: customTextColor ? "white" : theme.palette.text.primary,
        }}
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
        onClick={() => setIsHovered(!isHovered)}
      >
        <Box display="flex" alignItems="center">
          <Typography
            variant="h6"
            fontFamily="Inter, sans-serif" // Add your desired font family here
            fontSize="1rem"
          >
            Services
          </Typography>
          {!isHovered && (
            <KeyboardArrowDownIcon onClick={() => setIsHovered(!isHovered)} />
          )}
          {isHovered && (
            <KeyboardArrowUp onClick={() => setIsHovered(!isHovered)} />
          )}
        </Box>
      </Button>

      {isHovered && (
        <Card
          sx={{
            width: "25%",
            position: "absolute",
            zIndex: 100,
            cursor: "pointer", // Make the card appear clickable
          }}
          onMouseEnter={() => setIsHovered(true)}
          onMouseLeave={() => setIsHovered(false)} // Hide the card when the mouse leaves it
        >
          <CardContent
            className="customHoverEffect"
            onClick={() => {
              handleWebClick("/web");
            }}
            sx={{
              p: 2,
            }}
          >
            <Box
              display="flex"
              flexDirection="column"
              alignItems="flex-start"
              gap={2}
            >
              <Box
                display="flex"
                flexDirection="row"
                alignItems="center"
                gap={2}
              >
                <LanguageIcon /> {/* WebRippler Icon */}
                <Box>
                  <Typography variant="h6" className="typography-custom-css">
                    Web Rippler
                  </Typography>
                </Box>
              </Box>
              <Typography
                variant="caption"
                className="typography-custom-css"
                sx={{
                  ml: "15px",
                  color: theme.palette.text.secondary,
                }}
              >
                Comprehensive web development services for building modern
                websites.
              </Typography>
            </Box>
          </CardContent>

          <CardContent
            className="customHoverEffect"
            onClick={() => {
              handleAIClick("/ai");
            }}
            sx={{
              p: 2,
            }}
          >
            <Box
              display="flex"
              flexDirection="column"
              alignItems="flex-start"
              gap={2}
            >
              <Box
                display="flex"
                flexDirection="row"
                alignItems="center"
                gap={2}
              >
                <HubIcon /> {/* AIRippler Icon */}
                <Box sx={{}}>
                  <Typography variant="h6" className="typography-custom-css">
                    AI Rippler
                  </Typography>
                </Box>
              </Box>
              <Typography
                variant="caption"
                className="typography-custom-css"
                sx={{
                  ml: "15px",
                  color: theme.palette.text.secondary,
                }}
              >
                AI-powered solutions to optimize business processes and
                innovation.
              </Typography>
            </Box>
          </CardContent>
        </Card>
      )}
    </Box>
  );
};
