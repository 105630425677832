import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { Card, CardContent, Fab, Grid, Stack, Typography } from "@mui/material";
import { Box } from "@mui/system";
import React from "react";
import {
  HeroSubHeader,
  HeroText,
} from "../../../CodingRippler/CodingRipplerWidgets";
import { AnimatedButton } from "../../CRAnimations/Animations";

// export const Services = ({ children, ...props }) => {
//   const { WhatWeDoData } = props;

//   return (
//     <Grid
//       container
//       spacing={2}
//       p={{
//         xs: 0,
//         lg: 2,
//       }}
//     >
//       {WhatWeDoData.map((data, index) => {
//         return (
//           <Grid item xs={12} sm={6} lg={4} key={index}>
//             <Stack spacing={2}>
//               <Fab
//                 color="primary"
//                 className="theme-specfic-colored-text"
//                 aria-label="add"
//                 sx={{
//                   backgroundColor: "#6056ff",
//                   zIndex: 1,

//                   "&:hover": {
//                     backgroundColor: "#3b32f9", // Optional: Darker shade on hover
//                   },
//                 }}
//               >
//                 {data.icon}
//               </Fab>

//               <Typography
//                 variant="h6"
//                 fontWeight="bold"
//                 className="typography-custom-css"
//               >
//                 {data.title}
//               </Typography>

//               <Typography
//                 variant="body1"
//                 className="typography-custom-css sub-header"
//               >
//                 {data.description}
//               </Typography>
//             </Stack>
//           </Grid>
//         );
//       })}
//     </Grid>
//   );
// };

export const Services = ({ children, ...props }) => {
  const { WhatWeDoData } = props;

  return (
    <Grid
      container
      spacing={6}
      p={{
        xs: 0,
        lg: 2,
      }}
      alignItems="stretch"
    >
      {WhatWeDoData.map((data, index) => {
        return (
          <Grid item xs={12} sm={6} lg={4} key={index}>
            <Box
              sx={{
                position: "relative",
                height: "100%",
                padding: 3,
                transition: "all 0.4s ease",
                "&:hover": {
                  transform: "translateY(-10px)",
                  "& .service-icon-wrapper": {
                    transform: "translateY(-5px)",
                    boxShadow: "0 15px 35px rgba(96, 86, 255, 0.35)",
                  },
                  "& .service-title::after": {
                    width: "100px",
                  }
                },
              }}
            >
              {/* Background blur effect */}
              <Box 
                sx={{
                  position: "absolute",
                  top: 0,
                  left: 0,
                  right: 0,
                  bottom: 0,
                  background: "rgba(255, 255, 255, 0.7)",
                  backdropFilter: "blur(15px)",
                  borderRadius: "24px",
                  boxShadow: "0 10px 30px rgba(96, 86, 255, 0.12)",
                  border: "1px solid rgba(255, 255, 255, 0.7)",
                  zIndex: 0
                }}
              />
              
              {/* Content */}
              <Box sx={{ position: "relative", zIndex: 1, height: "100%", display: "flex", flexDirection: "column" }}>
                {/* Icon with background glow */}
                <Box 
                  className="service-icon-wrapper"
                  sx={{
                    background: "linear-gradient(135deg, #6056ff 0%, #943bff 100%)",
                    borderRadius: "50%",
                    p: 2.5,
                    width: "80px",
                    height: "80px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    boxShadow: "0 10px 25px rgba(96, 86, 255, 0.25)",
                    position: "relative",
                    mb: 4,
                    transition: "all 0.4s ease",
                    "&::after": {
                      content: '""',
                      position: "absolute",
                      width: "100%",
                      height: "100%",
                      background: "rgba(96, 86, 255, 0.3)",
                      borderRadius: "50%",
                      filter: "blur(15px)",
                      zIndex: -1
                    }
                  }}
                >
                  {React.cloneElement(data.icon, { 
                    sx: { 
                      fontSize: "2.5rem", 
                      color: "white" 
                    }
                  })}
                </Box>

                {/* Title with animated underline */}
                <Typography
                  className="service-title"
                  variant="h5"
                  sx={{
                    fontWeight: 700,
                    color: "#222",
                    position: "relative",
                    pb: 2.5,
                    mb: 2.5,
                    "&::after": {
                      content: '""',
                      position: "absolute",
                      bottom: 0,
                      left: 0,
                      width: "60px",
                      height: "4px",
                      background: "linear-gradient(90deg, #6056ff 0%, #943bff 100%)",
                      borderRadius: "4px",
                      transition: "width 0.4s ease"
                    }
                  }}
                >
                  {data.title}
                </Typography>

                {/* Description */}
                <Typography
                  variant="body1"
                  sx={{
                    fontSize: "1.1rem",
                    lineHeight: 1.7,
                    color: "#555",
                    flex: "1 1 auto"
                  }}
                >
                  {data.description}
                </Typography>
                
                {/* Decorative circle */}
                <Box 
                  sx={{
                    position: "absolute",
                    bottom: "10px",
                    right: "10px",
                    width: "70px",
                    height: "70px",
                    borderRadius: "50%",
                    border: "2px dashed rgba(148, 59, 255, 0.2)",
                    opacity: 0.6,
                    zIndex: 0
                  }}
                />
              </Box>
            </Box>
          </Grid>
        );
      })}
    </Grid>
  );
};

export default Services;



export const ServicesV2 = ({ children, ...props }) => {
  const { WhatWeDoData, columnSizeXs, columnSizeSm, columnSizeLg } = props;

  return (
    <Grid container spacing={2} p={2}>
      {WhatWeDoData.map((data, index) => {
        return (
          <Grid
            item
            xs={columnSizeXs ? columnSizeXs : 12}
            sm={columnSizeXs ? columnSizeSm : 6}
            lg={columnSizeLg ? columnSizeLg : 4}
            key={index}
          >
            <Stack spacing={2}>
              <Fab
                color="primary"
                className="theme-specfic-colored-text"
                aria-label="add"
                sx={{
                  backgroundColor: "#6056ff",
                  zIndex: 1,

                  "&:hover": {
                    backgroundColor: "#3b32f9", // Optional: Darker shade on hover
                  },
                }}
              >
                {data.icon}
              </Fab>

              <Typography
                variant="h6"
                fontWeight="bold"
                className="typography-custom-css"
              >
                {data.title}
              </Typography>

              <Typography
                variant="body1"
                className="typography-custom-css sub-header"
              >
                {data.description}
              </Typography>
            </Stack>
          </Grid>
        );
      })}
    </Grid>
  );
};

export const ServicesV3 = ({ children, ...props }) => {
  const { WhatWeDoData, columnSizeXs, columnSizeSm, columnSizeLg } = props;

  return (
    <Grid container spacing={2}>
      {WhatWeDoData.map((data, index) => {
        return (
          <Grid
            item
            xs={columnSizeXs ? columnSizeXs : 12}
            sm={columnSizeSm ? columnSizeSm : 6}
            lg={columnSizeLg ? columnSizeLg : 4}
            key={index}
          >
            <Stack spacing={2}>
              <Box
                display="flex"
                justifyContent={{ xs: "flex-start", lg: "flex-start" }}
                alignItems="center"
                width="100%"
              >
                <Fab
                  color="primary"
                  className="theme-specfic-colored-text"
                  aria-label="add"
                  sx={{
                    backgroundColor: "#6056ff",
                    zIndex: 1,
                    "&:hover": {
                      backgroundColor: "#3b32f9", // Optional: Darker shade on hover
                    },
                  }}
                >
                  {data.icon}
                </Fab>
              </Box>

              {/* <Box
                display="flex"
                justifyContent="center"
                alignItems="center"
                width="100%"
              > */}
              <Typography
                variant="h6"
                fontWeight="500"
                className="typography-custom-css"
                sx={{
                  textAlign: { lg: "left" },
                }}
              >
                {data.title}
              </Typography>
              {/* </Box> */}

              <Box
                display="flex"
                justifyContent="center"
                alignItems="center"
                width="100%"
              >
                <Typography
                  variant="body1"
                  className="typography-custom-css sub-header"
                  sx={{
                    textAlign: { lg: "left" },
                  }}
                >
                  {data.description}
                </Typography>
              </Box>
            </Stack>
          </Grid>
        );
      })}
    </Grid>
  );
};

export const AdditionalInfo = ({ children, ...props }) => {
  const { additionalInfo } = props;

  const handleClick = () => {
    window.location.href = `/ai`;
  };

  return (
    <Card
    // sx={{
    //   transform: { translateY },
    // }}
    >
      <CardContent>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={12} md={6}>
            <Stack spacing={2}>
              <Typography
                variant="h5"
                fontWeight="bold"
                className="typography-custom-css"
              >
                Dedicated app development platform
              </Typography>
              <Box m={2} />

              <Typography
                variant="h6"
                className="typography-custom-css sub-header"
              >
                From open source to premium services.
              </Typography>

              <Grid container spacing={2}>
                {additionalInfo.map((data) => (
                  <Grid item xs={12} sm={6} md={6}>
                    <Box
                      display="flex"
                      justifyContent="flex-start"
                      alignItems="center"
                      flexDirection="row"
                      gap={2}
                    >
                      <CheckCircleIcon
                        fontSize="large"
                        sx={{
                          color: "#f9b934",
                        }}
                      />
                      <Typography
                        variant="body1"
                        className="typography-custom-css"
                      >
                        {data.title}
                      </Typography>
                    </Box>
                  </Grid>
                ))}
              </Grid>
            </Stack>
          </Grid>
          <Grid
            item
            xs={12}
            md={6}
            sx={{
              borderLeft: {
                md: "1px solid #868a97",
              },

              mt: {
                xs: "5%",
                md: 0,
              },
            }}
          >
            {/* <Box
              display="flex"
              flexDirection="column"
              alignItems="flex-start" // Aligns items to the start of the cross axis
              justifyContent="center" // Aligns items to the center of the main axis
              height="100%"
            > */}
            <Stack spacing={2}>
              <Typography
                variant="h5"
                fontWeight="bold"
                className="typography-custom-css"
              >
                A better way to expand Globally.
              </Typography>
              <Box m={2} />

              <Typography
                variant="h6"
                className="typography-custom-css sub-header"
              >
                We make it simple to hire, onboard, and pay your global Hub in
                150+ countries in full compliance with local laws.
              </Typography>

              <Box m={2} />
              {/* </Box> */}
              <Box>
                <AnimatedButton text="Learn More" clickHandler={handleClick} />
              </Box>
            </Stack>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

export const AdditionalInfoV2 = ({ children, ...props }) => {
  const {
    isPhone,
    isTab,

    pageContent,
  } = props;

  if (isPhone) {
  } else if (isTab) {
  }

  const additionalInfo = pageContent[0].additionalInfo;

  const headers = pageContent[0].headers;

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} lg={12}>
        <Stack spacing={2}>
          <HeroText
            variant="h4"
            customStyle={{
              color: "white",
              textAlign: "left",
            }}
          >
            {" "}
            {headers.heading}
          </HeroText>
          <Box m={2} />

          <HeroSubHeader
            customStyle={{
              color: "white",
              textAlign: "left",
            }}
          >
            {headers.subHeading}
          </HeroSubHeader>

          <Grid container>
            {additionalInfo.map((data, index) => (
              <Grid item xs={12} key={index}>
                <Box
                  display="flex"
                  justifyContent="flex-start"
                  alignItems="center"
                  flexDirection="row"
                  gap={2}
                >
                  <CheckCircleIcon
                    sx={{
                      color: "#f9b934",
                    }}
                  />

                  <HeroSubHeader customStyle={{ color: "white" }}>
                    {data.title}
                  </HeroSubHeader>
                </Box>
                <Box m={2} />
              </Grid>
            ))}
          </Grid>
        </Stack>
      </Grid>
    </Grid>
  );
};

export const ServicesWithCardView = ({ children, ...props }) => {
  const { pageContent } = props;
  return (
    <Card
      sx={{
        borderRadius: "24px",
        m: 2,
        minHeight: {
          xs: 275,
        },
      }}
    >
      <CardContent>
        <Stack spacing={2}>
          <Fab
            color="primary"
            className="theme-specfic-colored-text"
            aria-label="add"
            sx={{
              backgroundColor: "#6056ff",
              zIndex: 1,
              "&:hover": {
                backgroundColor: "#3b32f9", // Optional: Darker shade on hover
              },
            }}
          >
            {pageContent.icon}
          </Fab>

          <Typography
            variant="h5"
            fontWeight="500"
            className="typography-custom-css"
          >
            {pageContent.title}
          </Typography>

          <Typography variant="h6" className="typography-custom-css sub-header">
            {pageContent.description}
          </Typography>
        </Stack>
      </CardContent>
    </Card>
  );
};
