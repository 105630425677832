import React, { useState, useEffect } from 'react';
import { Box, Typography, CircularProgress, useMediaQuery, useTheme } from '@mui/material';
import { Check, RefreshCw } from 'lucide-react';

// Workflow steps in order of appearance
const WORKFLOW_STEPS = [
  {
    id: 'planning',
    text: 'Planning agent for: "Streamline webinar and event follow-ups"...',
    color: '#10B981' // green
  },
  {
    id: 'coffee',
    text: 'Taking big sip of coffee',
    color: '#6366F1' // purple
  },
  {
    id: 'instructions',
    text: 'Coming up with agent\'s instructions',
    color: '#10B981' // green
  },
  {
    id: 'naming',
    text: 'Naming your agent',
    color: '#10B981' // green
  }
];

// Consistent colors
const STEP_COLORS = {
  inProgress: '#6366F1', // purple
  completed: '#10B981', // green
};

// Wizard character component
const WizardAvatar = () => (
  <Box sx={{ 
    width: 36, 
    height: 36, 
    borderRadius: '4px',
    overflow: 'hidden',
    mr: 2,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    fontSize: '20px',
    fontFamily: 'monospace'
  }}>
    <img 
      src="/wizard.png" 
      alt="Wizard" 
      onError={(e) => {
        e.target.onerror = null;
        e.target.parentNode.innerHTML = '🧙‍♂️';
      }}
      style={{ width: '100%', height: '100%', objectFit: 'cover' }}
    />
  </Box>
);

// User avatar with initial
const UserAvatar = () => (
  <Box sx={{ 
    width: 36, 
    height: 36, 
    borderRadius: '50%',
    bgcolor: '#6366F1', // purple
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    color: 'white',
    fontWeight: 600,
    fontSize: 18
  }}>
    H
  </Box>
);

// Loading dots component
const LoadingDots = () => (
  <Box sx={{ display: 'flex', alignItems: 'center', gap: 0.5 }}>
    {[1, 2, 3].map((dot) => (
      <Box 
        key={dot} 
        sx={{ 
          width: 8, 
          height: 8, 
          borderRadius: '50%', 
          bgcolor: '#9CA3AF',
          animation: `dotPulse 1.4s infinite ease-in-out both`,
          animationDelay: `${dot * 0.16}s`,
          '@keyframes dotPulse': {
            '0%, 80%, 100%': { opacity: 0.3 },
            '40%': { opacity: 1 }
          }
        }} 
      />
    ))}
  </Box>
);

// Step indicator component
const StepIndicator = ({ status }) => {
  // Use consistent colors based on status
  const color = status === 'completed' ? STEP_COLORS.completed : STEP_COLORS.inProgress;
  
  if (status === 'completed') {
    return (
      <Box sx={{ 
        width: 24, 
        height: 24, 
        borderRadius: '50%', 
        bgcolor: `${color}20`, // transparent version of color
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        color: color,
      }}>
        <Check size={16} />
      </Box>
    );
  } else if (status === 'in-progress') {
    return (
      <Box sx={{ 
        width: 24, 
        height: 24, 
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        color: color,
      }}>
        <CircularProgress size={20} thickness={4} sx={{ color: 'inherit' }} />
      </Box>
    );
  }
  
  return null;
};

// Integrated WorkflowCard component
const WorkflowCard = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  
  const [stepsState, setStepsState] = useState([]);
  const [showTyping, setShowTyping] = useState(true);
  const [animationComplete, setAnimationComplete] = useState(false);

  // Initialize and run the animation sequence
  useEffect(() => {
    // Initial typing animation
    const typingTimer = setTimeout(() => {
      setShowTyping(false);
      
      // Starting the step sequence
      runStepSequence(0);
    }, 2000);

    return () => clearTimeout(typingTimer);
  }, []);

  // Function to run each step in sequence
  const runStepSequence = (stepIndex) => {
    if (stepIndex >= WORKFLOW_STEPS.length) {
      setAnimationComplete(true);
      return;
    }

    // Start the current step (show as in-progress)
    setStepsState(currentSteps => {
      const newSteps = [...currentSteps];
      newSteps[stepIndex] = {
        ...WORKFLOW_STEPS[stepIndex],
        status: 'in-progress',
        color: STEP_COLORS.inProgress // Ensure in-progress color is purple
      };
      return newSteps;
    });

    // Complete the current step after delay and move to next
    setTimeout(() => {
      setStepsState(currentSteps => {
        const newSteps = [...currentSteps];
        newSteps[stepIndex] = {
          ...WORKFLOW_STEPS[stepIndex],
          status: 'completed',
          color: STEP_COLORS.completed // Ensure completed color is green
        };
        return newSteps;
      });

      // Start the next step after a delay
      setTimeout(() => {
        runStepSequence(stepIndex + 1);
      }, 800);
    }, 1500);
  };

  return (
    <Box
      sx={{
        backgroundColor: 'background.paper',
        borderRadius: { xs: 2, md: 3 },
        p: { xs: 2, md: 3 },
        height: '100%',
        border: '1px solid rgba(0, 0, 0, 0.15)',
        width: '100%',
        overflow: 'hidden'
      }}
    >
      {/* AIStudio Header */}
      <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
        <Box sx={{ mr: 1.5 }}>
          <svg width="18" height="18" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect width="24" height="24" rx="4" fill="#000000" />
            <rect x="7" y="7" width="10" height="10" rx="1" fill="white" />
          </svg>
        </Box>
        <Box>AIStudio</Box>
      </Box>

      {/* WorkflowCard Content with fixed height */}
      <Box 
        sx={{ 
          position: 'relative',
          height: { xs: '520px', sm: '550px', md: '580px' }, // Increased fixed height at different breakpoints
          display: 'flex',
          flexDirection: 'column'
        }}
      >
        {/* Header */}
        <Box sx={{ 
          backgroundColor: '#6366F1', 
          color: 'white',
          p: { xs: 1.5, md: 2 },
          pl: { xs: 2, md: 3 },
          borderTopLeftRadius: 8,
          borderTopRightRadius: 8,
          display: 'flex',
          alignItems: 'center',
        }}>
          <RefreshCw size={18} style={{ marginRight: 8 }} />
          <Typography variant="subtitle1" sx={{ fontSize: { xs: '0.875rem', md: '1rem' } }}>
            Inventing your AI Agent's "instructions"
          </Typography>
        </Box>
        
        {/* Chat content - with fixed height */}
        <Box sx={{ 
          flex: 1,
          backgroundColor: 'white', 
          p: { xs: 1.5, md: 2 },
          borderBottomLeftRadius: 8,
          borderBottomRightRadius: 8,
          borderLeft: '1px solid rgba(0, 0, 0, 0.1)',
          borderRight: '1px solid rgba(0, 0, 0, 0.1)',
          borderBottom: '1px solid rgba(0, 0, 0, 0.1)',
          display: 'flex',
          flexDirection: 'column',
          gap: 2,
          overflowY: 'auto',
          scrollbarWidth: 'none', /* Firefox */
          '&::-webkit-scrollbar': {
            display: 'none' /* Chrome, Safari, Edge */
          },
          msOverflowStyle: 'none', /* IE and Edge */
        }}>
          {/* User prompt */}
          <Box sx={{ 
            p: { xs: 1.5, md: 2 }, 
            borderRadius: 3, 
            border: '1px solid rgba(0, 0, 0, 0.1)',
            display: 'flex',
            alignItems: 'center',
            maxWidth: '90%',
            alignSelf: 'center'
          }}>
            <UserAvatar />
            <Typography sx={{ 
              ml: 2, 
              fontWeight: 500,
              fontSize: { xs: '0.875rem', md: '1rem' }
            }}>
              Streamline webinar and event follow-ups
            </Typography>
          </Box>
          
          {/* Wizard message */}
          <Box sx={{ 
            p: { xs: 1.5, md: 2 }, 
            borderRadius: 3, 
            border: '1px solid rgba(0, 0, 0, 0.1)',
            display: 'flex',
            alignItems: 'center',
            maxWidth: '90%',
            alignSelf: 'flex-start'
          }}>
            <WizardAvatar />
            {showTyping ? (
              <LoadingDots />
            ) : (
              <Typography sx={{ fontSize: { xs: '0.875rem', md: '1rem' } }}>
                Great! I'm generating your agent's instructions now. This might take a minute.
              </Typography>
            )}
          </Box>
          
          {/* Steps list */}
          <Box sx={{ 
            display: 'flex', 
            flexDirection: 'column', 
            gap: 1.5,
            mt: 1,
          }}>
            {stepsState.map((step, index) => (
              <Box key={step.id} sx={{ 
                p: { xs: 1.25, md: 1.5 },
                borderRadius: 3, 
                border: '1px solid rgba(0, 0, 0, 0.1)',
                display: 'flex',
                alignItems: 'center',
                maxWidth: '90%',
                alignSelf: 'flex-start'
              }}>
                <StepIndicator status={step.status} />
                <Typography sx={{ 
                  ml: 2, 
                  color: step.status === 'completed' ? STEP_COLORS.completed : STEP_COLORS.inProgress, 
                  fontWeight: 500,
                  fontSize: { xs: '0.8125rem', md: '0.95rem' }
                }}>
                  {step.text}
                </Typography>
              </Box>
            ))}
          </Box>
        </Box>
        
        {/* Message input */}
        <Box sx={{ 
          backgroundColor: 'white', 
          p: { xs: 1.5, md: 2 },
          pl: { xs: 2, md: 3 },
          mt: 2,
          borderRadius: 8,
          border: '1px solid rgba(0, 0, 0, 0.1)',
          display: 'flex',
          alignItems: 'center',
          color: 'rgba(0, 0, 0, 0.3)'
        }}>
          <Typography variant="body2" sx={{ fontSize: { xs: '0.75rem', md: '0.875rem' } }}>
            Type message for Inventor...
          </Typography>
          <Box sx={{ ml: 'auto', fontSize: { xs: '0.6875rem', md: '0.75rem' } }}>
            Press Enter to send
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default WorkflowCard;