import { Routes, Route } from "react-router-dom";
import { GridHome } from "./GridHome";
import { Helmet } from "react-helmet-async";

export const GridContainer = ({ children, ...props }) => {
  const { isPhoneorTab, isPhone, isTab, deviceWithSmallHeight } = props;

  return (
    <>
      <Helmet>
        <title>Web Rippler - grid</title>
        <meta
          name="description"
          content="Transform your website with a custom design, crafted by Web Rippler and the Coding Rippler team."
        />
        <meta property="og:title" content="Web Rippler - Home" />
        <meta
          property="og:description"
          content="Transform your website with a custom design, crafted by Web Rippler and the Coding Rippler team."
        />
        <meta
          property="og:url"
          content="https://www.codingrippler.com/web/grid"
        />
        <meta property="og:type" content="website" />
        <link rel="canonical" href="https://www.codingrippler.com/web/grid" />
      </Helmet>
      <Routes>
        <Route
          path="/*"
          element={
            <GridHome
              isPhone={isPhone}
              isTab={isTab}
              deviceWithSmallHeight={deviceWithSmallHeight}
              isPhoneorTab={isPhoneorTab}
            />
          }
        />
      </Routes>
    </>
  );
};
