// import React from 'react';
// import { 
//   Box, 
//   Container, 
//   Grid, 
//   CssBaseline,
//   ThemeProvider,
//   createTheme
// } from '@mui/material';

// // Import all the card components
// import TemplateCard from './TemplateCard';
// import IntegrationsCard from './IntegrationCard';
// import DataChatCard from './DataChatCard';
// import ApiForgeCard from './ApiForgeCard';
// import WorkflowCard from './WorkflowCard';
// // Create a unified theme with better color contrast
// const theme = createTheme({
//   palette: {
//     background: {
//       default: '#f8f8f8',
//       paper: '#FFFFFF'
//     },
//     text: {
//       primary: '#111827',
//       secondary: '#4B5563'
//     },
//     primary: {
//       main: '#6466F1',
//     }
//   },
//   typography: {
//     fontFamily: '"Inter", "Roboto", "Helvetica", "Arial", sans-serif',
//   },
//   shape: {
//     borderRadius: 12
//   },
//   components: {
//     MuiPaper: {
//       styleOverrides: {
//         root: {
//           boxShadow: 'none',
//           border: '1px solid rgba(0, 0, 0, 0.2)'
//         }
//       }
//     }
//   }
// });

// const CardLayout = () => {
//   return (
//     <ThemeProvider theme={theme}>
//       <CssBaseline />
//       <Box 
//         sx={{ 
//           backgroundColor: 'background.default',
//           minHeight: '100vh',
//           py: 4
//         }}
//       >
//         <Container maxWidth="xl">
//           <Grid container spacing={3}>
//             {/* First row: Templates Card - Full width */}
//             <Grid item xs={12}>
//               <Box
//                 sx={{
//                   backgroundColor: 'background.paper',
//                   borderRadius: 3,
//                   p: 3,
//                   border: '1px solid rgba(0, 0, 0, 0.15)',
//                   display: 'flex',
//                   flexDirection: { xs: 'column', md: 'row' },
//                   alignItems: { md: 'center' },
//                 }}
//               >
//                 <Box sx={{ p: 2, width: { md: '25%' } }}>
//                   <Box component="h2" sx={{ 
//                     fontSize: '2rem', 
//                     fontWeight: 700, 
//                     my: 1 
//                   }}>
//                     Templates
//                   </Box>
//                   <Box component="p" sx={{ 
//                     fontSize: '1rem', 
//                     color: 'text.secondary',
//                     mb: 2
//                   }}>
//                     Leverage our templates per app & per department to speed up work.
//                   </Box>
//                 </Box>
//                 <Box sx={{ flex: 1, p: 2 }}>
//                   <TemplateCard />
//                 </Box>
//               </Box>
//             </Grid>

//             {/* Second row: Integrations Card (smaller) and API Forge Card (larger) */}
//             <Grid item xs={12} md={4}>
//               {/* Use the existing IntegrationsCard component */}
//               <Box
//                 sx={{
//                   backgroundColor: 'background.paper',
//                   borderRadius: 3,
//                   height: '100%',
//                   border: '1px solid rgba(0, 0, 0, 0.15)',
//                   overflow: 'hidden'
//                 }}
//               >
//                 <Box sx={{ display: 'flex', alignItems: 'center', p: 1.5, borderBottom: '1px solid rgba(0, 0, 0, 0.1)' }}>
//                   <Box sx={{ mr: 1.5 }}>
//                     <svg width="18" height="18" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
//                       <rect width="24" height="24" rx="4" fill="#000000" />
//                       <rect x="7" y="7" width="10" height="10" rx="1" fill="white" />
//                     </svg>
//                   </Box>
//                   <Box>AIStudio</Box>
//                 </Box>
//                 <IntegrationsCard />
//               </Box>
//             </Grid>
            
//             <Grid item xs={12} md={8}>
//               <Box
//                 sx={{
//                   backgroundColor: 'background.paper',
//                   borderRadius: 3,
//                   p: 3,
//                   height: '100%',
//                   border: '1px solid rgba(0, 0, 0, 0.15)',
//                 }}
//               >
//                 <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
//                   <Box sx={{ mr: 1.5 }}>
//                     <svg width="18" height="18" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
//                       <rect width="24" height="24" rx="4" fill="#000000" />
//                       <rect x="7" y="7" width="10" height="10" rx="1" fill="white" />
//                     </svg>
//                   </Box>
//                   <Box>AIStudio</Box>
//                 </Box>
//                 {/* ApiForgeCard directly */}
//                 <ApiForgeCard />
//               </Box>
//             </Grid>

//             {/* Third row: DataChat Card and Custom Fields - 50/50 split */}
//             <Grid item xs={12} md={6}>
//               <Box
//                 sx={{
//                   backgroundColor: 'background.paper',
//                   borderRadius: 3,
//                   p: 3,
//                   height: '100%',
//                   border: '1px solid rgba(0, 0, 0, 0.15)',
//                 }}
//               >
//                 <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
//                   <Box sx={{ mr: 1.5 }}>
//                     <svg width="18" height="18" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
//                       <rect width="24" height="24" rx="4" fill="#000000" />
//                       <rect x="7" y="7" width="10" height="10" rx="1" fill="white" />
//                     </svg>
//                   </Box>
//                   <Box>AIStudio</Box>
//                 </Box>
//                 {/* DataChatCard directly */}
//                 <DataChatCard />
//               </Box>
//             </Grid>
            
//             <Grid item xs={12} md={6}>
//             <Box
//                 sx={{
//                   backgroundColor: 'background.paper',
//                   borderRadius: 3,
//                   p: 3,
//                   height: '100%',
//                   border: '1px solid rgba(0, 0, 0, 0.15)',
//                 }}
//               >
//                 <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
//                   <Box sx={{ mr: 1.5 }}>
//                     <svg width="18" height="18" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
//                       <rect width="24" height="24" rx="4" fill="#000000" />
//                       <rect x="7" y="7" width="10" height="10" rx="1" fill="white" />
//                     </svg>
//                   </Box>
//                   <Box>AIStudio</Box>
//                 </Box>
//                 {/* WorkflowCard directly */}
//                 <WorkflowCard/>
//               </Box>
//             </Grid>
//           </Grid>
//         </Container>
//       </Box>
//     </ThemeProvider>
//   );
// };

// export default CardLayout;

import {
  Box,
  CssBaseline,
  Grid,
  ThemeProvider,
  createTheme
} from '@mui/material';
import React from 'react';

// Import all the card components
import ApiForgeCard from './ApiForgeCard';
import DataChatCard from './DataChatCard';
import IntegrationsCard from './IntegrationCard';
import TemplateCard from './TemplateCard';
import WorkflowCard from './WorkflowCard';

// Create a unified theme with better color contrast
const theme = createTheme({
  palette: {
    background: {
      default: '#f8f8f8',
      paper: '#FFFFFF'
    },
    text: {
      primary: '#111827',
      secondary: '#4B5563'
    },
    primary: {
      main: '#6466F1',
    }
  },
  typography: {
    fontFamily: '"Inter", "Roboto", "Helvetica", "Arial", sans-serif',
  },
  shape: {
    borderRadius: 12
  },
  components: {
    MuiPaper: {
      styleOverrides: {
        root: {
          boxShadow: 'none',
          border: '1px solid rgba(0, 0, 0, 0.2)'
        }
      }
    }
  }
});

const CardLayout = () => {

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Box 
        sx={{ 
          backgroundColor: 'background.default',
          width: '100vw',
          maxWidth: '100%',
          overflowX: 'hidden', // Prevent horizontal scroll
          py: { xs: 2, md: 4 }
        }}
      >
        {/* Use custom container with adjusted padding for mobile */}
        <Box 
          sx={{ 
            width: '100%',
            maxWidth: '100%',
            mx: 0,
            px: { xs: 0, sm: 2, md: 3 }
          }}
        >
          <Grid container spacing={{ xs: 2, md: 3 }} sx={{ m: 0, width: '100%' }}>
            {/* First row: Templates Card - Full width */}
            <Grid item xs={12}>
              <Box
                sx={{
                  backgroundColor: 'background.paper',
                  borderRadius: { xs: 2, md: 3 },
                  p: { xs: 2, md: 3 },
                  border: '1px solid rgba(0, 0, 0, 0.15)',
                  display: 'flex',
                  flexDirection: { xs: 'column', md: 'row' },
                  alignItems: { md: 'center' },
                  width: '100%',
                  overflow: 'hidden' // Prevent content overflow
                }}
              >
                <Box sx={{ 
                  p: { xs: 1, md: 2 }, 
                  width: { xs: '100%', md: '25%' },
                  mb: { xs: 2, md: 0 }
                }}>
                  <Box component="h2" sx={{ 
                    fontSize: { xs: '1.5rem', md: '2rem' }, 
                    fontWeight: 700, 
                    my: { xs: 0.5, md: 1 }
                  }}>
                    Templates
                  </Box>
                  <Box component="p" sx={{ 
                    fontSize: { xs: '0.875rem', md: '1rem' }, 
                    color: 'text.secondary',
                    mb: { xs: 1, md: 2 }
                  }}>
                    Leverage our templates per app & per department to speed up work.
                  </Box>
                </Box>
                <Box sx={{ 
                  flex: 1, 
                  p: { xs: 0, md: 2 },
                  width: '100%'
                }}>
                  <TemplateCard />
                </Box>
              </Box>
            </Grid>

            {/* Second row: Integrations Card (smaller) and API Forge Card (larger) */}
            <Grid item xs={12} md={4}>
                <IntegrationsCard />
            </Grid>
            
            <Grid item xs={12} md={8}>
                {/* ApiForgeCard directly */}
                <ApiForgeCard />
            </Grid>

            {/* Third row: DataChat Card and Custom Fields - 50/50 split on desktop, full width on mobile */}
            <Grid item xs={12} md={6}>
                {/* DataChatCard directly */}
                <DataChatCard />
            </Grid>
            
            <Grid item xs={12} md={6}>
                {/* WorkflowCard directly */}
                <WorkflowCard/>
            </Grid>
          </Grid>
        </Box>
      </Box>
    </ThemeProvider>
  );
};

export default CardLayout;