// import React, { useState, useEffect } from 'react';
// import { Box, Container, Typography, Button, Grid, Chip, Card, List, ListItem, ListItemIcon, ListItemText, IconButton } from '@mui/material';
// import { Database, Activity, Cpu, ChevronLeft, ChevronRight, Zap, MessageSquare, Check, ArrowRight, Send, User, Bot, FileText, Cloud, Table, Linkedin, ListChecks } from 'lucide-react';
// import { SectionHeading, SectionTitle } from '../CodingRipplerWidgets';
// import ContactDialog, { trackAndOpenDialog } from '../CodingRipplerHome/ContactUs/ContactUsDialog';

// // Global keyframes for animations - unchanged
// const keyframes = `
//   @keyframes typingDot1 {
//     0%, 100% { transform: translateY(0); }
//     50% { transform: translateY(-2px); }
//   }
  
//   @keyframes typingDot2 {
//     0%, 100% { transform: translateY(0); }
//     50% { transform: translateY(-2px); }
//   }
  
//   @keyframes typingDot3 {
//     0%, 100% { transform: translateY(0); }
//     50% { transform: translateY(-2px); }
//   }
  
//   @keyframes ripple {
//     0% { transform: translate(-50%, -50%) scale(1); opacity: 0.8; }
//     100% { transform: translate(-50%, -50%) scale(1.5); opacity: 0; }
//   }
  
//   @keyframes waveMotion {
//     0%, 100% { transform: translateX(-30%); }
//     50% { transform: translateX(30%); }
//   }
  
//   @keyframes pulse {
//     0%, 100% { opacity: 0.6; }
//     50% { opacity: 1; }
//   }
// `;

// // Main wrapper component that contains both the feature card and carousel
// const CarouselStudioWorkflowSection = () => {
//     const [open, setOpen] = useState(false);

//     const handleClose = (success) => {
//         setOpen(false);
//         if (success) {
//             // Handle successful submission (e.g. show a success toast)
//         }
//     };

//     const handleContactClick = () => trackAndOpenDialog(setOpen);

//     return (
//         <>   
//             <Box 
//                 sx={{ 
//                     py: { xs: 6, md: 12 }, 
//                     background: 'linear-gradient(135deg, #EDF0FF 0%, #E0E6FF 100%)',
//                     position: 'relative',
//                     overflow: 'hidden',
//                     '&::before': {
//                         content: '""',
//                         position: 'absolute',
//                         width: '600px',
//                         height: '600px',
//                         top: '-100px',
//                         left: '-200px',
//                         background: 'radial-gradient(circle, rgba(204, 210, 255, 0.5) 0%, rgba(226, 232, 255, 0) 70%)',
//                         borderRadius: '50%',
//                         zIndex: 0
//                     },
//                     '&::after': {
//                         content: '""',
//                         position: 'absolute',
//                         width: '400px',
//                         height: '400px',
//                         bottom: '-100px',
//                         left: '10%',
//                         background: 'radial-gradient(circle, rgba(200, 210, 255, 0.4) 0%, rgba(226, 232, 255, 0) 70%)',
//                         borderRadius: '50%',
//                         zIndex: 0
//                     }
//                 }}
//             >
//                 <Box
//                     sx={{
//                         position: "absolute",
//                         width: "300px",
//                         height: "300px",
//                         borderRadius: "50%",
//                         background: "rgba(99, 102, 241, 0.1)",
//                         top: "-100px",
//                         left: "-100px",
//                         zIndex: 0,
//                     }}
//                 />
//                 <style dangerouslySetInnerHTML={{ __html: keyframes }} />
            
//                 <Container maxWidth="lg" sx={{ position: 'relative', zIndex: 1 }}>
//                     {/* Container content remains the same */}
//                     <Box sx={{ textAlign: 'center', mb: { xs: 6, md: 8 } }}>
//                         <SectionTitle variant="subtitle1">
//                             AI STUDIO
//                         </SectionTitle>
//                         <SectionHeading variant="h2" sx={{ fontSize: { xs: '1.75rem', md: '3rem' } }}>
//                             Our Specialized Product
//                         </SectionHeading>
//                         <Typography
//                             variant="body1"
//                             sx={{
//                                 maxWidth: '700px',
//                                 mx: 'auto',
//                                 color: 'rgba(0, 0, 0, 0.6)',
//                                 fontSize: { xs: '1rem', md: '1.1rem' },
//                                 lineHeight: 1.6,
//                                 px: { xs: 2, md: 0 }
//                             }}
//                         >
//                             Transform your data sources into intelligent workflows using our no-code AI Studio. 
//                             Build, connect, and deploy AI-powered solutions with minimal technical expertise.
//                         </Typography>
//                     </Box>
//                     <Grid container spacing={{ xs: 3, md: 5 }} sx={{ mb: { xs: 6, md: 10 } }}>
//                         <Grid item xs={12} md={6} lg={5}>
//                             <Card
//                                 sx={{
//                                     height: '100%',
//                                     p: { xs: 3, md: 4 },
//                                     borderRadius: 4,
//                                     boxShadow: '0 10px 40px rgba(0, 0, 0, 0.1)',
//                                     background: 'linear-gradient(135deg, #6366f1 0%, #8b5cf6 100%)',
//                                     color: 'white',
//                                     position: 'relative',
//                                     overflow: 'hidden'
//                                 }}
//                             >
//                                 {/* Decorative circle */}
//                                 <Box
//                                     sx={{
//                                         position: 'absolute',
//                                         top: '-10%',
//                                         right: '-10%',
//                                         width: '200px',
//                                         height: '200px',
//                                         borderRadius: '50%',
//                                         background: 'rgba(255, 255, 255, 0.1)'
//                                     }}
//                                 />
                            
//                                 <Typography variant="h4" fontWeight={600} mb={2} sx={{ position: 'relative', fontSize: { xs: '1.5rem', md: '2rem' } }}>
//                                     AI Studio
//                                 </Typography>
                            
//                                 {/* Description */}
//                                 <Typography variant="body1" mb={4} sx={{ position: 'relative', opacity: 0.9, fontSize: { xs: '0.9rem', md: '1rem' } }}>
//                                     Transform your data sources into intelligent workflows using our no-code AI Studio. 
//                                     Build, connect, and deploy AI-powered solutions with minimal technical expertise.
//                                 </Typography>
                            
//                                 {/* Feature list */}
//                                 <List sx={{ position: 'relative' }}>
//                                     {[
//                                         "Multi-source data integration",
//                                         "Automated triggers & notifications",
//                                         "Real-time chat interface"
//                                     ].map((feature, index) => (
//                                         <ListItem key={index} sx={{ px: 0, py: 1 }}>
//                                             <ListItemIcon sx={{ minWidth: '36px' }}>
//                                                 <Check size={20} color="white" />
//                                             </ListItemIcon>
//                                             <ListItemText primary={feature} primaryTypographyProps={{ fontSize: { xs: '0.85rem', md: '0.95rem' } }} />
//                                         </ListItem>
//                                     ))}
//                                 </List>
                            
//                                 {/* Action button */}
//                                 <Box sx={{ mt: 4, display: 'flex', justifyContent: 'flex-end', position: 'relative' }}>
//                                 </Box>
//                             </Card>
//                         </Grid>
                    
//                         {/* Right column: Carousel of workflow components */}
//                         <Grid item xs={12} md={6} lg={7}>
//                             <Box sx={{ position: 'relative' }}>
//                                 <Typography variant="h6" fontWeight={600} mb={2} fontSize={{ xs: '1rem', md: '1.25rem' }}>
//                                     Interactive Workflow Demo
//                                 </Typography>
                            
//                                 <Typography variant="body2" color="text.secondary" mb={3} fontSize={{ xs: '0.85rem', md: '0.9rem' }}>
//                                     Explore each workflow component by navigating through the carousel
//                                 </Typography>
                            
//                                 {/* Carousel component */}
//                                 <CarouselWorkflowComponent />
//                             </Box>
//                         </Grid>
//                     </Grid>
                
//                     {/* Bottom CTA */}
//                     <Box sx={{ textAlign: 'center' }}>
//                         <Button
//                             variant="contained"
//                             size="large"
//                             className="primary-button"
//                             onClick={handleContactClick}
//                         >
//                             Book a Demo
//                         </Button>
//                     </Box>
//                 </Container>
//             </Box>
//             <ContactDialog open={open} onClose={handleClose} />
//         </>
//     );
// };

// // Custom component to display workflow components in a carousel
// const CarouselWorkflowComponent = () => {
//     // Define the components to display
//     const components = [
//         {
//             id: "data-sources",
//             title: "Data Sources",
//             description: "Connect and integrate multiple data sources including files, APIs, and databases.",
//             icon: <Database size={24} />,
//             color: "#4285F4",
//             stage: "source"
//         },
//         {
//             id: "knowledge-base",
//             title: "Knowledge Base",
//             description: "Centralized storage for structured and unstructured data with smart indexing.",
//             icon: <Activity size={24} />,
//             color: "#3b82f6",
//             stage: "kb"
//         },
//         {
//             id: "workflow",
//             title: "Workflow Processor",
//             description: "Define custom processes and transformations to extract value from your data.",
//             icon: <Cpu size={24} />,
//             color: "#8b5cf6",
//             stage: "workflow"
//         },
//         {
//             id: "trigger",
//             title: "Automated Triggers",
//             description: "Set conditions to automatically trigger actions and notifications.",
//             icon: <Zap size={24} />,
//             color: "#f59e0b",
//             stage: "trigger"
//         },
//         {
//             id: "chat",
//             title: "Chat Interface",
//             description: "Interact with your data through a conversational AI interface.",
//             icon: <MessageSquare size={24} />,
//             color: "#10b981",
//             stage: "chat"
//         }
//     ];
  
//     // State to track active component index
//     const [activeIndex, setActiveIndex] = useState(0);
//     const [animationDirection, setAnimationDirection] = useState('next');
//     const [isAnimating, setIsAnimating] = useState(false);
  
//     // Navigate to next component
//     const nextComponent = () => {
//         if (isAnimating) return;
//         setAnimationDirection('next');
//         setIsAnimating(true);
//         setTimeout(() => {
//             setActiveIndex((prev) => (prev === components.length - 1 ? 0 : prev + 1));
//             setIsAnimating(false);
//         }, 300);
//     };
  
//     // Navigate to previous component
//     const prevComponent = () => {
//         if (isAnimating) return;
//         setAnimationDirection('prev');
//         setIsAnimating(true);
//         setTimeout(() => {
//             setActiveIndex((prev) => (prev === 0 ? components.length - 1 : prev - 1));
//             setIsAnimating(false);
//         }, 300);
//     };
  
//     // AutoPlay functionality
//     useEffect(() => {
//         const timer = setTimeout(() => {
//             nextComponent();
//         }, 6000); // Change card every 6 seconds
    
//         return () => clearTimeout(timer);
//     }, [activeIndex]);
  
//     return (
//         <Box
//             sx={{
//                 position: 'relative',
//                 height: { xs: '520px', sm: '550px' },
//                 width: '100%',
//             }}
//         >
//             {/* Component carousel - updated for better mobile responsiveness */}
//             <Box
//                 sx={{
//                     borderRadius: '16px',
//                     background: 'white',
//                     boxShadow: '0 4px 24px rgba(0, 0, 0, 0.08)',
//                     overflow: 'hidden',
//                     height: '100%',
//                     position: 'relative',
//                     mx: { xs: 4, sm: 0 }, // Add horizontal margin on mobile to make space for navigation buttons
//                 }}
//             >
//                 {/* Ready badge */}
//                 <Box
//                     sx={{
//                         position: 'absolute',
//                         top: 16,
//                         right: 16,
//                         zIndex: 10
//                     }}
//                 >
//                     <Chip
//                         label="Ready"
//                         sx={{
//                             backgroundColor: '#10b981',
//                             color: 'white',
//                             fontWeight: 600,
//                             fontSize: '0.75rem',
//                             height: '24px'
//                         }}
//                     />
//                 </Box>
          
//                 {/* Component Information - updated for better mobile display */}
//                 <Box
//                     sx={{
//                         position: 'absolute',
//                         top: 16,
//                         left: 16,
//                         zIndex: 10,
//                         display: 'flex',
//                         alignItems: 'center',
//                         gap: 1,
//                         p: { xs: 1, sm: 1.5 },
//                         borderRadius: '8px',
//                         background: 'white',
//                         boxShadow: '0 2px 12px rgba(0, 0, 0, 0.08)',
//                     }}
//                 >
//                     <Box
//                         sx={{
//                             width: { xs: 32, sm: 40 },
//                             height: { xs: 32, sm: 40 },
//                             borderRadius: '8px',
//                             display: 'flex',
//                             alignItems: 'center',
//                             justifyContent: 'center',
//                             backgroundColor: `${components[activeIndex].color}15`
//                         }}
//                     >
//                         {React.cloneElement(components[activeIndex].icon, { 
//                             color: components[activeIndex].color,
//                             size: { xs: 16, sm: 20 }
//                         })}
//                     </Box>
//                     <Box>
//                         <Typography variant="subtitle2" fontWeight={600} fontSize={{ xs: '0.8rem', sm: '0.9rem' }}>
//                             {components[activeIndex].title}
//                         </Typography>
//                         <Typography variant="caption" color="text.secondary" fontSize={{ xs: '0.7rem', sm: '0.75rem' }}>
//                             Step {activeIndex + 1} of {components.length}
//                         </Typography>
//                     </Box>
//                 </Box>
          
//                 {/* Dynamic component display */}
//                 <Box
//                     sx={{
//                         height: '100%',
//                         display: 'flex',
//                         justifyContent: 'center',
//                         alignItems: 'center',
//                         px: 2,
//                         pt: 6,
//                         pb: 8,
//                         position: 'relative',
//                         transition: 'transform 0.3s ease-in-out, opacity 0.3s ease-in-out',
//                         opacity: isAnimating ? 0 : 1,
//                         transform: isAnimating 
//                             ? (animationDirection === 'next' ? 'translateX(-5%)' : 'translateX(5%)')
//                             : 'translateX(0)'
//                     }}
//                 >
//                     <WorkflowStageComponent stage={components[activeIndex].stage} />
//                 </Box>
          
//                 {/* Description area - improved for mobile */}
//                 <Box
//                     sx={{
//                         position: 'absolute',
//                         bottom: 0,
//                         left: 0,
//                         right: 0,
//                         p: { xs: 1.5, sm: 2 },
//                         background: 'white',
//                         borderTop: '1px solid rgba(226, 232, 240, 0.7)',
//                         display: 'flex',
//                         alignItems: 'center',
//                         justifyContent: 'space-between',
//                         flexDirection: { xs: 'column', sm: 'row' },
//                         gap: { xs: 1, sm: 0 }
//                     }}
//                 >
//                     <Typography 
//                         variant="body2" 
//                         color="text.secondary" 
//                         sx={{ 
//                             flex: 1, 
//                             px: 1,
//                             fontSize: { xs: '0.75rem', sm: '0.8rem' },
//                             textAlign: { xs: 'center', sm: 'left' }
//                         }}
//                     >
//                         {components[activeIndex].description}
//                     </Typography>
            
//                     {/* Navigation dots */}
//                     <Box sx={{ display: 'flex', gap: 0.5 }}>
//                         {components.map((_, index) => (
//                             <Box
//                                 key={index}
//                                 onClick={() => {
//                                     if (!isAnimating) {
//                                         const direction = index > activeIndex ? 'next' : 'prev';
//                                         setAnimationDirection(direction);
//                                         setIsAnimating(true);
//                                         setTimeout(() => {
//                                             setActiveIndex(index);
//                                             setIsAnimating(false);
//                                         }, 300);
//                                     }
//                                 }}
//                                 sx={{
//                                     width: 8,
//                                     height: 8,
//                                     borderRadius: '50%',
//                                     backgroundColor: index === activeIndex ? '#6366f1' : '#e2e8f0',
//                                     transition: 'background-color 0.2s ease',
//                                     cursor: 'pointer'
//                                 }}
//                             />
//                         ))}
//                     </Box>
//                 </Box>
//             </Box>
      
//             {/* Navigation buttons - modified for better mobile positioning */}
//             <IconButton
//                 onClick={prevComponent}
//                 sx={{
//                     position: 'absolute',
//                     left: { xs: 0, sm: -20 },
//                     top: '50%',
//                     transform: 'translateY(-50%)',
//                     backgroundColor: 'white',
//                     boxShadow: '0 2px 10px rgba(0, 0, 0, 0.1)',
//                     '&:hover': {
//                         backgroundColor: '#f8fafc'
//                     },
//                     zIndex: 10,
//                     width: { xs: 32, sm: 40 },
//                     height: { xs: 32, sm: 40 }
//                 }}
//             >
//                 <ChevronLeft size={18} />
//             </IconButton>
      
//             <IconButton
//                 onClick={nextComponent}
//                 sx={{
//                     position: 'absolute',
//                     right: { xs: 0, sm: -20 },
//                     top: '50%',
//                     transform: 'translateY(-50%)',
//                     backgroundColor: 'white',
//                     boxShadow: '0 2px 10px rgba(0, 0, 0, 0.1)',
//                     '&:hover': {
//                         backgroundColor: '#f8fafc'
//                     },
//                     zIndex: 10,
//                     width: { xs: 32, sm: 40 },
//                     height: { xs: 32, sm: 40 }
//                 }}
//             >
//                 <ChevronRight size={18} />
//             </IconButton>
//         </Box>
//     );
// };

// // Component to render a specific workflow stage with dynamic behaviors
// const WorkflowStageComponent = ({ stage }) => {
//     // Animation states
//     const [dataSourceActive, setDataSourceActive] = useState(false);
//     const [kbStage, setKbStage] = useState(0);
//     const [workflowStage, setWorkflowStage] = useState(0);
//     const [triggerStage, setTriggerStage] = useState(0);
//     const [chatStage, setChatStage] = useState(0);
//     const [messages, setMessages] = useState([]);
    
//     // Run animation sequence when component mounts
//     useEffect(() => {
//         // Reset all states
//         setDataSourceActive(false);
//         setKbStage(0);
//         setWorkflowStage(0);
//         setTriggerStage(0);
//         setChatStage(0);
//         setMessages([]);
        
//         // Define animation timeline based on current stage
//         const runAnimation = () => {
//             if (stage === 'source') {
//                 setTimeout(() => setDataSourceActive(true), 500);
//             }
//             else if (stage === 'kb') {
//                 setTimeout(() => setKbStage(1), 500);
//                 setTimeout(() => setKbStage(2), 1500);
//                 setTimeout(() => setKbStage(3), 2500);
//             }
//             else if (stage === 'workflow') {
//                 setTimeout(() => setWorkflowStage(1), 500);
//                 setTimeout(() => setWorkflowStage(2), 1200);
//                 setTimeout(() => setWorkflowStage(3), 1900);
//                 setTimeout(() => setWorkflowStage(4), 2600);
//             }
//             else if (stage === 'trigger') {
//                 setTimeout(() => setTriggerStage(1), 500);
//                 setTimeout(() => setTriggerStage(2), 1500);
//             }
//             else if (stage === 'chat') {
//                 setTimeout(() => setChatStage(1), 500);
//                 setTimeout(() => {
//                     setChatStage(2);
//                     setMessages([{ type: 'user', content: 'I notice new data has been processed' }]);
//                 }, 1500);
//                 setTimeout(() => setChatStage(3), 2500);
//                 setTimeout(() => {
//                     setChatStage(4);
//                     setMessages(prev => [
//                         ...prev,
//                         { type: 'assistant', content: 'Yes, the workflow has processed new data from your knowledge base. Would you like me to analyze it for insights?' }
//                     ]);
//                 }, 3500);
//             }
//         };
        
//         // Start the animation with a small delay
//         const timer = setTimeout(runAnimation, 300);
        
//         return () => clearTimeout(timer);
//     }, [stage]);
    
//     // Connection Point component
//     const ConnectionPoint = ({ side }) => (
//         <Box
//             sx={{
//                 position: 'absolute',
//                 [side]: '-6px',
//                 top: '50%',
//                 transform: 'translateY(-50%)',
//                 width: 12,
//                 height: 12,
//                 borderRadius: '50%',
//                 backgroundColor: '#6366f1',
//                 border: '2px solid white',
//                 boxShadow: '0 0 6px rgba(99, 102, 241, 0.5)',
//                 zIndex: 10,
//                 '&::after': {
//                     content: '""',
//                     position: 'absolute',
//                     top: '50%',
//                     left: '50%',
//                     transform: 'translate(-50%, -50%)',
//                     width: 6,
//                     height: 6,
//                     borderRadius: '50%',
//                     backgroundColor: 'white',
//                 },
//                 animation: 'pulse 2s infinite ease-in-out'
//             }}
//         />
//     );
    
//     // Define common card styles - updated for responsive sizing with fixed height
//     const cardStyle = {
//         width: { xs: '100%', sm: '90%', md: '20rem' },
//         maxWidth: { xs: '280px', sm: '320px', md: '340px' },
//         height: { xs: '380px', sm: '420px' },
//         display: 'flex',
//         flexDirection: 'column',
//         boxShadow: '0 8px 16px rgba(0, 0, 0, 0.05)',
//         borderRadius: '0.5rem',
//         overflow: 'hidden',
//         border: '1px solid rgba(226, 232, 240, 0.7)',
//         mx: 'auto',
//         position: 'relative',
//         transition: 'transform 0.2s ease, box-shadow 0.2s ease',
//         '&:hover': {
//             transform: { xs: 'none', md: 'translateY(-3px)' },
//             boxShadow: '0 12px 24px rgba(0, 0, 0, 0.08)'
//         }
//     };
    
//     // Define header style for the colored top bar
//     const headerStyle = {
//         height: '0.375rem',
//         width: '100%'
//     };
    
//     // Data Sources Component
//     if (stage === 'source') {
//         return (
//             <Box sx={cardStyle}>
//                 {/* Connection points */}
//                 <ConnectionPoint side="left" />
//                 <ConnectionPoint side="right" />
                
//                 <Box sx={{ ...headerStyle, background: 'linear-gradient(90deg, #4285F4, #0F9D58)' }} />
//                 <Box sx={{ 
//                     p: { xs: 2, sm: 3 },
//                     display: 'flex',
//                     flexDirection: 'column',
//                     height: '100%',
//                     overflow: 'hidden'
//                 }}>
//                     <Typography variant="subtitle2" fontWeight={600} mb={2} fontSize={{ xs: '0.9rem', sm: '1rem' }}>
//                         Data Sources
//                     </Typography>
                    
//                     <Box sx={{ 
//                         display: 'flex', 
//                         flexDirection: 'column', 
//                         gap: 2,
//                         flex: 1,
//                         overflowY: 'auto'
//                     }}>
//                         {[
//                             { name: 'Drive', color: '#4285F4', icon: <FileText size={20} /> },
//                             { name: 'Sheets', color: '#0F9D58', icon: <Table size={20} /> },
//                             { name: 'LinkedIn', color: '#0077B5', icon: <Linkedin size={20} /> }
//                         ].map((source, idx) => (
//                             <Box 
//                                 key={idx}
//                                 sx={{ 
//                                     p: { xs: 1.5, sm: 2 }, 
//                                     borderRadius: '0.5rem',
//                                     border: '1px solid rgba(226, 232, 240, 0.7)',
//                                     display: 'flex',
//                                     alignItems: 'center',
//                                     gap: { xs: 1, sm: 2 },
//                                     transition: 'all 0.3s ease',
//                                     transform: dataSourceActive ? 'translateX(0)' : 'translateX(-10px)',
//                                     opacity: dataSourceActive ? 1 : 0.7,
//                                     animationDelay: `${idx * 0.2}s`
//                                 }}
//                             >
//                                 <Box 
//                                     sx={{ 
//                                         width: { xs: 36, sm: 40 }, 
//                                         height: { xs: 36, sm: 40 }, 
//                                         borderRadius: '0.5rem',
//                                         backgroundColor: `${source.color}15`,
//                                         display: 'flex',
//                                         alignItems: 'center',
//                                         justifyContent: 'center',
//                                         transition: 'all 0.3s ease'
//                                     }}
//                                 >
//                                     {React.cloneElement(source.icon, { 
//                                         color: source.color,
//                                         size: { xs: 16, sm: 20 }
//                                     })}
//                                 </Box>
//                                 <Box>
//                                     <Typography variant="body2" fontWeight={500} fontSize={{ xs: '0.8rem', sm: '0.9rem' }}>
//                                         {source.name}
//                                     </Typography>
//                                     <Box sx={{ display: 'flex', alignItems: 'center', gap: 0.5, mt: 0.5 }}>
//                                         <Box 
//                                             sx={{ 
//                                                 width: 6, 
//                                                 height: 6, 
//                                                 borderRadius: '50%',
//                                                 backgroundColor: dataSourceActive ? '#10b981' : '#94a3b8',
//                                                 transition: 'background-color 0.5s ease'
//                                             }} 
//                                         />
//                                         <Typography variant="caption" color="text.secondary" fontSize={{ xs: '0.7rem', sm: '0.75rem' }}>
//                                             {dataSourceActive ? 'Connected' : 'Standby'}
//                                         </Typography>
//                                     </Box>
//                                 </Box>
//                             </Box>
//                         ))}
//                     </Box>
//                 </Box>
//             </Box>
//         );
//     }
    
//     // Knowledge Base Component
//     if (stage === 'kb') {
//         return (
//             <Box sx={cardStyle}>
//                 {/* Connection points */}
//                 <ConnectionPoint side="left" />
//                 <ConnectionPoint side="right" />
                
//                 <Box sx={{ ...headerStyle, background: 'linear-gradient(90deg, #3b82f6, #14b8a6)' }} />
//                 <Box sx={{ 
//                     p: { xs: 2, sm: 3 },
//                     display: 'flex',
//                     flexDirection: 'column',
//                     height: '100%',
//                     overflow: 'hidden'
//                 }}>
//                     <Typography variant="subtitle2" fontWeight={600} mb={2} fontSize={{ xs: '0.9rem', sm: '1rem' }}>
//                         Knowledge Base
//                     </Typography>
                    
//                     {/* KB visualization */}
//                     <Box sx={{ display: 'flex', justifyContent: 'center', mb: 2 }}>
//                         <Box 
//                             sx={{ 
//                                 width: { xs: 80, sm: 100 }, 
//                                 height: { xs: 80, sm: 100 }, 
//                                 borderRadius: '50%',
//                                 border: '4px solid #f1f5f9',
//                                 position: 'relative',
//                                 display: 'flex',
//                                 alignItems: 'center',
//                                 justifyContent: 'center',
//                                 overflow: 'hidden'
//                             }}
//                         >
//                             {/* Water fill */}
//                             <Box 
//                                 sx={{ 
//                                     position: 'absolute',
//                                     bottom: 0,
//                                     left: 0,
//                                     width: '100%',
//                                     height: `${(kbStage / 3) * 100}%`,
//                                     background: 'linear-gradient(to top, #3b82f6, #60a5fa)',
//                                     opacity: 0.7,
//                                     transition: 'height 0.8s ease-out'
//                                 }} 
//                             />
                            
//                             {/* Wave effect */}
//                             <Box 
//                                 sx={{ 
//                                     position: 'absolute',
//                                     bottom: `calc(${(kbStage / 3) * 100}% - 3px)`,
//                                     width: '200%',
//                                     height: '6px',
//                                     background: 'linear-gradient(90deg, rgba(255,255,255,0) 0%, rgba(255,255,255,0.5) 50%, rgba(255,255,255,0) 100%)',
//                                     opacity: kbStage > 0 ? 0.7 : 0,
//                                     animation: 'waveMotion 2s infinite ease-in-out',
//                                     display: kbStage > 0 ? 'block' : 'none'
//                                 }}
//                             />
                            
//                             {/* Database icon */}
//                             <Database 
//                                 size={22} 
//                                 color={kbStage > 0 ? '#3b82f6' : '#94a3b8'} 
//                                 style={{ 
//                                     position: 'relative', 
//                                     zIndex: 1, 
//                                     transition: 'transform 0.5s ease, color 0.8s ease-out',
//                                     transform: `scale(${1 + (kbStage / 10)})`
//                                 }} 
//                             />
//                         </Box>
//                     </Box>
                    
//                     {/* Status indicators */}
//                     <Grid container spacing={2}>
//                         <Grid item xs={6}>
//                             <Typography variant="caption" color="text.secondary" fontSize={{ xs: '0.7rem', sm: '0.75rem' }}>Status</Typography>
//                             <Box sx={{ display: 'flex', alignItems: 'center', gap: 0.5, mt: 0.5 }}>
//                                 <Box 
//                                     sx={{ 
//                                         width: 6, 
//                                         height: 6, 
//                                         borderRadius: '50%',
//                                         backgroundColor: kbStage > 0 ? '#10b981' : '#94a3b8',
//                                         transition: 'background-color 0.5s ease'
//                                     }} 
//                                 />
//                                 <Typography variant="body2" fontSize={{ xs: '0.8rem', sm: '0.85rem' }}>
//                                     {kbStage === 0 ? 'Standby' : 'Active'}
//                                 </Typography>
//                             </Box>
//                         </Grid>
//                         <Grid item xs={6}>
//                             <Typography variant="caption" color="text.secondary" fontSize={{ xs: '0.7rem', sm: '0.75rem' }}>Entities</Typography>
//                             <Typography variant="body2" fontSize={{ xs: '0.8rem', sm: '0.85rem' }}>
//                                 {kbStage === 0 ? '0' : 
//                                 kbStage === 1 ? '450' : 
//                                 kbStage === 2 ? '950' : '1,450'}
//                             </Typography>
//                         </Grid>
//                         <Grid item xs={6}>
//                             <Typography variant="caption" color="text.secondary" fontSize={{ xs: '0.7rem', sm: '0.75rem' }}>Processed</Typography>
//                             <Typography variant="body2" fontSize={{ xs: '0.8rem', sm: '0.85rem' }}>
//                                 {kbStage === 0 ? '0' : 
//                                 kbStage === 1 ? '105 MB' : 
//                                 kbStage === 2 ? '287 MB' : '420 MB'}
//                             </Typography>
//                         </Grid>
//                         <Grid item xs={6}>
//                             <Typography variant="caption" color="text.secondary" fontSize={{ xs: '0.7rem', sm: '0.75rem' }}>Last Updated</Typography>
//                             <Typography variant="body2" fontSize={{ xs: '0.8rem', sm: '0.85rem' }}>
//                                 {kbStage === 0 ? '—' : 'Just now'}
//                             </Typography>
//                         </Grid>
//                     </Grid>
//                 </Box>
//             </Box>
//         );
//     }
    
//     // Workflow Component
//     if (stage === 'workflow') {
//         return (
//             <Box sx={cardStyle}>
//                 {/* Connection points */}
//                 <ConnectionPoint side="left" />
//                 <ConnectionPoint side="right" />
                
//                 <Box sx={{ ...headerStyle, background: 'linear-gradient(90deg, #8b5cf6, #d946ef)' }} />
//                 <Box sx={{ 
//                     p: { xs: 2, sm: 3 },
//                     display: 'flex',
//                     flexDirection: 'column',
//                     height: '100%',
//                     overflow: 'hidden'
//                 }}>
//                     <Typography variant="subtitle2" fontWeight={600} mb={2} fontSize={{ xs: '0.9rem', sm: '1rem' }}>
//                         Workflow Processor
//                     </Typography>
                    
//                     {/* Task list - with fixed height and scrollable if needed */}
//                     <Box sx={{ 
//                         mb: 2,
//                         flex: 1,
//                         overflowY: 'auto',
//                         pr: 1
//                     }}>
//                         {["Initialize processing", "Analyze data structure", "Apply transformation rules", "Generate output"].map((task, idx) => (
//                             <Box 
//                                 key={idx} 
//                                 sx={{ 
//                                     display: 'flex', 
//                                     alignItems: 'center', 
//                                     mb: 1.5,
//                                     opacity: idx + 1 <= workflowStage ? 1 : 0.7,
//                                     transition: 'opacity 0.3s ease'
//                                 }}
//                             >
//                                 <Box 
//                                     sx={{ 
//                                         width: { xs: 20, sm: 24 }, 
//                                         height: { xs: 20, sm: 24 }, 
//                                         borderRadius: '50%',
//                                         backgroundColor: idx + 1 <= workflowStage ? '#ddd6fe' : '#f1f5f9',
//                                         display: 'flex',
//                                         alignItems: 'center',
//                                         justifyContent: 'center',
//                                         mr: 1,
//                                         transition: 'background-color 0.3s ease',
//                                         flexShrink: 0
//                                     }}
//                                 >
//                                     {idx + 1 <= workflowStage ? (
//                                         <Check size={14} color="#8b5cf6" />
//                                     ) : (
//                                         <Typography variant="caption" color="text.secondary">
//                                             {idx + 1}
//                                         </Typography>
//                                     )}
//                                 </Box>
//                                 <Typography 
//                                     variant="body2" 
//                                     fontSize={{ xs: '0.8rem', sm: '0.85rem' }}
//                                     color={idx + 1 <= workflowStage ? 'text.primary' : 'text.secondary'}
//                                 >
//                                     {task}
//                                 </Typography>
//                             </Box>
//                         ))}
//                     </Box>
                    
//                     {/* Status */}
//                     <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 2 }}>
//                         <Typography variant="caption" color="text.secondary" fontSize={{ xs: '0.7rem', sm: '0.75rem' }}>Status</Typography>
//                         <Typography 
//                             variant="body2" 
//                             fontWeight={500}
//                             fontSize={{ xs: '0.8rem', sm: '0.85rem' }}
//                             sx={{ 
//                                 color: workflowStage > 0 ? '#8b5cf6' : '#94a3b8',
//                                 transition: 'color 0.3s ease'
//                             }}
//                         >
//                             {workflowStage === 0 ? 'Waiting for data' : 
//                             workflowStage < 4 ? 'Processing...' :
//                             'Complete'}
//                         </Typography>
//                     </Box>
                    
//                     {/* Additional stats */}
//                     <Grid container spacing={1} sx={{ pt: 1, borderTop: '1px solid #f1f5f9' }}>
//                         <Grid item xs={6}>
//                             <Typography variant="caption" color="text.secondary" fontSize={{ xs: '0.7rem', sm: '0.75rem' }}>Tasks</Typography>
//                             <Typography variant="body2" fontSize={{ xs: '0.8rem', sm: '0.85rem' }}>
//                                 {Math.min(workflowStage, 4)}/4 completed
//                             </Typography>
//                         </Grid>
//                         <Grid item xs={6}>
//                             <Typography variant="caption" color="text.secondary" fontSize={{ xs: '0.7rem', sm: '0.75rem' }}>CPU Usage</Typography>
//                             <Typography variant="body2" fontSize={{ xs: '0.8rem', sm: '0.85rem' }}>
//                                 {workflowStage === 0 ? '5%' : `${Math.min(60 + workflowStage * 10, 90)}%`}
//                             </Typography>
//                         </Grid>
//                     </Grid>
//                 </Box>
//             </Box>
//         );
//     }
    
//     // Trigger Component
//     if (stage === 'trigger') {
//         return (
//             <Box sx={cardStyle}>
//                 {/* Connection points */}
//                 <ConnectionPoint side="left" />
//                 <ConnectionPoint side="right" />
                
//                 <Box sx={{ ...headerStyle, background: 'linear-gradient(90deg, #f59e0b, #f43f5e)' }} />
//                 <Box sx={{ 
//                     p: { xs: 2, sm: 3 },
//                     display: 'flex',
//                     flexDirection: 'column',
//                     height: '100%',
//                     overflow: 'hidden'
//                 }}>
//                     <Typography variant="subtitle2" fontWeight={600} mb={2} fontSize={{ xs: '0.9rem', sm: '1rem' }}>
//                         Trigger
//                     </Typography>
                    
//                     {/* Trigger visualization */}
//                     <Box sx={{ display: 'flex', justifyContent: 'center', mb: 2 }}>
//                         <Box sx={{ position: 'relative', width: { xs: 60, sm: 80 }, height: { xs: 60, sm: 80 } }}>
//                             <Box 
//                                 sx={{ 
//                                     position: 'absolute',
//                                     top: '50%',
//                                     left: '50%',
//                                     transform: 'translate(-50%, -50%)',
//                                     width: { xs: 50, sm: 64 }, 
//                                     height: { xs: 50, sm: 64 }, 
//                                     borderRadius: '50%',
//                                     border: '3px solid',
//                                     borderColor: triggerStage === 0 ? '#f1f5f9' : 
//                                             triggerStage === 1 ? '#fef3c7' : 
//                                             '#fdba74',
//                                     backgroundColor: triggerStage === 0 ? 'white' : 
//                                             triggerStage === 1 ? '#fef3c7' : 
//                                             '#f59e0b',
//                                     display: 'flex',
//                                     alignItems: 'center',
//                                     justifyContent: 'center',
//                                     boxShadow: triggerStage === 0 ? 'none' : 
//                                             triggerStage === 1 ? '0 0 20px rgba(245, 158, 11, 0.3)' : 
//                                             '0 0 30px rgba(245, 158, 11, 0.5)',
//                                     transition: 'all 0.5s ease-out'
//                                 }}
//                             >
//                                 <Zap 
//                                     size={24} 
//                                     color={triggerStage === 0 ? '#94a3b8' : 
//                                         triggerStage === 1 ? '#f59e0b' : 
//                                         'white'} 
//                                 />
//                             </Box>
                            
//                             {/* Fixed ripple effect for triggered state */}
//                             {triggerStage === 2 && (
//                                 <Box 
//                                     sx={{ 
//                                         position: 'absolute',
//                                         top: '50%',
//                                         left: '50%',
//                                         width: { xs: 50, sm: 64 }, 
//                                         height: { xs: 50, sm: 64 },
//                                         borderRadius: '50%',
//                                         border: '2px solid #f59e0b',
//                                         animation: 'ripple 1.5s infinite ease-out',
//                                         opacity: 0
//                                     }} 
//                                 />
//                             )}
//                         </Box>
//                     </Box>
                    
//                     {/* Status information */}
//                     <Box sx={{ textAlign: 'center', mb: 3 }}>
//                         <Typography variant="caption" color="text.secondary" fontSize={{ xs: '0.7rem', sm: '0.75rem' }}>Trigger Status</Typography>
//                         <Typography 
//                             variant="body2" 
//                             fontWeight={500}
//                             fontSize={{ xs: '0.8rem', sm: '0.85rem' }}
//                             sx={{ 
//                                 color: triggerStage === 0 ? '#94a3b8' : 
//                                     triggerStage === 1 ? '#f59e0b' : 
//                                     '#ef4444',
//                                 transition: 'color 0.4s ease-out'
//                             }}
//                         >
//                             {triggerStage === 0 ? 'Waiting for condition' : 
//                             triggerStage === 1 ? 'Condition detected' : 
//                             'Triggered!'}
//                         </Typography>
//                     </Box>
                    
//                     {/* Trigger conditions - with flex to allow stretching */}
//                     <Box 
//                         sx={{ 
//                             backgroundColor: '#f8fafc',
//                             borderRadius: '0.5rem',
//                             p: { xs: 1.5, sm: 2 },
//                             mb: 2,
//                             border: '1px solid #f1f5f9',
//                             flex: 1,
//                             overflowY: 'auto'
//                         }}
//                     >
//                         <Typography variant="caption" color="text.secondary" sx={{ display: 'flex', alignItems: 'center', mb: 1.5 }} fontSize={{ xs: '0.7rem', sm: '0.75rem' }}>
//                             <ListChecks size={12} color="#f59e0b" style={{ marginRight: '4px' }} />
//                             Conditions
//                         </Typography>
                        
//                         <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1.5 }}>
//                             <Box sx={{ display: 'flex', alignItems: 'center' }}>
//                                 <Box 
//                                     sx={{ 
//                                         width: 6, 
//                                         height: 6, 
//                                         borderRadius: '50%',
//                                         backgroundColor: triggerStage >= 1 ? '#f59e0b' : '#94a3b8',
//                                         mr: 1.5,
//                                         transition: 'background-color 0.5s ease'
//                                     }} 
//                                 />
//                                 <Typography variant="body2" fontSize={{ xs: '0.8rem', sm: '0.85rem' }}>When entities &gt; 1000</Typography>
//                             </Box>
//                             <Box sx={{ display: 'flex', alignItems: 'center' }}>
//                                 <Box 
//                                     sx={{ 
//                                         width: 6, 
//                                         height: 6, 
//                                         borderRadius: '50%',
//                                         backgroundColor: triggerStage >= 1 ? '#f59e0b' : '#94a3b8',
//                                         mr: 1.5,
//                                         transition: 'background-color 0.5s ease'
//                                     }} 
//                                 />
//                                 <Typography variant="body2" fontSize={{ xs: '0.8rem', sm: '0.85rem' }}>Processing complete</Typography>
//                             </Box>
//                         </Box>
//                     </Box>
                    
//                     {/* Additional stats */}
//                     <Grid container spacing={2}>
//                         <Grid item xs={6}>
//                             <Typography variant="caption" color="text.secondary" fontSize={{ xs: '0.7rem', sm: '0.75rem' }}>Count</Typography>
//                             <Typography variant="body2" fontSize={{ xs: '0.8rem', sm: '0.85rem' }}>{Math.floor(triggerStage / 3)}</Typography>
//                         </Grid>
//                         <Grid item xs={6}>
//                             <Typography variant="caption" color="text.secondary" fontSize={{ xs: '0.7rem', sm: '0.75rem' }}>Action</Typography>
//                             <Typography variant="body2" fontSize={{ xs: '0.8rem', sm: '0.85rem' }}>Chat Notification</Typography>
//                         </Grid>
//                     </Grid>
//                 </Box>
//             </Box>
//         );
//     }
    
//     // Chat Interface Component
//     if (stage === 'chat') {
//         return (
//             <Box sx={cardStyle}>
//                 {/* Connection points */}
//                 <ConnectionPoint side="left" />
//                 <ConnectionPoint side="right" />
                
//                 <Box sx={{ ...headerStyle, background: 'linear-gradient(90deg, #10b981, #0ea5e9)' }} />
//                 <Box sx={{ 
//                     p: { xs: 2, sm: 3 },
//                     display: 'flex',
//                     flexDirection: 'column',
//                     height: '100%'
//                 }}>
//                     <Typography variant="subtitle2" fontWeight={600} mb={2} fontSize={{ xs: '0.9rem', sm: '1rem' }}>
//                         Chat Interface
//                     </Typography>
                    
//                     {/* Chat header */}
//                     <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 2 }}>
//                         <Box sx={{ display: 'flex', alignItems: 'center' }}>
//                             <Box 
//                                 sx={{ 
//                                     width: 6, 
//                                     height: 6, 
//                                     borderRadius: '50%',
//                                     backgroundColor: chatStage > 0 ? '#10b981' : '#94a3b8',
//                                     mr: 1,
//                                     transition: 'background-color 0.4s ease-out'
//                                 }} 
//                             />
//                             <Typography variant="caption" fontSize={{ xs: '0.7rem', sm: '0.75rem' }}>AI Assistant</Typography>
//                         </Box>
//                         <Typography variant="caption" color="text.secondary" fontSize={{ xs: '0.7rem', sm: '0.75rem' }}>
//                             {chatStage > 0 ? 'Online' : 'Standby'}
//                         </Typography>
//                     </Box>
                    
//                     {/* Chat content area - with flex-grow to use available space */}
//                     <Box 
//                         sx={{ 
//                             backgroundColor: '#f8fafc',
//                             borderRadius: '0.5rem',
//                             p: { xs: 1.5, sm: 2 },
//                             mb: 2,
//                             border: '1px solid #f1f5f9',
//                             flex: 1,
//                             overflowY: 'auto'
//                         }}
//                     >
//                         {/* User message */}
//                         {messages.map((msg, index) => (
//                             <Box 
//                                 key={index}
//                                 sx={{ 
//                                     backgroundColor: msg.type === 'user' ? '#e0f2fe' : '#dcfce7',
//                                     p: 1.5,
//                                     borderRadius: '0.5rem',
//                                     borderTopRightRadius: msg.type === 'user' ? 0 : '0.5rem',
//                                     borderTopLeftRadius: msg.type === 'assistant' ? 0 : '0.5rem',
//                                     maxWidth: '80%',
//                                     ml: msg.type === 'user' ? 'auto' : '0',
//                                     mr: msg.type === 'assistant' ? 'auto' : '0',
//                                     mb: 1.5,
//                                     display: 'flex'
//                                 }}
//                             >
//                                 {msg.type === 'assistant' && (
//                                     <Box 
//                                         sx={{ 
//                                             width: 16, 
//                                             height: 16, 
//                                             borderRadius: '50%',
//                                             backgroundColor: '#10b981',
//                                             display: 'flex',
//                                             alignItems: 'center',
//                                             justifyContent: 'center',
//                                             mr: 1
//                                         }}
//                                     >
//                                         <Bot size={10} color="white" />
//                                     </Box>
//                                 )}
                                
//                                 <Typography variant="caption" fontSize={{ xs: '0.7rem', sm: '0.75rem' }}>
//                                     {msg.content}
//                                 </Typography>
                                
//                                 {msg.type === 'user' && (
//                                     <Box 
//                                         sx={{ 
//                                             width: 16, 
//                                             height: 16, 
//                                             borderRadius: '50%',
//                                             backgroundColor: '#3b82f6',
//                                             display: 'flex',
//                                             alignItems: 'center',
//                                             justifyContent: 'center',
//                                             ml: 1
//                                         }}
//                                     >
//                                         <User size={10} color="white" />
//                                     </Box>
//                                 )}
//                             </Box>
//                         ))}
                        
//                         {/* Typing indicator */}
//                         {chatStage === 3 && (
//                             <Box 
//                                 sx={{ 
//                                     backgroundColor: '#dcfce7',
//                                     p: 1.5,
//                                     borderRadius: '0.5rem',
//                                     borderTopLeftRadius: 0,
//                                     maxWidth: '80%',
//                                     mr: 'auto',
//                                     mb: 1.5,
//                                     display: 'flex',
//                                     alignItems: 'center'
//                                 }}
//                             >
//                                 <Box 
//                                     sx={{ 
//                                         width: 16, 
//                                         height: 16, 
//                                         borderRadius: '50%',
//                                         backgroundColor: '#10b981',
//                                         display: 'flex',
//                                         alignItems: 'center',
//                                         justifyContent: 'center',
//                                         mr: 1
//                                     }}
//                                 >
//                                     <Bot size={10} color="white" />
//                                 </Box>
                                
//                                 <Box sx={{ display: 'flex', gap: 0.5, alignItems: 'center' }}>
//                                     <Box 
//                                         sx={{ 
//                                             width: 4, 
//                                             height: 4, 
//                                             borderRadius: '50%',
//                                             backgroundColor: '#10b981',
//                                             animation: 'typingDot1 0.8s infinite',
//                                         }} 
//                                     />
//                                     <Box 
//                                         sx={{ 
//                                             width: 4, 
//                                             height: 4, 
//                                             borderRadius: '50%',
//                                             backgroundColor: '#10b981',
//                                             animation: 'typingDot2 0.8s infinite',
//                                             animationDelay: '0.2s'
//                                         }} 
//                                     />
//                                     <Box 
//                                         sx={{ 
//                                             width: 4, 
//                                             height: 4, 
//                                             borderRadius: '50%',
//                                             backgroundColor: '#10b981',
//                                             animation: 'typingDot3 0.8s infinite',
//                                             animationDelay: '0.4s'
//                                         }} 
//                                     />
//                                 </Box>
//                             </Box>
//                         )}
//                     </Box>
                    
//                     {/* Chat input */}
//                     <Box sx={{ display: 'flex', gap: 1 }}>
//                         <input
//                             type="text"
//                             placeholder="Type message..."
//                             style={{ 
//                                 flex: 1,
//                                 border: '1px solid #e2e8f0',
//                                 borderRadius: '0.5rem',
//                                 padding: '8px 12px',
//                                 fontSize: '12px',
//                                 backgroundColor: chatStage > 0 ? 'white' : '#f8fafc'
//                             }}
//                             disabled={chatStage === 0}
//                         />
//                         <Button
//                             sx={{
//                                 minWidth: 'auto',
//                                 width: 32,
//                                 height: 32,
//                                 borderRadius: '50%',
//                                 p: 0,
//                                 backgroundColor: chatStage > 0 ? '#10b981' : '#f1f5f9',
//                                 '&:hover': {
//                                     backgroundColor: chatStage > 0 ? '#0d9488' : '#f1f5f9'
//                                 },
//                                 transition: 'background-color 0.4s ease-out'
//                             }}
//                             disabled={chatStage === 0}
//                         >
//                             <Send size={16} color={chatStage > 0 ? 'white' : '#94a3b8'} />
//                         </Button>
//                     </Box>
//                 </Box>
//             </Box>
//         );
//     }
    
//     // Default fallback
//     return (
//         <Typography variant="body1">Component not found</Typography>
//     );
// };

// export default CarouselStudioWorkflowSection
import { Box, Button, Card, Chip, Container, Grid, IconButton, List, ListItem, ListItemIcon, ListItemText, Typography } from '@mui/material';
import { Activity, Bot, Check, ChevronLeft, ChevronRight, Cpu, Database, FileText, ListChecks, MessageSquare, Send, Table, User, Zap } from 'lucide-react';
import React, { useCallback, useEffect, useState } from 'react';
import ContactDialog, { trackAndOpenDialog } from '../CodingRipplerHome/ContactUs/ContactUsDialog';
import { SectionHeading, SectionTitle } from '../CodingRipplerWidgets';

// Global keyframes for animations - unchanged
const keyframes = `
  @keyframes typingDot1 {
    0%, 100% { transform: translateY(0); }
    50% { transform: translateY(-2px); }
  }
  
  @keyframes typingDot2 {
    0%, 100% { transform: translateY(0); }
    50% { transform: translateY(-2px); }
  }
  
  @keyframes typingDot3 {
    0%, 100% { transform: translateY(0); }
    50% { transform: translateY(-2px); }
  }
  
  @keyframes ripple {
    0% { transform: translate(-50%, -50%) scale(1); opacity: 0.8; }
    100% { transform: translate(-50%, -50%) scale(1.5); opacity: 0; }
  }
  
  @keyframes waveMotion {
    0%, 100% { transform: translateX(-30%); }
    50% { transform: translateX(30%); }
  }
  
  @keyframes pulse {
    0%, 100% { opacity: 0.6; }
    50% { opacity: 1; }
  }
`;

// Main wrapper component that contains both the feature card and carousel
const CarouselStudioWorkflowSection = () => {
    const [open, setOpen] = useState(false);

    const handleClose = (success) => {
        setOpen(false);
        if (success) {
            // Handle successful submission (e.g. show a success toast)
        }
    };

    const handleContactClick = () => trackAndOpenDialog(setOpen);

    return (
        <>   
            <Box
                id="specialized-product" 
                sx={{ 
                    py: { xs: 6, md: 12 }, 
                    background: 'linear-gradient(135deg, #EDF0FF 0%, #E0E6FF 100%)',
                    position: 'relative',
                    overflow: 'hidden',
                    '&::before': {
                        content: '""',
                        position: 'absolute',
                        width: '600px',
                        height: '600px',
                        top: '-100px',
                        left: '-200px',
                        background: 'radial-gradient(circle, rgba(204, 210, 255, 0.5) 0%, rgba(226, 232, 255, 0) 70%)',
                        borderRadius: '50%',
                        zIndex: 0
                    },
                    '&::after': {
                        content: '""',
                        position: 'absolute',
                        width: '400px',
                        height: '400px',
                        bottom: '-100px',
                        left: '10%',
                        background: 'radial-gradient(circle, rgba(200, 210, 255, 0.4) 0%, rgba(226, 232, 255, 0) 70%)',
                        borderRadius: '50%',
                        zIndex: 0
                    }
                }}
            >
                <Box
                    sx={{
                        position: "absolute",
                        width: "300px",
                        height: "300px",
                        borderRadius: "50%",
                        background: "rgba(99, 102, 241, 0.1)",
                        top: "-100px",
                        left: "-100px",
                        zIndex: 0,
                    }}
                />
                <style dangerouslySetInnerHTML={{ __html: keyframes }} />
            
                <Container maxWidth="lg" sx={{ position: 'relative', zIndex: 1 }}>
                    {/* Container content remains the same */}
                    <Box sx={{ textAlign: 'center', mb: { xs: 6, md: 8 } }}>
                        <SectionTitle variant="subtitle1">
                            AI STUDIO
                        </SectionTitle>
                        <SectionHeading variant="h2" sx={{ fontSize: { xs: '1.75rem', md: '3rem' } }}>
                            Our Specialized Product
                        </SectionHeading>
                        <Typography
                            // variant="body1"
                            // sx={{
                            //     maxWidth: '700px',
                            //     mx: 'auto',
                            //     color: 'rgba(0, 0, 0, 0.6)',
                            //     fontSize: { xs: '1rem', md: '1.1rem' },
                            //     lineHeight: 1.6,
                            //     px: { xs: 2, md: 0 }
                            // }}
                            variant="h5"
                            component="p"
                            align="center"
                            color="text.secondary"
                            sx={{
                              maxWidth: "800px",
                              mx: "auto",
                              mb: 6,
                            }}
                
                        >
                            Turn your business data into intelligent workflows with AI Studio. Seamlessly connect, think, and act—without complexity.
                        </Typography>
                    </Box>
                    <Grid container spacing={{ xs: 3, md: 5 }} sx={{ mb: { xs: 6, md: 10 } }}>
                        <Grid item xs={12} md={6} lg={5}>
                            <Card
                                sx={{
                                    height: '100%',
                                    p: { xs: 3, md: 4 },
                                    borderRadius: 4,
                                    boxShadow: '0 10px 40px rgba(0, 0, 0, 0.1)',
                                    background: 'linear-gradient(135deg, #6366f1 0%, #8b5cf6 100%)',
                                    color: 'white',
                                    position: 'relative',
                                    overflow: 'hidden'
                                }}
                            >
                                {/* Decorative circle */}
                                <Box
                                    sx={{
                                        position: 'absolute',
                                        top: '-10%',
                                        right: '-10%',
                                        width: '200px',
                                        height: '200px',
                                        borderRadius: '50%',
                                        background: 'rgba(255, 255, 255, 0.1)'
                                    }}
                                />
                            
                                <Typography variant="h4" fontWeight={600} mb={2} sx={{ position: 'relative', fontSize: { xs: '1.5rem', md: '2rem' } }}>
                                    AI Studio
                                </Typography>
                            
                                {/* Description */}
                                <Typography variant="body1" mb={4} sx={{ position: 'relative', opacity: 0.9, fontSize: { xs: '0.9rem', md: '1rem' } }}>
                                The Unified Platform That Connects, Thinks & Acts—For Your Business Data.                                
                                </Typography>                            
                                {/* Feature list */}
                                <List sx={{ position: 'relative' }}>
                                    {[
                                        "Multi-source data integration",
                                        "Automated triggers & notifications",
                                        "Real-time chat interface"
                                    ].map((feature, index) => (
                                        <ListItem key={index} sx={{ px: 0, py: 1 }}>
                                            <ListItemIcon sx={{ minWidth: '36px' }}>
                                                <Check size={20} color="white" />
                                            </ListItemIcon>
                                            <ListItemText primary={feature} primaryTypographyProps={{ fontSize: { xs: '0.85rem', md: '0.95rem' } }} />
                                        </ListItem>
                                    ))}
                                </List>
                            
                                {/* Action button */}
                                <Box sx={{ mt: 4, display: 'flex', justifyContent: 'flex-end', position: 'relative' }}>
                                </Box>
                            </Card>
                        </Grid>
                    
                        {/* Right column: Carousel of workflow components */}
                        <Grid item xs={12} md={6} lg={7}>
                            <Box sx={{ position: 'relative' }}>
                                <Typography variant="h6" fontWeight={600} mb={2} fontSize={{ xs: '1rem', md: '1.25rem' }}>
                                    Interactive Workflow Demo
                                </Typography>
                            
                                <Typography variant="body2" color="text.secondary" mb={3} fontSize={{ xs: '0.85rem', md: '0.9rem' }}>
                                    Explore each workflow component by navigating through the carousel
                                </Typography>
                            
                                {/* Carousel component */}
                                <CarouselWorkflowComponent />
                            </Box>
                        </Grid>
                    </Grid>
                
                    {/* Bottom CTA */}
                    <Box sx={{ textAlign: 'center' }}>
                        <Button
                            variant="contained"
                            size="large"
                            className="primary-button"
                            onClick={handleContactClick}
                        >
                            Book a Demo
                        </Button>
                    </Box>
                </Container>
            </Box>
            <ContactDialog open={open} onClose={handleClose} />
        </>
    );
};

// Custom component to display workflow components in a carousel
const CarouselWorkflowComponent = () => {
    // Define the components to display
    const components = [
        {
            id: "data-sources",
            title: "Data Sources",
            description: "Connect and integrate multiple data sources including files, APIs, and databases.",
            icon: <Database size={24} />,
            color: "#4285F4",
            stage: "source"
        },
        {
            id: "knowledge-base",
            title: "Knowledge Base",
            description: "Centralized storage for structured and unstructured data with smart indexing.",
            icon: <Activity size={24} />,
            color: "#3b82f6",
            stage: "kb"
        },
        {
            id: "workflow",
            title: "Workflow Processor",
            description: "Define custom processes and transformations to extract value from your data.",
            icon: <Cpu size={24} />,
            color: "#8b5cf6",
            stage: "workflow"
        },
        {
            id: "trigger",
            title: "Automated Triggers",
            description: "Set conditions to automatically trigger actions and notifications.",
            icon: <Zap size={24} />,
            color: "#f59e0b",
            stage: "trigger"
        },
        {
            id: "chat",
            title: "Chat Interface",
            description: "Interact with your data through a conversational AI interface.",
            icon: <MessageSquare size={24} />,
            color: "#10b981",
            stage: "chat"
        }
    ];
  
    // State to track active component index
    const [activeIndex, setActiveIndex] = useState(0);
    const [animationDirection, setAnimationDirection] = useState('next');
    const [isAnimating, setIsAnimating] = useState(false);
  
    // Navigate to next component
    const nextComponent = useCallback(() => {
        if (isAnimating) return;
        setAnimationDirection('next');
        setIsAnimating(true);
        setTimeout(() => {
            setActiveIndex((prev) => (prev === components.length - 1 ? 0 : prev + 1));
            setIsAnimating(false);
        }, 300);
    }, [isAnimating, setAnimationDirection, setIsAnimating, setActiveIndex, components.length]);  
    // Navigate to previous component
    const prevComponent = () => {
        if (isAnimating) return;
        setAnimationDirection('prev');
        setIsAnimating(true);
        setTimeout(() => {
            setActiveIndex((prev) => (prev === 0 ? components.length - 1 : prev - 1));
            setIsAnimating(false);
        }, 300);
    };
  
    // AutoPlay functionality - fixed to ensure continuous rotation
    useEffect(() => {
        // Clear any existing timers first to prevent multiple timers
        let timer = null;
        
        // Only set timeout if not currently animating
        if (!isAnimating) {
            timer = setTimeout(() => {
                nextComponent();
            }, 3500); // Change card every 6 seconds
        }
        
        // Cleanup function
        return () => {
            if (timer) clearTimeout(timer);
        };
    }, [activeIndex, isAnimating, nextComponent]); // Watch both activeIndex and isAnimating
  
    return (
        <Box
            sx={{
                position: 'relative',
                height: { xs: '620px', sm: '650px' },
                width: '100%',
            }}
        >
            {/* Component carousel - updated for better mobile responsiveness */}
            <Box
                sx={{
                    borderRadius: '16px',
                    background: 'white',
                    boxShadow: '0 4px 24px rgba(0, 0, 0, 0.08)',
                    overflow: 'hidden',
                    height: '100%',
                    position: 'relative',
                    mx: { xs: 0, sm: 0 }, // Remove horizontal margin on mobile since buttons are hidden
                }}
            >
                {/* Ready badge */}
                <Box
                    sx={{
                        position: 'absolute',
                        top: 16,
                        right: 16,
                        zIndex: 10
                    }}
                >
                    <Chip
                        label="Ready"
                        sx={{
                            backgroundColor: '#10b981',
                            color: 'white',
                            fontWeight: 600,
                            fontSize: '0.75rem',
                            height: '24px'
                        }}
                    />
                </Box>
          
                {/* Component Information - updated for better mobile display */}
                <Box
                    sx={{
                        position: 'absolute',
                        top: 16,
                        left: 16,
                        zIndex: 10,
                        display: 'flex',
                        alignItems: 'center',
                        gap: 1,
                        p: { xs: 1, sm: 1.5 },
                        borderRadius: '8px',
                        background: 'white',
                        boxShadow: '0 2px 12px rgba(0, 0, 0, 0.08)',
                    }}
                >
                    <Box
                        sx={{
                            width: { xs: 32, sm: 40 },
                            height: { xs: 32, sm: 40 },
                            borderRadius: '8px',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            backgroundColor: `${components[activeIndex].color}15`
                        }}
                    >
                        {React.cloneElement(components[activeIndex].icon, { 
                            color: components[activeIndex].color,
                            size: { xs: 16, sm: 20 }
                        })}
                    </Box>
                    <Box>
                        <Typography variant="subtitle2" fontWeight={600} fontSize={{ xs: '0.8rem', sm: '0.9rem' }}>
                            {components[activeIndex].title}
                        </Typography>
                        <Typography variant="caption" color="text.secondary" fontSize={{ xs: '0.7rem', sm: '0.75rem' }}>
                            Step {activeIndex + 1} of {components.length}
                        </Typography>
                    </Box>
                </Box>
          
                {/* Dynamic component display */}
                <Box
                    sx={{
                        height: '100%',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        px: 2,
                        pt: 6,
                        pb: { xs: 14, sm: 12 },
                        position: 'relative',
                        transition: 'transform 0.3s ease-in-out, opacity 0.3s ease-in-out',
                        opacity: isAnimating ? 0 : 1,
                        transform: isAnimating 
                            ? (animationDirection === 'next' ? 'translateX(-5%)' : 'translateX(5%)')
                            : 'translateX(0)'
                    }}
                >
                    <WorkflowStageComponent stage={components[activeIndex].stage} />
                </Box>
          
                {/* Description area - improved for mobile */}
                <Box
                    sx={{
                        position: 'absolute',
                        bottom: 0,
                        left: 0,
                        right: 0,
                        p: { xs: 1.5, sm: 2 },
                        background: 'white',
                        borderTop: '1px solid rgba(226, 232, 240, 0.7)',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                        flexDirection: { xs: 'column', sm: 'row' },
                        gap: { xs: 1, sm: 0 },
                        zIndex: 10
                    }}
                >
                    <Typography 
                        variant="body2" 
                        color="text.secondary" 
                        sx={{ 
                            flex: 1, 
                            px: 1,
                            fontSize: { xs: '0.75rem', sm: '0.8rem' },
                            textAlign: { xs: 'center', sm: 'left' }
                        }}
                    >
                        {components[activeIndex].description}
                    </Typography>
            
                    {/* Navigation dots - enhanced for mobile */}
                    <Box sx={{ 
                        display: 'flex', 
                        gap: 0.8,
                        justifyContent: 'center',
                        width: { xs: '100%', sm: 'auto' },
                        py: { xs: 1, sm: 0 }
                    }}>
                        {components.map((_, index) => (
                            <Box
                                key={index}
                                onClick={() => {
                                    if (!isAnimating) {
                                        const direction = index > activeIndex ? 'next' : 'prev';
                                        setAnimationDirection(direction);
                                        setIsAnimating(true);
                                        setTimeout(() => {
                                            setActiveIndex(index);
                                            setIsAnimating(false);
                                        }, 300);
                                    }
                                }}
                                sx={{
                                    width: { xs: 10, sm: 8 },
                                    height: { xs: 10, sm: 8 },
                                    borderRadius: '50%',
                                    backgroundColor: index === activeIndex ? '#6366f1' : '#e2e8f0',
                                    transition: 'background-color 0.2s ease',
                                    cursor: 'pointer'
                                }}
                            />
                        ))}
                    </Box>
                </Box>
            </Box>
      
            {/* Navigation buttons - hidden on mobile, visible on larger screens */}
            <IconButton
                onClick={prevComponent}
                sx={{
                    position: 'absolute',
                    left: { xs: 0, sm: -20 },
                    top: '50%',
                    transform: 'translateY(-50%)',
                    backgroundColor: 'white',
                    boxShadow: '0 2px 10px rgba(0, 0, 0, 0.1)',
                    '&:hover': {
                        backgroundColor: '#f8fafc'
                    },
                    zIndex: 10,
                    width: { xs: 32, sm: 40 },
                    height: { xs: 32, sm: 40 },
                    display: { xs: 'none', sm: 'flex' } // Hide on mobile, show on sm and up
                }}
            >
                <ChevronLeft size={18} />
            </IconButton>
      
            <IconButton
                onClick={nextComponent}
                sx={{
                    position: 'absolute',
                    right: { xs: 0, sm: -20 },
                    top: '50%',
                    transform: 'translateY(-50%)',
                    backgroundColor: 'white',
                    boxShadow: '0 2px 10px rgba(0, 0, 0, 0.1)',
                    '&:hover': {
                        backgroundColor: '#f8fafc'
                    },
                    zIndex: 10,
                    width: { xs: 32, sm: 40 },
                    height: { xs: 32, sm: 40 },
                    display: { xs: 'none', sm: 'flex' } // Hide on mobile, show on sm and up
                }}
            >
                <ChevronRight size={18} />
            </IconButton>
        </Box>
    );
};

// Component to render a specific workflow stage with dynamic behaviors
const WorkflowStageComponent = ({ stage }) => {
    // Animation states
    const [dataSourceActive, setDataSourceActive] = useState(false);
    const [kbStage, setKbStage] = useState(0);
    const [workflowStage, setWorkflowStage] = useState(0);
    const [triggerStage, setTriggerStage] = useState(0);
    const [chatStage, setChatStage] = useState(0);
    const [messages, setMessages] = useState([]);
    
    // Run animation sequence when component mounts
    useEffect(() => {
        // Reset all states
        setDataSourceActive(false);
        setKbStage(0);
        setWorkflowStage(0);
        setTriggerStage(0);
        setChatStage(0);
        setMessages([]);
        
        // Define animation timeline based on current stage
        const runAnimation = () => {
            if (stage === 'source') {
                setTimeout(() => setDataSourceActive(true), 500);
            }
            else if (stage === 'kb') {
                setTimeout(() => setKbStage(1), 500);
                setTimeout(() => setKbStage(2), 1500);
                setTimeout(() => setKbStage(3), 2500);
            }
            else if (stage === 'workflow') {
                setTimeout(() => setWorkflowStage(1), 500);
                setTimeout(() => setWorkflowStage(2), 1200);
                setTimeout(() => setWorkflowStage(3), 1900);
                setTimeout(() => setWorkflowStage(4), 2600);
            }
            else if (stage === 'trigger') {
                setTimeout(() => setTriggerStage(1), 500);
                setTimeout(() => setTriggerStage(2), 1500);
            }
            else if (stage === 'chat') {
                setTimeout(() => setChatStage(1), 500);
                setTimeout(() => {
                    setChatStage(2);
                    setMessages([{ type: 'user', content: 'I notice new data has been processed' }]);
                }, 1500);
                setTimeout(() => setChatStage(3), 2500);
                setTimeout(() => {
                    setChatStage(4);
                    setMessages(prev => [
                        ...prev,
                        { type: 'assistant', content: 'Yes, the workflow has processed new data from your knowledge base. Would you like me to analyze it for insights?' }
                    ]);
                }, 3500);
            }
        };
        
        // Start the animation with a small delay
        const timer = setTimeout(runAnimation, 300);
        
        return () => clearTimeout(timer);
    }, [stage]);
    
    // Connection Point component
    const ConnectionPoint = ({ side }) => (
        <Box
            sx={{
                position: 'absolute',
                [side]: '-6px',
                top: '50%',
                transform: 'translateY(-50%)',
                width: 12,
                height: 12,
                borderRadius: '50%',
                backgroundColor: '#6366f1',
                border: '2px solid white',
                boxShadow: '0 0 6px rgba(99, 102, 241, 0.5)',
                zIndex: 10,
                '&::after': {
                    content: '""',
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    width: 6,
                    height: 6,
                    borderRadius: '50%',
                    backgroundColor: 'white',
                },
                animation: 'pulse 2s infinite ease-in-out'
            }}
        />
    );
    
    // Define common card styles - updated for responsive sizing with fixed height
    const cardStyle = {
        width: { xs: '100%', sm: '90%', md: '20rem' },
        maxWidth: { xs: '280px', sm: '320px', md: '340px' },
        height: { xs: '400px', sm: '460px' },
        display: 'flex',
        flexDirection: 'column',
        boxShadow: '0 8px 16px rgba(0, 0, 0, 0.05)',
        borderRadius: '0.5rem',
        overflow: 'hidden',
        border: '1px solid rgba(226, 232, 240, 0.7)',
        mx: 'auto',
        position: 'relative',
        transition: 'transform 0.2s ease, box-shadow 0.2s ease',
        '&:hover': {
            transform: { xs: 'none', md: 'translateY(-3px)' },
            boxShadow: '0 12px 24px rgba(0, 0, 0, 0.08)'
        }
    };
    
    // Define header style for the colored top bar
    const headerStyle = {
        height: '0.375rem',
        width: '100%'
    };
    
    // Data Sources Component
    if (stage === 'source') {
        return (
            <Box sx={cardStyle}>
                {/* Connection points */}
                <ConnectionPoint side="left" />
                <ConnectionPoint side="right" />
                
                <Box sx={{ ...headerStyle, background: 'linear-gradient(90deg, #4285F4, #0F9D58)' }} />
                <Box sx={{ 
                    p: { xs: 2, sm: 3 },
                    display: 'flex',
                    flexDirection: 'column',
                    height: '100%',
                    overflow: 'hidden'
                }}>
                    <Typography variant="subtitle2" fontWeight={600} mb={2} fontSize={{ xs: '0.9rem', sm: '1rem' }}>
                        Data Sources
                    </Typography>
                    
                    <Box sx={{ 
                        display: 'flex', 
                        flexDirection: 'column', 
                        gap: 2,
                        flex: 1,
                        overflowY: 'auto'
                    }}>
                        {[
                            { name: 'Drive', color: '#4285F4', icon: <FileText size={20} /> },
                            { name: 'Sheets', color: '#0F9D58', icon: <Table size={20} /> },
                            { name: 'MySQL', color: '#0077B5', icon: <Database size={20} /> }
                        ].map((source, idx) => (
                            <Box 
                                key={idx}
                                sx={{ 
                                    p: { xs: 1.5, sm: 2 }, 
                                    borderRadius: '0.5rem',
                                    border: '1px solid rgba(226, 232, 240, 0.7)',
                                    display: 'flex',
                                    alignItems: 'center',
                                    gap: { xs: 1, sm: 2 },
                                    transition: 'all 0.3s ease',
                                    transform: dataSourceActive ? 'translateX(0)' : 'translateX(-10px)',
                                    opacity: dataSourceActive ? 1 : 0.7,
                                    animationDelay: `${idx * 0.2}s`
                                }}
                            >
                                <Box 
                                    sx={{ 
                                        width: { xs: 36, sm: 40 }, 
                                        height: { xs: 36, sm: 40 }, 
                                        borderRadius: '0.5rem',
                                        backgroundColor: `${source.color}15`,
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                        transition: 'all 0.3s ease'
                                    }}
                                >
                                    {React.cloneElement(source.icon, { 
                                        color: source.color,
                                        size: { xs: 16, sm: 20 }
                                    })}
                                </Box>
                                <Box>
                                    <Typography variant="body2" fontWeight={500} fontSize={{ xs: '0.8rem', sm: '0.9rem' }}>
                                        {source.name}
                                    </Typography>
                                    <Box sx={{ display: 'flex', alignItems: 'center', gap: 0.5, mt: 0.5 }}>
                                        <Box 
                                            sx={{ 
                                                width: 6, 
                                                height: 6, 
                                                borderRadius: '50%',
                                                backgroundColor: dataSourceActive ? '#10b981' : '#94a3b8',
                                                transition: 'background-color 0.5s ease'
                                            }} 
                                        />
                                        <Typography variant="caption" color="text.secondary" fontSize={{ xs: '0.7rem', sm: '0.75rem' }}>
                                            {dataSourceActive ? 'Connected' : 'Standby'}
                                        </Typography>
                                    </Box>
                                </Box>
                            </Box>
                        ))}
                    </Box>
                </Box>
            </Box>
        );
    }
    
    // Knowledge Base Component
    if (stage === 'kb') {
        return (
            <Box sx={cardStyle}>
                {/* Connection points */}
                <ConnectionPoint side="left" />
                <ConnectionPoint side="right" />
                
                <Box sx={{ ...headerStyle, background: 'linear-gradient(90deg, #3b82f6, #14b8a6)' }} />
                <Box sx={{ 
                    p: { xs: 2, sm: 3 },
                    display: 'flex',
                    flexDirection: 'column',
                    height: '100%',
                    overflow: 'hidden'
                }}>
                    <Typography variant="subtitle2" fontWeight={600} mb={2} fontSize={{ xs: '0.9rem', sm: '1rem' }}>
                        Knowledge Base
                    </Typography>
                    
                    {/* KB visualization */}
                    <Box sx={{ display: 'flex', justifyContent: 'center', mb: 2 }}>
                        <Box 
                            sx={{ 
                                width: { xs: 80, sm: 100 }, 
                                height: { xs: 80, sm: 100 }, 
                                borderRadius: '50%',
                                border: '4px solid #f1f5f9',
                                position: 'relative',
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                overflow: 'hidden'
                            }}
                        >
                            {/* Water fill */}
                            <Box 
                                sx={{ 
                                    position: 'absolute',
                                    bottom: 0,
                                    left: 0,
                                    width: '100%',
                                    height: `${(kbStage / 3) * 100}%`,
                                    background: 'linear-gradient(to top, #3b82f6, #60a5fa)',
                                    opacity: 0.7,
                                    transition: 'height 0.8s ease-out'
                                }} 
                            />
                            
                            {/* Wave effect */}
                            <Box 
                                sx={{ 
                                    position: 'absolute',
                                    bottom: `calc(${(kbStage / 3) * 100}% - 3px)`,
                                    width: '200%',
                                    height: '6px',
                                    background: 'linear-gradient(90deg, rgba(255,255,255,0) 0%, rgba(255,255,255,0.5) 50%, rgba(255,255,255,0) 100%)',
                                    opacity: kbStage > 0 ? 0.7 : 0,
                                    animation: 'waveMotion 2s infinite ease-in-out',
                                    display: kbStage > 0 ? 'block' : 'none'
                                }}
                            />
                            
                            {/* Database icon */}
                            <Database 
                                size={22} 
                                color={kbStage > 0 ? '#3b82f6' : '#94a3b8'} 
                                style={{ 
                                    position: 'relative', 
                                    zIndex: 1, 
                                    transition: 'transform 0.5s ease, color 0.8s ease-out',
                                    transform: `scale(${1 + (kbStage / 10)})`
                                }} 
                            />
                        </Box>
                    </Box>
                    
                    {/* Status indicators */}
                    <Grid container spacing={2}>
                        <Grid item xs={6}>
                            <Typography variant="caption" color="text.secondary" fontSize={{ xs: '0.7rem', sm: '0.75rem' }}>Status</Typography>
                            <Box sx={{ display: 'flex', alignItems: 'center', gap: 0.5, mt: 0.5 }}>
                                <Box 
                                    sx={{ 
                                        width: 6, 
                                        height: 6, 
                                        borderRadius: '50%',
                                        backgroundColor: kbStage > 0 ? '#10b981' : '#94a3b8',
                                        transition: 'background-color 0.5s ease'
                                    }} 
                                />
                                <Typography variant="body2" fontSize={{ xs: '0.8rem', sm: '0.85rem' }}>
                                    {kbStage === 0 ? 'Standby' : 'Active'}
                                </Typography>
                            </Box>
                        </Grid>
                        <Grid item xs={6}>
                            <Typography variant="caption" color="text.secondary" fontSize={{ xs: '0.7rem', sm: '0.75rem' }}>Entities</Typography>
                            <Typography variant="body2" fontSize={{ xs: '0.8rem', sm: '0.85rem' }}>
                                {kbStage === 0 ? '0' : 
                                kbStage === 1 ? '450' : 
                                kbStage === 2 ? '950' : '1,450'}
                            </Typography>
                        </Grid>
                        <Grid item xs={6}>
                            <Typography variant="caption" color="text.secondary" fontSize={{ xs: '0.7rem', sm: '0.75rem' }}>Processed</Typography>
                            <Typography variant="body2" fontSize={{ xs: '0.8rem', sm: '0.85rem' }}>
                                {kbStage === 0 ? '0' : 
                                kbStage === 1 ? '105 MB' : 
                                kbStage === 2 ? '287 MB' : '420 MB'}
                            </Typography>
                        </Grid>
                        <Grid item xs={6}>
                            <Typography variant="caption" color="text.secondary" fontSize={{ xs: '0.7rem', sm: '0.75rem' }}>Last Updated</Typography>
                            <Typography variant="body2" fontSize={{ xs: '0.8rem', sm: '0.85rem' }}>
                                {kbStage === 0 ? '—' : 'Just now'}
                            </Typography>
                        </Grid>
                    </Grid>
                </Box>
            </Box>
        );
    }
    
    // Workflow Component
    if (stage === 'workflow') {
        return (
            <Box sx={cardStyle}>
                {/* Connection points */}
                <ConnectionPoint side="left" />
                <ConnectionPoint side="right" />
                
                <Box sx={{ ...headerStyle, background: 'linear-gradient(90deg, #8b5cf6, #d946ef)' }} />
                <Box sx={{ 
                    p: { xs: 2, sm: 3 },
                    display: 'flex',
                    flexDirection: 'column',
                    height: '100%',
                    overflow: 'hidden'
                }}>
                    <Typography variant="subtitle2" fontWeight={600} mb={2} fontSize={{ xs: '0.9rem', sm: '1rem' }}>
                        Workflow Processor
                    </Typography>
                    
                    {/* Task list - with fixed height and scrollable if needed */}
                    <Box sx={{ 
                        mb: 2,
                        flex: 1,
                        overflowY: 'auto',
                        pr: 1
                    }}>
                        {["Initialize processing", "Analyze data structure", "Apply transformation rules", "Generate output"].map((task, idx) => (
                            <Box 
                                key={idx} 
                                sx={{ 
                                    display: 'flex', 
                                    alignItems: 'center', 
                                    mb: 1.5,
                                    opacity: idx + 1 <= workflowStage ? 1 : 0.7,
                                    transition: 'opacity 0.3s ease'
                                }}
                            >
                                <Box 
                                    sx={{ 
                                        width: { xs: 20, sm: 24 }, 
                                        height: { xs: 20, sm: 24 }, 
                                        borderRadius: '50%',
                                        backgroundColor: idx + 1 <= workflowStage ? '#ddd6fe' : '#f1f5f9',
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                        mr: 1,
                                        transition: 'background-color 0.3s ease',
                                        flexShrink: 0
                                    }}
                                >
                                    {idx + 1 <= workflowStage ? (
                                        <Check size={14} color="#8b5cf6" />
                                    ) : (
                                        <Typography variant="caption" color="text.secondary">
                                            {idx + 1}
                                        </Typography>
                                    )}
                                </Box>
                                <Typography 
                                    variant="body2" 
                                    fontSize={{ xs: '0.8rem', sm: '0.85rem' }}
                                    color={idx + 1 <= workflowStage ? 'text.primary' : 'text.secondary'}
                                >
                                    {task}
                                </Typography>
                            </Box>
                        ))}
                    </Box>
                    
                    {/* Status */}
                    <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 2 }}>
                        <Typography variant="caption" color="text.secondary" fontSize={{ xs: '0.7rem', sm: '0.75rem' }}>Status</Typography>
                        <Typography 
                            variant="body2" 
                            fontWeight={500}
                            fontSize={{ xs: '0.8rem', sm: '0.85rem' }}
                            sx={{ 
                                color: workflowStage > 0 ? '#8b5cf6' : '#94a3b8',
                                transition: 'color 0.3s ease'
                            }}
                        >
                            {workflowStage === 0 ? 'Waiting for data' : 
                            workflowStage < 4 ? 'Processing...' :
                            'Complete'}
                        </Typography>
                    </Box>
                    
                    {/* Additional stats */}
                    <Grid container spacing={1} sx={{ pt: 1, borderTop: '1px solid #f1f5f9' }}>
                        <Grid item xs={6}>
                            <Typography variant="caption" color="text.secondary" fontSize={{ xs: '0.7rem', sm: '0.75rem' }}>Tasks</Typography>
                            <Typography variant="body2" fontSize={{ xs: '0.8rem', sm: '0.85rem' }}>
                                {Math.min(workflowStage, 4)}/4 completed
                            </Typography>
                        </Grid>
                        <Grid item xs={6}>
                            <Typography variant="caption" color="text.secondary" fontSize={{ xs: '0.7rem', sm: '0.75rem' }}>CPU Usage</Typography>
                            <Typography variant="body2" fontSize={{ xs: '0.8rem', sm: '0.85rem' }}>
                                {workflowStage === 0 ? '5%' : `${Math.min(60 + workflowStage * 10, 90)}%`}
                            </Typography>
                        </Grid>
                    </Grid>
                </Box>
            </Box>
        );
    }
    
    // Trigger Component
    if (stage === 'trigger') {
        return (
            <Box sx={cardStyle}>
                {/* Connection points */}
                <ConnectionPoint side="left" />
                <ConnectionPoint side="right" />
                
                <Box sx={{ ...headerStyle, background: 'linear-gradient(90deg, #f59e0b, #f43f5e)' }} />
                <Box sx={{ 
                    p: { xs: 2, sm: 3 },
                    display: 'flex',
                    flexDirection: 'column',
                    height: '100%',
                    overflow: 'hidden'
                }}>
                    <Typography variant="subtitle2" fontWeight={600} mb={2} fontSize={{ xs: '0.9rem', sm: '1rem' }}>
                        Trigger
                    </Typography>
                    
                    {/* Trigger visualization */}
                    <Box sx={{ display: 'flex', justifyContent: 'center', mb: 2 }}>
                        <Box sx={{ position: 'relative', width: { xs: 60, sm: 80 }, height: { xs: 60, sm: 80 } }}>
                            <Box 
                                sx={{ 
                                    position: 'absolute',
                                    top: '50%',
                                    left: '50%',
                                    transform: 'translate(-50%, -50%)',
                                    width: { xs: 50, sm: 64 }, 
                                    height: { xs: 50, sm: 64 }, 
                                    borderRadius: '50%',
                                    border: '3px solid',
                                    borderColor: triggerStage === 0 ? '#f1f5f9' : 
                                            triggerStage === 1 ? '#fef3c7' : 
                                            '#fdba74',
                                    backgroundColor: triggerStage === 0 ? 'white' : 
                                            triggerStage === 1 ? '#fef3c7' : 
                                            '#f59e0b',
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    boxShadow: triggerStage === 0 ? 'none' : 
                                            triggerStage === 1 ? '0 0 20px rgba(245, 158, 11, 0.3)' : 
                                            '0 0 30px rgba(245, 158, 11, 0.5)',
                                    transition: 'all 0.5s ease-out'
                                }}
                            >
                                <Zap 
                                    size={24} 
                                    color={triggerStage === 0 ? '#94a3b8' : 
                                        triggerStage === 1 ? '#f59e0b' : 
                                        'white'} 
                                />
                            </Box>
                            
                            {/* Fixed ripple effect for triggered state */}
                            {triggerStage === 2 && (
                                <Box 
                                    sx={{ 
                                        position: 'absolute',
                                        top: '50%',
                                        left: '50%',
                                        width: { xs: 50, sm: 64 }, 
                                        height: { xs: 50, sm: 64 },
                                        borderRadius: '50%',
                                        border: '2px solid #f59e0b',
                                        animation: 'ripple 1.5s infinite ease-out',
                                        opacity: 0
                                    }} 
                                />
                            )}
                        </Box>
                    </Box>
                    
                    {/* Status information */}
                    <Box sx={{ textAlign: 'center', mb: 3 }}>
                        <Typography variant="caption" color="text.secondary" fontSize={{ xs: '0.7rem', sm: '0.75rem' }}>Trigger Status</Typography>
                        <Typography 
                            variant="body2" 
                            fontWeight={500}
                            fontSize={{ xs: '0.8rem', sm: '0.85rem' }}
                            sx={{ 
                                color: triggerStage === 0 ? '#94a3b8' : 
                                    triggerStage === 1 ? '#f59e0b' : 
                                    '#ef4444',
                                transition: 'color 0.4s ease-out'
                            }}
                        >
                            {triggerStage === 0 ? 'Waiting for condition' : 
                            triggerStage === 1 ? 'Condition detected' : 
                            'Triggered!'}
                        </Typography>
                    </Box>
                    
                    {/* Trigger conditions - with flex to allow stretching */}
                    <Box 
                        sx={{ 
                            backgroundColor: '#f8fafc',
                            borderRadius: '0.5rem',
                            p: { xs: 1.5, sm: 2 },
                            mb: 2,
                            border: '1px solid #f1f5f9',
                            flex: 1,
                            overflowY: 'auto'
                        }}
                    >
                        <Typography variant="caption" color="text.secondary" sx={{ display: 'flex', alignItems: 'center', mb: 1.5 }} fontSize={{ xs: '0.7rem', sm: '0.75rem' }}>
                            <ListChecks size={12} color="#f59e0b" style={{ marginRight: '4px' }} />
                            Conditions
                        </Typography>
                        
                        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1.5 }}>
                            <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                <Box 
                                    sx={{ 
                                        width: 6, 
                                        height: 6, 
                                        borderRadius: '50%',
                                        backgroundColor: triggerStage >= 1 ? '#f59e0b' : '#94a3b8',
                                        mr: 1.5,
                                        transition: 'background-color 0.5s ease'
                                    }} 
                                />
                                <Typography variant="body2" fontSize={{ xs: '0.8rem', sm: '0.85rem' }}>When entities &gt; 1000</Typography>
                            </Box>
                            <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                <Box 
                                    sx={{ 
                                        width: 6, 
                                        height: 6, 
                                        borderRadius: '50%',
                                        backgroundColor: triggerStage >= 1 ? '#f59e0b' : '#94a3b8',
                                        mr: 1.5,
                                        transition: 'background-color 0.5s ease'
                                    }} 
                                />
                                <Typography variant="body2" fontSize={{ xs: '0.8rem', sm: '0.85rem' }}>Processing complete</Typography>
                            </Box>
                        </Box>
                    </Box>
                    
                    {/* Additional stats */}
                    <Grid container spacing={2}>
                        <Grid item xs={6}>
                            <Typography variant="caption" color="text.secondary" fontSize={{ xs: '0.7rem', sm: '0.75rem' }}>Count</Typography>
                            <Typography variant="body2" fontSize={{ xs: '0.8rem', sm: '0.85rem' }}>{Math.floor(triggerStage / 3)}</Typography>
                        </Grid>
                        <Grid item xs={6}>
                            <Typography variant="caption" color="text.secondary" fontSize={{ xs: '0.7rem', sm: '0.75rem' }}>Action</Typography>
                            <Typography variant="body2" fontSize={{ xs: '0.8rem', sm: '0.85rem' }}>Chat Notification</Typography>
                        </Grid>
                    </Grid>
                </Box>
            </Box>
        );
    }
    
    // Chat Interface Component
    if (stage === 'chat') {
        return (
            <Box sx={cardStyle}>
                {/* Connection points */}
                <ConnectionPoint side="left" />
                <ConnectionPoint side="right" />
                
                <Box sx={{ ...headerStyle, background: 'linear-gradient(90deg, #10b981, #0ea5e9)' }} />
                <Box sx={{ 
                    p: { xs: 2, sm: 3 },
                    display: 'flex',
                    flexDirection: 'column',
                    height: '100%'
                }}>
                    <Typography variant="subtitle2" fontWeight={600} mb={2} fontSize={{ xs: '0.9rem', sm: '1rem' }}>
                        Chat Interface
                    </Typography>
                    
                    {/* Chat header */}
                    <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 2 }}>
                        <Box sx={{ display: 'flex', alignItems: 'center' }}>
                            <Box 
                                sx={{ 
                                    width: 6, 
                                    height: 6, 
                                    borderRadius: '50%',
                                    backgroundColor: chatStage > 0 ? '#10b981' : '#94a3b8',
                                    mr: 1,
                                    transition: 'background-color 0.4s ease-out'
                                }} 
                            />
                            <Typography variant="caption" fontSize={{ xs: '0.7rem', sm: '0.75rem' }}>AI Assistant</Typography>
                        </Box>
                        <Typography variant="caption" color="text.secondary" fontSize={{ xs: '0.7rem', sm: '0.75rem' }}>
                            {chatStage > 0 ? 'Online' : 'Standby'}
                        </Typography>
                    </Box>
                    
                    {/* Chat content area - with flex-grow to use available space */}
                    <Box 
                        sx={{ 
                            backgroundColor: '#f8fafc',
                            borderRadius: '0.5rem',
                            p: { xs: 1.5, sm: 2 },
                            mb: 2,
                            border: '1px solid #f1f5f9',
                            flex: 1,
                            overflowY: 'auto'
                        }}
                    >
                        {/* User message */}
                        {messages.map((msg, index) => (
                            <Box 
                                key={index}
                                sx={{ 
                                    backgroundColor: msg.type === 'user' ? '#e0f2fe' : '#dcfce7',
                                    p: 1.5,
                                    borderRadius: '0.5rem',
                                    borderTopRightRadius: msg.type === 'user' ? 0 : '0.5rem',
                                    borderTopLeftRadius: msg.type === 'assistant' ? 0 : '0.5rem',
                                    maxWidth: '80%',
                                    ml: msg.type === 'user' ? 'auto' : '0',
                                    mr: msg.type === 'assistant' ? 'auto' : '0',
                                    mb: 1.5,
                                    display: 'flex'
                                }}
                            >
                                {msg.type === 'assistant' && (
                                    <Box 
                                        sx={{ 
                                            width: 16, 
                                            height: 16, 
                                            borderRadius: '50%',
                                            backgroundColor: '#10b981',
                                            display: 'flex',
                                            alignItems: 'center',
                                            justifyContent: 'center',
                                            mr: 1
                                        }}
                                    >
                                        <Bot size={10} color="white" />
                                    </Box>
                                )}
                                
                                <Typography variant="caption" fontSize={{ xs: '0.7rem', sm: '0.75rem' }}>
                                    {msg.content}
                                </Typography>
                                
                                {msg.type === 'user' && (
                                    <Box 
                                        sx={{ 
                                            width: 16, 
                                            height: 16, 
                                            borderRadius: '50%',
                                            backgroundColor: '#3b82f6',
                                            display: 'flex',
                                            alignItems: 'center',
                                            justifyContent: 'center',
                                            ml: 1
                                        }}
                                    >
                                        <User size={10} color="white" />
                                    </Box>
                                )}
                            </Box>
                        ))}
                        
                        {/* Typing indicator */}
                        {chatStage === 3 && (
                            <Box 
                                sx={{ 
                                    backgroundColor: '#dcfce7',
                                    p: 1.5,
                                    borderRadius: '0.5rem',
                                    borderTopLeftRadius: 0,
                                    maxWidth: '80%',
                                    mr: 'auto',
                                    mb: 1.5,
                                    display: 'flex',
                                    alignItems: 'center'
                                }}
                            >
                                <Box 
                                    sx={{ 
                                        width: 16, 
                                        height: 16, 
                                        borderRadius: '50%',
                                        backgroundColor: '#10b981',
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                        mr: 1
                                    }}
                                >
                                    <Bot size={10} color="white" />
                                </Box>
                                
                                <Box sx={{ display: 'flex', gap: 0.5, alignItems: 'center' }}>
                                    <Box 
                                        sx={{ 
                                            width: 4, 
                                            height: 4, 
                                            borderRadius: '50%',
                                            backgroundColor: '#10b981',
                                            animation: 'typingDot1 0.8s infinite',
                                        }} 
                                    />
                                    <Box 
                                        sx={{ 
                                            width: 4, 
                                            height: 4, 
                                            borderRadius: '50%',
                                            backgroundColor: '#10b981',
                                            animation: 'typingDot2 0.8s infinite',
                                            animationDelay: '0.2s'
                                        }} 
                                    />
                                    <Box 
                                        sx={{ 
                                            width: 4, 
                                            height: 4, 
                                            borderRadius: '50%',
                                            backgroundColor: '#10b981',
                                            animation: 'typingDot3 0.8s infinite',
                                            animationDelay: '0.4s'
                                        }} 
                                    />
                                </Box>
                            </Box>
                        )}
                    </Box>
                    
                    {/* Chat input */}
                    <Box sx={{ display: 'flex', gap: 1 }}>
                        <input
                            type="text"
                            placeholder="Type message..."
                            style={{ 
                                flex: 1,
                                border: '1px solid #e2e8f0',
                                borderRadius: '0.5rem',
                                padding: '8px 12px',
                                fontSize: '12px',
                                backgroundColor: chatStage > 0 ? 'white' : '#f8fafc'
                            }}
                            disabled={chatStage === 0}
                        />
                        <Button
                            sx={{
                                minWidth: 'auto',
                                width: 32,
                                height: 32,
                                borderRadius: '50%',
                                p: 0,
                                backgroundColor: chatStage > 0 ? '#10b981' : '#f1f5f9',
                                '&:hover': {
                                    backgroundColor: chatStage > 0 ? '#0d9488' : '#f1f5f9'
                                },
                                transition: 'background-color 0.4s ease-out'
                            }}
                            disabled={chatStage === 0}
                        >
                            <Send size={16} color={chatStage > 0 ? 'white' : '#94a3b8'} />
                        </Button>
                    </Box>
                </Box>
            </Box>
        );
    }
    
    // Default fallback
    return (
        <Typography variant="body1">Component not found</Typography>
    );
};

export default CarouselStudioWorkflowSection