import FeaturedPlayListOutlinedIcon from "@mui/icons-material/FeaturedPlayListOutlined";
import SearchIcon from "@mui/icons-material/Search";
import SettingsIcon from "@mui/icons-material/Settings";
import {
  Box,
  Card,
  CardContent,
  CardMedia,
  Collapse,
  Fab,
  Grid,
  IconButton,
  Stack,
  Tab,
  Tabs,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import React, { useEffect, useState } from "react";
import { Cards, MarqueeContainer, MarqueeRow } from "../../AI/AIWidgets";

import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { FeaturedTabPanel } from "./AITools/Tabs/FeaturedTabPanel";
import { OperationsTabPanel } from "./AITools/Tabs/OperationsTabPanel";
import { ResearchTabPanel } from "./AITools/Tabs/ResearchTabPanel";

import { KeyboardArrowUp } from "@mui/icons-material";

export const TopicContainer = ({ children, ...props }) => {
  const theme = useTheme();

  const { topicText } = props;

  return (
    <Box display="flex" justifyContent="center">
      <Fab
        variant="extended"
        size="large"
        sx={{ backgroundColor: theme.palette.background.default, zIndex: 1 }}
        p={10}
        pointerEvents="none"
      >
        {/* <AutoAwesomeIcon /> */}
        {children}

        <Typography ml={1} className="typography-custom-css">
          {topicText}
        </Typography>
      </Fab>
    </Box>
  );
};

export const ProductsPageHeader = ({ children, ...props }) => {
  const { headerText } = props;

  return (
    <Box display="flex" justifyContent="center">
      <Box>{headerText}</Box>
    </Box>
  );
};

export const ProductsPageSubHeader = ({ children, ...props }) => {
  const { headerText } = props;

  return (
    <Box display="flex" justifyContent="center">
      {headerText}
    </Box>
  );
};

export const FAQsCard = ({
  question,
  answer,
  points,
  openingIcon,
  closingIcon,
  isPhoneOrTab,
}) => {
  const [isOpen, setIsOpen] = React.useState(false);

  return (
    <>
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        onClick={() => setIsOpen(!isOpen)}
      >
        <Typography
          variant={isPhoneOrTab ? "h5" : "h4"}
          fontWeight="bold"
          sx={{ fontFamily: "Roboto, sans-serif" }}
        >
          {question}
        </Typography>
        <IconButton onClick={() => setIsOpen(!isOpen)}>
          {isOpen ? closingIcon : openingIcon}
        </IconButton>
      </Box>
      <Collapse in={isOpen} timeout="auto" unmountOnExit>
        <Typography
          marginTop={2}
          variant="h6"
          className="sub-header"
          lineHeight={2}
          sx={{ fontFamily: "Roboto, sans-serif" }}
        >
          {answer}
          <ul>
            {points.map((point, idx) => (
              <li key={idx}>{point}</li>
            ))}
          </ul>
        </Typography>
      </Collapse>
    </>
  );
};

export const FeatureCard = ({ image, title, description, ...props }) => {
  return (
    <Card
      sx={{
        borderRadius: "24px",
        m: 2,
        //   height: "400px",
        position: "relative",
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        minHeight: "500px", // Set a minimum height for consistency
      }}
    >
      <CardMedia component="img" image={image} alt={title} height="70%" />
      <CardContent sx={{ mt: 5 }}>
        <Box display="flex" flexDirection="column" alignItems="center">
          <Typography
            variant="h5"
            component="h2"
            className="typography-custom-css"
            fontWeight="bold"
          >
            {title}
          </Typography>
          <Box m={2} />
          <Typography className="sub-header, typography-custom-css">
            {description}
          </Typography>
        </Box>
      </CardContent>
    </Card>
  );
};

export const AgentsFeatureContainer = ({ children, ...props }) => {
  return (
    <Grid container spacing={2}>
      <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
        <FeatureCard
          image="/airippler/features/no-coding-required.png"
          title="Automate Collaborative Work"
          description="Empower your team to solve problems and achieve goals together, effortlessly."
        />
      </Grid>

      <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
        <FeatureCard
          image="/airippler/features/templatesToGetStarted.png"
          title="Recruit a Team of Experts"
          description="Leverage our no-code builder to transform your knowledge into specialized agents, tailored to your business needs."
        />
      </Grid>

      <Grid item xs={12} sm={12} md={4}>
        <FeatureCard
          image="/airippler/features/teachYourProcess.png"
          title="Your AI Hub, Your Workflow"
          description="Effortlessly integrate escalation points into any process, ensuring you stay in control where it matters most."
        />
      </Grid>

      <Grid item xs={12} sm={12} md={4}>
        <FeatureCard
          image="/airippler/features/agent-skill.png"
          title="Enable Smooth Agent Feedback"
          description="Enhance the performance of your AI Hub by allowing agents to provide valuable feedback to one another, improving quality and efficiency."
        />
      </Grid>

      <Grid item xs={12} sm={12} md={4}>
        <FeatureCard
          image="/airippler/features/IntegrateWithTechTrack.png"
          title="Your Data Stays Secure"
          description="Rest easy knowing your data is never used to train models, ensuring complete privacy and control."
        />
      </Grid>
    </Grid>
  );
};

export const CustomAIToolsContainer = ({ children, ...props }) => {
  return (
    <Grid container spacing={2}>
      <Grid item xs={12} md={4}>
        <Cards
          image="/airippler/features/yourTechStack.png"
          title="Integrate Your AI with Your Tech Stack"
          description="Connect your AI to your CRM, website, and beyond, unlocking limitless possibilities."
       //   minHeight = "500px"
          customStyle={{
            opacity: 0.5,
            transition: "opacity 0.3s ease-in-out",
            "&:hover": {
              opacity: 1,
            },
          }}
        />
      </Grid>

      <Grid item xs={12} md={4}>
        <Cards
          image="/airippler/features/custom-workflow.jpg"
          title="Build Custom Workflows for Your AI"
          description="Empower your AI to manage tasks—from searching the web to handling calls—streamlining your operations."
          minHeight = "500px"
          customStyle={{
            opacity: 0.5,
            transition: "opacity 0.3s ease-in-out",
            "&:hover": {
              opacity: 1,
            },
          }}
        />
      </Grid>

      <Grid item xs={12} md={4}>
        <Cards
          image="/airippler/features/no-coding-required.png"
          title="Enable Your AI Agent to Act on Your Behalf"
          description="Equip your agent to select tools and perform tasks with accuracy and efficiency."
          minHeight = "500px"
          customStyle={{
            opacity: 0.5,
            transition: "opacity 0.3s ease-in-out",
            "&:hover": {
              opacity: 1,
            },
          }}
        />
      </Grid>
    </Grid>
  );
};

export const AIToolsFeatures = ({ children, ...props }) => {
  return (
    <Grid container spacing={2}>
      <Grid item xs={12} md={6}>
        <Cards
          image="/airippler/features/no-coding-required.png"
          title="Integrate Your AI Agent with Your Tech Stack"
          description="Enable your agent to seamlessly access and utilize tools, from CRM data to website publishing, for optimized performance."
          minHeight="500px"
        />
      </Grid>

      <Grid item xs={12} md={6}>
        <Cards
          image="/airippler/features/templatesToGetStarted.png"
          title="Design Custom Workflows for Your AI Agent"
          description="Automate tasks, from web searches to phone calls, with tailored workflows."
          minHeight="500px"
        />
      </Grid>

      <Grid item xs={12} md={6}>
        <Cards
          image="/airippler/features/templatesToGetStarted.png"
          title="Run Tools in Bulk"
          description="Effortlessly execute custom AI tools on large datasets with a single click. Ideal for data enrichment or summarization."
          minHeight="500px"
        />
      </Grid>

      <Grid item xs={12} md={6}>
        <Cards
          image="/airippler/features/templatesToGetStarted.png"
          title="Select the Ideal LLM for Your Needs"
          description="Seamlessly integrate with all leading LLMs, with automatic updates to keep you ahead of the curve."
          minHeight="500px"
        />
      </Grid>
    </Grid>
  );
};

export const ToolTemplateContainer = () => {
  const [value, setValue] = useState(0);
  const theme = useTheme();

  const issmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const isMiddleScreen = useMediaQuery(theme.breakpoints.between("sm", "md"));

  let variant = "fullWidth";

  if (issmallScreen) {
    variant = "scrollable";
  } else if (isMiddleScreen) {
    variant = "scrollable";
  }

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Card className="tab-container-ai-tools">
      <Box className="tab-container-child-ai-tools">
        <Tabs
          value={value}
          onChange={handleChange}
          aria-label="use case tabs"
          TabIndicatorProps={{ style: { display: "none" } }}
          variant={variant}
          scrollButtons="auto"
        >
          {/* <Tab
            icon={<TrendingUpIcon />}
            iconPosition="start"
            label="Sales"
            className={value === 0 ? "tab-selected" : "tab-default"}
            value={0}
            disableRipple
          /> */}
          <Tab
            icon={<FeaturedPlayListOutlinedIcon />}
            iconPosition="start"
            label="Featured"
            className={value === 0 ? "tab-selected" : "tab-default"}
            value={0}
            disableRipple
          />
          <Tab
            icon={<SearchIcon />}
            iconPosition="start"
            label="Research"
            className={value === 2 ? "tab-selected" : "tab-default"}
            value={2}
            disableRipple
          />
          {/* <Tab
            icon={<Leaderboard />}
            iconPosition="start"
            label="Marketing"
            className={value === 3 ? "tab-selected" : "tab-default"}
            value={3}
            disableRipple
          /> */}
          <Tab
            icon={<SettingsIcon />}
            iconPosition="start"
            label="Operations"
            className={value === 4 ? "tab-selected" : "tab-default"}
            value={4}
            disableRipple
          />

          {/* <Tab
            icon={<SchoolOutlinedIcon />}
            iconPosition="start"
            label="Knowledge"
            className={value === 5 ? "tab-selected" : "tab-default"}
            value={5}
            disableRipple
          /> */}
        </Tabs>
      </Box>
      {/* <SalesTabPanel value={value} /> */}
      <FeaturedTabPanel value={value} />
      <ResearchTabPanel value={value} />
      {/* <MarketingTabPanel value={value} /> */}
      <OperationsTabPanel value={value} />
      {/* <KnowledgeTabPanel value={value} /> */}
    </Card>
  );
};

export const TriggerScreenShots = ({ children, ...props }) => {
  const { headingIcon } = props;

  return (
    <Grid container spacing={5}>
      <Grid item xs={12}></Grid>

      <Grid item xs={12}></Grid>

      <Grid item xs={12}>
        <CardMedia
          component="img"
          image={headingIcon}
          alt="Loading issue"
          width="75%"
        />
      </Grid>
    </Grid>
  );
};

export const TriggerHeaderAndDescriptionWidget = ({ children, ...props }) => {
  const { headingIcon, subHeaderText, opacity, handler } = props;

  return (
    <Grid container sx={{ opacity: opacity || 0.5, cursor: "pointer" }}>
      <Grid item xs={12}>
        <Stack onClick={handler}>
          <CardMedia
            component="img"
            image={headingIcon}
            alt="Loading issue"
            sx={{
              objectFit: "contain",
              width: "25%",
              height: "auto",
            }}
          />
          <Box
          // sx={{ transform: `translateY(${translateYValue || "-50%"})` }}
          >
            <Typography variant="h6" className="sub-header">
              {subHeaderText}
            </Typography>
          </Box>
        </Stack>
      </Grid>
    </Grid>
  );
};

export const TriggerHeaderAndDescriptionWidgetSm = ({ children, ...props }) => {
  const {
    headingIcon,
    translateYValue,
    subHeaderText,
    handler,
    clickIdentifier,
    index,
  } = props;

  return (
    <Grid container sx={{ cursor: "pointer" }}>
      <Grid item xs={12}>
        <Stack>
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
          >
            <CardMedia
              component="img"
              image={headingIcon}
              alt="Loading issue"
              sx={{
                objectFit: "contain",
                width: "25%",
                height: "auto",
              }}
            />
            {clickIdentifier && (
              <>
                <KeyboardArrowUp
                  onClick={() => {
                    handler(index);
                  }}
                />
              </>
            )}

            {!clickIdentifier && (
              <KeyboardArrowDownIcon
                onClick={() => {
                  handler(index);
                }}
              />
            )}
          </Box>

          <Box sx={{ transform: `translateY(${translateYValue || "0%"})` }}>
            <Typography variant="h6" className="sub-header">
              {subHeaderText}
            </Typography>
          </Box>
        </Stack>
      </Grid>
    </Grid>
  );
};

export const TriggerContainer = ({ children, ...props }) => {
  const { isPhoneOrTab } = props;

  const [opacities, setOpacities] = useState([0.5, 0.5, 0.5, 0.5, 0.5, 0.5]);
  const [visibleItems, setVisibleItems] = useState([
    true,
    false,
    false,
    false,
    false,
    false,
  ]);

  // This snippet is added for small screen view
  const [itemClicked, setItemClicked] = useState([
    false,
    false,
    false,
    false,
    false,
    false,
  ]);

  useEffect(() => {
    const newOpacities = visibleItems.map((item, index) => (item ? 1 : 0.5));
    setOpacities(newOpacities);
  }, [visibleItems]);

  const handleItemClick = (index) => {
    const newVisibleItems = visibleItems.map(() => false);
    newVisibleItems[index] = true;
    setVisibleItems(newVisibleItems);
  };

  // This snippet is added for smaller screen view
  const itemClickHandler = (index) => {
    const newVisibleItems = [...itemClicked];
    newVisibleItems[index] = !newVisibleItems[index];
    setItemClicked(newVisibleItems);
  };

  return (
    <>
      {!isPhoneOrTab && (
        <Grid container>
          <Grid item xs={12} sm={12} md={5} lg={4}>
            <TriggerHeaderAndDescriptionWidget
              subHeaderText={
                "Sync your Hubspot CRM to enable automatic AI Agent responses to activities."
              }
              headingIcon={"/airippler/marquee/Hubspot.svg"}
              handler={() => handleItemClick(0)}
              opacity={opacities[0]}
            />

            <TriggerHeaderAndDescriptionWidget
              headingIcon={"/airippler/integration-automation/whatsapp.jpg"}
              subHeaderText={
                "Set up your AI Agent to automatically handle actions from WhatsApp messages."
              }
              handler={() => handleItemClick(1)}
              opacity={opacities[1]}
            />

            <TriggerHeaderAndDescriptionWidget
              subHeaderText={
                "Configure your AI Agent to manage new emails directly from your Gmail inbox."
              }
              headingIcon={"/airippler/integration-automation/gmail.jpg"}
              handler={() => handleItemClick(2)}
              opacity={opacities[2]}
            />

            <TriggerHeaderAndDescriptionWidget
              subHeaderText={
                "Integrate with Zapier to trigger your AI Agent across more than 6000+ apps."
              }
              headingIcon={"/airippler/marquee/Hubspot.svg"}
              handler={() => handleItemClick(3)}
              opacity={opacities[3]}
            />

            <TriggerHeaderAndDescriptionWidget
              subHeaderText={
                "Trigger your AI agent from your own HTTP endpoints."
              }
              headingIcon={"/airippler/integration-automation/webhook.jpg"}
              handler={() => handleItemClick(4)}
              opacity={opacities[4]}
            />

            <TriggerHeaderAndDescriptionWidget
              subHeaderText={
                "For prototyping and deploying AI workers and automations."
              }
              headingIcon={"/airippler/marquee/Async-Api.svg"}
              handler={() => handleItemClick(5)}
              opacity={opacities[5]}
            />
          </Grid>

          <Grid item xs={12} sm={12} md={7} lg={8}>
            {visibleItems[0] && (
              <TriggerScreenShots
                headingIcon={
                  "/airippler/integration-automation/HubspotScreenshot.png"
                }
              />
            )}
            {visibleItems[1] && (
              <TriggerScreenShots
                headingIcon={
                  "/airippler/integration-automation/WhatsappScreenshot.png"
                }
              />
            )}
            {visibleItems[2] && (
              <TriggerScreenShots
                headingIcon={
                  "/airippler/integration-automation/GmailScreenshot.png"
                }
              />
            )}
            {visibleItems[3] && (
              <TriggerScreenShots
                headingIcon={
                  "/airippler/integration-automation/ZapierScreenshot.png"
                }
              />
            )}
            {visibleItems[4] && (
              <TriggerScreenShots
                headingIcon={
                  "/airippler/integration-automation/WebHookScreenshot.png"
                }
              />
            )}
            {visibleItems[5] && (
              <TriggerScreenShots
                headingIcon={
                  "/airippler/integration-automation/APIScreenshot.png"
                }
              />
            )}
          </Grid>
        </Grid>
      )}
      {isPhoneOrTab && (
        <Grid container>
          <Grid item xs={12} sx={{ position: "relative" }}>
            <TriggerHeaderAndDescriptionWidgetSm
              subHeaderText={
                "Sync your Hubspot CRM to enable automatic AI Agent responses to activities."
              }
              headingIcon={"/airippler/marquee/Hubspot.svg"}
              handler={itemClickHandler}
              clickIdentifier={itemClicked[0]}
              index={0}
            />
            {itemClicked[0] && (
              <TriggerScreenShots
                headingIcon={
                  "/airippler/integration-automation/HubspotScreenshot.png"
                }
              />
            )}

            <TriggerHeaderAndDescriptionWidgetSm
              headingIcon={"/airippler/integration-automation/whatsapp.jpg"}
              subHeaderText={
                "Set up your AI Agent to automatically handle actions from WhatsApp messages."
              }
              handler={itemClickHandler}
              clickIdentifier={itemClicked[1]}
              index={1}
            />
            {itemClicked[1] && (
              <TriggerScreenShots
                headingIcon={
                  "/airippler/integration-automation/WhatsappScreenshot.png"
                }
              />
            )}

            <TriggerHeaderAndDescriptionWidgetSm
              subHeaderText={
                "Configure your AI Agent to manage new emails directly from your Gmail inbox."
              }
              headingIcon={"/airippler/integration-automation/gmail.jpg"}
              handler={itemClickHandler}
              clickIdentifier={itemClicked[2]}
              index={2}
            />
            {itemClicked[2] && (
              <TriggerScreenShots
                headingIcon={
                  "/airippler/integration-automation/GmailScreenshot.png"
                }
              />
            )}

            <TriggerHeaderAndDescriptionWidgetSm
              subHeaderText={
                "Integrate with Zapier to trigger your AI Agent across more than 6000+ apps."
              }
              headingIcon={"/airippler/marquee/Hubspot.svg"}
              handler={itemClickHandler}
              clickIdentifier={itemClicked[3]}
              index={3}
            />
            {itemClicked[3] && (
              <TriggerScreenShots
                headingIcon={
                  "/airippler/integration-automation/ZapierScreenshot.png"
                }
              />
            )}

            <TriggerHeaderAndDescriptionWidgetSm
              subHeaderText={
                "Trigger your AI agent from your own HTTP endpoints."
              }
              headingIcon={"/airippler/integration-automation/webhook.jpg"}
              handler={itemClickHandler}
              clickIdentifier={itemClicked[4]}
              index={4}
            />
            {itemClicked[4] && (
              <TriggerScreenShots
                headingIcon={
                  "/airippler/integration-automation/WebHookScreenshot.png"
                }
              />
            )}

            <TriggerHeaderAndDescriptionWidgetSm
              subHeaderText={
                "For prototyping and deploying AI workers and automations."
              }
              headingIcon={"/airippler/marquee/Async-Api.svg"}
              handler={itemClickHandler}
              clickIdentifier={itemClicked[5]}
              index={5}
            />
            {itemClicked[5] && (
              <TriggerScreenShots
                headingIcon={
                  "/airippler/integration-automation/APIScreenshot.png"
                }
              />
            )}
          </Grid>
        </Grid>
      )}
    </>
  );
};

// LLLms widget
export const LLLmsWidget = ({ children, ...props }) => {
  const { isPhoneOrTab } = props;

  const theme = useTheme();

  const isSmallScreen = useMediaQuery(theme.breakpoints.down("md"));
  const isMediumScreen = useMediaQuery(theme.breakpoints.between("sm", "md"));
  const isXtraLargeScreen = useMediaQuery(theme.breakpoints.up("xl"));

  let dynamicRadius = 60; // Radius of the semicircle in percentage
  let leftMarginMagicNumber = 13; // Magic number for left margin calculation
  if (isSmallScreen) {
    dynamicRadius = 60;
  } else if (isMediumScreen) {
    dynamicRadius = 60;
  } else if (isXtraLargeScreen) {
  }

  // if we decide to increase the number of icon images, we need to decrease the  leftMarginMagicNumber
  const images = [
    "/airippler/marquee/Microsoft-Azure.svg",
    "/airippler/marquee/Anthropic.svg",
    "/airippler/marquee/Aws-Lambda.svg",
    "/airippler/marquee/Google-Gemini.svg",
    "/airippler/marquee/Google-Palm.svg",
    "/airippler/marquee/Mistral-Ai.svg",
    "/airippler/marquee/Openai.svg",
    "/airippler/marquee/Meta.svg",
  ];

  const calculateMargins = (index, total) => {
    const radius = dynamicRadius; // Radius of the semicircle in percentage

    // Original semicircle calculation for larger screens
    const angle = (index / (total - 1)) * Math.PI - Math.PI; // Angle for each image (-π to π)
    const topMargin =
      0.6 * radius * (1 - Math.sin(angle) * Math.sin(Math.PI / 4)) - 25; // Calculate top margin in percentage
    // const leftMargin = (radius / 1.25) * 2 * (1 + Math.cos(angle)); // Calculate left margin in percentage
    const leftMargin = index * leftMarginMagicNumber; // Calculate left margin in percentage

    return { topMargin: `${topMargin}%`, leftMargin: `${leftMargin}%` };
  };

  return (
    <Grid container>
      <Grid
        item
        xs={1}
        sx={{
          display: {
            xs: "none",
            sm: "none",
            md: "block",
            lg: "block",
            xl: "block",
          },
        }}
      />

      <Grid
        item
        xs={12}
        lg={10}
        sx={{
          overflow: "hidden",
          position: "relative",
        }}
      >
        {isPhoneOrTab && (
          <Box
            position="relative"
            display="flex"
            justifyContent="center"
            alignItems="center"
            height="auto"
            width="100%"
            flexWrap="wrap"
            gap={2}
          >
            {images.map((src, index) => {
              return (
                <Card
                  sx={{
                    //                    mr: 2,
                    width: "100px", // Adjust the size as needed
                    height: "75px", // Adjust the size as needed
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    // backgroundColor: "#FFFAFA",
                    borderRadius: "10px", // Adjust the radius as needed
                    borderTop: "1px solid #EFEEF3",
                    borderRight: "1px solid #EFEEF3",
                    borderBottom: "5px solid #EFEEF3", // Slightly thicker bottom border
                    borderLeft: "1px solid #EFEEF3",
                    transition: "transform 0.2s, box-shadow 0.2s", // Smooth transition,
                    "&:hover": {
                      transform: "translateY(-4px)", // Lift more on hover
                      boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)", // More pronounced shadow on hover
                    },
                  }}
                  key={index}
                >
                  <CardMedia
                    key={index}
                    component="img"
                    image={src}
                    alt={`Image ${index + 1}`}
                    sx={{
                      width: "70%",
                      height: "70%",
                      objectFit: "contain",
                    }}
                  />
                </Card>
              );
            })}
          </Box>
        )}
        {!isPhoneOrTab && (
          <Box
            position="relative"
            height="40vh"
            width="100%"
            sx={{
              position: "relative",
              overflow: { xs: "hidden", lg: "visible" },
              "&::before": {
                content: '""',
                position: "absolute",
                top: { xs: "0%", lg: "0%" },
                left: { xs: "20px", lg: "0%" },
                transform: {
                  xs: "translate(0%, 0%)",
                  lg: "translate(0%, -52%)",
                  xl: "translate(0%, -52%)",
                },
                width: { lg: "100%" },
                height: { lg: "100%" },
                backgroundImage: {
                  lg: "url(/airippler/integration-llms/line-vector.svg)",
                },
                backgroundSize: { xs: "cover", lg: "cover" },
                backgroundPosition: "center",
                backgroundRepeat: "no-repeat",
                opacity: 0.4,
              },
            }}
          >
            {images.map((src, index) => {
              const { topMargin, leftMargin } = calculateMargins(
                index,
                images.length
              );
              return (
                <Card
                  //                  container
                  key={index}
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    backgroundColor: "#FFFAFA",
                    border: "0.002px solid",
                    position: "absolute",
                    top: `${topMargin}`,
                    left: `${leftMargin}`,
                    width: "90px", // Adjust the size as needed
                    height: "67.5px", // Adjust the size as needed
                    transition: "transform 0.2s, box-shadow 0.2s", // Smooth transition

                    borderRadius: "10px", // Adjust the radius as needed
                    borderTop: "1px solid #EFEEF3",
                    borderRight: "1px solid #EFEEF3",
                    borderBottom: "5px solid #EFEEF3", // Slightly thicker bottom border
                    borderLeft: "1px solid #EFEEF3",

                    "&:hover": {
                      transform: "translateY(-4px)", // Lift more on hover
                      boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)", // More pronounced shadow on hover
                    },
                  }}
                >
                  <CardMedia
                    key={index}
                    component="img"
                    image={src}
                    alt={`Image ${index + 1}`}
                    sx={{
                      width: "70%",
                      height: "70%",
                      objectFit: "contain",
                    }}
                  />
                </Card>
              );
            })}
          </Box>
        )}
      </Grid>

      <Grid
        item
        lg={1}
        sx={{
          display: {
            xs: "none",
            sm: "none",
            lg: "block",
            xl: "block",
          },
        }}
      />
    </Grid>
  );
};

export const MarqueeEffect = ({ children, ...props }) => {
  const iconsSet1 = [
    /* Array of icons for set 1 Need to add according to the need */

    // "/airippler/marquee/Microsoft-Azure.svg",
    "/airippler/sales-tab-icons/gmail-2.svg",
    "/airippler/marquee/Aol.svg",
    "/airippler/marquee/Icloud.svg",
    "/airippler/sales-tab-icons/google-calendar.svg",
    "/airippler/marquee/Kahoot.svg",
    "/airippler/marquee/Google-Drive.svg",
    "/airippler/marquee/Google-Cloud.svg",
    "/airippler/marquee/Git.svg",
    "/airippler/marquee/Github.svg",
    "/airippler/marquee/Builder.svg",
    "/airippler/marquee/Buffer.svg",
    "/airippler/marquee/Brackets.svg",
    "/airippler/sales-tab-icons/google-calendar.svg",
    "/airippler/marquee/Google-Meet.svg",
  ];
  const iconsSet2 = [
    /* Array of icons for set 2 */

    "/airippler/marquee/Microsoft-Azure.svg",
    "/airippler/marquee/Aws.svg",
    "/airippler/marquee/Anthropic.svg",
    "/airippler/marquee/Webflow.svg",
    "/airippler/marquee/Aws-Lambda.svg",
    "/airippler/marquee/Async-Api.svg",
    "/airippler/marquee/Google-Gemini.svg",
    "/airippler/marquee/Google-Palm.svg",
    "/airippler/marquee/Mistral-Ai.svg",
    "/airippler/marquee/Openai.svg",
    "/airippler/marquee/Meta.svg",
    "/airippler/marquee/Zoho.svg",
    "/airippler/marquee/Hubspot.svg",
    "/airippler/marquee/Hubspot.svg",
  ];
  const iconsSet3 = [
    /* Array of icons for set 3 */

    "/airippler/marquee/Google.svg",
    "/airippler/marquee/Whatsapp.svg",
    "/airippler/sales-tab-icons/linkedIn.svg",
    "/airippler/marquee/Async-Api.svg",
    "/airippler/marquee/Meta.svg",
    "/airippler/marquee/Whatsapp.svg",
    "/airippler/marquee/wordpress.svg",
    "/airippler/marquee/Zoom.svg",
    "/airippler/ai-tools/featured/youtube-icon.png",
    "/airippler/marquee/Postman.svg",
    "/airippler/marquee/Salesforce.svg",
    "/airippler/marquee/Jira.svg",
    "/airippler/marquee/Chrome.svg",
    "/airippler/marquee/Figma.svg",
  ];

  return (
    <MarqueeContainer>
      <Grid container spacing={2}>
        <Grid item>
          <Box display="flex" gap={2}>
            <MarqueeRow>
              {iconsSet1.map((src, index) => (
                <Card
                  sx={{
                    //                    mr: 2,
                    width: "100px", // Adjust the size as needed
                    height: "75px", // Adjust the size as needed
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    // backgroundColor: "#FFFAFA",
                    borderRadius: "10px", // Adjust the radius as needed
                    borderTop: "1px solid #EFEEF3",
                    borderRight: "1px solid #EFEEF3",
                    borderBottom: "5px solid #EFEEF3", // Slightly thicker bottom border
                    borderLeft: "1px solid #EFEEF3",
                    transition: "transform 0.2s, box-shadow 0.2s", // Smooth transition,
                    "&:hover": {
                      transform: "translateY(-4px)", // Lift more on hover
                      boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)", // More pronounced shadow on hover
                    },
                  }}
                  key={index}
                >
                  <CardMedia
                    key={index}
                    component="img"
                    image={src}
                    alt={`Image ${index + 1}`}
                    sx={{
                      objectFit: "contain",
                      width: "70%",
                      height: "70%",
                    }}
                  />
                </Card>
              ))}
            </MarqueeRow>

            <MarqueeRow>
              {iconsSet1.map((src, index) => (
                <Card
                  sx={{
                    //                    mr: 2,
                    width: "100px", // Adjust the size as needed
                    height: "75px", // Adjust the size as needed
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    // backgroundColor: "#FFFAFA",
                    boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)", // Adjust the shadow as needed
                    borderRadius: "10px", // Adjust the radius as needed
                    borderTop: "1px solid #EFEEF3",
                    borderRight: "1px solid #EFEEF3",
                    borderBottom: "5px solid #EFEEF3", // Slightly thicker bottom border
                    borderLeft: "1px solid #EFEEF3",
                  }}
                  key={index}
                >
                  <CardMedia
                    key={index}
                    component="img"
                    image={src}
                    alt={`Image ${index + 1}`}
                    sx={{
                      objectFit: "contain",
                      width: "70%",
                      height: "70%",
                    }}
                  />
                </Card>
              ))}
            </MarqueeRow>
          </Box>
        </Grid>

        <Grid item>
          <Box display="flex" gap={2}>
            <MarqueeRow direction="reverse">
              {iconsSet2.map((src, index) => (
                <Card
                  sx={{
                    //                    mr: 2,
                    width: "100px", // Adjust the size as needed
                    height: "75px", // Adjust the size as needed
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    // backgroundColor: "#FFFAFA",
                    boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)", // Adjust the shadow as needed
                    borderRadius: "10px", // Adjust the radius as needed
                    borderTop: "1px solid #EFEEF3",
                    borderRight: "1px solid #EFEEF3",
                    borderBottom: "5px solid #EFEEF3", // Slightly thicker bottom border
                    borderLeft: "1px solid #EFEEF3",
                  }}
                  key={index}
                >
                  <CardMedia
                    key={index}
                    component="img"
                    image={src}
                    alt={`Image ${index + 1}`}
                    sx={{
                      objectFit: "contain",
                      width: "75%",
                      height: "75%",
                    }}
                  />
                </Card>
              ))}
            </MarqueeRow>

            <MarqueeRow direction="reverse">
              {iconsSet2.map((src, index) => (
                <Card
                  sx={{
                    //                    mr: 2,
                    width: "100px", // Adjust the size as needed
                    height: "75px", // Adjust the size as needed
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    // backgroundColor: "#FFFAFA",
                    boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)", // Adjust the shadow as needed
                    borderRadius: "10px", // Adjust the radius as needed
                    borderTop: "1px solid #EFEEF3",
                    borderRight: "1px solid #EFEEF3",
                    borderBottom: "5px solid #EFEEF3", // Slightly thicker bottom border
                    borderLeft: "1px solid #EFEEF3",
                  }}
                  key={index}
                >
                  <CardMedia
                    key={index}
                    component="img"
                    image={src}
                    alt={`Image ${index + 1}`}
                    sx={{
                      objectFit: "contain",
                      width: "75%",
                      height: "75%",
                    }}
                  />
                </Card>
              ))}
            </MarqueeRow>
          </Box>
        </Grid>

        <Grid item>
          <Box display="flex" gap={2}>
            <MarqueeRow>
              {iconsSet3.map((src, index) => (
                <Card
                  sx={{
                    //                    mr: 2,
                    width: "100px", // Adjust the size as needed
                    height: "75px", // Adjust the size as needed
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    // backgroundColor: "#FFFAFA",
                    boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)", // Adjust the shadow as needed
                    borderRadius: "10px", // Adjust the radius as needed
                    borderTop: "1px solid #EFEEF3",
                    borderRight: "1px solid #EFEEF3",
                    borderBottom: "5px solid #EFEEF3", // Slightly thicker bottom border
                    borderLeft: "1px solid #EFEEF3",
                  }}
                  key={index}
                >
                  <CardMedia
                    key={index}
                    component="img"
                    image={src}
                    alt={`Image ${index + 1}`}
                    sx={{
                      objectFit: "contain",
                      width: "75%",
                      height: "75%",
                    }}
                  />
                </Card>
              ))}
            </MarqueeRow>

            <MarqueeRow>
              {iconsSet3.map((src, index) => (
                <Card
                  sx={{
                    //                    mr: 2,
                    width: "100px", // Adjust the size as needed
                    height: "75px", // Adjust the size as needed
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    // backgroundColor: "#FFFAFA",
                    boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)", // Adjust the shadow as needed
                    borderRadius: "10px", // Adjust the radius as needed
                    borderTop: "1px solid #EFEEF3",
                    borderRight: "1px solid #EFEEF3",
                    borderBottom: "5px solid #EFEEF3", // Slightly thicker bottom border
                    borderLeft: "1px solid #EFEEF3",
                  }}
                  key={index}
                >
                  <CardMedia
                    key={index}
                    component="img"
                    image={src}
                    alt={`Image ${index + 1}`}
                    sx={{
                      objectFit: "contain",
                      width: "75%",
                      height: "75%",
                    }}
                  />
                </Card>
              ))}
            </MarqueeRow>
          </Box>
        </Grid>
      </Grid>
    </MarqueeContainer>
  );
};

export const YourCodeContainer = ({ children, ...props }) => {
  return (
    <Grid container spacing={2}>
      <Grid item xs={2}></Grid>

      <Grid item xs={8}>
        <CardMedia
          component="img"
          image={"/airippler/product/api-menu/YourCode.png"}
          alt={`failed to load`}
          sx={{
            objectFit: "contain",
            width: "100%",
            height: "100%",
          }}
        />
      </Grid>

      <Grid item xs={2}></Grid>
    </Grid>
  );
};

export const AIApiKeyFeatures = ({ children, ...props }) => {
  return (
    <Grid container spacing={2}>
      <Grid item xs={12} md={4}>
        <Cards
          image="/airippler/product/api-menu/FullyManagedService.png"
          title="Instantly Preview & Improve Workflows"
          description="Visualize and refine your AI workflows on the fly for better collaboration and results."
          minHeight="450"
        />
      </Grid>

      <Grid item xs={12} md={4}>
        <Cards
          image="/airippler/product/api-menu/HighlyScalable.png"
          title="Switch LLMs with Ease"
          description="Effortlessly switch between language models without the hassle or complexity."
          minHeight="450"
        />
      </Grid>

      <Grid item xs={12} md={4}>
        <Cards
          image="/airippler/product/api-menu/LockIn.png"
          title="Custom APIs to Boost Your Business"
          description="Create tailored APIs to enhance your AI integration and streamline operations."
          minHeight="450"
        />
      </Grid>
    </Grid>
  );
};
