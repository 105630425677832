import { CardContent } from "@mui/material";
import React, { useState } from "react";
import { DropDownButtonWithDescription } from "../../CodingRipplerWidgets";
import { About } from "../About";
import { ContactUs } from "../ContactUs";
import { HomeButton } from "../HomeButton";
import HubIcon from "@mui/icons-material/Hub";
import LanguageIcon from "@mui/icons-material/Language";

export const CRNavigationButtonsDrawer = ({ children, ...props }) => {
  const { customTextColor } = props;

  const [activeDropdown, setActiveDropdown] = useState(null);

  const handleButtonClick = (index) => {
    setActiveDropdown((prevIndex) => (prevIndex === index ? null : index));
  };

  const buttons = [
    {
      name: "Services",
      shortcuts: [
        {
          name: "Web Rippler",
          description:
            "Comprehensive web development services for building modern websites.",
          context: "/web",
          icon: <LanguageIcon />,
        },
        {
          name: "AI Rippler",
          description:
            "AI-powered solutions to optimize business processes and innovation.",
          context: "/ai",
          icon: <HubIcon />,
        },
      ],
    },
  ];

  return (
    <>
      <CardContent sx={{ flexGrow: 1, width: "100%" }}>
        <HomeButton customTextColor={customTextColor} />
      </CardContent>

      {/* <CardContent sx={{ flexGrow: 1, width: "100%" }}>
        <Services customTextColor={customTextColor} />
      </CardContent> */}

      {buttons.map((button, index) => (
        <CardContent sx={{ flexGrow: 1, width: "100%" }} key={index}>
          <DropDownButtonWithDescription
            activeDropdown={activeDropdown}
            handleButtonClick={handleButtonClick}
            buttonName={button.name}
            dropDownMenu={button.shortcuts}
            buttonIndex={index}
          />
        </CardContent>
      ))}

      <CardContent sx={{ flexGrow: 1, width: "100%" }}>
        <About customTextColor={customTextColor} context="/about" />
      </CardContent>

      {/* <CardContent sx={{ flexGrow: 1, width: "100%" }}>
        <CareerButton customTextColor={customTextColor} />
      </CardContent> */}
      <CardContent sx={{ flexGrow: 1, width: "100%", position: "relative" }}>
        <ContactUs customTextColor={customTextColor} />
      </CardContent>
    </>
  );
};
