import {
  Box,
  Container,
  Grid,
  Typography
} from '@mui/material';
import React, { useState } from 'react';
import { SectionHeading, SectionTitle } from '../../CodingRipplerWidgets';
import SolutionCard from './SolutionCard';
import SolutionTabs from './SolutionTabs';


// Solution showcase data
// const solutions = [
//   {
//     id: 1,
//     title: "AI Studio",
//     category: "AI Solutions",
//     description: "Build custom chatbots trained on your business data. Deploy across multiple channels with no coding required.",
//     features: [
//       "Custom knowledge base training",
//       "Multi-channel deployment",
//       "Analytics dashboard",
//       "User behavior insights"
//     ],
//     image: "/codingrippler/landings/item15.jpg",
//     readyToUse: 90,
//     demoAvailable: true,
//     tags: ["Chatbots", "AI", "NLP"]
//   },
//   {
//     id: 2,
//     title: "GPT Workflows",
//     category: "AI Solutions",
//     description: "Automate complex business processes with AI-powered workflows. Connect your existing tools and systems.",
//     features: [
//       "Drag-and-drop workflow builder",
//       "Pre-built integrations",
//       "Natural language processing",
//       "Customizable templates"
//     ],
//     image: "/codingrippler/landings/item13.jpg",
//     readyToUse: 100,
//     demoAvailable: true,
//     tags: ["Automation", "GPT", "Workflows"]
//   },
//   {
//     id: 3,
//     title: "Landing Pages",
//     category: "Web Development",
//     description: "Convert more visitors with our high-performance, conversion-optimized landing page templates for your businesses.",
//     features: [
//       "Mobile-responsive design",
//       "A/B testing ready",
//       "SEO optimized",
//       "Fast loading speeds"
//     ],
//     image: "/codingrippler/landings/item4.jpg",
//     demoAvailable: true,
//     tags: ["Web Design", "SaaS", "Conversion"]
//   },
//   {
//     id: 4,
//     title: "Data Dashboards",
//     category: "Data Visualization",
//     description: "Turn complex data into actionable insights with customizable, real-time dashboards and visualizations.",
//     features: [
//       "Real-time data processing",
//       "Multiple visualization options",
//       "Data source integrations",
//       "Automated reporting"
//     ],
//     image: "/codingrippler/landings/item5.jpg",
//     readyToUse: 85,
//     demoAvailable: true,
//     tags: ["Analytics", "Data Visualization", "Reporting"]
//   },
//   {
//     id: 5,
//     title: "Access Control System",
//     category: "Web Development",
//     description: "Enterprise-grade authentication and authorization system with role-based access control and SSO integration.",
//     features: [
//       "OAuth 2.0 & OpenID Connect",
//       "Role-based permissions",
//       "Audit logging",
//       "SSO integration"
//     ],
//     image: "/codingrippler/landings/item3.jpg",
//     readyToUse: 100,
//     demoAvailable: true,
//     tags: ["Security", "Authentication", "Enterprise"]
//   },
//   {
//     id: 6,
//     title: "Catalyst Bots",
//     category: "AI Solutions",
//     description: "Intelligent agents that can be deployed across your digital properties to engage customers and provide support.",
//     features: [
//       "24/7 customer support",
//       "Personality customization",
//       "Multi-language support",
//       "Knowledge base integration"
//     ],
//     image: "/codingrippler/landings/item1.jpg",
//     readyToUse: 100,
//     demoAvailable: true,
//     tags: ["Customer Support", "AI", "Automation"]
//   }
// ];

const taskPlannerDemoUrl = process.env.REACT_APP_TASK_PLANNER_DEMO_URL || 'https://apps.codingrippler.com/planner';

const solutions = [
  {
    id: 1,
    title: "AI Studio",
    category: "AI Solutions",
    description: "The Unified Platform That Connects, Thinks & Acts—For Your Business Data.",
    features: [
      "Seamlessly Connect Your Data",
      "Custom Knowledge for Decisions",
      "Turn Insights into Actions",
      "Studio Tailored to Your Needs",
    ],
    image: "/codingrippler/landings/item15.jpg",
    readyToUse: 90,
    demoAvailable: true,
    openInNewTab: true, // Opens in a new tab
    demoUrl: "https://ai-studio.codingrippler.com/", // Custom URL for the demo, will make it configuratble
    tags: ["AI", "NLP" , "Automation"]
  },
  {
    id: 2,
    title: "GPT Workflows",
    category: "AI Solutions",
    description: "Automate complex business processes with AI-powered workflows. Connect your existing tools and systems.",
    features: [
      "API & workflow automation",
      "Pre-built integrations",
      "Natural language processing",
      "Customizable templates"
    ],
    image: "/codingrippler/landings/item13.jpg",
    readyToUse: 100,
    demoAvailable: true,
    openInNewTab: true, // Opens in the same tab
    demoUrl: taskPlannerDemoUrl,
    tags: ["Chatbots", "GPT", "Workflows"]
  },
  // {
  //   id: 3,
  //   title: "Landing Pages",
  //   category: "Web Solutions",
  //   description: "Convert more visitors with our high-performance, conversion-optimized landing page templates for your businesses.",
  //   features: [
  //     "Mobile-responsive design",
  //     "A/B testing ready",
  //     "SEO optimized",
  //     "Fast loading speeds"
  //   ],
  //   image: "/codingrippler/landings/item4.jpg",
  //   readyToUse: 100, // Added readyToUse property
  //   demoAvailable: true,
  //   openInNewTab: false,
  //   demoUrl: `/web/portfolio`,
  //   tags: ["Web Design", "SaaS", "Conversion"]
  // },
  // {
  //   id: 4,
  //   title: "Data Dashboards",
  //   category: "Data Visualization",
  //   description: "Turn complex data into actionable insights with customizable, real-time dashboards and visualizations.",
  //   features: [
  //     "Real-time data processing",
  //     "Multiple visualization options",
  //     "Data source integrations",
  //     "Automated reporting"
  //   ],
  //   image: "/codingrippler/landings/item5.jpg",
  //   readyToUse: 100,
  //   demoAvailable: true,
  //   openInNewTab: false,
  //   demoUrl: `/web/dashboards`,
  //   tags: ["Analytics", "Data Visualization", "Reporting"]
  // },
  // {
  //   id: 5,
  //   title: "Access Control System",
  //   category: "Web Solutions",
  //   description: "Enterprise-grade authentication and authorization system with role-based access control and SSO integration.",
  //   features: [
  //     "OAuth 2.0 & JWT authentication",
  //     "Role-based permissions",
  //     "Audit logging",
  //     "SSO integration"
  //   ],
  //   image: "/codingrippler/landings/item3.jpg",
  //   readyToUse: 100,
  //   demoAvailable: true,
  //   openInNewTab: false,
  //   demoUrl: `https://apps.codingrippler.com/`,
  //   tags: ["Security", "Authentication", "Enterprise"]
  // },
  {
    id: 6,
    title: "Catalyst Bots",
    category: "AI Solutions",
    description: "Intelligent agents that can be deployed across your digital properties to engage customers and provide support.",
    features: [
      "24/7 customer support",
      "Personality customization",
      "Multi-language support",
      "Knowledge base integration"
    ],
    image: "/codingrippler/landings/item1.jpg",
    readyToUse: 100,
    demoAvailable: true,
    openInNewTab: false,
    demoUrl: `/ai/agents/catalyst`,
    tags: ["Customer Support", "AI", "Automation"]
  }
];


// Categories for filtering
// const categories = ["All Solutions", "AI Solutions", "Web Solutions", "Data Visualization"];
const categories = ["All Solutions", "AI Solutions",];

const SolutionsShowcasePage = () => {
  const [categoryFilter, setCategoryFilter] = useState("All Solutions");
  const [activeTab, setActiveTab] = useState(0);

  const handleTabChange = (event, newValue) => {
    setActiveTab(newValue);
    setCategoryFilter(categories[newValue]);
  };

  const filteredSolutions = solutions.filter(solution => {
    return categoryFilter === "All Solutions" || solution.category === categoryFilter;
  });

  return (
    <Box sx={{ bgcolor: "background.default", minHeight: "100vh" }} id = "live-demo-section" >
      {/* Hero Section */}
      <Box
        sx={{
          background: "linear-gradient(135deg, #e0e7ff 0%, #ffffff 100%)",
          py: 10,
          position: "relative",
          overflow: "hidden",
        }}
      >
        <Box
          sx={{
            position: "absolute",
            width: "300px",
            height: "300px",
            borderRadius: "50%",
            background: "rgba(99, 102, 241, 0.1)",
            top: "-100px",
            left: "-100px",
            zIndex: 0,
          }}
        />
        <Box
          sx={{
            position: "absolute",
            width: "200px",
            height: "200px",
            borderRadius: "50%",
            background: "rgba(99, 102, 241, 0.1)",
            bottom: "-50px",
            right: "-50px",
            zIndex: 0,
          }}
        />
        <Container maxWidth="lg" sx={{ position: "relative", zIndex: 1 }}>
        <Box sx={{ textAlign: 'center', mb: 8 }}>
            <SectionTitle variant="subtitle1">
              AI IN ACTION
            </SectionTitle>  

          <SectionHeading variant="h2" sx={{ fontSize: { xs: '2rem', md: '3rem' } }}>
              Solutions Showcase
          </SectionHeading>
          <Typography
            variant="h5"
            component="p"
            align="center"
            color="text.secondary"
            sx={{
              maxWidth: "800px",
              mx: "auto",
              mb: 6,
            }}
          >
  Explore our AI solution demos, showcasing capabilities like LLM-driven chatbots and agentic AI. Experience AI in action with real-world examples.          </Typography>
        </Box>

          <SolutionTabs
            handleTabChange={handleTabChange}
            activeTab={activeTab}
            setActiveTab={setActiveTab}
          />
        </Container>
      </Box>

      {/* Solutions Grid */}
      <Container maxWidth="lg" sx={{ py: 8 }}>
        <Grid container spacing={4}>
          {filteredSolutions.map((solution) => (
            <Grid item xs={12} md={6} lg={4} key={solution.id}>
              {/* <Card
                sx={{
                  height: "100%",
                  display: "flex",
                  flexDirection: "column",
                  borderRadius: 3,
                  overflow: "hidden",
                  boxShadow: "0 10px 25px rgba(0,0,0,0.05)",
                  transition: "transform 0.3s ease, box-shadow 0.3s ease",
                  "&:hover": {
                    transform: "translateY(-5px)",
                    boxShadow: "0 15px 30px rgba(0,0,0,0.1)",
                  },
                }}
              >

                <CardMedia
                  component="img"
                  image={solution.image}
                  alt={solution.title}
                  sx={{
                    height: 200, // Set a fixed height for all images
                    objectFit: "cover", // Ensures the image covers the entire area without distortion
                    bgcolor: "#f0f4ff", // Fallback background color
                  }}
                />

                <Box sx={{ position: "relative", mt: -3, mx: 2 }}>
                  <Chip
                    label={solution.category}
                    color="primary"
                    sx={{
                      fontSize: "0.875rem",
                      fontWeight: 600,
                      boxShadow: "0 4px 8px rgba(0,0,0,0.1)",
                    }}
                  />
                </Box>
                <CardContent sx={{ flexGrow: 1, pt: 3 }}>
                  <Typography
                    variant="h5"
                    component="h2"
                    gutterBottom
                    sx={{ fontWeight: 700 }}
                  >
                    {solution.title}
                  </Typography>
                  <Typography variant="body1" color="text.secondary" paragraph>
                    {solution.description}
                  </Typography>

                  <Box sx={{ mb: 2 }}>
                    <Typography
                      variant="subtitle2"
                      gutterBottom
                      sx={{
                        fontWeight: 600,
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      <DesignServicesIcon fontSize="small" sx={{ mr: 1 }} />
                      Key Features
                    </Typography>
                    <Box component="ul" sx={{ pl: 2, mb: 0 }}>
                      {solution.features.map((feature, index) => (
                        <Typography
                          component="li"
                          variant="body2"
                          key={index}
                          sx={{ mb: 0.5 }}
                        >
                          {feature}
                        </Typography>
                      ))}
                    </Box>
                  </Box>

                  <Box sx={{ mb: 2 }}>
                    <Typography
                      variant="body2"
                      sx={{
                        mb: 0.5,
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <span>Development Status</span>
                      <span>{solution.readyToUse}% Complete</span>
                    </Typography>
                    <LinearProgress
                      variant="determinate"
                      value={solution.readyToUse}
                      sx={{
                        height: 8,
                        borderRadius: 4,
                        mb: 2,
                        backgroundColor: "rgba(99, 102, 241, 0.1)",
                        "& .MuiLinearProgress-bar": {
                          backgroundColor:
                            solution.readyToUse === 100
                              ? "primary.main"
                              : "primary.main",
                        },
                      }}
                    />
                  </Box>

                  <Stack direction="row" spacing={1} flexWrap="wrap">
                    {solution.tags.map((tag) => (
                      <Chip key={tag} label={tag} size="small" sx={{ mb: 1 }} />
                    ))}
                  </Stack>
                </CardContent>
                <Divider />
                <CardActions sx={{ p: 2, pt: 1 }}>
                  <Button
                    variant="contained"
                    startIcon={<PlayArrowIcon />}
                    size="medium"
                    sx={{ borderRadius: "20px" }}
                  >
                    Try Live Demo
                  </Button>
                  <Button
                    variant="outlined"
                    size="medium"
                    sx={{ ml: 1, borderRadius: "20px" }}
                  >
                    Learn More
                  </Button>
                </CardActions>
              </Card> */}

              <SolutionCard solution={solution} />

            </Grid>
          ))}
        </Grid>
      </Container>

    </Box>
  );
};

export default SolutionsShowcasePage;