import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { Box, IconButton, Stack, Typography, useTheme } from '@mui/material';
import React, { useEffect, useRef } from 'react';

/**
 * Reusable CustomTabs component with horizontal scrolling
 * 
 * @param {Object} props
 * @param {Array} props.tabs - Array of tab objects with id, label, and icon
 * @param {number} props.activeTab - ID of the currently active tab
 * @param {function} props.handleTabChange - Function to handle tab change
 * @param {Object} props.sx - Additional styles for the container
 * @param {boolean} props.showScrollButtons - Whether to show scroll buttons (default: true)
 * @param {string} props.variant - Variant of the tabs: 'standard' or 'contained' (default: 'standard')
 * @param {boolean} props.fullWidth - Whether tabs should take full width on desktop (default: true)
 * @param {string} props.activeIndicator - Style of active indicator: 'underline' or 'background' (default: 'underline')
 */
const CustomTabs = ({ 
  tabs, 
  activeTab, 
  handleTabChange, 
  sx = {}, 
  showScrollButtons = true,
  variant = 'standard',
  fullWidth = true,
  activeIndicator = 'underline'
}) => {
  const theme = useTheme();
  const scrollRef = useRef(null);
  const [showLeftArrow, setShowLeftArrow] = React.useState(false);
  const [showRightArrow, setShowRightArrow] = React.useState(false);
  
  const checkScrollPosition = () => {
    if (!scrollRef.current) return;
    
    const { scrollLeft, scrollWidth, clientWidth } = scrollRef.current;
    setShowLeftArrow(scrollLeft > 0);
    setShowRightArrow(scrollLeft < scrollWidth - clientWidth - 5);
  };
  
  useEffect(() => {
    const scrollEl = scrollRef.current;
    if (scrollEl) {
      checkScrollPosition();
      scrollEl.addEventListener('scroll', checkScrollPosition);
      window.addEventListener('resize', checkScrollPosition);
      
      return () => {
        scrollEl.removeEventListener('scroll', checkScrollPosition);
        window.removeEventListener('resize', checkScrollPosition);
      };
    }
  }, []);
  
  const scrollToTab = (direction) => {
    if (!scrollRef.current) return;
    
    const { clientWidth } = scrollRef.current;
    const scrollAmount = direction === 'left' ? -clientWidth / 2 : clientWidth / 2;
    
    scrollRef.current.scrollBy({
      left: scrollAmount,
      behavior: 'smooth'
    });
  };

  // Determine background color based on variant
  const getBackgroundColor = (isActive) => {
    if (variant === 'contained') {
      return isActive ? theme.palette.primary.main : 'transparent';
    } else if (activeIndicator === 'background' && isActive) {
      return 'rgba(99, 102, 241, 0.08)';
    } else {
      return 'transparent';
    }
  };

  // Determine text color based on variant
  const getTextColor = (isActive) => {
    if (variant === 'contained' && isActive) {
      return theme.palette.primary.contrastText;
    } else {
      return isActive ? theme.palette.primary.main : '#A0A0A0';
    }
  };

  return (
    <Box
      sx={{
        width: '100%',
        bgcolor: '#FFFFFF',
        borderRadius: 4,
        boxShadow: '0px 4px 20px rgba(0, 0, 0, 0.05)',
        overflow: 'hidden',
        mb: 4,
        position: 'relative',
        ...sx
      }}
    >
      {/* Left scroll button */}
      {showScrollButtons && showLeftArrow && (
        <IconButton
          sx={{
            position: 'absolute',
            left: 0,
            top: '50%',
            transform: 'translateY(-50%)',
            zIndex: 5,
            bgcolor: 'rgba(255, 255, 255, 0.9)',
            boxShadow: '2px 0 8px rgba(0,0,0,0.1)',
            '&:hover': {
              bgcolor: 'rgba(255, 255, 255, 1)',
            }
          }}
          onClick={() => scrollToTab('left')}
          size="small"
        >
          <ChevronLeftIcon />
        </IconButton>
      )}
      
      {/* Right scroll button */}
      {showScrollButtons && showRightArrow && (
        <IconButton
          sx={{
            position: 'absolute',
            right: 0,
            top: '50%',
            transform: 'translateY(-50%)',
            zIndex: 5,
            bgcolor: 'rgba(255, 255, 255, 0.9)',
            boxShadow: '-2px 0 8px rgba(0,0,0,0.1)',
            '&:hover': {
              bgcolor: 'rgba(255, 255, 255, 1)',
            }
          }}
          onClick={() => scrollToTab('right')}
          size="small"
        >
          <ChevronRightIcon />
        </IconButton>
      )}

      <Box
        ref={scrollRef}
        sx={{
          overflowX: 'auto',
          scrollbarWidth: 'none', // Firefox
          '&::-webkit-scrollbar': { // Chrome, Safari, etc.
            display: 'none'
          },
          msOverflowStyle: 'none', // IE and Edge
        }}
      >
        <Stack 
          direction="row" 
          sx={{ 
            width: { xs: '100%', md: fullWidth ? '100%' : 'max-content' }, 
            position: 'relative' 
          }}
        >
          {tabs.map((tab) => {
            const isActive = activeTab === tab.id;
            return (
              <Box
                key={tab.id}
                onClick={(event) => handleTabChange(event, tab.id)}
                sx={{
//                  flex: { xs: '0 0 auto', md: fullWidth ? 1 : '0 0 auto' },
                  flex: { xs: '1', md: fullWidth ? 1 : '0 0 auto' },
                  minWidth: { xs: '150px', sm: '170px', md: fullWidth ? '0' : '170px' },
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center',
                  justifyContent: 'center',
                  py: 2,
                  px: 2,
                  cursor: 'pointer',
                  position: 'relative',
                  transition: 'all 0.3s ease',
                  bgcolor: getBackgroundColor(isActive),
                  color: getTextColor(isActive),
                  borderRadius: variant === 'contained' ? 1 : 0,
                  mx: variant === 'contained' ? 0.5 : 0,
                  '&:hover': {
                    bgcolor: isActive ? getBackgroundColor(isActive) : 'rgba(99, 102, 241, 0.04)'
                  }
                }}
              >
                {tab.icon && (
                  <Box
                    sx={{
                      mr: 1.5,
                      display: 'flex',
                      alignItems: 'center',
                      color: getTextColor(isActive),
                    }}
                  >
                    {tab.icon}
                  </Box>
                )}
                <Typography
                  variant="subtitle1"
                  sx={{
                    fontWeight: isActive ? 600 : 500,
                    fontSize: { xs: '0.8rem', sm: '0.9rem', md: '1rem' },
                    whiteSpace: 'nowrap',
                    color: getTextColor(isActive),
                  }}
                >
                  {tab.label}
                </Typography>

                {/* Active indicator line (only shown when activeIndicator is 'underline') */}
                {isActive && activeIndicator === 'underline' && (
                  <Box
                    sx={{
                      position: 'absolute',
                      bottom: 0,
                      left: 0,
                      width: '100%',
                      height: 3,
                      bgcolor: theme.palette.primary.main,
                      borderTopLeftRadius: 3,
                      borderTopRightRadius: 3
                    }}
                  />
                )}
              </Box>
            );
          })}
        </Stack>
      </Box>
    </Box>
  );
};

export default CustomTabs;