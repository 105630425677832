import CodeIcon from '@mui/icons-material/Code';
import EmailIcon from '@mui/icons-material/Email';
import HubIcon from "@mui/icons-material/Hub";
import InfoIcon from '@mui/icons-material/Info';
import InstagramIcon from '@mui/icons-material/Instagram';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import LockIcon from '@mui/icons-material/Lock';
import TwitterIcon from '@mui/icons-material/Twitter';
import YouTubeIcon from '@mui/icons-material/YouTube';
import {
  Box,
  Button,
  CardMedia,
  Container,
  Divider,
  Grid,
  IconButton,
  Link,
  Stack,
  Typography,
  useMediaQuery,
  useTheme
} from '@mui/material';
import React, { useState } from 'react';
import ContactDialog, { trackAndOpenDialog } from '../CodingRipplerHome/ContactUs/ContactUsDialog';

const Footer = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const isTablet = useMediaQuery(theme.breakpoints.between('sm', 'md'));

  // Original footer sections with added Privacy Policy link
  const footerSections = [
    {
      title: 'Solutions',
      links: [
        { name: 'AI Solutions', href: '/ai' },
        // { name: 'Web Solutions', href: '/web' },
        { name: 'Custom Software', href: '/' },
      ]
    },
    {
      title: 'Company',
      links: [
        { name: 'About Us', href: '/about' },
        { name: 'Privacy Policy', href: '/privacy-policy', target: '_blank' },
      ]
    }
  ];

  // Mobile footer links with icons (added Privacy Policy)
  const mobileFooterLinks = [
    {
      icon: <HubIcon />,
      name: 'AI Solutions',
      href: '/ai'
    },
    {
      icon: <CodeIcon />,
      name: 'Custom Software',
      href: '/'
    },
    {
      icon: <InfoIcon />,
      name: 'About Us',
      href: '/about'
    },
    {
      icon: <LockIcon />,
      name: 'Privacy Policy', 
      href: '/privacy-policy', 
      target: '_blank' 
    }
  ];

  const clickHandler = () => {
    window.location.href = "/";
  };

  const logoPath = "/codingrippler/brand-logos/logo-main-light.svg";
  
  // Contact information
  const contactInfo = [
    { icon: <LocationOnIcon fontSize="small" />, text: "Ahmedabad, Gujarat, India, 380021", href: "https://maps.google.com" },
    { icon: <EmailIcon fontSize="small" />, text: "contact@codingrippler.com", href: "mailto:info@codingrippler.com" },
  ];
  
  // Social media links
  const socialLinks = [
    { icon: <LinkedInIcon />, href: "https://www.linkedin.com/company/codingrippler/" },
    { icon: <TwitterIcon />, href: "https://twitter.com" },
    { icon: <InstagramIcon />, href: "https://www.instagram.com/codingrippler/" },
    { icon: <YouTubeIcon />, href: "https://www.youtube.com/@CodingRippler" }
  ];

  const [open, setOpen] = useState(false);
    
  const handleContactClick = () => trackAndOpenDialog(setOpen);
    
  const handleClose = (success) => {
    setOpen(false);
    if (success) {
      // Handle successful submission (e.g. show a success toast)
    }
  };

  // Open privacy policy in a new window
  const openPrivacyPolicy = (e) => {
    e.preventDefault();
    window.open('/privacy-policy', '_blank', 'noopener,noreferrer');
  };

  // Mobile Footer Layout
  const mobileFooterLayout = (
    <>
      {/* Brand Section */}
      <Box 
        sx={{ 
          p: 4,
          mb: 4,
          background: 'rgba(255, 255, 255, 0.03)',
          borderRadius: 2,
          border: '1px solid rgba(255, 255, 255, 0.05)',
          textAlign: 'center'
        }}
      >
        <CardMedia
          component="img"
          image={logoPath}
          alt="CodingRippler"
          onClick={clickHandler}
          sx={{
            objectFit: "contain",
            maxHeight: "60px",
            margin: "0 auto 16px auto",
            cursor: "pointer",
          }}
        />

        <Typography 
          sx={{ 
            color: 'rgba(255, 255, 255, 0.8)',
            mb: 3,
            fontSize: '1rem',
          }}
        >
          The home of innovative tech solutions
        </Typography>
        
        <Stack direction="row" spacing={2} justifyContent="center">
          {socialLinks.map((link, index) => (
            <IconButton 
              key={index} 
              href={link.href}
              aria-label={`Visit our ${link.href.split('/').pop()} page`}
              size="medium"
              sx={{ 
                color: 'white',
                backgroundColor: 'rgba(255, 255, 255, 0.08)',
                '&:hover': { 
                  backgroundColor: theme.palette.primary.main,
                  transform: 'translateY(-2px)'
                },
                transition: 'all 0.2s ease',
                padding: 1.2
              }}
            >
              {link.icon}
            </IconButton>
          ))}
        </Stack>
      </Box>

      {/* Contact Section */}
      <Box 
        sx={{ 
          p: 4,
          mb: 4,
          background: 'rgba(255, 255, 255, 0.03)',
          borderRadius: 2,
          border: '1px solid rgba(255, 255, 255, 0.05)'
        }}
      >
        <Typography sx={{ 
          fontSize: '1.1rem', 
          fontWeight: 600, 
          mb: 3, 
          color: 'white', 
          textAlign: 'center',
          textTransform: 'uppercase',
          letterSpacing: '0.05em'
        }}>
          Contact Us
        </Typography>

        <Stack spacing={3}>
          {contactInfo.map((item, index) => (
            <Link
              key={index}
              href={item.href}
              underline="none"
              sx={{
                display: 'flex',
                alignItems: 'center',
                color: 'rgba(255, 255, 255, 0.8)',
                fontSize: '0.95rem',
                transition: 'color 0.2s',
                '&:hover': {
                  color: theme.palette.primary.main
                }
              }}
            >
              <Box sx={{ 
                mr: 2, 
                display: 'flex', 
                alignItems: 'center',
                backgroundColor: 'rgba(255, 255, 255, 0.08)',
                p: 1,
                borderRadius: '50%'
              }}>
                {item.icon}
              </Box>
              {item.text}
            </Link>
          ))}
        </Stack>
      </Box>

      {/* Quick Links Section */}
      <Box 
        sx={{ 
          p: 4,
          mb: 4,
          background: 'rgba(255, 255, 255, 0.03)',
          borderRadius: 2,
          border: '1px solid rgba(255, 255, 255, 0.05)'
        }}
      >
        <Typography sx={{ 
          fontSize: '1.1rem', 
          fontWeight: 600, 
          mb: 3, 
          color: 'white', 
          textAlign: 'center',
          textTransform: 'uppercase',
          letterSpacing: '0.05em'
        }}>
          Quick Links
        </Typography>

        <Grid container spacing={2}>
          {mobileFooterLinks.map((link, index) => (
            <Grid item xs={6} key={index}>
              <Link
                href={link.href}
                underline="none"
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  justifyContent: 'center',
                  backgroundColor: 'rgba(255, 255, 255, 0.05)',
                  borderRadius: 2,
                  p: 2,
                  height: '100%',
                  transition: 'all 0.2s ease',
                  '&:hover': {
                    backgroundColor: 'rgba(255, 255, 255, 0.1)',
                    transform: 'translateY(-3px)',
                    boxShadow: '0 5px 15px rgba(0, 0, 0, 0.15)'
                  }
                }}
              >
                <Box sx={{ 
                  backgroundColor: 'rgba(99, 102, 241, 0.15)',
                  borderRadius: '50%',
                  p: 1.5,
                  display: 'flex',
                  mb: 1.5,
                  color: theme.palette.primary.main
                }}>
                  {link.icon}
                </Box>
                <Typography sx={{ 
                  color: 'white',
                  fontWeight: 500,
                  fontSize: '0.9rem',
                  textAlign: 'center'
                }}>
                  {link.name}
                </Typography>
              </Link>
            </Grid>
          ))}
        </Grid>
        
        {/* Privacy Policy link for mobile */}
        {/* <Box sx={{ textAlign: 'center', mt: 3 }}>
          <Link
            onClick={openPrivacyPolicy}
            underline="hover"
            sx={{
              color: 'rgba(255, 255, 255, 0.7)',
              fontSize: '0.9rem',
              fontWeight: 500,
              cursor: 'pointer',
              '&:hover': {
                color: theme.palette.primary.main
              }
            }}
          >
            Privacy Policy
          </Link>
        </Box> */}
      </Box>

      {/* CTA Section */}
      <Box 
        sx={{ 
          p: 4,
          mb: 4,
          background: 'linear-gradient(90deg, rgba(99, 102, 241, 0.15) 0%, rgba(139, 92, 246, 0.15) 100%)',
          borderRadius: 2,
          border: '1px solid rgba(255, 255, 255, 0.05)'
        }}
      >
        <Typography sx={{ 
          fontSize: '1.2rem',
          mb: 2,
          color: 'white', 
          fontWeight: 600,
          textAlign: 'center'
        }}>
          Ready to start your project?
        </Typography>
        
        <Typography sx={{ 
          fontSize: '1rem',
          mb: 3,
          color: 'rgba(255, 255, 255, 0.8)',
          textAlign: 'center'
        }}>
          Get in touch to discuss how we can help.
        </Typography>
        
        <Box sx={{ textAlign: 'center' }}>
          <Button
            variant="contained"
            color="primary"
            sx={{
              borderRadius: 30,
              py: 1.5,
              px: 4,
              background: "linear-gradient(90deg, #6366F1 0%, #8B5CF6 100%)",
              textTransform: 'none',
              fontSize: '1rem',
              fontWeight: 600,
              '&:hover': { transform: 'translateY(-2px)' },
              transition: 'all 0.3s ease',
              boxShadow: "0 8px 25px rgba(99, 102, 241, 0.6)",
            }}
            onClick={handleContactClick}
          >
            Let's Talk
          </Button>
        </Box>
      </Box>
    </>
  );

  // Desktop Footer Layout
  const desktopFooterLayout = (
    <>
      <Box sx={{ mt: 2, mb: 7 }} id = "footer-section" >
        <Grid container>
          {/* Left Column - Logo and Contact Information */}
          <Grid item xs={12} md={4} >
            <Box sx={{ pr: 4 }}>
              <CardMedia
                component="img"
                image={logoPath}
                alt="CodingRippler"
                onClick={clickHandler}
                sx={{
                  objectFit: "contain",
                  maxHeight: "60px",
                  mb: 3,
                  cursor: "pointer",
                }}
              />
              {contactInfo.map((item, index) => (
                <Link
                  key={index}
                  href={item.href}
                  underline="none"
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    color: 'rgba(255, 255, 255, 0.7)',
                    fontSize: '0.85rem',
                    mb: 1.5,
                    transition: 'color 0.2s',
                    '&:hover': {
                      color: theme.palette.primary.main
                    }
                  }}
                >
                  <Box sx={{ mr: 1, display: 'flex', alignItems: 'center' }}>
                    {item.icon}
                  </Box>
                  {item.text}
                </Link>
              ))}
            </Box>
          </Grid>

          {/* Middle Column - Tagline and Social */}
          <Grid item xs={12} md={4} sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
            <Typography 
              sx={{ 
                color: 'rgba(255, 255, 255, 0.7)',
                mb: 2.5,
                textAlign: 'center',
                pt:2
              }}
            >
              The home of innovative tech solutions
            </Typography>
            
            <Box sx={{ display: 'flex', justifyContent: 'center' }}>
              {socialLinks.map((link, index) => (
                <IconButton 
                  key={index} 
                  href={link.href}
                  size="small"
                  sx={{ 
                    color: 'rgba(255, 255, 255, 0.7)',
                    mx: 1,
                    '&:hover': { color: theme.palette.primary.main }
                  }}
                >
                  {link.icon}
                </IconButton>
              ))}
            </Box>
          </Grid>

          {/* Right Column - CTA */}
          <Grid item xs={12} md={4} sx={{ display: 'flex', justifyContent: 'flex-end' }}>
            <Box 
              sx={{ 
                p: 3, 
                background: 'rgba(255, 255, 255, 0.03)',
                borderRadius: 2,
                border: '1px solid rgba(255, 255, 255, 0.05)',
                width: '300px',
              }}
            >
              <Typography variant="h6" sx={{ fontSize: '1.1rem', mb: 2, color: 'white', fontWeight: 600 }}>
                Ready to start your project?
              </Typography>
              
              <Typography sx={{ fontSize: '0.9rem', mb: 3, color: 'rgba(255, 255, 255, 0.8)' }}>
                Get in touch to discuss how we can help.
              </Typography>
              
              <Button
                variant="contained"
                color="primary"
                sx={{
                  borderRadius: 30,
                  py: 1,
                  px: 3,
                  background: "linear-gradient(90deg, #6366F1 0%, #8B5CF6 100%)",
                  textTransform: 'none',
                  fontWeight: 600,
                  '&:hover': { transform: 'translateY(-2px)' },
                  transition: 'all 0.3s ease',
                  boxShadow: "0 8px 25px rgba(99, 102, 241, 0.6)",
                }}
                onClick={handleContactClick}
              >
                Let's Talk
              </Button>
            </Box>
          </Grid>
        </Grid>
      </Box>

      {/* Links section - Bottom Row */}
      <Grid container spacing={3} sx={{ mb: 5 }}>
        <Grid item md={2}>
          <Typography variant="subtitle2" sx={{ fontSize: '1rem', fontWeight: 700, mb: 2, color: 'white' }}>
            SOLUTIONS
          </Typography>
          <Stack spacing={1.5}>
            {footerSections[0].links.map((link) => (
              <Link
                key={link.name}
                href={link.href}
                sx={{
                  fontSize: '0.9rem',
                  color: 'rgba(255, 255, 255, 0.7)',
                  textDecoration: 'none',
                  fontWeight: 400,
                  transition: 'all 0.2s ease',
                  '&:hover': { color: theme.palette.primary.main }
                }}
              >
                {link.name}
              </Link>
            ))}
          </Stack>
        </Grid>
        <Grid item md={2}>
          <Typography variant="subtitle2" sx={{ fontSize: '1rem', fontWeight: 700, mb: 2, color: 'white' }}>
            COMPANY
          </Typography>
          <Stack spacing={1.5}>
            {footerSections[1].links.map((link) => (
              <Link
                key={link.name}
                href={link.href}
                onClick={link.name === 'Privacy Policy' ? openPrivacyPolicy : undefined}
                sx={{
                  fontSize: '0.9rem',
                  color: 'rgba(255, 255, 255, 0.7)',
                  textDecoration: 'none',
                  fontWeight: 400,
                  transition: 'all 0.2s ease',
                  '&:hover': { color: theme.palette.primary.main },
                  cursor: 'pointer'
                }}
              >
                {link.name}
              </Link>
            ))}
          </Stack>
        </Grid>
      </Grid>
    </>
  );

  // Tablet layout uses desktop layout for this component
  const tableFooterLayout = desktopFooterLayout;

  return (
    <>
      <Box 
        component="footer" 
        sx={{
          background: 'linear-gradient(180deg, #1A1A2E 0%, #0F0F1A 100%)',
          color: 'white',
          position: 'relative',
          overflow: 'hidden',
          pt: { xs: 6, md: 8 },
          pb: { xs: 6, md: 4 },
        }}
      >
        <Container maxWidth="lg">
          {/* Render different layouts based on screen size */}
          {isMobile ? mobileFooterLayout : isTablet ? tableFooterLayout : desktopFooterLayout}

          <Divider sx={{ borderColor: 'rgba(255, 255, 255, 0.1)', mb: 4 }} />

          <Box sx={{ 
            textAlign: 'center', 
            color: 'rgba(255, 255, 255, 0.6)', 
            fontSize: '0.9rem'
          }}>
            <Typography sx={{ mb: 1 }}>
              © 2025 CodingRippler Tech Private Ltd. All rights reserved.
            </Typography>
          </Box>
        </Container>
      </Box>

      <ContactDialog open={open} onClose={handleClose} />
    </>
  );
};

export default Footer;