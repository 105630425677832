import ReactGA from "react-ga4";

// Initialize GA with your Measurement ID
export const initGA = () => {
  // ReactGA.initialize("G-40KM18CLCH"); // Replace with your GA4 Measurement ID

  ReactGA.initialize("G-40KM18CLCH", {
    gaOptions: {
      cookieDomain: ".codingrippler.com", // Enables cross-subdomain tracking
      cookieFlags: "SameSite=None; Secure", // Ensures cookies are shared securely
    },
  });
};

// Log page views
export const logPageView = () => {
  ReactGA.send({ hitType: "pageview", page: window.location.pathname });
};


/**
 * Homepage Section Scroll Tracking
 * Tracks which sections of your homepage users are viewing and for how long
 */

// Call this function after your page has loaded
export const initHomepageSectionTracking = () => {
  // Define the sections you want to track
  const sections = [
    { id: 'hero-section', name: 'hero' },
    { id: 'specialized-product', name: 'specialized_product'},
    { id: 'features-section', name: 'features' },
    { id: 'live-demo-section', name: 'live_demos' },    
    { id: 'pricing-section', name: 'pricing' },
    { id: 'aiStudio-pricing', name: 'ai_studio_pricing' },
    { id: 'webSolution-pricing', name: 'web_solutions_pricing' },
    { id: 'whyUs-section', name: 'why_us' },
    { id: 'footer-cta-section', name: 'footer' },
    { id: 'footer-section', name: 'footer' },
    // Add all your homepage sections here
  ];

  // Set up variables to track currently visible section
  let currentSection = null;
  let sectionStartTime = null;
  let sectionVisibleThreshold = 0.25; // Changed to 25% of section must be visible to count
  
  // Generate unique event names for each section
  const getEventName = (section, action) => {
    return `${section.name}_${action}`;
  };
  
  // Check which section is currently in view
  const checkVisibleSection = () => {
    let visibleSection = null;
    let maxVisiblePercentage = 0;
    
    sections.forEach(section => {
      const element = document.getElementById(section.id);
      if (!element) return;
      
      const rect = element.getBoundingClientRect();
      const windowHeight = window.innerHeight;
      
      // Calculate how much of the element is visible
      const visibleHeight = Math.min(rect.bottom, windowHeight) - Math.max(rect.top, 0);
      const visiblePercentage = visibleHeight / element.offsetHeight;
      
      // If this section is more visible than previous ones and meets threshold
      if (visiblePercentage > maxVisiblePercentage && visiblePercentage >= sectionVisibleThreshold) {
        visibleSection = section;
        maxVisiblePercentage = visiblePercentage;
      }
    });
    
    return visibleSection;
  };
  
  // Track when section changes
  const trackSectionChange = () => {
    const newSection = checkVisibleSection();
    
    // If we've moved to a different section
    if (newSection && (!currentSection || newSection.id !== currentSection.id)) {
      // If we were tracking a previous section, send event for time spent there
      if (currentSection && sectionStartTime) {
        const timeSpent = Math.round((new Date().getTime() - sectionStartTime) / 1000);
        
        // Only send if they spent at least 10 seconds in section (meaningful engagement)
        if (timeSpent >= 10 && window.gtag) {
          window.gtag('event', getEventName(currentSection, 'viewed'), {
            'event_category': 'Homepage Engagement',
            'event_label': currentSection.name,
            'section_id': currentSection.id,
            'time_spent': timeSpent
          });
        }
      }
      
      // Start tracking new section
      currentSection = newSection;
      sectionStartTime = new Date().getTime();
      
      // Send section_entered event
      if (window.gtag) {
        window.gtag('event', 'section_entered', {
          'event_category': 'Homepage Engagement',
          'event_label': currentSection.name,
          'section_id': currentSection.id
        });
      }
    }
  };
  
  // Set up scroll event listener
  window.addEventListener('scroll', () => {
    // Use requestAnimationFrame to avoid performance issues
    window.requestAnimationFrame(trackSectionChange);
  }, { passive: true });
  
  // Check initial section on page load
  window.requestAnimationFrame(trackSectionChange);
  
  // Track final section view when user leaves page
  window.addEventListener('beforeunload', () => {
    if (currentSection && sectionStartTime) {
      const timeSpent = Math.round((new Date().getTime() - sectionStartTime) / 1000);
      
      if (timeSpent >= 10 && window.gtag) {
        window.gtag('event', getEventName(currentSection, 'viewed'), {
          'event_category': 'Homepage Engagement',
          'event_label': currentSection.name,
          'section_id': currentSection.id,
          'time_spent': timeSpent
        });
      }
    }
  });
};
