const API_URL = process.env.REACT_APP_API_URL;

export const contactService = {
  sendContactForm: async (formData) => {
    try {
      const response = await fetch(`${API_URL}/api/auth/public/v1/contact-us`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(formData),
      });

      if (!response.ok) {
        throw new Error("Network response was not ok");
      }

      const data = await response.json();
      return data;
    } catch (error) {
      console.error("There was a problem with the fetch operation:", error);
      throw error;
    }
  },
};
