import AccountTreeIcon from "@mui/icons-material/AccountTree";
import BuildIcon from "@mui/icons-material/Build";
import SupportAgentIcon from "@mui/icons-material/SupportAgent";
import { Box, Divider, Grid } from "@mui/material";
import { Helmet } from "react-helmet-async";
import { SlideInFromTopEffect } from "../../../BoilarPlateJs/MotionEffects";
import { ContactWebRippler } from "../../CodingRipplerHome/ContactUs/ContactWebRippler";
import {
  CommonContainer,
  HeroSubHeader,
  HeroText,
  ViewPagesCard,
} from "../../CodingRipplerWidgets";

export const OurWebPortfolio = ({ children, ...props }) => {
  const { isPhoneorTab, isPhone, isTab, deviceWithSmallHeight, theme } = props;

  const clickHandler = (context) => {
    window.location.href = `${context}`;
  };

  const viewPages = (context) => {
    window.location.href = `/web/portfolio`;
  };

  const contactUs = (context) => {
    window.location.href = `/web/contact`;
  };

  const pageContent = {
    whyUs: {
      header: "Develop anything your business needs",
      subHeader:
        "Craft beautiful, modern websites with flexible, fully customizable designs.",
      customStyle: {
        customHeader: {
          textAlign: "left",
          fontSize: {
            xs: "2.5rem",
            lg: "3rem",
          },
        },
        customSubHeader: {
          textAlign: "left",
          fontSize: {
            xs: "1.25rem",
            lg: "1.5rem",
          },
        },
      },

      OurWork: [
        {
          title: "Tailored Expertise Over Templates",
          description:
            "While website builders offer templates, Web Rippler delivers custom solutions tailored to your specific needs, ensuring your website stands out.",
          icon: (
            <>
              <BuildIcon />
            </>
          ),
          benefits:
            "A unique, branded experience builds credibility and attracts more customers.",
          image: "/codingrippler/webrippler/whyItmatters/tailored-solution.png",
        },
        {
          title: "Scalable and Flexible Solutions",
          description:
            "Our websites are designed for growth. Unlike basic builders, we create scalable architectures that adapt as your business evolves.",
          icon: (
            <>
              <AccountTreeIcon />
            </>
          ),
          benefits:
            "As your business expands, your website will need to handle more traffic and complex features without needing a complete overhaul.",
          image: "/codingrippler/webrippler/whyItmatters/scalable-solution.png",
        },
        {
          title: "Comprehensive Support",
          description:
            "With Web Rippler, you receive ongoing support, including updates, security, and optimization—something most website builders lack.",
          icon: (
            <>
              <SupportAgentIcon />
            </>
          ),
          benefits:
            "This means less downtime and peace of mind, allowing you to focus on your business rather than technical issues.",
          image: "/codingrippler/webrippler/whyItmatters/customer-suport.png",
        },
      ],

      ContactWebRippler: {
        header: (
          <>
            {" "}
            Get started with{" "}
            <span className="theme-specfic-colored-text">Web Rippler</span>{" "}
            today{" "}
          </>
        ),
        subHeader:
          "Build a beautiful, modern website with flexible, fully customizable, atomic MUI components.",
        buttonText: "View Pages",
      },
    },
    header: "Landing pages",
    subHeader:
      "From rich starting points to simple single pagers, anything is possible.",
    customStyle: {
      customHeader: {
        textAlign: "left",
        fontWeight: "normal",
        fontSize: {
          xs: "1.5rem",
        },
      },
      customSubHeader: {
        textAlign: "left",
        fontSize: {
          xs: "1.25rem",
        },
      },

      topHeading: {
        textAlign: "left",
        fontWeight: "normal",
      },

      topSubHeading: {
        textAlign: "left",
      },
    },

    landingPages: [
      {
        imagePath: "/codingrippler/landings/item4.jpg",
        headerText: "Current",
        subHeaderText: "",
        context: `/web`,
      },
      {
        imagePath: "/codingrippler/landings/item5.jpg",
        headerText: "Dashboard",
        subHeaderText: "",
        context: `/web/dashboards`,
      },
      {
        imagePath: "/codingrippler/landings/item6.jpg",
        headerText: "E-commerce",
        subHeaderText: "",
        context: `/web/e-commerce`,
      },
      {
        imagePath: "/codingrippler/landings/item9.jpg",
        headerText: "Parallax",
        subHeaderText: "",
        context: `/web/parallax`,
      },
      {
        imagePath: "/codingrippler/landings/item8.jpg",
        headerText: "Masanory",
        subHeaderText: "",
        context: `/web/masanory`,
      },
      {
        imagePath: "/codingrippler/landings/item7.jpg",
        headerText: "Grid",
        subHeaderText: "",
        context: `/web/grid`,
      },
      {
        imagePath: "/codingrippler/landings/item11.jpg",
        headerText: "Startup",
        subHeaderText: "",
        context: `/`,
      },
      {
        imagePath: "/codingrippler/landings/item10.jpg",
        headerText: "Product",
        subHeaderText: "",
        context: `/ai`,
      },
      {
        imagePath: "/codingrippler/landings/item1.jpg",
        headerText: "Overview",
        subHeaderText: "",
        context: `/ai/agents/catalyst`,
      },
      {
        imagePath: "/codingrippler/landings/item3.jpg",
        headerText: "Account Login",
        subHeaderText: "",
        context: `https://apps.codingrippler.com/`,
      },

      {
        imagePath: "/codingrippler/landings/item2.jpg",
        headerText: "Basic",
        subHeaderText: "",
        context: `/web/basic`,
      },

      {
        imagePath: "/codingrippler/landings/item12.jpg",
        headerText: "About Page",
        subHeaderText: "",
        context: `/about`,
      },
    ],

    footer: {
      header: "...and more to come",
      subHeader:
        "Web Rippler is a dynamic, ever-evolving system of composable components. We’re continually adding examples, refining features, and listening to feedback to enhance your experience.",
    },
  };

  return (
    <>
      <Helmet>
        <title>Web Rippler - portfolio</title>
        <meta
          name="description"
          content="Explore the wide range of web development services offered by Web Rippler."
        />
        <meta property="og:title" content="Web Rippler - portfolio" />
        <meta
          property="og:description"
          content="Explore the wide range of web development services offered by Web Rippler."
        />
        <meta
          property="og:url"
          content="https://www.codingrippler.com/portfolio"
        />
        <meta property="og:type" content="website" />
        <link rel="canonical" href="https://www.codingrippler.com/portfolio" />
      </Helmet>

      {/* <Grid container spacing={2}>
        <Grid
          item
          lg={1}
          sx={{
            display: {
              xs: "none",
              sm: "none",
              md: "none",
              lg: "block",
            },
          }}
        />
        <Grid
          item
          xs={12}
          lg={10}
          mt={{
            xs: `max(12.5vh, 64px)`,
            sm: `max(10vh, 80px)`,
            lg: `max(20vh, 125px)`,
          }}
        >
          <CommonContainer>
            <Box
              className="overlay"
              position="relative"
              sx={{
                width: "100%",
                minHeight: {
                  lg: 500,
                },
                height: {
                  xs: "auto",
                  lg: "40vh",
                },
              }}
            >
              <WhyUs
                pageContent={pageContent.whyUs}
                isPhone={isPhone}
                isTab={isTab}
                isPhoneorTab={isPhoneorTab}
                deviceWithSmallHeight={deviceWithSmallHeight}
              />
            </Box>
          </CommonContainer>
        </Grid>{" "}
        <Grid
          item
          lg={1}
          sx={{
            display: {
              xs: "none",
              sm: "none",
              md: "none",
              lg: "block",
            },
          }}
        />
      </Grid> */}
      <Grid container className="white-bg">
        <Grid
          item
          xs={0}
          lg={1}
          sx={{
            display: {
              xs: "none",
              sm: "none",
              md: "none",
              lg: "block",
            },
          }}
        />
        <Grid
          item
          xs={12}
          lg={10}
          sx={{
            position: "relative",
          }}
          mt={{

            xs: 5,
            // xs: `max(12.5vh, 64px)`,
            // sm: `max(10vh, 80px)`,
            // lg: `max(20vh, 125px)`,
          }}
        >
          <Box
            display="flex"
            justifyContent="center"
            alignItems="flex-start"
            flexDirection="column"
            width="100%"
            height="100%"
          >
            <CommonContainer>
              <HeroText
                variant="h4"
                theme={theme}
                customStyle={pageContent?.customStyle?.topHeading}
              >
                {pageContent.header}
              </HeroText>

              <Box m={2} />

              <HeroSubHeader
                theme={theme}
                customStyle={pageContent?.customStyle?.topSubHeading}
              >
                {pageContent.subHeader}
              </HeroSubHeader>
            </CommonContainer>

            <Grid container spacing={2}>
              {pageContent.landingPages.map((gridItem, index) => (
                <Grid item xs={12} sm={6} lg={4} key={index}>
                  <Box
                    display="flex"
                    flexDirection="column"
                    justifyContent="center"
                    alignItems="center"
                    width="100%"
                    height="100%"
                  >
                    <ViewPagesCard
                      image={gridItem.imagePath}
                      title={gridItem.headerText}
                      description={gridItem.headerText}
                      handler={() => clickHandler(gridItem.context)}
                    />
                    <CommonContainer>
                      <HeroText
                        variant="h5"
                        customStyle={pageContent?.customStyle?.customHeader}
                        theme={theme}
                      >
                        {gridItem.headerText}
                      </HeroText>
                    </CommonContainer>
                  </Box>
                </Grid>
              ))}

              <Grid item xs={12}>
                <Divider />
              </Grid>

              <Grid item xs={12}>
                <Box
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                  flexDirection="column"
                  gap={2}
                  width="100%"
                  height="100%"
                >
                  <CommonContainer>
                    <HeroSubHeader
                      customStyle={{
                        color: theme.palette.text.primary,
                        textAlign: "center",
                      }}
                      theme={theme}
                    >
                      {pageContent.footer.header}
                    </HeroSubHeader>

                    <Box m={2} />

                    <HeroSubHeader
                      customStyle={{
                        color: theme.palette.text.secondary,
                      }}
                      theme={theme}
                    >
                      {pageContent.footer.subHeader}
                    </HeroSubHeader>
                  </CommonContainer>
                </Box>
              </Grid>
            </Grid>
          </Box>
        </Grid>
        <Grid
          item
          xs={0}
          lg={1}
          sx={{
            display: {
              xs: "none",
              sm: "none",
              md: "none",
              lg: "block",
            },
          }}
        />
        <Grid
          item
          xs={12}
          sx={{
            minHeight: "25vh",
          }}
        />
      </Grid>

      <Box
        component="svg"
        sx={{
          position: "relative",
          width: "100%",
          height: "auto",
          transform: {
            xs: "translateY(-50%)",
            lg: "translateY(-100%)",
          },
        }}
        preserveAspectRatio="none"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 1920 100.1"
      >
        <path fill="#ffffff" d="M0,0c0,0,934.4,93.4,1920,0v100.1H0L0,0z"></path>
      </Box>

      <Grid container spacing={2}>
        <Grid item xs={12}>
          <CommonContainer>
            <SlideInFromTopEffect>
              <ContactWebRippler
                isPhone={isPhone}
                isTab={isTab}
                deviceWithSmallHeight={deviceWithSmallHeight}
                isPhoneorTab={isPhoneorTab}
                pageContent={pageContent.whyUs.ContactWebRippler}
                viewPages={viewPages}
                contactUs={contactUs}
              />
            </SlideInFromTopEffect>
          </CommonContainer>
        </Grid>
      </Grid>
    </>
  );
};
