import LayersIcon from "@mui/icons-material/Layers";
import { Box, Fab, Typography } from "@mui/material";
import { useTheme } from "@mui/material/styles";

export const UseCaseWidgets = (children, ...props) => {
  const theme = useTheme();

  return (
    <Box display="flex" justifyContent="center">
      <Fab
        variant="extended"
        size="large"
        sx={{ backgroundColor: theme.palette.background.default, zIndex: 1 }}
        p={10}
        pointerEvents="none"
      >
        <LayersIcon />

        <Typography ml={1}>Use Cases</Typography>
      </Fab>
    </Box>
  );
};

export const IntroHeaderUseCases = ({ children, ...props }) => {
  return (
    <Box display="flex" justifyContent="center">
      <Box>
        <Typography
          variant="h4"
          fontWeight="bold"
          className="typography-custom-css"
          sx={{
            textAlign: {
              xs: "center",
              lg: "center",
            },
          }}
        >
          Empower the AI Hub to manage tasks across your
          <span className="theme-specfic-colored-text">
            {" "}
            entire organization
          </span>
        </Typography>
      </Box>
    </Box>
  );
};
