import React, { useState, useRef } from 'react';
import {
  AppBar,
  Box,
  Toolbar,
  IconButton,
  Typography,
  Button,
  Container,
  Paper,
  Drawer,
  List,
  ListItem,
  ListItemText,
  useMediaQuery,
  useTheme,
  styled,
} from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import CloseIcon from '@mui/icons-material/Close';

// Custom styled components
const StyledAppBar = styled(AppBar)(({ theme }) => ({
  boxShadow: '0 4px 20px rgba(0, 0, 0, 0.08)',
  background: 'white',
  position: 'fixed',
  top: 0,
  left: 0,
  right: 0,
  zIndex: 1100,
  borderBottom: '1px solid rgba(114, 129, 255, 0.08)',
  height: '80px', // Set a consistent height for the AppBar
}));

const NavButton = styled(Button)(({ theme, active }) => ({
  color: active ? '#7c4fe0' : '#333',
  fontWeight: 600,
  fontSize: '1rem',
  padding: '10px 18px',
  borderRadius: '8px',
  textTransform: 'none',
  position: 'relative',
  margin: '0 4px',
  transition: 'all 0.2s ease',
  '&:hover': {
    backgroundColor: 'rgba(124, 79, 224, 0.06)',
    transform: 'translateY(-2px)',
  },
  '&::after': active ? {
    content: '""',
    position: 'absolute',
    bottom: '2px',
    left: '50%',
    transform: 'translateX(-50%)',
    width: '25px',
    height: '3px',
    borderRadius: '4px',
    background: 'linear-gradient(90deg, #7c4fe0, #5f82ff)',
  } : {},
}));

const DropdownButton = styled(Button)(({ theme, active, isHovered }) => ({
  color: active || isHovered ? '#7c4fe0' : '#333',
  fontWeight: 600,
  fontSize: '1rem',
  padding: '10px 16px 10px 18px',
  borderRadius: '8px',
  textTransform: 'none',
  margin: '0 4px',
  transition: 'all 0.2s ease',
  '&:hover': {
    backgroundColor: 'rgba(124, 79, 224, 0.06)',
    transform: 'translateY(-2px)',
  },
}));

const DropdownContainer = styled(Box)(({ theme }) => ({
  position: 'relative',
  display: 'inline-block',
  zIndex: 1200, // Set a high z-index to ensure dropdown appears above other content
}));

// Redesigned dropdown menu for better description display
const DropdownMenu = styled(Paper)(({ theme }) => ({
  position: 'absolute',
  top: 'calc(100% + 8px)',
  left: '50%',
  transform: 'translateX(-50%)',
  width: '320px', // Increased width to accommodate longer descriptions
  boxShadow: '0 10px 40px rgba(0, 0, 0, 0.08)',
  border: '1px solid rgba(124, 79, 224, 0.08)',
  borderRadius: '16px',
  padding: '8px',
  maxHeight: '500px', // Maximum height to avoid extremely long dropdowns
  overflowY: 'auto', // Allow scrolling if many items
  zIndex: 1201,
  '&::before': {
    content: '""',
    position: 'absolute',
    top: -8,
    left: '50%',
    transform: 'translateX(-50%) rotate(45deg)',
    width: 14,
    height: 14,
    backgroundColor: 'white',
    borderTop: '1px solid rgba(124, 79, 224, 0.08)',
    borderLeft: '1px solid rgba(124, 79, 224, 0.08)',
    zIndex: -1,
  },
  // Styling for scrollbar
  '&::-webkit-scrollbar': {
    width: '6px',
  },
  '&::-webkit-scrollbar-track': {
    background: 'rgba(0, 0, 0, 0.03)',
    borderRadius: '10px',
  },
  '&::-webkit-scrollbar-thumb': {
    background: 'rgba(124, 79, 224, 0.2)',
    borderRadius: '10px',
  },
  '&::-webkit-scrollbar-thumb:hover': {
    background: 'rgba(124, 79, 224, 0.4)',
  }
}));

// Redesigned dropdown item for better spacing and full description display
const DropdownItem = styled(Box)(({ theme }) => ({
  padding: '16px',
  borderRadius: '12px',
  margin: '4px 0',
  transition: 'all 0.2s ease',
  cursor: 'pointer',
  display: 'flex',
  alignItems: 'flex-start',
  '&:hover': {
    backgroundColor: 'rgba(124, 79, 224, 0.06)',
    transform: 'translateX(4px)',
  },
  '&:hover .MuiTypography-root.title': {
    color: '#7c4fe0',
  },
  // Add border between items for better visual separation
  '&:not(:last-child)': {
    borderBottom: '1px solid rgba(124, 79, 224, 0.06)',
    paddingBottom: '16px',
    marginBottom: '8px',
  }
}));

const MenuItemIcon = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  width: '40px', // Slightly larger icon container
  height: '40px',
  minWidth: '40px',
  borderRadius: '10px',
  marginRight: '16px', // More spacing between icon and text
  backgroundColor: 'rgba(124, 79, 224, 0.08)',
  color: '#7c4fe0',
}));

const ActionButton = styled(Button)(({ theme }) => ({
  background: 'linear-gradient(90deg, #6366F1 0%, #8B5CF6 100%)',
  color: 'white',
  fontWeight: 600,
  fontSize: '1rem',
  padding: '10px 28px',
  borderRadius: '30px',
  textTransform: 'none',
  boxShadow: "0 6px 15px rgba(99, 102, 241, 0.4)",
  transition: 'all 0.3s ease',
  '&:hover': {
    boxShadow: '0 6px 20px rgba(95, 130, 255, 0.4)',
    transform: 'translateY(-2px)',
    background: "linear-gradient(90deg, #5661F0 0%, #7048F5 100%)"
  },
}));

const MobileNavDrawer = styled(Drawer)(({ theme }) => ({
  '& .MuiDrawer-paper': {
    width: '85%',
    maxWidth: '300px',
    background: 'white',
    borderRadius: '0 16px 16px 0',
    padding: '20px 0',
    boxShadow: '0 10px 40px rgba(0, 0, 0, 0.15)',
  },
}));

// Updated mobile menu item for better description display
const MobileMenuItem = styled(ListItem)(({ theme }) => ({
  padding: '16px 24px',
  borderLeft: '4px solid transparent',
  transition: 'all 0.2s ease',
  '&.active': {
    borderLeft: '4px solid #7c4fe0',
    backgroundColor: 'rgba(124, 79, 224, 0.06)',
  },
  '&:hover': {
    backgroundColor: 'rgba(124, 79, 224, 0.04)',
  },
}));

const NavLogo = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  cursor: 'pointer',
  '& img': {
    height: '38px',
    maxWidth: '100%',
  },
  '& .logo-text': {
    fontWeight: 700,
    fontSize: '1.6rem',
    background: 'linear-gradient(135deg, #000, #333)',
    WebkitBackgroundClip: 'text',
    WebkitTextFillColor: 'transparent',
  },
}));

// Fixed NavbarSpacer with consistent height
const NavbarSpacer = styled('div')(() => ({
  height: '80px', // Fixed height that exactly matches the navbar height
}));

// Create a wrapper component for dropdowns
const NavDropdown = ({ item, activeItem, onItemClick, onSubItemClick }) => {
  const [showDropdown, setShowDropdown] = useState(false);
  const timeoutRef = useRef(null);

  const handleMouseEnter = () => {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
      timeoutRef.current = null;
    }
    setShowDropdown(true);
  };

  const handleMouseLeave = () => {
    // Add a small delay before hiding the dropdown to allow movement to menu
    timeoutRef.current = setTimeout(() => {
      setShowDropdown(false);
    }, 100);
  };

  const handleClick = () => {
    // Toggle the dropdown on click for better mobile and accessibility support
    setShowDropdown(!showDropdown);
  };

  return (
    <DropdownContainer
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      <DropdownButton
        active={activeItem === item.id}
        isHovered={showDropdown}
        onClick={handleClick}
        endIcon={showDropdown ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
      >
        {item.label}
      </DropdownButton>

      {showDropdown && (
        <DropdownMenu elevation={6}>
          {item.subMenu.map((subItem) => (
            <DropdownItem
              key={subItem.id}
              onClick={() => {
                setShowDropdown(false);
                onSubItemClick(item, subItem);
              }}
            >
              <MenuItemIcon>
                {subItem.icon}
              </MenuItemIcon>
              <Box sx={{ width: '100%' }}>
                <Typography 
                  variant="body1" 
                  fontWeight={600}
                  className="title"
                  sx={{ 
                    lineHeight: 1.3,
                    mb: 0.5,
                    fontSize: '1rem', 
                  }}
                >
                  {subItem.label}
                </Typography>
                {subItem.description && (
                  <Typography 
                    variant="body2" 
                    color="text.secondary" 
                    sx={{ 
                      mt: 0.5,
                      lineHeight: 1.5,
                      fontSize: '0.875rem',
                      wordWrap: 'break-word',
                    }}
                  >
                    {subItem.description}
                  </Typography>
                )}
              </Box>
            </DropdownItem>
          ))}
        </DropdownMenu>
      )}
    </DropdownContainer>
  );
};

// Main NavigationBar component
const NavigationBar = ({
  logoSrc,
  logoAlt = 'Logo',
  title = 'CodingRippler',
  menuItems = [],
  actionButtonText = "LET'S TALK",
  actionButtonIcon = '→',
  actionButtonLink = '/contact',
  onActionButtonClick,
  navigate,
}) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  
  const [mobileOpen, setMobileOpen] = useState(false);
  const [activeItem, setActiveItem] = useState(null);
  const [mobileExpandedMenus, setMobileExpandedMenus] = useState({});
  
  const handleLogoClick = () => {
    if (navigate) {
      navigate('/');
    } else {
      window.location.href = '/';
    }
  };
  
  const handleMenuItemClick = (item) => {
    setActiveItem(item.id);
    if (item.onClick) {
      item.onClick();
    }
    
    if (isMobile) {
      setMobileOpen(false);
    }
  };
  
  const handleSubMenuClick = (item, subItem) => {
    if (subItem.onClick) {
      subItem.onClick();
    }
    
    if (isMobile) {
      setMobileOpen(false);
    }
  };
  



  const toggleMobileSubmenu = (menuId) => {
    setMobileExpandedMenus(prev => ({
      ...prev,
      [menuId]: !prev[menuId]
    }));
  };
  
  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };
  
  const handleActionButton = () => {
    if (onActionButtonClick) {
      onActionButtonClick();
    }
  };
  
  const renderDesktopNavItems = () => (
    <>
      {menuItems.map((item) => {
        if (item.subMenu) {
          // Use dedicated dropdown component for items with submenus
          return (
            <NavDropdown
              key={item.id}
              item={item}
              activeItem={activeItem}
              onItemClick={handleMenuItemClick}
              onSubItemClick={handleSubMenuClick}
            />
          );
        }
        
        // Regular menu items
        return (
          <NavButton
            key={item.id}
            active={activeItem === item.id}
            onClick={() => handleMenuItemClick(item)}
          >
            {item.label}
          </NavButton>
        );
      })}
    </>
  );
  
  const renderMobileDrawer = () => (
    <MobileNavDrawer
      anchor="left"
      open={mobileOpen}
      onClose={handleDrawerToggle}
    >
      <Box display="flex" justifyContent="space-between" px={3} py={1} mb={2}>
        <NavLogo onClick={handleLogoClick}>
          {logoSrc ? (
            <img src={logoSrc} alt={logoAlt} />
          ) : (
            <Box component="span" className="logo-text">
              {title}
            </Box>
          )}
        </NavLogo>
        <IconButton onClick={handleDrawerToggle}>
          <CloseIcon />
        </IconButton>
      </Box>

      <List component="nav">
        {menuItems.map((item) => {
          if (item.subMenu) {
            const isExpanded = mobileExpandedMenus[item.id];

            return (
              <React.Fragment key={item.id}>
                <MobileMenuItem
                  button
                  className={activeItem === item.id ? "active" : ""}
                  onClick={() => toggleMobileSubmenu(item.id)}
                >
                  <ListItemText
                    primary={item.label}
                    primaryTypographyProps={{ fontWeight: 600 }}
                  />
                  {isExpanded ? (
                    <KeyboardArrowUpIcon />
                  ) : (
                    <KeyboardArrowDownIcon />
                  )}
                </MobileMenuItem>

                {isExpanded && (
                  <List component="div" disablePadding>
                    {item.subMenu.map((subItem) => (
                      <MobileMenuItem
                        key={subItem.id}
                        button
                        onClick={() => handleSubMenuClick(item, subItem)}
                        sx={{ pl: 4, borderLeft: "none" }}
                      >
                        {subItem.icon && (
                          <MenuItemIcon>{subItem.icon}</MenuItemIcon>
                        )}
                        <ListItemText
                          primary={subItem.label}
                          secondary={subItem.description}
                          primaryTypographyProps={{
                            fontWeight: 600,
                            fontSize: "0.9rem",
                            lineHeight: 1.3,
                          }}
                          secondaryTypographyProps={{ 
                            fontSize: "0.8rem",
                            lineHeight: 1.5,
                            whiteSpace: 'normal',
                            wordWrap: 'break-word'
                          }}
                        />
                      </MobileMenuItem>
                    ))}
                  </List>
                )}
              </React.Fragment>
            );
          }

          return (
            <MobileMenuItem
              key={item.id}
              button
              className={activeItem === item.id ? "active" : ""}
              onClick={() => handleMenuItemClick(item)}
            >
              <ListItemText
                primary={item.label}
                primaryTypographyProps={{ fontWeight: 600 }}
              />
            </MobileMenuItem>
          );
        })}
      </List>

      <Box p={3} mt={2}>
        <ActionButton
          fullWidth
          onClick={handleActionButton}
        >
          {actionButtonText}
        </ActionButton>
      </Box>
    </MobileNavDrawer>
  );
  
  return (
    <>
      <StyledAppBar>
        <Container maxWidth="xl">
          <Toolbar 
            disableGutters 
            sx={{ 
              justifyContent: 'space-between', 
              minHeight: '80px !important', // Force consistent height
              height: '80px', 
              py: 0, // Remove padding to prevent height issues
              boxSizing: 'border-box' // Ensure padding is included in height calculation
            }}
          >
            {/* Logo */}
            <NavLogo onClick={handleLogoClick}>
              {logoSrc ? (
                <img src={logoSrc} alt={logoAlt} />
              ) : (
                <Box component="span" className="logo-text">{title}</Box>
              )}
            </NavLogo>
            
            {/* Desktop/Tablet Navigation */}
            {!isMobile && (
              <Box display="flex" alignItems="center" gap={1}>
                {renderDesktopNavItems()}
              </Box>
            )}
            
            {/* Action Button and Mobile Menu Toggle */}
            <Box display="flex" alignItems="center">
              {!isMobile && (
                <ActionButton
                  onClick={handleActionButton}
                >
                  {actionButtonText}
                </ActionButton>
              )}
              
              {isMobile && (
                <IconButton
                  edge="start"
                  color="inherit"
                  aria-label="menu"
                  onClick={handleDrawerToggle}
                  sx={{
                    background: 'rgba(114, 129, 255, 0.05)',
                    borderRadius: '8px',
                    p: 1
                  }}
                >
                  <MenuIcon sx={{ color: '#333' }} />
                </IconButton>
              )}
            </Box>
            
            {/* Mobile Navigation Drawer */}
            {renderMobileDrawer()}
          </Toolbar>
        </Container>
      </StyledAppBar>
      
      {/* Spacer element to prevent content from being hidden under the fixed navbar */}
      <NavbarSpacer />
    </>
  );
};

export default NavigationBar;