import React from 'react';
import { Box } from '@mui/material'; // Assuming you're using Material UI

/**
 * Modern animated loader component with bouncing dots and progress bar
 * 
 * @param {Object} props
 * @param {string} [props.dotColor='linear-gradient(135deg, #7c4fe0 0%, #5f82ff 100%)'] - Gradient or color for the dots
 * @param {string} [props.barColor='linear-gradient(90deg, #7c4fe0, #5f82ff, #7c4fe0)'] - Gradient or color for the progress bar
 * @param {string} [props.barBgColor='rgba(124, 79, 224, 0.2)'] - Background color for the progress bar container
 * @param {number} [props.dotSize=16] - Size of the dots in pixels
 * @param {number} [props.dotCount=3] - Number of dots to display
 * @param {number} [props.barWidth=180] - Width of the progress bar in pixels
 * @param {number} [props.barHeight=6] - Height of the progress bar in pixels
 * @param {number} [props.gap=3] - Gap between the dots and bar (in theme spacing units)
 * @returns {JSX.Element} The ModernLoader component
 */
const ModernLoader = ({
  dotColor = 'linear-gradient(135deg, #7c4fe0 0%, #5f82ff 100%)',
  barColor = 'linear-gradient(90deg, #7c4fe0, #5f82ff, #7c4fe0)',
  barBgColor = 'rgba(124, 79, 224, 0.2)',
  dotSize = 16,
  dotCount = 3,
  barWidth = 180,
  barHeight = 6,
  gap = 3
}) => {
  return (
    <Box sx={{
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      gap
    }}>
      <Box sx={{
        display: 'flex',
        justifyContent: 'center',
        gap: 1
      }}>
        {[...Array(dotCount)].map((_, i) => (
          <Box
            key={i}
            sx={{
              width: `${dotSize}px`,
              height: `${dotSize}px`,
              borderRadius: '50%',
              background: dotColor,
              animation: 'bounce 1.4s infinite ease-in-out',
              animationDelay: `${i * 0.16}s`,
              boxShadow: '0 4px 10px rgba(124, 79, 224, 0.3)',
              '@keyframes bounce': {
                '0%, 100%': {
                  transform: 'translateY(0)',
                  opacity: 0.7
                },
                '50%': {
                  transform: 'translateY(-20px)',
                  opacity: 1
                }
              }
            }}
          />
        ))}
      </Box>
      
      <Box sx={{
        position: 'relative',
        width: `${barWidth}px`,
        height: `${barHeight}px`,
        backgroundColor: barBgColor,
        borderRadius: `${barHeight/2}px`,
        overflow: 'hidden'
      }}>
        <Box
          sx={{
            position: 'absolute',
            top: 0,
            left: 0,
            height: '100%',
            background: barColor,
            backgroundSize: '200% 100%',
            animation: 'gradient-move 2s infinite linear',
            width: '50%',
            borderRadius: `${barHeight/2}px`,
            '@keyframes gradient-move': {
              '0%': { left: '-50%' },
              '100%': { left: '100%' }
            }
          }}
        />
      </Box>
    </Box>
  );
};

export default ModernLoader;