import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import HubIcon from "@mui/icons-material/Hub";
import ViewQuiltIcon from '@mui/icons-material/ViewQuilt';
import { Box, IconButton, Stack, Typography, useTheme } from '@mui/material';
import React, { useEffect, useRef } from 'react';


const SolutionTabs = ({ handleTabChange, activeTab }) => {
  const theme = useTheme();
  const scrollRef = useRef(null);
  const [showLeftArrow, setShowLeftArrow] = React.useState(false);
  const [showRightArrow, setShowRightArrow] = React.useState(false);

  const tabs = [
    { id: 0, label: 'ALL SOLUTIONS', icon: <ViewQuiltIcon /> },
    { id: 1, label: 'AI SOLUTIONS', icon: <HubIcon /> },
    // { id: 2, label: 'WEB SOLUTIONS', icon: <ComputerIcon /> },
//    { id: 3, label: 'DATA VISUALIZATION', icon: <InsightsIcon /> },
  ];

  const checkScrollPosition = () => {
    if (!scrollRef.current) return;
    
    const { scrollLeft, scrollWidth, clientWidth } = scrollRef.current;
    setShowLeftArrow(scrollLeft > 0);
    setShowRightArrow(scrollLeft < scrollWidth - clientWidth - 5);
  };
  
  useEffect(() => {
    const scrollEl = scrollRef.current;
    if (scrollEl) {
      checkScrollPosition();
      scrollEl.addEventListener('scroll', checkScrollPosition);
      window.addEventListener('resize', checkScrollPosition);
      
      return () => {
        scrollEl.removeEventListener('scroll', checkScrollPosition);
        window.removeEventListener('resize', checkScrollPosition);
      };
    }
  }, []);
  
  const scrollToTab = (direction) => {
    if (!scrollRef.current) return;
    
    const { clientWidth } = scrollRef.current;
    const scrollAmount = direction === 'left' ? -clientWidth / 2 : clientWidth / 2;
    
    scrollRef.current.scrollBy({
      left: scrollAmount,
      behavior: 'smooth'
    });
  };

  return (
    <Box
      sx={{
        width: '100%',
        bgcolor: '#FFFFFF',
        borderRadius: 4,
        boxShadow: '0px 4px 20px rgba(0, 0, 0, 0.05)',
        overflow: 'hidden',
        mb: 4,
        position: 'relative'
      }}
    >
      {/* Left scroll button */}
      {showLeftArrow && (
        <IconButton
          sx={{
            position: 'absolute',
            left: 0,
            top: '50%',
            transform: 'translateY(-50%)',
            zIndex: 5,
            bgcolor: 'rgba(255, 255, 255, 0.9)',
            boxShadow: '2px 0 8px rgba(0,0,0,0.1)',
            '&:hover': {
              bgcolor: 'rgba(255, 255, 255, 1)',
            }
          }}
          onClick={() => scrollToTab('left')}
          size="small"
        >
          <ChevronLeftIcon />
        </IconButton>
      )}
      
      {/* Right scroll button */}
      {showRightArrow && (
        <IconButton
          sx={{
            position: 'absolute',
            right: 0,
            top: '50%',
            transform: 'translateY(-50%)',
            zIndex: 5,
            bgcolor: 'rgba(255, 255, 255, 0.9)',
            boxShadow: '-2px 0 8px rgba(0,0,0,0.1)',
            '&:hover': {
              bgcolor: 'rgba(255, 255, 255, 1)',
            }
          }}
          onClick={() => scrollToTab('right')}
          size="small"
        >
          <ChevronRightIcon />
        </IconButton>
      )}

      <Box
        ref={scrollRef}
        sx={{
          overflowX: 'auto',
          scrollbarWidth: 'none', // Firefox
          '&::-webkit-scrollbar': { // Chrome, Safari, etc.
            display: 'none'
          },
          msOverflowStyle: 'none', // IE and Edge
        }}
      >
        <Stack 
          direction="row" 
          sx={{ 
            width: { xs: 'max-content', md: '100%' }, 
            position: 'relative' 
          }}
        >
          {tabs.map((tab) => {
            const isActive = activeTab === tab.id;
            return (
              <Box
                key={tab.id}
                onClick={(event) => handleTabChange(event, tab.id)}
                sx={{
                  flex: { xs: '0 0 auto', md: 1 },
                  minWidth: { xs: '150px', sm: '170px', md: '0' },
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center',
                  justifyContent: 'center',
                  py: 2,
                  px: 2,
                  cursor: 'pointer',
                  position: 'relative',
                  transition: 'all 0.3s ease',
                  color: isActive ? theme.palette.primary.main : '#6E7681',
                  '&:hover': {
                    bgcolor: 'rgba(99, 102, 241, 0.04)'
                  }
                }}
              >
                <Box
                  sx={{
                    mr: 1.5,
                    display: 'flex',
                    alignItems: 'center',
                    color: isActive ? theme.palette.primary.main : '#A0A0A0',
                  }}
                >
                  {tab.icon}
                </Box>
                <Typography
                  variant="subtitle1"
                  sx={{
                    fontWeight: isActive ? 600 : 500,
                    fontSize: { xs: '0.8rem', sm: '0.9rem', md: '1rem' },
                    whiteSpace: 'nowrap',
                    color: isActive ? theme.palette.primary.main : '#A0A0A0',
                  }}
                >
                  {tab.label}
                </Typography>

                {/* Active indicator line */}
                {isActive && (
                  <Box
                    sx={{
                      position: 'absolute',
                      bottom: 0,
                      left: 0,
                      width: '100%',
                      height: 3,
                      bgcolor: theme.palette.primary.main,
                      borderTopLeftRadius: 3,
                      borderTopRightRadius: 3
                    }}
                  />
                )}
              </Box>
            );
          })}
        </Stack>
      </Box>
    </Box>
  );
};

export default SolutionTabs;