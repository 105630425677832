import React, { useState } from 'react';
import { 
  Grid, 
  Box, 
  Button, 
  Typography,
  Container,
  useMediaQuery,
  useTheme
} from '@mui/material';
import ContactDialog, { trackAndOpenDialog } from '../ContactUs/ContactUsDialog';

const AboutUsHero = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const [open, setOpen] = useState(false);

  const handleContactClick = () => trackAndOpenDialog(setOpen);
  const handleClose = (success) => {
    setOpen(false);
    if (success) {
      // Handle successful submission (e.g. show a success toast)
    }
  };  

  return (

    <>
      <Box sx={{ 
        width: '100%',
        position: 'relative',
        overflow: 'hidden',
        backgroundColor: '#f4f5ff',
        pb: 10
      }}>
        {/* Background pattern */}
        <Box
          sx={{
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            opacity: 0.03,
            backgroundImage: 'url(\'data:image/svg+xml,%3Csvg width="60" height="60" viewBox="0 0 60 60" xmlns="http://www.w3.org/2000/svg"%3E%3Cpath d="M54.627 0l.83.828-1.415 1.415L51.8 0h2.827zM5.373 0l-.83.828L5.96 2.243 8.2 0H5.374zM48.97 0l3.657 3.657-1.414 1.414L46.143 0h2.828zM11.03 0L7.372 3.657 8.787 5.07 13.857 0H11.03zm32.284 0L49.8 6.485 48.384 7.9l-7.9-7.9h2.83zM16.686 0L10.2 6.485 11.616 7.9l7.9-7.9h-2.83zm20.97 0l9.315 9.314-1.414 1.414L34.828 0h2.83zM22.344 0L13.03 9.314l1.414 1.414L25.172 0h-2.83zM32 0l12.142 12.142-1.414 1.414L30 1.828 17.272 14.556l-1.414-1.414L28 0h4zM.284 0l28 28-1.414 1.414L0 2.544V0h.284zM0 5.373l25.456 25.455-1.414 1.415L0 8.2V5.374zm0 5.656l22.627 22.627-1.414 1.414L0 13.86v-2.83z" fill="%236056ff" fill-rule="evenodd"/%3E%3C/svg%3E\')',
            zIndex: 0,
          }}
        />

        {/* Gradient transition at bottom */}
        <Box
          sx={{
            position: 'absolute',
            bottom: 0,
            left: 0,
            width: '100%',
            height: '150px',
            background: 'linear-gradient(to bottom, rgba(240, 240, 255, 0) 0%, rgba(15, 12, 41, 1) 100%)',
            zIndex: 1,
          }}
        />

        <Container maxWidth="lg" sx={{ pt: { xs: 12, sm: 15, md: 15 }, pb: 10, position: 'relative', zIndex: 2 }}>
          <Grid container spacing={3}>
            {/* Text Content */}
            <Grid item xs={12} md={6} sx={{ 
              textAlign: { xs: 'center', md: 'left' }, 
              mb: { xs: 5, md: 0 },
              order: { xs: 1, md: 1 } 
            }}>
              <Box sx={{ maxWidth: { xs: '100%', md: '90%' } }}>
                <Typography 
                  variant={isMobile ? "h3" : "h2"} 
                  component="h1"
                  sx={{
                    fontWeight: 800,
                    background: 'linear-gradient(90deg, #6056ff 0%, #943bff 100%)',
                    WebkitBackgroundClip: 'text',
                    WebkitTextFillColor: 'transparent',
                    mb: 3,
                    mt: 2
                  }}
                >
                  Driven by Excellence, Built for You
                </Typography>

                <Typography 
                  variant="body1" 
                  sx={{ 
                    fontSize: '1.1rem', 
                    lineHeight: 1.6,
                    color: '#555',
                    mb: 4
                  }}
                >
                  As tech enthusiasts, we see every challenge as an opportunity to innovate and bring value to the people and businesses we serve.
                </Typography>

                <Box sx={{ 
                  display: 'flex', 
                  justifyContent: { xs: 'center', md: 'flex-start' } 
                }}>
                  <Button
                    variant="contained"
                    size="large"
                    onClick={handleContactClick}
                    sx={{
                      background: 'linear-gradient(90deg, #6366F1 0%, #8B5CF6 100%)',
                      color: 'white',
                      px: 4,
                      py: 1.5,
                      borderRadius: 30,
                      fontSize: '1rem',
                      fontWeight: 600,
                      boxShadow: '0 6px 15px rgba(99, 102, 241, 0.4)',
                      '&:hover': {
                        transform: 'translateY(-2px)',
                        boxShadow: '0 8px 25px rgba(99, 102, 241, 0.6)',
                        background: 'linear-gradient(90deg, #5661F0 0%, #7048F5 100%)'
                      },
                      transition: 'all 0.3s ease'
                    }}
                  >
                    Let's Talk
                  </Button>
                </Box>
              </Box>
            </Grid>

            {/* Image */}
            <Grid item xs={12} md={6} sx={{ 
              order: { xs: 2, md: 2 }, 
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center'
            }}>
              <Box
                sx={{
                  width: { xs: '90%', sm: '80%', md: '100%' },
                  maxWidth: '550px',
                  height: { xs: 'auto', md: '400px' },
                  borderRadius: '24px',
                  overflow: 'hidden',
                  boxShadow: '0 20px 40px rgba(0,0,0,0.15)',
                  transform: { xs: 'none', md: 'translateX(-10%)' },
                  position: 'relative',
                  transition: 'transform 0.3s ease, box-shadow 0.3s ease',
                  '&:hover': {
                    transform: { xs: 'translateY(-8px)', md: 'translateX(-10%) translateY(-8px)' },
                    boxShadow: '0 25px 50px rgba(0,0,0,0.2)'
                  }
                }}
              >
                <Box
                  component="img"
                  src="/codingrippler/about-us/teamwork.jpg"
                  alt="Team collaboration at CodingRippler"
                  sx={{
                    width: '100%',
                    height: '100%',
                    objectFit: 'cover',
                    transition: 'transform 0.5s ease',
                    '&:hover': {
                      transform: 'scale(1.03)'
                    }
                  }}
                />
              </Box>
            </Grid>
          </Grid>
        </Container>
      </Box>
      <ContactDialog open={open} onClose={handleClose} />
    </>
  );
};

export default AboutUsHero;