// import {
//   Cancel as CancelIcon,
//   CheckCircle as CheckCircleIcon
// } from "@mui/icons-material";
// import {
//   Box,
//   Button,
//   Card,
//   CardContent,
//   Chip,
//   Divider,
//   FormControlLabel,
//   Grid,
//   List,
//   ListItem,
//   ListItemIcon,
//   ListItemText,
//   Switch,
//   Typography
// } from "@mui/material";
// import { styled } from "@mui/material/styles";
// import React, { useState } from "react";
// import AddonSection from "./AddonSection";
// import { addonServices, aiStudioPlans } from "./pricing-data";

// // Styled components
// const StyledCard = styled(Card)(({ theme, premium, enterprise }) => ({
//   height: "100%",
//   display: "flex",
//   flexDirection: "column",
//   transition: "all 0.3s ease",
//   position: "relative",
//   overflow: "hidden",
//   borderRadius: "16px",
//   border: premium
//     ? `2px solid ${theme.palette.primary.main}`
//     : "1px solid rgba(0,0,0,0.08)",
//   backgroundColor: enterprise
//     ? theme.palette.primary.main
//     : theme.palette.background.paper,
//   color: enterprise ? theme.palette.common.white : "inherit",
//   boxShadow: premium
//     ? "0 12px 24px rgba(96,86,255,0.15)"
//     : "0 6px 20px rgba(0,0,0,0.05)",
//   "&:hover": {
//     transform: "translateY(-8px)",
//     boxShadow: premium
//       ? "0 20px 40px rgba(96,86,255,0.2)"
//       : "0 12px 28px rgba(0,0,0,0.1)",
//   },
//   // Ensure card doesn't overflow on mobile
//   [theme.breakpoints.down('sm')]: {
//     width: '100%',
//     margin: '0 auto',
//     maxWidth: 'calc(100vw - 32px)'
//   }
// }));

// const PopularBanner = styled(Box)(({ theme }) => ({
//   position: "absolute",
//   width: "100%",
//   top: 0,
//   left: 0,
//   right: 0,
//   padding: "12px",
//   textAlign: "center",
//   backgroundColor: theme.palette.primary.main,
//   color: theme.palette.common.white,
//   fontWeight: 700,
//   fontSize: "14px",
//   letterSpacing: "1px",
//   zIndex: 1,
//   textTransform: "uppercase",
//   marginBottom: "24px",
// }));

// // Combine all possible features across all plans for better comparison
// const getAllFeatures = (plans) => {
//   const allFeatures = new Set();
  
//   plans.forEach(plan => {
//     plan.features.forEach(feature => {
//       allFeatures.add(feature);
//     });
//   });
  
//   return Array.from(allFeatures);
// };

// // Function to determine if a feature is from a previous plan
// const isFeatureFromPreviousPlan = (feature, planIndex, plans) => {
//   // Return false if this is the first plan (no previous plans)
//   if (planIndex === 0) return false;
  
//   // List of phrases that indicate a feature is from a previous plan
//   const previousPlanPhrases = [
//     'Everything in Startup plan',
//     'Everything in Essential plan',
//     'Everything in Growth plan'
//   ];
  
//   // Check if the current plan explicitly mentions it includes everything from a previous plan
//   const hasPreviousPlanFeatures = plans[planIndex].features.some(f => 
//     previousPlanPhrases.some(phrase => f.includes(phrase))
//   );
  
//   if (hasPreviousPlanFeatures) {
//     // Check if the feature appears in any previous plan
//     for (let i = 0; i < planIndex; i++) {
//       if (plans[i].features.includes(feature)) {
//         return true;
//       }
//     }
//   }
  
//   return false;
// };

// // Function to check if a feature should be hidden due to an upgraded version
// const isSupersededFeature = (feature, planIndex, plans) => {
//   // Check for support duration features
//   if (feature.includes("post-launch support") || feature.includes("support & maintenance")) {
//     // Get the current support duration for this feature
//     const durationMatch = feature.match(/(\d+)-(day|month)/);
//     if (!durationMatch) return false;
    
//     const currentDuration = durationMatch[1];
//     const durationType = durationMatch[2]; // "day" or "month"
    
//     // Look for a higher duration in the current plan's features
//     const higherDurationFeature = plans[planIndex].features.find(f => {
//       // Check if it's a support feature
//       if (!f.includes("post-launch support") && !f.includes("support & maintenance")) return false;
      
//       // Get the duration of this support feature
//       const matchDuration = f.match(/(\d+)-(day|month)/);
//       if (!matchDuration) return false;
      
//       // If types don't match (day vs month), assume month is higher
//       if (durationType !== matchDuration[2]) {
//         return matchDuration[2] === "month";
//       }
      
//       // Compare durations
//       return parseInt(matchDuration[1]) > parseInt(currentDuration);
//     });
    
//     return !!higherDurationFeature;
//   }
  
//   return false;
// };

// const AIStudioPricing = ({ handleContactSales }) => {
//   const [billingType, setBillingType] = useState("monthly");

//   const handleBillingToggle = (event) => {
//     setBillingType(event.target.checked ? "yearly" : "monthly");
//   };
  
//   // Get all unique features for comparison
//   const allFeatures = getAllFeatures(aiStudioPlans);

//   return (
//     <>
//       {/* Billing Toggle */}
//       <Box
//         sx={{
//           display: "flex",
//           flexDirection: "column",
//           alignItems: "center",
//           justifyContent: "center",
//           mb: 4,
//           width: "100%"
//         }}
//         id = "aiStudio-pricing"      
//         >
//         <Box
//           sx={{
//             display: "flex",
//             alignItems: "center",
//             justifyContent: "center",
//           }}
//         >
//           <Typography
//             variant="subtitle1"
//             fontWeight={billingType === "monthly" ? 700 : 500}
//             color={
//               billingType === "monthly"
//                 ? "text.primary"
//                 : "text.secondary"
//             }
//             sx={{ width: "80px", textAlign: "right" }}
//           >
//             Monthly
//           </Typography>

//           <Box sx={{ position: "relative", mx: 1 }}>
//             <FormControlLabel
//               control={
//                 <Switch
//                   checked={billingType === "yearly"}
//                   onChange={handleBillingToggle}
//                   color="primary"
//                 />
//               }
//               label=""
//             />
//           </Box>

//           <Typography
//             variant="subtitle1"
//             fontWeight={billingType === "yearly" ? 700 : 500}
//             color={
//               billingType === "yearly"
//                 ? "text.primary"
//                 : "text.secondary"
//             }
//             sx={{ width: "80px", textAlign: "left" }}
//           >
//             Yearly
//           </Typography>
//         </Box>

//         {/* Chip positioned below the toggle, only visible when yearly is selected */}
//         <Box
//           sx={{
//             mt: 1,
//             height: "24px", // Fixed height to prevent layout shift
//             visibility: billingType === "yearly" ? "visible" : "hidden",
//             opacity: billingType === "yearly" ? 1 : 0,
//             transition: "opacity 0.3s ease",
//           }}
//         >
//           {billingType === "yearly" && (
//             <Chip
//               label="Save 2 months"
//               size="small"
//               sx={{
//                 fontWeight: 700,
//                 bgcolor: "rgba(46, 204, 113, 0.15)",
//                 color: "#2ecc71",
//               }}
//             />
//           )}
//         </Box>
//       </Box>

//       {/* Pricing Cards */}
//       <Grid container spacing={{ xs: 2, md: 4 }} justifyContent="center" sx={{ width: '100%', mx: 'auto' }}>
//         {aiStudioPlans.map((plan, index) => (
//           <Grid
//             item
//             xs={12}
//             sm={12}
//             md={4}
//             key={index}
//             sx={{
//               transform: plan.popular ? { md: "scale(1.05)" } : "none",
//               zIndex: plan.popular ? 10 : 1,
//               mt: plan.popular ? { xs: 4, md: 0 } : 0,
//               mb: plan.popular ? { xs: 4, md: 0 } : 0,
//               // Ensure grid items don't overflow
//               width: { xs: '100%', md: 'auto' }
//             }}
//           >
//             <StyledCard
//               elevation={plan.popular ? 10 : 2}
//               premium={plan.popular}
//               enterprise={plan.enterprise}
//             >
//               {plan.popular && (
//                 <Box
//                   sx={{
//                     position: "relative",
//                     height: "50px",
//                     width: "100%",
//                     mb: 1,
//                   }}
//                 >
//                   <PopularBanner>Most Popular</PopularBanner>
//                 </Box>
//               )}

//               <CardContent
//                 sx={{
//                   p: { xs: 2, md: 4 },
//                   pt: plan.popular ? 0 : { xs: 2, md: 4 },
//                 }}
//               >
//                 {/* Card Header */}
//                 <Box textAlign="center" mb={3}>
//                   <Typography
//                     variant="h3"
//                     component="h2"
//                     fontWeight={800}
//                     sx={{
//                       mb: 1,
//                       color: plan.enterprise
//                         ? "common.white"
//                         : plan.popular
//                         ? "primary.main"
//                         : "text.primary",
//                       fontSize: { xs: plan.popular ? "2rem" : "1.75rem", md: plan.popular ? "2.5rem" : "2rem" },
//                     }}
//                   >
//                     {plan.name}
//                   </Typography>

//                   <Typography
//                     variant="body1"
//                     sx={{
//                       color: plan.enterprise
//                         ? "rgba(255, 255, 255, 0.7)"
//                         : "text.secondary",
//                       mb: 3,
//                       fontSize: { xs: '0.875rem', md: '1rem' }
//                     }}
//                   >
//                     {plan.audience}
//                   </Typography>
//                 </Box>

//                 {/* Pricing */}
//                 <Box textAlign="center" mb={3}>
//                   {/* Original price with strikethrough */}
//                   {plan.monthlyPrice !== null && (
//                     <Typography
//                       variant="body1"
//                       sx={{
//                         textDecoration: "line-through",
//                         color: plan.enterprise
//                           ? "rgba(255, 255, 255, 0.6)"
//                           : "text.secondary",
//                         mb: 0.5,
//                         fontSize: { xs: '0.875rem', md: '1rem' }
//                       }}
//                     >
//                       ${plan.monthlyPrice}/month
//                     </Typography>
//                   )}

//                   {/* Current price */}
//                   {plan.monthlyPrice !== null ? (
//                     <Box
//                       sx={{
//                         display: "flex",
//                         alignItems: "flex-end",
//                         justifyContent: "center",
//                         mb: 1,
//                       }}
//                     >
//                       <Typography
//                         variant="h3"
//                         component="span"
//                         sx={{
//                           fontWeight: 800,
//                           lineHeight: 1,
//                           color: plan.enterprise
//                             ? "common.white"
//                             : "text.primary",
//                           fontSize: { xs: "2.5rem", md: "3rem" }
//                         }}
//                       >
//                         $
//                         {billingType === "yearly"
//                           ? plan.yearlyPerMonth
//                           : plan.discountedMonthlyPrice}
//                       </Typography>

//                       <Typography
//                         variant="h6"
//                         component="span"
//                         sx={{
//                           fontWeight: 500,
//                           color: plan.enterprise
//                             ? "rgba(255, 255, 255, 0.7)"
//                             : "text.secondary",
//                           ml: 0.5,
//                           mb: 0.5,
//                           fontSize: { xs: '1rem', md: '1.25rem' }
//                         }}
//                       >
//                         /{billingType === "yearly" ? "mo" : "month"}
//                       </Typography>
//                     </Box>
//                   ) : (
//                     <Typography
//                       variant="h3"
//                       sx={{
//                         fontWeight: 800,
//                         color: plan.enterprise
//                           ? "common.white"
//                           : "text.primary",
//                         mb: 1,
//                         fontSize: { xs: "2.5rem", md: "3rem" }
//                       }}
//                     >
//                       Custom
//                     </Typography>
//                   )}

//                   {/* Yearly total (if applicable) */}
//                   {billingType === "yearly" && plan.yearlyPrice && (
//                     <Typography
//                       variant="body2"
//                       sx={{
//                         color: plan.enterprise
//                           ? "rgba(255, 255, 255, 0.7)"
//                           : "text.secondary",
//                         mb: 1,
//                         fontSize: { xs: '0.75rem', md: '0.875rem' }
//                       }}
//                     >
//                       ${plan.yearlyPrice} billed annually
//                     </Typography>
//                   )}

//                   {/* Discount badge */}
//                   {plan.discountPercentage && (
//                     <Chip
//                       label={
//                         billingType === "yearly"
//                           ? plan.yearlySavingsBadge
//                           : `${plan.discountPercentage}% OFF`
//                       }
//                       size="small"
//                       sx={{
//                         fontWeight: 700,
//                         bgcolor: "rgba(46, 204, 113, 0.15)",
//                         color: "#2ecc71",
//                         border: "none",
//                         fontSize: { xs: '0.7rem', md: '0.75rem' }
//                       }}
//                     />
//                   )}
//                 </Box>

//                 <Divider
//                   sx={{
//                     my: 2,
//                     borderColor: plan.enterprise
//                       ? "rgba(255, 255, 255, 0.15)"
//                       : "divider",
//                   }}
//                 />

//                 {/* Usage info */}
//                 <Box textAlign="center" mb={2}>
//                   <Typography
//                     variant="body1"
//                     sx={{
//                       fontWeight: 600,
//                       color: plan.enterprise
//                         ? "common.white"
//                         : "text.primary",
//                       mb: 0.5,
//                       fontSize: { xs: '0.875rem', md: '1rem' }
//                     }}
//                   >
//                     {plan.usageLimits}
//                   </Typography>

//                   <Typography
//                     variant="body2"
//                     sx={{
//                       color: plan.enterprise
//                         ? "rgba(255, 255, 255, 0.7)"
//                         : "text.secondary",
//                       fontSize: { xs: '0.75rem', md: '0.875rem' }
//                     }}
//                   >
//                     {plan.support}
//                   </Typography>
//                 </Box>

//                 {/* Features */}
//                 <List disablePadding sx={{ mb: 4 }}>
//                   {allFeatures.map((feature, featureIndex) => {
//                     // Skip "Everything in X plan" features
//                     if (feature.startsWith('Everything in ')) {
//                       return null;
//                     }
                    
//                     const planIndex = aiStudioPlans.indexOf(plan);
                    
//                     // Check if feature is directly included in this plan
//                     const isDirectlyIncluded = plan.features.includes(feature);
                    
//                     // Check if feature is from a previous plan (only for higher tier plans)
//                     const isFromPreviousPlan = isFeatureFromPreviousPlan(feature, planIndex, aiStudioPlans);
                    
//                     // Check if this feature is superseded by a better version
//                     const isSuperseded = isSupersededFeature(feature, planIndex, aiStudioPlans);
                    
//                     // Don't show superseded features
//                     if (isSuperseded) {
//                       return null;
//                     }
                    
//                     // Feature is included if it's directly in this plan or inherited from previous plan
//                     const isIncluded = isDirectlyIncluded || isFromPreviousPlan;
                    
//                     return (
//                       <ListItem
//                         key={featureIndex}
//                         disablePadding
//                         disableGutters
//                         sx={{ mb: 1 }}
//                       >
//                         <ListItemIcon sx={{ minWidth: 30 }}>
//                           {isIncluded ? (
//                             <CheckCircleIcon
//                               fontSize="small"
//                               sx={{
//                                 color: plan.enterprise
//                                   ? "common.white" // White check mark for enterprise plan
//                                   : "#2ecc71", // Green color for included features in other plans
//                                 fontSize: { xs: '1rem', md: '1.25rem' }
//                               }}
//                             />
//                           ) : (
//                             <CancelIcon
//                               fontSize="small"
//                               sx={{
//                                 color: plan.enterprise
//                                   ? "rgba(255, 255, 255, 0.4)"
//                                   : "rgba(0, 0, 0, 0.25)", // Gray color for excluded features
//                                 fontSize: { xs: '1rem', md: '1.25rem' }
//                               }}
//                             />
//                           )}
//                         </ListItemIcon>
//                         <ListItemText
//                           primary={feature}
//                           primaryTypographyProps={{
//                             variant: "body2",
//                             color: plan.enterprise
//                               ? isIncluded 
//                                 ? "common.white" 
//                                 : "rgba(255, 255, 255, 0.5)"
//                               : isIncluded 
//                                 ? "text.primary" 
//                                 : "text.secondary",
//                             fontWeight: isIncluded ? 500 : 400,
//                             sx: {
//                               opacity: isIncluded ? 1 : 0.7,
//                               fontSize: { xs: '0.75rem', md: '0.875rem' }
//                             }
//                           }}
//                         />
//                       </ListItem>
//                     );
//                   })}
//                 </List>

//                 {/* CTA Button */}
//                 <Button
//                   variant={plan.enterprise ? "outlined" : "contained"}
//                   fullWidth
//                   size="large"
//                   onClick={() => {handleContactSales(plan.category, plan.name)}}
//                   sx={{
//                     py: { xs: 1, md: plan.popular ? 1.5 : 1.2 },
//                     fontSize: { xs: "14px", md: plan.popular ? "16px" : "15px" },
//                     fontWeight: 600,
//                     borderRadius: 30,
//                     bgcolor: plan.enterprise
//                       ? "common.white"
//                       : plan.popular
//                       ? "primary.main"
//                       : undefined,
//                     color: plan.enterprise
//                       ? "primary.main"
//                       : plan.popular
//                       ? "white"
//                       : undefined,
//                     borderColor: plan.enterprise ? "white" : undefined,
//                     textTransform: "none",
//                     boxShadow: plan.popular
//                       ? "0 8px 16px rgba(96, 86, 255, 0.2)"
//                       : "none",
//                     "&:hover": {
//                       bgcolor: plan.enterprise
//                         ? "rgba(255, 255, 255, 0.9)"
//                         : plan.popular
//                         ? "primary.dark"
//                         : undefined,
//                       boxShadow: plan.popular
//                         ? "0 12px 20px rgba(96, 86, 255, 0.3)"
//                         : "none",
//                     },
//                   }}
//                 >
//                   {plan.popular ? "Contact Sales" : "Contact Sales"}
//                 </Button>
//               </CardContent>
//             </StyledCard>
//           </Grid>
//         ))}
//       </Grid>

//       {/* Add-on section */}
//       <AddonSection addonServices={addonServices} handleContactSales={handleContactSales} />
//     </>
//   );
// };

// export default AIStudioPricing;



import React, { useState } from "react";
import {
  Box,
  Button,
  Card,
  CardContent,
  Chip,
  Divider,
  FormControlLabel,
  Grid,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Switch,
  Typography
} from "@mui/material";
import {
  Cancel as CancelIcon,
  CheckCircle as CheckCircleIcon
} from "@mui/icons-material";
import { styled } from "@mui/material/styles";
import AddonSection from "./AddonSection";
import { addonServices, aiStudioPlans } from "./pricing-data";
// Import currency converter components
import { 
  CurrencyProvider, 
  CurrencySelector, 
  PriceDisplay, 
  OriginalPrice,
} from "./PriceConverter";

// Styled components
const StyledCard = styled(Card)(({ theme, premium, enterprise }) => ({
  height: "100%",
  display: "flex",
  flexDirection: "column",
  transition: "all 0.3s ease",
  position: "relative",
  overflow: "hidden",
  borderRadius: "16px",
  border: premium
    ? `2px solid ${theme.palette.primary.main}`
    : "1px solid rgba(0,0,0,0.08)",
  backgroundColor: enterprise
    ? theme.palette.primary.main
    : theme.palette.background.paper,
  color: enterprise ? theme.palette.common.white : "inherit",
  boxShadow: premium
    ? "0 12px 24px rgba(96,86,255,0.15)"
    : "0 6px 20px rgba(0,0,0,0.05)",
  "&:hover": {
    transform: "translateY(-8px)",
    boxShadow: premium
      ? "0 20px 40px rgba(96,86,255,0.2)"
      : "0 12px 28px rgba(0,0,0,0.1)",
  },
  // Ensure card doesn't overflow on mobile
  [theme.breakpoints.down('sm')]: {
    width: '100%',
    margin: '0 auto',
    maxWidth: 'calc(100vw - 32px)'
  }
}));

const PopularBanner = styled(Box)(({ theme }) => ({
  position: "absolute",
  width: "100%",
  top: 0,
  left: 0,
  right: 0,
  padding: "12px",
  textAlign: "center",
  backgroundColor: theme.palette.primary.main,
  color: theme.palette.common.white,
  fontWeight: 700,
  fontSize: "14px",
  letterSpacing: "1px",
  zIndex: 1,
  textTransform: "uppercase",
  marginBottom: "24px",
}));

// Combine all possible features across all plans for better comparison
// const getAllFeatures = (plans) => {
//   const allFeatures = new Set();
  
//   plans.forEach(plan => {
//     plan.features.forEach(feature => {
//       allFeatures.add(feature);
//     });
//   });
  
//   return Array.from(allFeatures);
// };

// Function to determine if a feature is from a previous plan
// const isFeatureFromPreviousPlan = (feature, planIndex, plans) => {
//   // Return false if this is the first plan (no previous plans)
//   if (planIndex === 0) return false;
  
//   // List of phrases that indicate a feature is from a previous plan
//   const previousPlanPhrases = [
//     'Everything in Startup plan',
//     'Everything in Essential plan',
//     'Everything in Growth plan'
//   ];
  
//   // Check if the current plan explicitly mentions it includes everything from a previous plan
//   const hasPreviousPlanFeatures = plans[planIndex].features.some(f => 
//     previousPlanPhrases.some(phrase => f.includes(phrase))
//   );
  
//   if (hasPreviousPlanFeatures) {
//     // Check if the feature appears in any previous plan
//     for (let i = 0; i < planIndex; i++) {
//       if (plans[i].features.includes(feature)) {
//         return true;
//       }
//     }
//   }
  
//   return false;
// };

// Function to check if a feature should be hidden due to an upgraded version
// const isSupersededFeature = (feature, planIndex, plans) => {
//   // Check for support duration features
//   if (feature.includes("post-launch support") || feature.includes("support & maintenance")) {
//     // Get the current support duration for this feature
//     const durationMatch = feature.match(/(\d+)-(day|month)/);
//     if (!durationMatch) return false;
    
//     const currentDuration = durationMatch[1];
//     const durationType = durationMatch[2]; // "day" or "month"
    
//     // Look for a higher duration in the current plan's features
//     const higherDurationFeature = plans[planIndex].features.find(f => {
//       // Check if it's a support feature
//       if (!f.includes("post-launch support") && !f.includes("support & maintenance")) return false;
      
//       // Get the duration of this support feature
//       const matchDuration = f.match(/(\d+)-(day|month)/);
//       if (!matchDuration) return false;
      
//       // If types don't match (day vs month), assume month is higher
//       if (durationType !== matchDuration[2]) {
//         return matchDuration[2] === "month";
//       }
      
//       // Compare durations
//       return parseInt(matchDuration[1]) > parseInt(currentDuration);
//     });
    
//     return !!higherDurationFeature;
//   }
  
//   return false;
// };

// const isSupersededFeature = (feature, planIndex, plans) => {
//   // Define feature groups that need to be superseded
//   const supersededMappings = [
//     { baseFeature: "1 data source connection", higherFeature: "Up to 3 data source connections" },
//     { baseFeature: "1 workflow customization", higherFeature: "Up to 3 workflow customizations" },
//     { baseFeature: "Up to 3 data source connections", higherFeature: "Custom data source connections" },
//     { baseFeature: "Up to 3 workflow customizations", higherFeature: "Custom workflow automation" }
//   ];

//   // Check if the current feature is superseded in the same plan
//   return supersededMappings.some(({ baseFeature, higherFeature }) => {
//     return feature.includes(baseFeature) &&
//       plans[planIndex].features.some(f => f.includes(higherFeature));
//   });
// };

// Modified AIStudioPricing component
const AIStudioPricing = ({ handleContactSales }) => {
  const [billingType, setBillingType] = useState("monthly");

  const handleBillingToggle = (event) => {
    setBillingType(event.target.checked ? "yearly" : "monthly");
  };
  
  // const allFeatures = getAllFeatures(aiStudioPlans);

  return (
    // Wrap everything in the CurrencyProvider
    <CurrencyProvider>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          width: "100%"
        }}
        id="aiStudio-pricing"
      >
        {/* Add the currency selector at the top level */}
        <Box sx={{ width: '100%', display: 'flex', justifyContent: 'flex-end', mb: 2 }}>
          <CurrencySelector />
        </Box>
        
        {/* Billing Toggle - Remains unchanged */}
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            mb: 4,
            width: "100%"
          }}
        >
          {/* Your existing billing toggle code */}
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Typography
              variant="subtitle1"
              fontWeight={billingType === "monthly" ? 700 : 500}
              color={billingType === "monthly" ? "text.primary" : "text.secondary"}
              sx={{ width: "80px", textAlign: "right" }}
            >
              Monthly
            </Typography>

            <Box sx={{ position: "relative", mx: 1 }}>
              <FormControlLabel
                control={
                  <Switch
                    checked={billingType === "yearly"}
                    onChange={handleBillingToggle}
                    color="primary"
                  />
                }
                label=""
              />
            </Box>

            <Typography
              variant="subtitle1"
              fontWeight={billingType === "yearly" ? 700 : 500}
              color={billingType === "yearly" ? "text.primary" : "text.secondary"}
              sx={{ width: "80px", textAlign: "left" }}
            >
              Yearly
            </Typography>
          </Box>

          {/* Chip positioned below the toggle */}
          <Box
            sx={{
              mt: 1,
              height: "24px",
              visibility: billingType === "yearly" ? "visible" : "hidden",
              opacity: billingType === "yearly" ? 1 : 0,
              transition: "opacity 0.3s ease",
            }}
          >
            {billingType === "yearly" && (
              <Chip
                label="Save 2 months"
                size="small"
                sx={{
                  fontWeight: 700,
                  bgcolor: "rgba(46, 204, 113, 0.15)",
                  color: "#2ecc71",
                }}
              />
            )}
          </Box>
        </Box>

        {/* Pricing Cards */}
        <Grid container spacing={{ xs: 2, md: 4 }} justifyContent="center" sx={{ width: '100%', mx: 'auto' }}>
          {aiStudioPlans.map((plan, index) => (
            <Grid
              item
              xs={12}
              sm={12}
              md={4}
              key={index}
              sx={{
                transform: plan.popular ? { md: "scale(1.05)" } : "none",
                zIndex: plan.popular ? 10 : 1,
                mt: plan.popular ? { xs: 4, md: 0 } : 0,
                mb: plan.popular ? { xs: 4, md: 0 } : 0,
                width: { xs: '100%', md: 'auto' }
              }}
            >
              <StyledCard
                elevation={plan.popular ? 10 : 2}
                premium={plan.popular}
                enterprise={plan.enterprise}
              >
                {plan.popular && (
                  <Box
                    sx={{
                      position: "relative",
                      height: "50px",
                      width: "100%",
                      mb: 1,
                    }}
                  >
                    <PopularBanner>Most Popular</PopularBanner>
                  </Box>
                )}

                <CardContent
                  sx={{
                    p: { xs: 2, md: 4 },
                    pt: plan.popular ? 0 : { xs: 2, md: 4 },
                  }}
                >
                  {/* Card Header - Unchanged */}
                  <Box textAlign="center" mb={3}>
                    <Typography
                      variant="h3"
                      component="h2"
                      fontWeight={800}
                      sx={{
                        mb: 1,
                        color: plan.enterprise
                          ? "common.white"
                          : plan.popular
                          ? "primary.main"
                          : "text.primary",
                        fontSize: { xs: plan.popular ? "2rem" : "1.75rem", md: plan.popular ? "2.5rem" : "2rem" },
                      }}
                    >
                      {plan.name}
                    </Typography>

                    <Typography
                      variant="body1"
                      sx={{
                        color: plan.enterprise
                          ? "rgba(255, 255, 255, 0.7)"
                          : "text.secondary",
                        mb: 3,
                        fontSize: { xs: '0.875rem', md: '1rem' }
                      }}
                    >
                      {plan.audience}
                    </Typography>
                  </Box>

                  {/* UPDATED Pricing Section with Currency Conversion */}
                  <Box textAlign="center" mb={3}>
                    {/* Original price with strikethrough - Using OriginalPrice component */}
                    {plan.monthlyPrice !== null && (
                      <OriginalPrice 
                        usdPrice={plan.monthlyPrice} 
                        isEnterprise={plan.enterprise}
                      />
                    )}

                    {/* Current price - Using PriceDisplay component */}
                    <PriceDisplay
                      usdPrice={plan.monthlyPrice}
                      discountedPrice={plan.discountedMonthlyPrice}
                      yearlyPrice={plan.yearlyPrice}
                      yearlyPerMonth={plan.yearlyPerMonth}
                      billingType={billingType}
                      isEnterprise={plan.enterprise}
                      fontSize={{ xs: "2.5rem", md: "3rem" }}
                    />

                    {/* Discount badge - Unchanged */}
                    {plan.discountPercentage && (
                      <Chip
                        label={
                          billingType === "yearly"
                            ? plan.yearlySavingsBadge
                            : `${plan.discountPercentage}% OFF`
                        }
                        size="small"
                        sx={{
                          fontWeight: 700,
                          bgcolor: "rgba(46, 204, 113, 0.15)",
                          color: "#2ecc71",
                          border: "none",
                          fontSize: { xs: '0.7rem', md: '0.75rem' }
                        }}
                      />
                    )}
                  </Box>

                  <Divider
                    sx={{
                      my: 2,
                      borderColor: plan.enterprise
                        ? "rgba(255, 255, 255, 0.15)"
                        : "divider",
                    }}
                  />

                  {/* Rest of the card content remains the same */}
                  {/* Usage Info */}
                  <Box textAlign="center" mb={2}>
                    {/* Your existing usage info code */}
                    <Typography
                      variant="body1"
                      sx={{
                        fontWeight: 600,
                        color: plan.enterprise
                          ? "common.white"
                          : "text.primary",
                        mb: 0.5,
                        fontSize: { xs: '0.875rem', md: '1rem' }
                      }}
                    >
                      {plan.usageLimits}
                    </Typography>

                    <Typography
                      variant="body2"
                      sx={{
                        color: plan.enterprise
                          ? "rgba(255, 255, 255, 0.7)"
                          : "text.secondary",
                        fontSize: { xs: '0.75rem', md: '0.875rem' }
                      }}
                    >
                      {plan.support}
                    </Typography>
                  </Box>

                  {/* Features List */}
                  <List disablePadding sx={{ mb: 4 }}>
                    {/* Your existing features list code */}
                    {/* {allFeatures.map((feature, featureIndex) => {
                      // Skip "Everything in X plan" features
                      if (feature.startsWith('Everything in ')) {
                        return null;
                      }
                      
                      const planIndex = aiStudioPlans.indexOf(plan);
                      const isDirectlyIncluded = plan.features.includes(feature);
                      const isFromPreviousPlan = isFeatureFromPreviousPlan(feature, planIndex, aiStudioPlans);
                      const isSuperseded = false;//isSupersededFeature(feature, planIndex, aiStudioPlans);
                      
                      if (isSuperseded) {
                        return null;
                      }
                      
                      const isIncluded = isDirectlyIncluded || isFromPreviousPlan;
                      
                      return (
                        <ListItem
                          key={featureIndex}
                          disablePadding
                          disableGutters
                          sx={{ mb: 1 }}
                        >
                          <ListItemIcon sx={{ minWidth: 30 }}>
                            {isIncluded ? (
                              <CheckCircleIcon
                                fontSize="small"
                                sx={{
                                  color: plan.enterprise
                                    ? "common.white"
                                    : "#2ecc71",
                                  fontSize: { xs: '1rem', md: '1.25rem' }
                                }}
                              />
                            ) : (
                              <CancelIcon
                                fontSize="small"
                                sx={{
                                  color: plan.enterprise
                                    ? "rgba(255, 255, 255, 0.4)"
                                    : "rgba(0, 0, 0, 0.25)",
                                  fontSize: { xs: '1rem', md: '1.25rem' }
                                }}
                              />
                            )}
                          </ListItemIcon>
                          <ListItemText
                            primary={feature}
                            primaryTypographyProps={{
                              variant: "body2",
                              color: plan.enterprise
                                ? isIncluded 
                                  ? "common.white" 
                                  : "rgba(255, 255, 255, 0.5)"
                                : isIncluded 
                                  ? "text.primary" 
                                  : "text.secondary",
                              fontWeight: isIncluded ? 500 : 400,
                              sx: {
                                opacity: isIncluded ? 1 : 0.7,
                                fontSize: { xs: '0.75rem', md: '0.875rem' }
                              }
                            }}
                          />
                        </ListItem>
                      );
                    })} */}

                    {plan.features.map((feature, featureIndex) => {
                      // Skip "Everything in X plan" features
                      // if (feature.startsWith('Everything in ')) {
                      //   return null;
                      // }
                      
                   //   const planIndex = aiStudioPlans.indexOf(plan);
                      // const isDirectlyIncluded = plan.features.includes(feature);
                      // const isFromPreviousPlan = isFeatureFromPreviousPlan(feature, planIndex, aiStudioPlans);
                      const isSuperseded = false;//isSupersededFeature(feature, planIndex, aiStudioPlans);
                      
                      if (isSuperseded) {
                        return null;
                      }
                      
                      // const isIncluded = isDirectlyIncluded || isFromPreviousPlan;
                      
                      return (
                        <ListItem
                          key={featureIndex}
                          disablePadding
                          disableGutters
                          sx={{ mb: 1 }}
                        >
                          <ListItemIcon sx={{ minWidth: 30 }}>
                            {feature.active ? (
                              <CheckCircleIcon
                                fontSize="small"
                                sx={{
                                  color: plan.enterprise
                                    ? "common.white"
                                    : "#2ecc71",
                                  fontSize: { xs: '1rem', md: '1.25rem' }
                                }}
                              />
                            ) : (
                              <CancelIcon
                                fontSize="small"
                                sx={{
                                  color: plan.enterprise
                                    ? "rgba(255, 255, 255, 0.4)"
                                    : "rgba(0, 0, 0, 0.25)",
                                  fontSize: { xs: '1rem', md: '1.25rem' }
                                }}
                              />
                            )}
                          </ListItemIcon>
                          <ListItemText
                            primary={feature.name}
                            primaryTypographyProps={{
                              variant: "body2",
                              color: plan.enterprise
                                ? feature.active 
                                  ? "common.white" 
                                  : "rgba(255, 255, 255, 0.5)"
                                : feature.active 
                                  ? "text.primary" 
                                  : "text.secondary",
                              fontWeight: feature.active ? 500 : 400,
                              sx: {
                                opacity: feature.active ? 1 : 0.7,
                                fontSize: { xs: '0.75rem', md: '0.875rem' }
                              }
                            }}
                          />
                        </ListItem>
                      );
                    })} 
                  </List>

                  {/* CTA Button */}
                  <Button
                    variant={plan.enterprise ? "outlined" : "contained"}
                    fullWidth
                    size="large"
                    onClick={() => {handleContactSales(plan.category, plan.name)}}
                    sx={{
                      py: { xs: 1, md: plan.popular ? 1.5 : 1.2 },
                      fontSize: { xs: "14px", md: plan.popular ? "16px" : "15px" },
                      fontWeight: 600,
                      borderRadius: 30,
                      bgcolor: plan.enterprise
                        ? "common.white"
                        : plan.popular
                        ? "primary.main"
                        : undefined,
                      color: plan.enterprise
                        ? "primary.main"
                        : plan.popular
                        ? "white"
                        : undefined,
                      borderColor: plan.enterprise ? "white" : undefined,
                      textTransform: "none",
                      boxShadow: plan.popular
                        ? "0 8px 16px rgba(96, 86, 255, 0.2)"
                        : "none",
                      "&:hover": {
                        bgcolor: plan.enterprise
                          ? "rgba(255, 255, 255, 0.9)"
                          : plan.popular
                          ? "primary.dark"
                          : undefined,
                        boxShadow: plan.popular
                          ? "0 12px 20px rgba(96, 86, 255, 0.3)"
                          : "none",
                      },
                    }}
                  >
                    {plan.popular ? "Contact Sales" : "Contact Sales"}
                  </Button>
                </CardContent>
              </StyledCard>
            </Grid>
          ))}
        </Grid>

        {/* Add-on section - You would also need to update AddonSection.js to use AddonPrice component */}
        <AddonSection addonServices={addonServices} handleContactSales={handleContactSales} />
      </Box>
    </CurrencyProvider>
  );
};

export default AIStudioPricing;