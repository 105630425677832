import ArrowRightAltIcon from "@mui/icons-material/ArrowRightAlt";
import { Box, Fab, useMediaQuery } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import React, { useRef, useState } from "react";
import ContactDialog from "../CodingRipplerHome/ContactUs/ContactUsDialog";

export const ContactUs = ({ children, ...props }) => {
  const theme = useTheme();
  const isLargeScreen = useMediaQuery(theme.breakpoints.up("lg"));
  const buttonRef = useRef(null);

  const [open, setOpen] = useState(false);
  
  const handleOpen = () => {
    setOpen(true);
  };
  
  const handleClose = (success) => {
    setOpen(false);
    if (success) {
      // Handle successful submission (e.g. show a success toast)
    }
  };  

  const { scrollView, webRippler, commonFlow } = props;

  return (
    <>
      {isLargeScreen && (
        <Fab
          ref={buttonRef}
          variant="extended"
          size="large"
          className={
            scrollView || webRippler || commonFlow ? "button" : "button-white"
          }
          color="primary"
          onKeyDown={(e) => {
            if (e.key === "Enter" || e.key === " ") {
              handleOpen();
            }
          }}
          sx={{ zIndex: 1 }}
          onClick={handleOpen}
          p={10}
        >
          <Box
            display="flex"
            alignItems="center"
            justifyContent="center"
            gap={1}
          >
            Let's Talk
            <ArrowRightAltIcon />
          </Box>
        </Fab>
      )}

      {!isLargeScreen && (
        <Fab
          variant="extended"
          size="large"
          color="primary"
          className="button"
          onClick={handleOpen}
          sx={{ width: "100%", zIndex: 1 }}
        >
          Let's Talk{" "}
        </Fab>
      )}

       <ContactDialog open={open} onClose={handleClose} />

    </>
  );
};
