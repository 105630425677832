import { Box, CardMedia, Grid } from "@mui/material";
import React from "react";
import { ServicesV3 } from "../../CodingRipplerHome/WhatWeDo/WhatWeDoWidgets";
import {
  CommonContainer,
  HeroSubHeader,
  HeroText,
  LeadContainer,
  TopicContainer,
} from "../../CodingRipplerWidgets";
import { SlideInFromTopEffect } from "../../../BoilarPlateJs/MotionEffects";
import HandymanIcon from '@mui/icons-material/Handyman';

export const WhyUs = ({ children, ...props }) => {
  const { pageContent, isPhone, isPhoneorTab } = props;

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Box
          display="flex"
          justifyContent={pageContent?.customStyle?.justifyContent || "center"}
          alignItems={isPhoneorTab ? "left" : "center"}
          flexDirection="column"
        >
          <HeroText
            variant="h4"
            customStyle={{
              textAlign: { xs: "left", sm: "left", lg: "center" },
            }}
          >
            {pageContent.header}
          </HeroText>

          <Box m={2} />

          <HeroSubHeader
            customStyle={{ textAlign: { xs: "left", sm: "left" } }}
          >
            {pageContent.subHeader}
          </HeroSubHeader>

          <Box m={2} />
        </Box>
      </Grid>

      <Grid item xs={12}>
        <Grid container>
          <Grid
            item
            xs={0}
            lg={1}
            sx={{
              display: isPhone ? "none" : "block",
            }}
          ></Grid>

          <Grid item xs={12} lg={10} sx={{}}>
            <ServicesV3
              WhatWeDoData={pageContent.OurWork}
              columnSizeLg={4}
              columnSizeSm={12}
            />
          </Grid>
          <Grid
            item
            xs={0}
            lg={1}
            sx={{
              display: isPhone ? "none" : "block",
            }}
          ></Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};


export const WhatWeOffer = ({ children, ...props }) => {
  
  const { pageContent, isPhone} = props;

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <SlideInFromTopEffect>
          <TopicContainer topicText="What We Offer">
            <HandymanIcon />
          </TopicContainer>
        </SlideInFromTopEffect>
      </Grid>

      <Grid item xs={12}>
        <Grid container>
          <Grid
            item
            xs={0}
            lg={1}
            sx={{
              display: isPhone ? "none" : "block",
            }}
          ></Grid>

          <Grid item xs={12}  lg={10} sx={{}}>
            <ServicesV3
              WhatWeDoData={pageContent.OurWork}
              columnSizeLg={4}
              columnSizeSm={4}
            />
          </Grid>
          <Grid
            item
            xs={0}
            lg={1}
            sx={{
              display: isPhone ? "none" : "block",
            }}
          ></Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};


export const WhatWeHave = ({ children, ...props }) => {
  const { leadData, isPhoneorTab } = props;

  return (
    <Grid
      container
      spacing={2}
      height={{
        xs: "auto",
        // lg: "100vh",
      }}
      // mt={{ xs: 4, lg: 10 }}
      mb={{ xs: 4, lg: 10 }}
    >
      <Grid
        item
        xs={12}
        lg={1}
        sx={{
          display: {
            xs: "none", // Display on extra-small screenslg
            lg: "block", // Hide on large screens
          },
        }}
      ></Grid>
      <Grid item xs={12} lg={6}>
        <Box
          display="flex"
          flexDirection="column"
          justifyContent="center"
          alignItems="center"
        >
          <HeroText
            variant="h4"
            customStyle={{ textAlign: "left", width: "100%" }}
          >
            Driven by Commitment, Powered by Expertise in Web Development
          </HeroText>
          <Box m={2} />
          <HeroSubHeader customStyle={{ textAlign: "left", width: "100%" }}>
            Empowering you to create exceptional products, from subscription
            services to e-commerce stores.{" "}
          </HeroSubHeader>
          <Box m={2} />
          <CommonContainer>
            <LeadContainer data={leadData} isPhoneorTab={isPhoneorTab} />
          </CommonContainer>
        </Box>
      </Grid>

      <Grid
        item
        xs={12}
        lg={4}
        sx={{
          display: {
            xs: "none", // Display on extra-small screens
            lg: "block", // Hide on large screens
          },
        }}
      >
        <Box
          display="flex"
          flexDirection="column"
          justifyContent="center"
          alignItems="center"
        >
          <CardMedia
            component="img"
            image="/codingrippler/webrippler/whyus/expertise.png"
            alt="Expertise"
            sx={{
              objectFit: "cover",
              width: "100%",
              height: "100%",
            }}
          />
        </Box>
      </Grid>
      <Grid item xs={1} lg={1}></Grid>
    </Grid>
  );
};
