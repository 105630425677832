import { Routes, Route } from "react-router-dom";
import { ContactHome } from "./ContactHome";
import { ThankYou } from "./ThankYou";
import { Grid, useMediaQuery, useTheme } from "@mui/material";
import { CommonNavigation } from "../../Navigations/CommonNavigation/CommonNavigation";
import { CRNavigationSmallerDevice } from "../../Navigations/NavigationSmallerDevice/CRNavigationSmallerDevice";
import { SlideInFromTopEffect } from "../../../BoilarPlateJs/MotionEffects";
import { CRFooter } from "../../Footer/CRFooter";
import { Helmet } from "react-helmet-async";

export const Contact = ({ children, ...props }) => {
  const theme = useTheme();
  const isPhone = useMediaQuery(theme.breakpoints.down("sm"));
  const isTab = useMediaQuery(theme.breakpoints.down("lg"));
  const deviceWithSmallHeight = useMediaQuery("(max-height: 750px)");

  const isPhoneOrTab = isPhone || isTab;

  const { logoPath } = props;

  return (
    <>
      <Helmet>
        <title>Contact Us - Coding Rippler</title>
        <meta
          name="description"
          content="Get in touch with Coding Rippler, Web Rippler, and AI Rippler for any inquiries, support, or feedback. We're here to help you."
        />
        <meta property="og:title" content="Contact Us - Coding Rippler" />
        <meta
          property="og:description"
          content="Get in touch with Coding Rippler, Web Rippler, and AI Rippler for any inquiries, support, or feedback. We're here to help you."
        />
        <meta
          property="og:url"
          content="https://www.codingrippler.com/contact"
        />
        <meta property="og:type" content="website" />
        <link rel="canonical" href="https://www.codingrippler.com/contact" />
      </Helmet>
      <Grid container>
        <Grid item xs={12}>
          {isPhoneOrTab ? (
            <CRNavigationSmallerDevice
              isPhone={isPhone}
              isTab={isTab}
              deviceWithSmallHeight={deviceWithSmallHeight}
              logoPath={logoPath}
            />
          ) : (
            <CommonNavigation
              isPhone={isPhone}
              isTab={isTab}
              deviceWithSmallHeight={deviceWithSmallHeight}
              logoPath={logoPath}
            />
          )}
        </Grid>
        <Routes>
          <Route
            path="/"
            element={
              <ContactHome
                isPhone={isPhone}
                isTab={isTab}
                isPhoneorTab={isPhoneOrTab}
                deviceWithSmallHeight={deviceWithSmallHeight}
                theme={theme}
              />
            }
          />

          <Route
            path="/success"
            element={
              <ThankYou
                isPhone={isPhone}
                isTab={isTab}
                isPhoneorTab={isPhoneOrTab}
                deviceWithSmallHeight={deviceWithSmallHeight}
                theme={theme}
              />
            }
          />
        </Routes>

        <Grid item xs={12} className="white-bg">
          <SlideInFromTopEffect>
            <CRFooter
              isPhone={isPhone}
              isPhoneorTab={isPhoneOrTab}
              isTab={isTab}
              deviceWithSmallHeight={deviceWithSmallHeight}
            />
          </SlideInFromTopEffect>
        </Grid>
      </Grid>
    </>
  );
};
