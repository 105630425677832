import { Box } from "@mui/material";
import React from "react";
import { useNavigate } from "react-router-dom";
import {
  CommonButtonV2,
  HeroSubHeader,
  HeroText,
} from "../../CodingRipplerWidgets";

export const ThankYou = () => {
  const navigate = useNavigate();

  const handleBackToHome = () => {
    navigate("/");
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        minHeight: 600,
        height: "100vh",
        width: "100%",
        textAlign: "center",
        padding: 3,
        gap: 2,
      }}
    >
      <HeroText variant="h4">Thank You!</HeroText>
      <HeroSubHeader variant="subtitle1" gutterBottom>
        Your submission has been received.
      </HeroSubHeader>
      <HeroSubHeader variant="body1" gutterBottom>
        We will get back to you shortly.
      </HeroSubHeader>

      <CommonButtonV2
        buttonText={"Back to Home"}
        clickHandler={handleBackToHome}
      />
    </Box>
  );
};
