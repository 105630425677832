import ApiIcon from '@mui/icons-material/Api';
import CodeIcon from '@mui/icons-material/Code';
import DesignServicesIcon from '@mui/icons-material/DesignServices';
import DevicesIcon from '@mui/icons-material/Devices';
import HubIcon from "@mui/icons-material/Hub";
import SpeedIcon from '@mui/icons-material/Speed';
import {
  alpha,
  Box,
  Card,
  CardContent,
  Container,
  Divider,
  Grid,
  Typography,
  useTheme
} from '@mui/material';
import React from 'react';
import { SectionHeading } from '../../CodingRipplerWidgets';

const WhyWorkWithUs = () => {
  const theme = useTheme();

  const benefits = [
    {
      icon: <SpeedIcon sx={{ fontSize: 48 }} />,
      title: "Rapid Deployment",
      description: "Get to market faster with our pre-built solutions that can be customized and deployed in days, not months."
    },
    {
      icon: <ApiIcon sx={{ fontSize: 48 }} />,
      title: "Seamless Integration",
      description: "All our solutions are built with integration in mind, connecting smoothly with your existing systems and workflows."
    },
    {
      icon: <CodeIcon sx={{ fontSize: 48 }} />,
      title: "Modern Technology Stack",
      description: "Built using the latest technologies and best practices to ensure performance, security, and scalability."
    },
    {
      icon: <HubIcon sx={{ fontSize: 48 }} />,
      title: "AI-Powered Insights",
      description: "Leverage the power of artificial intelligence to gain valuable insights and automate complex processes."
    },
    {
      icon: <DesignServicesIcon sx={{ fontSize: 48 }} />,
      title: "User-Centric Design",
      description: "Beautiful, intuitive interfaces designed with your users in mind to maximize engagement and satisfaction."
    },
    {
      icon: <DevicesIcon sx={{ fontSize: 48 }} />,
      title: "Cross-Platform Compatibility",
      description: "Deliver consistent experiences across all devices and platforms, from desktop to mobile and beyond."
    }
  ];

  // Generate a gradient color for each card
  const getGradient = (index) => {
    const gradients = [
      'linear-gradient(135deg, #6366F1 0%, #8B5CF6 100%)',
      'linear-gradient(135deg, #3B82F6 0%, #2DD4BF 100%)',
      'linear-gradient(135deg, #8B5CF6 0%, #EC4899 100%)',
      'linear-gradient(135deg, #F59E0B 0%, #EF4444 100%)',
      'linear-gradient(135deg, #10B981 0%, #3B82F6 100%)',
      'linear-gradient(135deg, #6366F1 0%, #EC4899 100%)'
    ];
    return gradients[index % gradients.length];
  };

  return (
    <Box
      sx={{
        py: 12,
        position: "relative",
        backgroundColor: alpha(theme.palette.primary.main, 0.03),
        borderRadius: "0px",
        overflow: "hidden",
        "&::before": {
          content: '""',
          position: "absolute",
          top: 0,
          left: 0,
          right: 0,
          height: "5px",
          background: theme.palette.primary.main,
        },
      }}
      id= "whyUs-section"
    >
      {/* Decorative Elements */}
      <Box
        sx={{
          position: "absolute",
          width: "300px",
          height: "300px",
          borderRadius: "50%",
          background: alpha(theme.palette.primary.main, 0.05),
          top: "10%",
          left: "-150px",
          zIndex: 0,
        }}
      />
      <Box
        sx={{
          position: "absolute",
          width: "200px",
          height: "200px",
          borderRadius: "50%",
          background: alpha(theme.palette.secondary.main, 0.05),
          bottom: "10%",
          right: "-100px",
          zIndex: 0,
        }}
      />

      <Container maxWidth="lg" sx={{ position: "relative", zIndex: 1 }}>
        <Box sx={{ textAlign: "center", mb: 8 }}>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              mb: 2,
            }}
          >
            <Box
              sx={{
                height: "2px",
                width: "30px",
                backgroundColor: "primary.main",
                mr: 2,
              }}
            />
            <Typography
              variant="subtitle1"
              component="span"
              sx={{
                color: "primary.main",
                fontWeight: 600,              
              }}
            >
              PARTNERSHIP BENEFITS
            </Typography>

            <Box
              sx={{
                height: "2px",
                width: "30px",
                backgroundColor: "primary.main",
                ml: 2,
              }}
            />
          </Box>

          {/* <Typography 
            variant="h2" 
            sx={{ 
              mb: 3,
              fontWeight: 800,
              background: 'linear-gradient(90deg, #1a237e 0%, #6366F1 100%)',
              WebkitBackgroundClip: 'text',
              WebkitTextFillColor: 'transparent',
              textShadow: '0px 4px 8px rgba(0,0,0,0.05)',
              display: 'inline-block'
            }}
          >
            Why Work With Us
          </Typography> */}

          <SectionHeading
            variant="h2"
            sx={{
              fontSize: { xs: "2rem", md: "3rem" },
              mb: 3,
              fontWeight: 800,
              background: "linear-gradient(90deg, #1a237e 0%, #6366F1 100%)",
              WebkitBackgroundClip: "text",
              WebkitTextFillColor: "transparent",
              textShadow: "0px 4px 8px rgba(0,0,0,0.05)",
              display: "inline-block",
            }}
          >
            Why Work With Us
          </SectionHeading>

          <Typography
            variant="h6"
            sx={{
              maxWidth: "650px",
              mx: "auto",
              color: "text.secondary",
              lineHeight: 1.6,
              fontWeight: "normal",
            }}
          >
            We don't just provide solutions – we build partnerships that drive
            growth and transform businesses.
          </Typography>
        </Box>

        <Grid container spacing={4}>
          {benefits.map((benefit, index) => (
            <Grid item xs={12} md={6} lg={4} key={index}>
              <Card
                elevation={0}
                sx={{
                  height: "100%",
                  position: "relative",
                  overflow: "visible",
                  background: theme.palette.background.paper,
                  borderRadius: 3,
                  transition: "transform 0.3s, box-shadow 0.3s",
                  boxShadow: "0 4px 20px rgba(0,0,0,0.05)",
                  "&:hover": {
                    transform: "translateY(-10px)",
                    boxShadow: "0 12px 30px rgba(0,0,0,0.08)",
                    "& .icon-container": {
                      transform: "scale(1.1) translateY(-5px)",
                    },
                  },
                }}
              >
                <CardContent sx={{ p: 4, zIndex: 2, position: "relative" }}>
                  {/* Floating Icon */}
                  <Box
                    className="icon-container"
                    sx={{
                      position: "relative",
                      display: "inline-flex",
                      alignItems: "center",
                      justifyContent: "center",
                      width: "80px",
                      height: "80px",
                      borderRadius: "16px",
                      background: getGradient(index),
                      color: "white",
                      mb: 3,
                      boxShadow: "0 10px 20px rgba(0,0,0,0.12)",
                      transition: "transform 0.3s ease",
                    }}
                  >
                    {benefit.icon}
                  </Box>

                  <Typography
                    variant="h5"
                    sx={{
                      mb: 2,
                      fontWeight: 700,
                      minHeight: "64px",
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    {benefit.title}
                  </Typography>

                  <Divider
                    sx={{
                      width: "40px",
                      borderWidth: "3px",
                      borderColor: theme.palette.primary.main,
                      opacity: 0.7,
                      mb: 2,
                    }}
                  />

                  <Typography
                    variant="body1"
                    color="text.secondary"
                    sx={{ lineHeight: 1.7 }}
                  >
                    {benefit.description}
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
          ))}
        </Grid>
      </Container>
    </Box>
  );
};

export default WhyWorkWithUs;