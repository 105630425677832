import { ArrowBackIos, ArrowForwardIos } from "@mui/icons-material";
import ArrowRightAltOutlinedIcon from "@mui/icons-material/ArrowRightAltOutlined";
import AutoAwesomeIcon from "@mui/icons-material/AutoAwesome";
import FavoriteBorderIcon from "@mui/icons-material/FavoriteBorder";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import StarRateIcon from "@mui/icons-material/StarRate";
import TrendingDownIcon from "@mui/icons-material/TrendingDown";
import TrendingUpIcon from "@mui/icons-material/TrendingUp";
import { Masonry } from "@mui/lab";
import {
  Autocomplete,
  Avatar,
  AvatarGroup,
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CardMedia,
  Chip,
  Container,
  Fab,
  Grid,
  IconButton,
  LinearProgress,
  Paper,
  Rating,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Tooltip,
  Typography,
  useTheme,
} from "@mui/material";
import { styled } from "@mui/system";
import React, { useCallback, useEffect, useRef, useState } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";
import { SwiperSlideContainerV2 } from "../BoilarPlateJs/SwiperSlide";
import { ApexChartOfTypeRadialBar } from "./CodingRipplerHome/Dashboards/ApexCharts/ApexChartOfTypeRadialBar";
import {
  ApexChartOfTypeLine,
  LineCharts,
} from "./CodingRipplerHome/Dashboards/ApexCharts/LineCharts";

import { KeyboardArrowUp } from "@mui/icons-material";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { useMediaQuery } from "@mui/material";



export const CRContainer = ({ children, ...props }) => {
  const { customStyle } = props;
  const { headerText } = props;

  return (
    <Box
      display="flex"
      justifyContent="center"
      sx={{
        ...customStyle,
      }}
    >
      {headerText}
    </Box>
  );
};

export const getButtonStyle = (props) => {
  const {
    isHovered,
    theme,
    buttonRef,
    position,
    hasMoved = false,
    xAxisMovementControler,
    yAxisMovementControler,
  } = props;

  const backgroundColor = isHovered ? theme.palette.background.default : "auto";
  const color = isHovered ? theme.palette.text.primary : "";

  return {
    backgroundColor,
    color,
    ...getTransformStyle({
      isHovered,
      theme,
      buttonRef,
      position,
      hasMoved,
      xAxisMovementControler,
      yAxisMovementControler,
    }),
  };
};

export const whiteHover = (props) => {
  const { isHovered, theme } = props;

  const backgroundColor = isHovered ? theme.palette.background.default : "auto";
  const color = isHovered ? theme.palette.text.primary : "";

  return {
    backgroundColor,
    color,
  };
};

export const getTransformStyle = (props) => {
  const {
    isHovered,
    buttonRef,
    position,
    xAxisMovementControler,
    yAxisMovementControler,
  } = props;

  if (!buttonRef.current) return {};
  if (!isHovered) return { transition: "transform 0.2s ease-out" };
  const rect = buttonRef.current.getBoundingClientRect();
  const buttonCenterX = rect.left + rect.width / 2;
  const buttonCenterY = rect.top + rect.height / 2;

  const xAxisDivisor = xAxisMovementControler || 10; // Adjust divisor to limit movement
  const yAxisDivisor = yAxisMovementControler || 5; // Adjust divisor to limit movement

  const { x, y } = position;
  const offsetX = (x - buttonCenterX) / xAxisDivisor; // Adjust divisor to limit movement
  const offsetY = (y - buttonCenterY) / yAxisDivisor; // Adjust divisor to limit movement
  return {
    transform: `translate(${offsetX}px, ${offsetY}px)`,
    transition: "transform 0.2s ease-out",
  };
};

export const PageHeader = ({ children, ...props }) => {
  const { headerText } = props;

  return (
    <Container>
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        width="100%"
        height="100%"
      >
        {headerText}
      </Box>
    </Container>
  );
};

export const PageSubHeader = ({ children, ...props }) => {
  const { headerText } = props;

  return (
    <Container>
      <Box display="flex" justifyContent="center">
        {headerText}
      </Box>
    </Container>
  );
};

export const PageHeaderV2 = ({ children, ...props }) => {
  const { headerText } = props;

  return (
    <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
      sx={{
        pl: {
          xs: 4, // Margin for extra-small screens
          lg: 2, // Margin for large
        },
        pr: {
          xs: 4, // Margin for extra-small screens
          lg: 2, // Margin for large
        },
        width: {
          xs: "calc(100%)", // Adjust width to account for margins
          sm: "calc(100%)", // Adjust width to account for margins
        },
      }}
    >
      {headerText}
    </Box>
  );
};

export const PageSubHeaderV2 = ({ children, ...props }) => {
  const { headerText, customStyle } = props;

  return (
    <Box
      sx={{
        display: {
          xs: "flex", // Hide on extra-small screens
        },
        justifyContent: "center",
        pl: {
          xs: "10%", // Margin for extra-small screens
          sm: "10%", // Margin for small screens
          lg: 4, // Margin for small screens
        },
        pr: {
          xs: "10%", // Margin for extra-small screens
          lg: "10%", // Margin for small screens
        },
        width: {
          xs: `calc(100%)`, // Adjust width to account for margins
        },

        ...customStyle,
      }}
    >
      {headerText}
    </Box>
  );
};

export const HeroText = ({ children, ...props }) => {
  const { theme, variant } = props;
  const { className = "typography-custom-css", customStyle } = props;

  return (
    <Typography
      variant={variant || "h1"}
      className={className}
      fontWeight="bold"
      fontFamily='"Inter", sans-serif'
      sx={{
        textAlign: {
          xs: "center",
          sm: "center",
          md: "center",
          lg: "center",
        },
        color: theme?.palette.text.primary,
        ...customStyle,
      }}
    >
      {children}
    </Typography>
  );
};

export const CommonHeaderTypography = ({ children, ...props }) => {
  const { theme, variant } = props;
  const { className = "typography-custom-css", customStyle } = props;

  return (
    <Typography
      variant={variant || "h1"}
      className={className}
      fontWeight="bold"
      fontFamily='"Inter", sans-serif'
      sx={{
        textAlign: {
          xs: "left",
          sm: "left",
          md: "left",
          lg: "center",
        },

        color: theme?.palette.text.primary,
        fontSize: {
          xs: "1.5rem", // small screens
          sm: "2.5rem", // medium screens
          md: "2.5rem", // large screens
          lg: "2.5rem", // extra large screens
          xl: "3rem",
        },
        ...customStyle,
      }}
    >
      {children}
    </Typography>
  );
};

export const CommonHeaderTypographyV2 = ({ children, ...props }) => {
  const { theme, variant } = props;
  const { className = "typography-custom-css", customStyle } = props;

  return (
    <Typography
      variant={variant || "h1"}
      className={className}
      fontWeight="bold"
      fontFamily='"Inter", sans-serif'
      sx={{
        textAlign: {
          xs: "left",
          sm: "left",
          md: "left",
          lg: "center",
        },

        color: theme?.palette.text.primary,
        ...customStyle,
      }}
    >
      {children}
    </Typography>
  );
};

export const HeroSubHeader = ({ children, ...props }) => {
  const { customStyle, theme, variant } = props;
  const { className = "typography-custom-css" } = props;

  return (
    <Typography
      variant={variant || "h6"}
      fontFamily='"Inter", sans-serif'
      fontWeight="normal"
      className={`sub-header ${className}`}
      sx={{
        textAlign: {
          xs: "center",
          sm: "center",
          lg: "center",
        },
        color: theme?.palette.text.secondary,
        ...customStyle,
      }}
    >
      {children}
    </Typography>
  );
};
export const CommonSubHeaderTypography = ({ children, ...props }) => {
  const { customStyle, theme, variant } = props;
  const { className = "typography-custom-css" } = props;

  return (
    <Typography
      variant={variant || "h6"}
      fontFamily='"Inter", sans-serif'
      fontWeight="normal"
      className={`sub-header ${className}`}
      sx={{
        textAlign: {
          xs: "left",
          sm: "left",
          lg: "center",
        },
        // m: {
        //   xs: 2.5, // Margin for extra-small screens
        //   sm: 2.5,
        //   md: 5,
        // },
        color: theme?.palette.text.secondary,
        fontSize: {
          xs: "1.2rem", // small screens
          sm: "1.5rem", // medium screens
          md: "1.5rem", // large screens
          lg: "1.5rem", // extra large screens
          xl: "1.5rem",
        },
        ...customStyle,
      }}
    >
      {children}
    </Typography>
  );
};

export const CommonSubHeaderTypographyV2 = ({ children, ...props }) => {
  const { customStyle } = props;
  const { className = "typography-custom-css" } = props;

  return (
    <Typography
      variant="h6"
      fontWeight="normal"
      className={`${className}`}
      pl={2}
      pr={2}
      sx={{
        fontSize: {
          xs: "1rem", // small screens
          sm: "1rem", // medium screens
          md: "1.5rem", // large screens
          lg: "1.5rem", // extra large screens
          xl: "1.5rem",
        },
        ...customStyle,
      }}
    >
      {children}
    </Typography>
  );
};

export const TopicContainer = ({ children, ...props }) => {
  const theme = useTheme();

  const { topicText } = props;

  return (
    <Box display="flex" justifyContent="center" mb={5}>
      <Fab
        variant="extended"
        size="large"
        sx={{ backgroundColor: theme.palette.background.default, zIndex: 1 }}
        p={10}
        pointerEvents="none"
      >
        {children}

        <Typography ml={1} className="typography-custom-css">
          {topicText}
        </Typography>
      </Fab>
    </Box>
  );
};

export const MarqueeContainer = styled(Box)({
  overflow: "hidden",
  whiteSpace: "nowrap",
  width: "100%",
  height: "100%",
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  // maskImage:
  //   "linear-gradient(to right, transparent, black 10px, black 90%, transparent)",
  maskImage:
    "linear-gradient(to right, hsl(0 0% 0%/0), hsl(0 0% 0%/1) 10%, hsl(0 0% 0%/1) 90%, hsl(0 0% 0%/0))",
});

export const MarqueeRow = styled(Box)(({ direction }) => ({
  display: "flex",
  flexDirection: "row",
  justifyContent: "space-between",
  alignItems: "center",
  height: "100%", // Adjust the height as needed
  width: "100%", // Adjust the width as needed
  minWidth: "100%",
  gap: "1rem", // Adjust the gap as needed
  animation: `marquee 120s linear infinite ${
    direction === "reverse" ? "reverse" : "normal"
  }`,
  "@keyframes marquee": {
    "0%": { transform: "translateX(0%)" },
    "100%": { transform: "translateX(-100%)" },
  },
}));

export const MarqueeEffect = ({ children, ...props }) => {
  const { isPhone } = props;

  const iconsSet1 = [
    /* Array of icons for set 1 Need to add according to the need */

    "/codingrippler/marquee/azure-icon.svg",
    "/codingrippler/marquee/aws-icon.svg",
    "/codingrippler/marquee/Java-Icon.svg",
    "/codingrippler/marquee/python-icon.svg",
    "/codingrippler/marquee/mySql-icon.svg",
    "/codingrippler/marquee/react-icon.svg",
    "/codingrippler/marquee/angular-icon.svg",
  ];

  const iconsSet2 = [
    /* Array of icons for set 1 Need to add according to the need */

    "/codingrippler/marquee/aws-icon.svg",
    "/codingrippler/marquee/Java-Icon.svg",
    "/codingrippler/marquee/python-icon.svg",
    "/codingrippler/marquee/react-icon.svg",
  ];

  let resolvedIcons = iconsSet1;

  if (isPhone) {
    resolvedIcons = iconsSet2;
  }

  // aws, azure, react, python, java, node, angular, mysql, javascript
  return (
    <MarqueeContainer>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Box
            display="flex"
            flexDirection="row"
            alignItems="center"
            width="100%"
            height="100%"
            gap={isPhone ? 6 : 15}
          >
            <MarqueeRow>
              {resolvedIcons.map((src, index) => (
                // <Box width={100} height={100}>
                <CardMedia
                  key={index}
                  component="img"
                  image={src}
                  alt={`Image ${index + 1}`}
                  sx={{
                    objectFit: "contain",
                    width: 150,
                    height: {
                      xs: (9 / 16) * 100,
                      sm: (9 / 16) * 120,
                      md: (9 / 16) * 150,
                    },
                  }}
                />
                // </Box>
              ))}
            </MarqueeRow>
            <MarqueeRow>
              {resolvedIcons.map((src, index) => (
                <CardMedia
                  key={index}
                  component="img"
                  image={src}
                  alt={`Image ${index + 1}`}
                  sx={{
                    objectFit: "contain",
                    width: 150,
                    height: {
                      xs: (9 / 16) * 100,
                      sm: (9 / 16) * 120,
                      md: (9 / 16) * 150,
                    },
                  }}
                />
                // </Box>
              ))}
            </MarqueeRow>
          </Box>
        </Grid>
      </Grid>
    </MarqueeContainer>
  );
};

export const SliderEffect = ({ children, ...props }) => {
  // aws, azure, react, python, java, node, angular, mysql, javascript
  return (
    <SwiperSlideContainerV2 slidesToShow={1}>
      <CardMedia
        component="img"
        image={"/codingrippler/marquee/azure-icon.svg"}
        alt={`Image 1`}
        sx={{
          objectFit: "contain",
          width: "50%",
          height: "50%",
        }}
      />

      <CardMedia
        component="img"
        image={"/codingrippler/marquee/azure-icon.svg"}
        alt={`Image 1`}
        sx={{
          objectFit: "contain",
          width: "50%",
          height: "50%",
        }}
      />
    </SwiperSlideContainerV2>
  );
};

export const PercentageContainer = ({ children, ...props }) => {
  const [percentage, setPercentage] = useState(0);
  const percentageRef = useRef(null);
  const intervalRef = useRef(null);

  const { text, value, suffix, isPhoneorTab } = props;

  const startInterval = useCallback(() => {
    let currentPercentage = 0;
    const duration = 2000; // Total duration in milliseconds
    const startTime = Date.now();

    const easeOutExpo = (t) => {
      return t === 1 ? 1 : 1 - Math.pow(2, -10 * t);
    };

    intervalRef.current = setInterval(() => {
      const elapsedTime = Date.now() - startTime;
      const progress = Math.min(elapsedTime / duration, 1); // Ensure progress does not exceed 1
      currentPercentage = Math.round(value * easeOutExpo(progress));
      setPercentage(currentPercentage);

      if (progress === 1) {
        clearInterval(intervalRef.current);
      }
    }, 20); // 20 milliseconds interval time
  }, [value]);

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          startInterval();
        } else {
          clearInterval(intervalRef.current);
        }
      },
      { threshold: 0.1 } // Adjust threshold as needed
    );

    if (percentageRef.current) {
      observer.observe(percentageRef.current);
    }

    return () => {
      observer.disconnect();
      clearInterval(intervalRef.current);
    };
  }, [startInterval]);

  return (
    <Box
      ref={percentageRef}
      display="flex"
      justifyContent="center"
      alignItems="flex-start"
      flexDirection="column"
      width="100%"
      maxWidth="100%"
    >
      <Typography
        className="theme-specfic-colored-text"
        variant={isPhoneorTab ? "h4" : "h3"}
        fontFamily='"Inter", sans-serif'
        textAlign="left"
        gutterBottom
      >
        <Box>
          {percentage}
          {suffix}
        </Box>
      </Typography>

      <HeroSubHeader
        customStyle={{
          textAlign: "left",
        }}
      >
        {text}
      </HeroSubHeader>
    </Box>
  );
};

export const LeadContainer = ({ children, ...props }) => {
  const { data, isPhoneorTab } = props;

  return (
    <Grid container spacing={2}>
      {data.map((item, index) => (
        <Grid item xs={12} md={6} lg={4} key={index}>
          <PercentageContainer
            value={item.value}
            text={item.text}
            suffix={item.suffix}
            prefix={item.prefix}
            isPhoneorTab={isPhoneorTab}
          />
        </Grid>
      ))}
    </Grid>
  );
};

export const Cards = ({ image, title, description, ...props }) => {
  const { customStyle } = props;

  return (
    <Card
      sx={{
        borderRadius: "24px",
        height: "100%",
        width: "100%",
        position: "relative",
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        "&:hover img": {
          transform: "scale(1.2)",
        },
      }}
    >
      <CardMedia
        component="img"
        image={image}
        alt={title}
        sx={{
          ...customStyle,
          height:"100%",
          width:"100%", 
          objectFit: "cover",
          position: "relative",
          transform: "scale(1)", // Initial scale
          transition: "transform 0.7s ease",
          "&:hover": {
            transform: "scale(1.2)",
          },
        }}
      />
      <CardContent
        sx={{
          position: "absolute",
          top: {
            xs: "50%",
            sm: "65%",
            md: "65%",
            lg: "65%",
            xl: "75%",
          },
          left: "2%",
          overflow: "visible",
        }}
      >
        <Stack direction="column" spacing={2}>
          <Typography
            variant="h5"
            component="h5"
            className="typography-custom-css"
            fontWeight="bold"
            color="white"
          >
            {title}
          </Typography>
          <Typography
            variant="h6"
            component="h6"
            className="sub-header, typography-custom-css"
            color="white"
          >
            {description}
          </Typography>
          <Box>
            <Button
              variant="contained"
              color="primary"
              size="large"
              endIcon={<ArrowRightAltOutlinedIcon />}
              className={"button-shoping"}
              style={{
                fontSize: "1.0rem",
                padding: "1rem 2rem",
                borderRadius: "14px",
              }}
            >
              Learn More
            </Button>
          </Box>
        </Stack>
      </CardContent>
    </Card>
  );
};

export const CardsV2 = ({ image, title, description, ...props }) => {
  const { customStyle, objectFit } = props;

  return (
    <Card
      sx={{
        borderRadius: "24px",
        position: "relative",
        width: "100%",
        height: "100%",
        m: 2,
        "&:hover img": {
          transform: "scale(1.2)",
        },
      }}
    >
      <CardMedia
        component="img"
        image={image}
        alt={title}
        sx={{
          ...customStyle,
          height: "100%",
          width: "100%",
          objectFit: objectFit ? objectFit : "cover",
          position: "relative",
          transform: "scale(1)", // Initial scale
          transition: "transform 0.7s ease",
          "&:hover": {
            transform: "scale(1.2)",
          },
        }}
      />
    </Card>
  );
};

export const ViewPagesCard = ({ image, title, description, ...props }) => {
  const { customStyle, objectFit, handler } = props;

  return (
    <Card
      elevation={3}
      sx={{
        borderRadius: "24px",
        position: "relative",
        m: 2,
        "&:hover img": {
          transform: "scale(1.2)",
        },
        height: 400,
      }}
      onClick={handler}
    >
      <CardMedia
        component="img"
        image={image}
        alt={title}
        sx={{
          ...customStyle,
          height: "100%",
          width: "100%",
          objectFit: objectFit ? objectFit : "cover",
          position: "relative",
          transform: "scale(1)", // Initial scale
          transition: "transform 0.7s ease",
          "&:hover": {
            transform: "scale(1.2)",
          },
        }}
      />
    </Card>
  );
};

export const ProductCardContainer = ({ children, ...props }) => {
  const { isPhone, isTab, deviceWithSmallHeight, productsInfo } = props;

  return (
    <Grid container spacing={10} id="product-card-container">
      <Grid item xs={12}></Grid>
      <Grid
        item
        xs={1}
        sx={{
          display: {
            xs: "none",
            lg: "block",
          },
        }}
      ></Grid>
      <Grid
        item
        xs={12}
        lg={10}
        sx={{
          ml: {
            xs: 2,
            lg: 0,
          },
          mr: {
            xs: 2,
            lg: 0,
          },
        }}
      >
        <Grid container spacing={2}>
          {productsInfo.map((product, index) => {
            return (
              <Grid
                item
                xs={12}
                lg={6}
                key={index}
                sx={{
                  minHeight: {
                    xs: 425,
                    sm: 500,
                    lg: 500,
                  },
                  height: {
                    xs: "50vh",
                    lg: "75vh",
                  },
                }}
              >
                <Cards
                  image={product.image}
                  title={product.title}
                  description={product.description}
                  isPhone={isPhone}
                  isTab={isTab}
                  deviceWithSmallHeight={deviceWithSmallHeight}
                />
              </Grid>
            );
          })}
        </Grid>
      </Grid>
      <Grid
        item
        xs={1}
        sx={{
          display: {
            xs: "none",
            lg: "block",
          },
        }}
      ></Grid>
      <Grid item xs={12}></Grid>
    </Grid>
  );
};

export const AdvertiseBrands = ({ children, ...props }) => {
  const { isPhone, pageContent } = props;

  const brandsList1 = pageContent[0].imageList1;
  const brandsList2 = pageContent[1].imageList2;
  const combinedBrandsList = [...brandsList1, ...brandsList2]; // Combine the lists

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} lg={12}>
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          width="100%"
          flexWrap="wrap" // Add this line to enable wrapping
          flexDirection="row"
          maxWidth="100%"
          overflow="hidden"
          gap={2}
          sx={{}}
        >
          {!isPhone &&
            brandsList1.map((brand, index) => (
              <Box
                key={index}
                sx={{
                  mr: { xs: 0, sm: 2, md: 4, lg: 6 },
                }}
              >
                <CardMedia
                  component="img"
                  image={brand.image}
                  alt="brand1"
                  sx={{
                    objectFit: "contain",
                    width: { xs: "75px", md: "100px", lg: "100px" },
                    height: { xs: "75px", md: "100px", lg: "100px" },
                  }}
                />
              </Box>
            ))}
        </Box>

        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          width="100%"
          flexWrap="wrap" // Add this line to enable wrapping
          flexDirection="row"
          overflow="hidden"
          gap={4}
          sx={{}}
        >
          {!isPhone &&
            brandsList2.map((brand, index) => (
              <Box
                key={index}
                sx={{
                  mr: { xs: 0, sm: 2, md: 4, lg: 6 },
                }}
              >
                <CardMedia
                  component="img"
                  image={brand.image}
                  alt="brand1"
                  sx={{
                    objectFit: "contain",
                    width: { xs: "75px", md: "100px", lg: "100px" },
                    height: { xs: "75px", md: "100px", lg: "100px" },
                  }}
                />
              </Box>
            ))}

          {isPhone &&
            combinedBrandsList.map((brand, index) => (
              <Box key={index} sx={{}}>
                <CardMedia
                  component="img"
                  image={brand.image}
                  alt="brand1"
                  sx={{ objectFit: "contain", width: "75px", height: "75px" }}
                />
              </Box>
            ))}
        </Box>
      </Grid>
    </Grid>
  );
};

// export const RecognizedBrands = ({ children, ...props }) => {
//   const { isPhone, pageContent } = props;

//   const brandsList1 = pageContent[0].imageList1;
//   const brandsList2 = pageContent[1].imageList2;
//   const combinedBrandsList = [...brandsList1, ...brandsList2]; // Combine the lists

//   return (
//     <Grid container spacing={2}>
//       <Grid item xs={12} lg={12}>
//         <Box
//           display="flex"
//           justifyContent="center"
//           alignItems="center"
//           width="100%"
//           flexWrap="wrap" // Add this line to enable wrapping
//           flexDirection="row"
//           maxWidth="100%"
//           overflow="hidden"
//           gap={2}
//           sx={{}}
//         >
//           {!isPhone &&
//             brandsList1.map((brand, index) => (
//               <Box
//                 key={index}
//                 component="a"
//                 href={brand.backlink}
//                 target="_blank"
//                 rel="noopener noreferrer"
//                 sx={{
//                   mr: { xs: 0, sm: 2, md: 4, lg: 6 },
//                   textDecoration: "none",
//                 }}
//               >
//                 <CardMedia
//                   component="img"
//                   image={brand.image}
//                   alt={brand.alt}
//                   sx={{
//                     objectFit: "contain",
//                     width: { xs: "175px", md: "175px", lg: "175px" },
//                     height: { xs: brand?.customHeight ||"auto", md: brand?.customHeight|| "auto", lg: brand?.customHeight||"auto" },
//                   }}
//                 />
//               </Box>
//             ))}
//         </Box>

//         <Box
//           display="flex"
//           justifyContent="center"
//           alignItems="center"
//           width="100%"
//           flexWrap="wrap" // Add this line to enable wrapping
//           flexDirection="row"
//           overflow="hidden"
//           gap={4}
//           sx={{}}
//         >
//           {!isPhone &&
//             brandsList2.map((brand, index) => (
//               <Box
//                 key={index}
//                 component="a"
//                 href={brand.backlink}
//                 target="_blank"
//                 rel="noopener noreferrer"
//                 sx={{
//                   mr: { xs: 0, sm: 2, md: 4, lg: 6 },
//                   textDecoration: "none",
//                 }}
//               >
//                 <CardMedia
//                   component="img"
//                   image={brand.image}
//                   alt="brand1"
//                   sx={{
//                     objectFit: "cover",
//                     width: { xs: "175px", md: "175px", lg: "175px" },
//                     height: { xs: brand?.customHeight ||"auto", md: brand?.customHeight || "auto", lg: brand?.customHeight || "auto" },
//                   }}
//                 />
//               </Box>
//             ))}

//           {isPhone &&
//             combinedBrandsList.map((brand, index) => (
//               <Box
//                 key={index}
//                 sx={{}}
//                 component="a"
//                 href={brand.backlink}
//                 target="_blank"
//                 rel="noopener noreferrer"
//               >
//                 <CardMedia
//                   component="img"
//                   image={brand.image}
//                   alt="brand1"
//                   sx={{ objectFit: "contain", width: "125px", height: brand?.customHeightPhone || "auto" }}
//                 />
//               </Box>
//             ))}
//         </Box>
//       </Grid>
//     </Grid>
//   );
// };


export const RecognizedBrands = ({ children, ...props }) => {
  const { isPhone, pageContent } = props;

  // Handle cases where pageContent might be incomplete
  const brandsList1 = pageContent?.[0]?.imageList1 || [];
  const brandsList2 = pageContent?.[1]?.imageList2 || [];
  const combinedBrandsList = [...brandsList1, ...(brandsList2 || [])]; // Combine the lists

  // Simple image error handler
  const handleImageError = (event) => {
    // Replace with a styled div containing the brand name
    const brandName = event.target.alt || "Brand";
    const parent = event.target.parentElement;
    
    if (parent) {
      // Create replacement element
      const fallback = document.createElement('div');
      fallback.style.width = isPhone ? "125px" : "175px";
      fallback.style.height = isPhone ? "50px" : "60px";
      fallback.style.display = "flex";
      fallback.style.alignItems = "center";
      fallback.style.justifyContent = "center";
      fallback.style.backgroundColor = "rgba(96, 86, 255, 0.05)";
      fallback.style.borderRadius = "8px";
      fallback.style.color = "#6056ff";
      fallback.style.fontWeight = "500";
      fallback.style.fontSize = "14px";
      fallback.textContent = brandName;
      
      // Replace the img with our fallback
      parent.replaceChild(fallback, event.target);
    }
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} lg={12}>
        {/* Desktop view - first row */}
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          width="100%"
          flexWrap="wrap"
          flexDirection="row"
          maxWidth="100%"
          overflow="hidden"
          gap={2}
          sx={{ mb: 4 }}
        >
          {!isPhone &&
            brandsList1.map((brand, index) => (
              <Box
                key={index}
                component="a"
                href={brand.backlink}
                target="_blank"
                rel="noopener noreferrer"
                sx={{
                  mr: { xs: 0, sm: 2, md: 4, lg: 6 },
                  textDecoration: "none",
                  transition: "transform 0.3s ease, opacity 0.3s ease",
                  opacity: 0.8,
                  "&:hover": {
                    opacity: 1,
                    transform: "scale(1.05)",
                  }
                }}
              >
                <CardMedia
                  component="img"
                  image={brand.image}
                  alt={brand.alt || "Brand logo"}
                  onError={handleImageError}
                  sx={{
                    objectFit: "contain",
                    width: { xs: "175px", md: "175px", lg: "175px" },
                    height: { xs: brand?.customHeight || "auto", md: brand?.customHeight || "auto", lg: brand?.customHeight || "auto" },
                    filter: "grayscale(20%)",
                    transition: "filter 0.3s ease",
                    "&:hover": {
                      filter: "grayscale(0%)",
                    }
                  }}
                />
              </Box>
            ))}
        </Box>

        {/* Desktop view - second row */}
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          width="100%"
          flexWrap="wrap"
          flexDirection="row"
          overflow="hidden"
          gap={4}
        >
          {!isPhone &&
            brandsList2?.map((brand, index) => (
              <Box
                key={index}
                component="a"
                href={brand.backlink}
                target="_blank"
                rel="noopener noreferrer"
                sx={{
                  mr: { xs: 0, sm: 2, md: 4, lg: 6 },
                  textDecoration: "none",
                  transition: "transform 0.3s ease, opacity 0.3s ease",
                  opacity: 0.8,
                  "&:hover": {
                    opacity: 1,
                    transform: "scale(1.05)",
                  }
                }}
              >
                <CardMedia
                  component="img"
                  image={brand.image}
                  alt={brand.alt || "Brand logo"}
                  onError={handleImageError}
                  sx={{
                    objectFit: "contain", // Changed from "cover" for better logo display
                    width: { xs: "175px", md: "175px", lg: "175px" },
                    height: { xs: brand?.customHeight || "auto", md: brand?.customHeight || "auto", lg: brand?.customHeight || "auto" },
                    filter: "grayscale(20%)",
                    transition: "filter 0.3s ease",
                    "&:hover": {
                      filter: "grayscale(0%)",
                    }
                  }}
                />
              </Box>
            ))}

          {/* Mobile view */}
          {isPhone &&
            combinedBrandsList.map((brand, index) => (
              <Box
                key={index}
                component="a"
                href={brand.backlink}
                target="_blank"
                rel="noopener noreferrer"
                sx={{
                  transition: "transform 0.3s ease",
                  "&:hover": {
                    transform: "scale(1.05)",
                  }
                }}
              >
                <CardMedia
                  component="img"
                  image={brand.image}
                  alt={brand.alt || "Brand logo"}
                  onError={handleImageError}
                  sx={{ 
                    objectFit: "contain", 
                    width: "125px", 
                    height: brand?.customHeightPhone || "auto",
                    filter: "grayscale(20%)",
                    transition: "filter 0.3s ease",
                    "&:hover": {
                      filter: "grayscale(0%)",
                    }
                  }}
                />
              </Box>
            ))}
        </Box>
      </Grid>
    </Grid>
  );
};

export const MasonryContainer = ({ children, ...props }) => {
  const { isPhoneorTab, isPhone, pageContent } = props;

  const images = pageContent;

  return (
    <Grid
      container
      spacing={2}
      sx={{
        height: "100%",
        // mt: 2,
      }}
    >
      <Grid
        item
        lg={1}
        sx={{
          display: {
            xs: "none",
            lg: "block",
          },
        }}
      />
      <Grid
        item
        xs={12}
        lg={10}
        sx={{
          ml: {
            xs: 2,
            md: 2,
          },
        }}
      >
        <Masonry columns={{ xs: 1, sm: 2, md: 3, lg: 3 }} spacing={2}>
          {images.map((image, index) => (
            <Card
              sx={{
                minHeight: 400,
                borderRadius: "24px",
                height: image.height,
                width: "100%",
                overflow: "hidden",
                position: "relative",
                "&:hover img": {
                  transform: "scale(1.2)",
                },
                "&:hover .hover-box": {
                  transform: "translateY(0%)",
                },
              }}
              key={index}
            >
              <CardMedia
                key={index}
                component="img"
                image={image.image}
                alt="Description of the image"
                width="100%"
                height="100%"
                overflow="hidden"
                sx={{
                  objectFit: "cover",
                  transform: "scale(1)", // Initial scale
                  transition: "transform 0.7s ease",
                  position: "relative",
                  "&:hover": {
                    transform: "scale(1.2)",
                  },
                }}
              />

              <CardContent>
                <Box
                  className="hover-box"
                  sx={{
                    position: "absolute",
                    bottom: 0,
                    left: 0,
                    right: 0,
                    width: "100%",
                    bgcolor: "rgba(255, 255, 255, 1)",
                    minHeight: {
                      xs: "30%",
                      lg: "30%",
                    },
                    // height: { xs: "15vh", md: "20vh", lg: "20vh" },
                    transform: "translateY(100%)",
                    transition: "transform 0.7s ease",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "left",
                    "&:hover": {
                      transform: "translateY(0%)",
                    },
                  }}
                >
                  <Box
                    component="svg"
                    sx={{
                      position: "absolute",
                      top: 0,
                      left: 0,
                      right: 0,
                      width: "110%",
                      height: "auto",
                      transform: "translateY(-50%)",
                    }}
                    preserveAspectRatio="none"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 1920 100.1"
                  >
                    <path
                      fill="rgba(255, 255, 255, 1)"
                      d="M0,0c0,0,934.4,93.4,1920,0v100.1H0L0,0z"
                    ></path>
                  </Box>

                  <HeroText variant={isPhoneorTab ? "h5" : "h5"}>
                    {image.heading}
                  </HeroText>

                  <HeroSubHeader variant={isPhone ? "body1" : "body1"}>
                    {image.subHeading}
                  </HeroSubHeader>
                </Box>
              </CardContent>
            </Card>
          ))}
        </Masonry>
      </Grid>
      <Grid
        item
        lg={1}
        sx={{
          display: {
            xs: "none",
            lg: "block",
          },
        }}
      />
      <Grid
        item
        xs={12}
        sx={{
          minHeight: "25%",
        }}
      />
    </Grid>
  );
};

export const CommonButton = ({ children, ...props }) => {
  const { buttonText, clickHandler } = props;

  const [isHovered, setIsHovered] = useState(false);
  const theme = useTheme();

  const handleMouseLeave = () => {
    setIsHovered(false);
  };

  return (
    <Fab
      variant="extended"
      size="large"
      className={isHovered ? "button-animation-hover" : "button-animation"}
      color="primary"
      onClick={clickHandler}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={handleMouseLeave}
      p={10}
      style={{
        ...whiteHover({ isHovered, theme }),
        zIndex: 1,
      }}
    >
      <Box display="flex" alignItems="center" justifyContent="center" gap={1}>
        {buttonText}
        <ArrowRightAltOutlinedIcon />
      </Box>
    </Fab>
  );
};

export const CommonButtonV2 = ({ children, ...props }) => {
  const { buttonText, clickHandler, customStyle, customClass } = props;

  return (
    <Fab
      variant="extended"
      size="large"
      className={customClass || "button"}
      color="primary"
      onClick={clickHandler}
      p={10}
      style={{
        //   ...whiteHover({ isHovered, theme }),
        zIndex: 1,
        ...customStyle,
      }}
    >
      <Box display="flex" alignItems="center" justifyContent="center" gap={1}>
        {buttonText}
      </Box>
    </Fab>
  );
};

export const CardWithTextAndButton = ({
  image,
  title,
  description,
  ...props
}) => {
  const {
    customStyle,
    cardClass = "card-translate-effect",
    className = "theme-specfic-colored-text",
    isPhoneorTab,
    isPhone,
    deviceWithSmallHeight,
    headerTextAlignment = "left",
  } = props;

  const buttonStyle = {
    padding: "1rem 2rem",
    borderRadius: "14px",
    color: "#6056ff",
  };

  return (
    <Card
      className={cardClass}
      sx={{
        borderRadius: "24px",
        m: {
          xs: 2,
          sm: 2.5,
        },
        position: "relative",
        minHeight: {
          xs: 550,
        },
        height: {
          xs: deviceWithSmallHeight ? "80vh" : "65vh",
          sm: deviceWithSmallHeight ? "85vh" : "50vh",
          lg: "75vh",
          xl: "60vh",
        },
      }}
    >
      <CardMedia
        component="img"
        image={image}
        alt={title}
        sx={{
          ...customStyle,
          objectFit: "cover",
          height: {
            xs: "70%",
            sm: "70%",
            lg: "70%",
          },
        }}
      />
      <Box
        sx={{
          display: "flex",
          justifyContent: "flex-start",
          alignItems: "flex-start",
          flexDirection: "column",
          height: {
            xs: "30%",
            sm: "30%",
            lg: "30%",
          },
          position: "relative",
        }}
      >
        <CardContent sx={{ mt: 0 }}>
          <HeroText
            customStyle={{ textAlign: headerTextAlignment }}
            variant={isPhoneorTab ? "h5" : "h5"}
          >
            {title}
          </HeroText>

          <HeroSubHeader
            customStyle={{ textAlign: headerTextAlignment }}
            variant={isPhone ? "body1" : "body1"}
          >
            {description}
          </HeroSubHeader>
        </CardContent>

        <CardActions
          sx={{
            position: "absolute",
            top: "60%",
          }}
        >
          <Box
            sx={
              {
                // position: "absolute",
                // width: "100%",
              }
            }
          >
            <Button
              variant="text"
              size="large"
              endIcon={<ArrowRightAltOutlinedIcon />}
              className={`${className}`}
              style={{
                ...buttonStyle,
              }}
            >
              Learn More
            </Button>
          </Box>
        </CardActions>
      </Box>
    </Card>
  );
};

export const LayoutWithImage = ({ children, ...props }) => {
  const { textStyle } = props;

  return (
    <Grid container spacing={2}>
      <Grid
        item
        xs={12}
        md={12}
        lg={12}
        sx={{
          ...textStyle,
        }}
      >
        {children}
      </Grid>
    </Grid>
  );
};

export const EcommerceBanner = ({ children, ...props }) => {
  const { customStyle, pageContent } = props;

  const imagePath = pageContent.imagePath;

  return (
    <Grid container spacing={2}>
      <Grid
        item
        xs={12}
        md={6}
        lg={6}
        sx={{
          ...customStyle,
        }}
      >
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          height="100%"
          width="100%"
          sx={{}}
        >
          {children}
        </Box>
      </Grid>

      <Grid
        item
        xs={12}
        md={6}
        lg={6}
        sx={{
          ...customStyle,
          position: "relative",
          display: {
            xs: "none",
            sm: "none",
            md: "block",
            lg: "block",
          },
        }}
      >
        <Box
          height="auto"
          width="auto"
          sx={{
            position: "absolute",
            top: {
              md: "5%",
              lg: "5%",
              xl: "5%",
            },
          }}
        >
          <CardMedia
            component="img"
            image={imagePath}
            alt={pageContent.alt}
            sx={{
              objectFit: "contain",
              width: "100%",
              height: "100%",
              overflow: "hidden",
              transform: { sm: "scale(1)", md: "scale(1)", lg: "scale(1)" },
            }}
          />
        </Box>
      </Grid>
    </Grid>
  );
};

export const DynamicLayoutWithImage = ({ children, ...props }) => {
  const { xs, lg } = props;

  return (
    <Grid container spacing={2}>
      <Grid item xs={xs || 12} lg={lg || 6}>
        {children}
      </Grid>
    </Grid>
  );
};

export const BannerLayoutWithImage = ({ children, ...props }) => {
  const { customStyle, deviceWithSmallHeight, pageContent } = props;

  const style = customStyle;

  const bgClassName = style?.customBgColorCss;

  return (
    <Grid
      container
      className={bgClassName || "theme-specific-background-color"}
      overflow="hidden"
    >
      <Grid
        item
        xs={12}
        md={3}
        sx={{
          minHeight: {
            xs: 175,
          },
          height: {
            xs: deviceWithSmallHeight ? "25vh" : "20vh",
            sm: deviceWithSmallHeight ? "25vh" : "15vh",
            md: deviceWithSmallHeight ? "30vh" : "15vh",
            lg: "30vh",
            xl: "25vh",
          },
        }}
      >
        <Box
          display="flex"
          justifyContent="center"
          alignItems="flex-start"
          width={{ xs: "100%", sm: "100%", md: "auto", lg: "auto", xl: "100%" }}
          height={{
            xs: "100%",
            sm: "100%",
            md: "auto",
            lg: "auto",
            xl: "100%",
          }}
        >
          <CardMedia
            component="img"
            image={"/codingrippler/e-commerce/item3.png"}
            alt={pageContent.alt}
            sx={{
              objectFit: "contain",
              width: "100%",
              height: "100%",
              transform: "scale(1)",
            }}
          />
        </Box>
      </Grid>

      <Grid
        item
        xs={12}
        md={6}
        sx={{
          minHeight: {
            xs: 175,
          },
          height: {
            xs: deviceWithSmallHeight ? "25vh" : "20vh",
            sm: deviceWithSmallHeight ? "25vh" : "15vh",
            md: deviceWithSmallHeight ? "30vh" : "15vh",
            lg: "30vh",
            xl: "25vh",
          },
          mt: { xs: 2, sm: 2, lg: 0 },
        }}
      >
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          height="100%"
          width="100%"
        >
          <CommonContainer>
            <Stack direction="column" spacing={2}>
              <HeroText variant="h4" customStyle={{ color: "white" }}>
                {pageContent.header}
              </HeroText>
              <HeroSubHeader customStyle={{ color: "white" }}>
                {pageContent.subHeader}
              </HeroSubHeader>
            </Stack>
          </CommonContainer>
        </Box>
      </Grid>

      <Grid
        item
        xs={12}
        md={3}
        sx={{
          minHeight: {
            xs: 175,
          },
          height: {
            xs: deviceWithSmallHeight ? "25vh" : "20vh",
            sm: deviceWithSmallHeight ? "25vh" : "15vh",
            md: deviceWithSmallHeight ? "30vh" : "15vh",
            lg: "30vh",
            xl: "25vh",
          },
        }}
      >
        <Box
          display="flex"
          justifyContent="flex-end"
          alignItems="center"
          width={{ xs: "100%", sm: "auto", md: "100%", lg: "100%" }}
          height={{ xs: "100%", sm: "auto", md: "100%", lg: "100%" }}
        >
          <CardMedia
            component="img"
            image={"/codingrippler/e-commerce/item2.png"}
            alt={pageContent.alt}
            sx={{
              objectFit: "contain",
              width: "50%",
              height: "50%",
              overflow: "hidden",
              transform: "scale(1)",
            }}
          />
        </Box>
      </Grid>
    </Grid>
  );
};

export const EcommerceCard = ({ children, ...props }) => {
  const {
    customStyle,
    className = "theme-specfic-colored-text",
    isPhoneorTab,
    itemToDisplay,
  } = props;

  const buttonStyle = {
    padding: "1rem 1rem",
    borderRadius: "14px",
    color: "#6056ff",
  };

  const buttonStyleSm = {
    padding: "0.5rem 1rem",
    borderRadius: "14px",
    color: "#6056ff",
  };

  return (
    <Card
      sx={{
        borderRadius: "24px",
        m: 2,
        position: "relative",
        minHeight: {
          xs: 450,
          sm: 450,
          md: 500,
          lg: 550,
        },
        height: {
          xs: "50vh",
          sm: "50vh",
          lg: "60vh",
        },
        boxShadow: "0px 0px 6px  rgba(0, 0, 0, 0.2)", // Custom shadow
      }}
    >
      <CardContent
        sx={{
          position: "absolute",
          top: 10,
          right: 0,
        }}
      >
        <Fab
          variant="extended"
          size="large"
          sx={{
            backgroundColor: "white",
            zIndex: 1,
            "&:hover": {
              backgroundColor: "#e0e0e0",
            },
          }}
        >
          <FavoriteBorderIcon className={className} />
        </Fab>
      </CardContent>

      <CardMedia
        component="img"
        image={itemToDisplay.image}
        alt={"loading"}
        sx={{
          ...customStyle,
          objectFit: "cover",
          width: "100%",
          backgroundColor: "#f7faff",
          height: {
            xs: "65%",
          },
        }}
      />

      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "flex-start",
          flexDirection: "column",
          position: "relative",
          height: {
            xs: "35%",
            // sm: deviceWithSmallHeight ? "22.5vh" : "20vh",
            // lg: "32.5vh",
            // xl: "22.5vh",
          },
        }}
      >
        <CardContent sx={{ mt: 0, position: "relative" }}>
          <HeroText variant={isPhoneorTab ? "h6" : "h5"}>
            {itemToDisplay.title}
          </HeroText>
          <Rating
            name="read-only"
            value={itemToDisplay.ratings}
            readOnly
            icon={<StarRateIcon fontSize="inherit" />}
            sx={{ paddingTop: "10px" }}
          />
        </CardContent>

        <CardActions
          sx={{
            position: "absolute",
            width: "100%",
            top: "60%",
          }}
        >
          <Box
            display="flex"
            flexDirection="row"
            justifyContent="space-between"
            alignItems="center"
            width="100%"
            sx={{ pl: 2, pr: 2 }}
          >
            <HeroSubHeader customStyle={customStyle.customStylePricing}>
              <span className={`${className}`}>
                {itemToDisplay.currency}
                {itemToDisplay.price}
              </span>
            </HeroSubHeader>

            <Button
              variant="outlined"
              size="large"
              startIcon={<ShoppingCartIcon />}
              className={`${className}`}
              style={isPhoneorTab ? buttonStyleSm : buttonStyle}
            >
              Add to Cart
            </Button>
          </Box>
        </CardActions>
      </Box>
    </Card>
  );
};

export const PageIntroWithButtonContainer = ({ children, ...props }) => {
  const { customStyle, pageContent, buttonHandler } = props;

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <TopicContainer topicText={pageContent.topicText}>
          <AutoAwesomeIcon />
        </TopicContainer>
      </Grid>

      <Grid item xs={12}>
        <Box
          display="flex"
          justifyContent={pageContent.customStyle?.justifyContent || "center"}
          alignItems={pageContent.customStyle?.alignItems || "center"}
          flexDirection="column"
        >
          <HeroText variant="h4" customStyle={customStyle?.customHeader}>
            {pageContent.header}
          </HeroText>

          <Box m={2} />

          <HeroSubHeader customStyle={customStyle?.customSubHeader}>
            {pageContent.subHeader}
          </HeroSubHeader>

          <Box m={2} />

          <Box>
            {pageContent.buttonText && (
              <CommonButtonV2
                buttonText={pageContent.buttonText}
                onClick={buttonHandler}
                customStyle={{ fontSize: "1rem" }}
              />
            )}
          </Box>
        </Box>
      </Grid>
    </Grid>
  );
};

// Custom arrow components
const NextArrow = ({ className, style, onClick, ...props }) => {
  const { buttonColor } = props;

  return (
    <IconButton
      onClick={(e) => {
        e.stopPropagation();
        onClick();
      }}
      style={{
        ...style,
        position: "absolute",
        top: "80%",
        right: "10%",
        zIndex: 1,
      }}
    >
      <ArrowForwardIos className={buttonColor} />
    </IconButton>
  );
};

const PrevArrow = ({ className, style, onClick, ...props }) => {
  const { buttonColor } = props;

  return (
    <IconButton
      onClick={(e) => {
        e.stopPropagation();
        onClick();
      }}
      style={{
        ...style,
        position: "absolute",
        top: "80%",
        right: "15%",
        zIndex: 1,
      }}
    >
      <ArrowBackIos className={buttonColor} />
    </IconButton>
  );
};

export const SliderContainer = ({ children, ...props }) => {
  let { sliderButtonColors = "theme-background-color" } = props;

  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    prevArrow: <PrevArrow buttonColor={sliderButtonColors} />,
    nextArrow: <NextArrow buttonColor={sliderButtonColors} />,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          dots: false,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          dots: true,
          prevArrow: <></>,
          nextArrow: <></>,
        },
      },
    ],
  };

  return <Slider {...settings}>{children}</Slider>;
};

export const InfoCard = ({ children, ...props }) => {
  const { customStyle, pageContent } = props;

  return (
    <Card
      sx={{
        position: "relative",
        borderRadius: "24px",
        m: 2,
        backgroundColor: customStyle?.customBgColor,
        transition: "transform 0.2s, box-shadow 0.2s", // Smooth transition
        minHeight: {
          xs: 250,
          sm: 400,
          md: 300,
          lg: 300,
        },
        "&:hover": {
          transform: "translateY(-4px)", // Lift more on hover
        },
      }}
    >
      <CardContent>
        <HeroText
          variant="h6"
          customStyle={{
            textAlign: "left",
          }}
        >
          {pageContent.header}
        </HeroText>
      </CardContent>

      <CardContent>
        <HeroSubHeader
          customStyle={{
            textAlign: "left",
          }}
          variant="body1"
        >
          {pageContent.description}
        </HeroSubHeader>
      </CardContent>

      <CardActions>
        <Box
          sx={{
            position: "absolute",
            bottom: 16,
            right: 16,
          }}
        >
          <Button
            variant="text"
            size="large"
            endIcon={<ArrowRightAltOutlinedIcon />}
            style={{
              fontSize: "1rem",
              padding: "1rem 2rem",
              borderRadius: "14px",
              color: "#6056ff",
            }}
            //      onClick={clickHandler}
          >
            Learn More
          </Button>
        </Box>
      </CardActions>
    </Card>
  );
};

export const ReviewCard = ({ children, ...props }) => {
  const { customStyle, pageContent } = props;

  return (
    <Card
      sx={{
        position: "relative",
        borderRadius: "24px",
        m: 2,
        backgroundColor: customStyle?.customBgColor,
        transition: "transform 0.2s, box-shadow 0.2s", // Smooth transition
        "&:hover": {
          transform: "translateY(-4px)", // Lift more on hover
        },

        minHeight: {
          xs: 250,
          sm: 175,
          md: 400,
          lg: 175,
        },
      }}
    >
      <CardContent
        sx={{
          display: "flex",
          justifyContent: "flex-start",
          alignItems: "center",
          gap: 4,
        }}
      >
        <Box>
          <CardMedia
            component="img"
            image={pageContent.image}
            alt="Review"
            sx={{ width: 100, height: 100, borderRadius: "50%" }}
          />
        </Box>
        <Box>
          <HeroText variant="h5" customStyle={customStyle?.customHeaderInfo}>
            {pageContent.name}
          </HeroText>

          <HeroSubHeader customStyle={customStyle?.customDescription}>
            {pageContent.designation}
          </HeroSubHeader>
        </Box>
      </CardContent>

      <CardContent>
        <HeroSubHeader customStyle={customStyle?.customDescription}>
          {pageContent.description}
        </HeroSubHeader>
      </CardContent>
    </Card>
  );
};

export const WishCard = ({ children, ...props }) => {
  const { pageContent, buttonHandler } = props;

  return (
    <Card
      sx={{
        position: "relative",
        borderRadius: "24px",
        height: {
          xs: 600,
          lg: 400,
        },
        m: 2,
        backgroundImage: `url(${pageContent.backgroundImage})`,
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
        backgroundPosition: "center",
      }}
    >
      <CardContent
        sx={{
          height: "100%",
        }}
      >
        <Grid
          container
          spacing={2}
          sx={{
            height: "100%",
          }}
        >
          <Grid item xs={12} lg={6}>
            <Box
              display="flex"
              flexDirection="column"
              alignItems="flex-start"
              justifyContent={{ xs: "flex-start", lg: "center" }}
              width="100%"
              height="100%"
              p={{
                xs: 2,
                sm: 5,
              }}
              gap={2}
              sx={{}}
            >
              <CommonHeaderTypography
                customStyle={pageContent.customStyle.headerTextStyle}
              >
                {pageContent.wish}
              </CommonHeaderTypography>
              <CommonHeaderTypography
                customStyle={pageContent.customStyle.headerTextStyle}
              >
                {pageContent.userName}
              </CommonHeaderTypography>
              <CommonSubHeaderTypography
                customStyle={pageContent.customStyle.subHeaderTextStyle}
              >
                {pageContent.description}
              </CommonSubHeaderTypography>

              <CommonButtonV2
                buttonText={pageContent.buttonText}
                onClick={buttonHandler}
                customStyle={{ fontSize: "1rem" }}
              />
            </Box>
          </Grid>

          <Grid item xs={12} lg={6}>
            <Box
              display="flex"
              flexDirection="row"
              justifyContent={{ xs: "center", lg: "flex-end" }}
              alignItems="center"
              height="100%"
            >
              <Box
                sx={{
                  transform: "translateX(25%)",
                }}
              >
                <CardMedia
                  component="img"
                  image={"/codingrippler/dashboards/welcome-graph.svg"}
                  alt="Description of the image"
                  sx={{
                    width: { xs: 125, lg: 125 },
                    height: { xs: 125, lg: 125 },
                    objectFit: "contain",
                  }}
                />
              </Box>
              <Box>
                <CardMedia
                  component="img"
                  image={pageContent.welcomeImage}
                  alt="Description of the image"
                  sx={{
                    width: { xs: 200, lg: 200 },
                    height: { xs: 200, lg: 200 },
                    objectFit: "contain",
                  }}
                />
              </Box>
            </Box>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

export const OfferCard = ({ children, ...props }) => {
  const { pageContent, itemToDisplay, buttonHandler } = props;

  return (
    <Card
      sx={{
        position: "relative",
        borderRadius: "24px",
        height: 400,
        m: 2,
        backgroundImage: `linear-gradient(to top, black 20%, transparent 100%), url(${itemToDisplay?.image})`,
        // backgroundImage: `url(${itemToDisplay?.image})`,
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
        backgroundPosition: "center",
      }}
    >
      <CardContent
        sx={{
          height: "100%",
        }}
      >
        <Grid
          container
          sx={{
            height: "100%",
          }}
        >
          <Grid item xs={12}>
            <Box
              display="flex"
              flexDirection="column"
              alignItems="flex-start"
              justifyContent="flex-end"
              width="100%"
              height="100%"
              p={{
                xs: 2,
                sm: 5,
              }}
              gap={2}
              sx={{}}
            >
              <CommonSubHeaderTypography
                customStyle={pageContent.customStyle.subHeaderTextStyle}
              >
                {itemToDisplay.offer}
              </CommonSubHeaderTypography>
              <CommonHeaderTypography
                customStyle={pageContent.customStyle.headerTextStyle}
              >
                {itemToDisplay.title}
              </CommonHeaderTypography>

              <CommonButtonV2
                buttonText={pageContent.buttonText}
                onClick={buttonHandler}
                customStyle={{ fontSize: "1rem" }}
              />
            </Box>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

export const ShowNumbers = ({ children, ...props }) => {
  const { pageContent } = props;

  const changeInPercentage = parseFloat(pageContent.changeInPercentage);

  return (
    <Card
      sx={{
        position: "relative",
        borderRadius: "24px",
        height: 300,
        m: 2,
      }}
    >
      <CardContent
        sx={{
          height: "100%",
        }}
      >
        <Grid
          container
          sx={{
            height: "100%",
          }}
        >
          <Grid item xs={12}>
            <Box pt={2.5}>
              <CommonHeaderTypography
                customStyle={pageContent?.customStyle?.headerTextStyle}
              >
                {pageContent.topic}
              </CommonHeaderTypography>
            </Box>
          </Grid>
          <Grid item xs={6}>
            <Box
              display="flex"
              flexDirection="column"
              alignItems="flex-start"
              justifyContent="center"
              width="100%"
              height={150}
              sx={{}}
            >
              <CommonHeaderTypography>
                {pageContent.total}
              </CommonHeaderTypography>
            </Box>
          </Grid>

          <Grid item xs={6}>
            <Box
              display="flex"
              flexDirection="column"
              alignItems="center"
              justifyContent="center"
              width="100%"
              height={150}
              sx={{}}
            >
              <ApexChartOfTypeLine
                seriesName={pageContent.seriesName}
                seriesData={pageContent.monthlyData}
                xAxisCategory={pageContent.monthlyCategories}
                color={pageContent?.customStyle?.graphColor}
                customHeight={"100%"}
                customWidth={"100%"}
                yAxisMin={pageContent.yAxisMin}
                yAxisMax={pageContent.yAxisMax}
              />
            </Box>
          </Grid>

          <Grid item xs={12}>
            <Box
              display="flex"
              justifyContent="flex-start"
              alignItems="center"
              gap={2}
              width="100%"
            >
              <Fab
                variant="extended"
                size="large"
                className={
                  changeInPercentage < 0
                    ? "light-error-bg"
                    : "light-green-background"
                }
                sx={{
                  zIndex: 1,
                }}
              >
                {changeInPercentage < 0 ? (
                  <TrendingDownIcon fontSize="small" className="error-main" />
                ) : (
                  <TrendingUpIcon fontSize="small" className="green-color" />
                )}
              </Fab>

              <CommonHeaderTypography
                customStyle={pageContent?.customStyle?.headerTextStyle}
              >
                {pageContent.changeInPercentage}
              </CommonHeaderTypography>

              <CommonSubHeaderTypography
                customStyle={pageContent?.customStyle?.subHeaderTextStyle}
              >
                {pageContent.frequency}
              </CommonSubHeaderTypography>
            </Box>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

export const ShowNumbersUsingRadialBar = ({ children, ...props }) => {
  const { pageContent } = props;

  return (
    <Card
      sx={{
        position: "relative",
        borderRadius: "24px",
        height: 600,
        m: 2,
      }}
    >
      <CardContent
        sx={{
          height: "100%",
        }}
      >
        <Box
          display="flex"
          flexDirection="column"
          alignItems="center"
          justifyContent="space-between"
          width="100%"
          height="100%"
          sx={{}}
        >
          <Box
            display="flex"
            justifyContent="flex-start"
            alignItems="center"
            width="100%"
            mt={2}
          >
            <CommonHeaderTypography
              customStyle={pageContent?.customStyle?.headerTextStyle}
            >
              {pageContent.topic}
            </CommonHeaderTypography>
          </Box>
          <Box
            sx={{
              height: { xl: 400, lg: 400, md: 400, sm: 400, xs: 400 },
              width: { xl: 400, lg: 400, md: 400, sm: 400, xs: 400 },
            }}
          >
            <ApexChartOfTypeRadialBar
              seriesData={pageContent.seriesData}
              labels={pageContent.labels}
              colors={pageContent.colors}
            />
          </Box>

          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            width="100%"
            gap={2}
          >
            <Box display="flex" alignItems="center">
              <FiberManualRecordIcon
                fontSize="small"
                sx={{
                  color: pageContent.colors[0],
                }}
              />
              <Typography variant="h6" ml={1} fontFamily='"Inter", sans-serif'>
                {pageContent.labels[0]}
              </Typography>
            </Box>
            <Box display="flex" alignItems="center">
              <FiberManualRecordIcon
                fontSize="small"
                sx={{
                  color: pageContent.colors[1],
                }}
              />
              <Typography variant="h6" ml={1} fontFamily='"Inter", sans-serif'>
                {pageContent.labels[1]}
              </Typography>
            </Box>
            <Box display="flex" alignItems="center">
              <FiberManualRecordIcon
                fontSize="small"
                sx={{
                  color: pageContent.colors[2],
                }}
              />
              <Typography variant="h6" ml={1} fontFamily='"Inter", sans-serif'>
                {pageContent.labels[2]}
              </Typography>
            </Box>
          </Box>
        </Box>
      </CardContent>
    </Card>
  );
};

export const ShowYearlyNumbers = ({ children, ...props }) => {
  const { pageContent } = props;

  const [selectedYear, setSelectedYear] = useState(2024);

  const handleYearChange = (event, newValue) => {
    setSelectedYear(newValue);
  };

  // const years = Array.from(
  //   new Array(2),
  //   (val, index) => new Date().getFullYear() - index
  // );

  const years = [2024, 2023];

  return (
    <Card
      sx={{
        position: "relative",
        borderRadius: "24px",
        height: 600,
        m: 2,
      }}
    >
      <CardContent
        sx={{
          height: "100%",
        }}
      >
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Box
              sx={{
                height: "100%",
                width: "100%",
              }}
            >
              <Box
                display="flex"
                justifyContent="center"
                alignItems="flex-start"
                flexDirection="column"
                width="100%"
                gap={1}
              >
                <Box
                  display="flex"
                  justifyContent="space-between"
                  alignItems="center"
                  width="100%"
                >
                  <CommonHeaderTypography
                    customStyle={pageContent?.customStyle?.headerTextStyle}
                  >
                    {pageContent.topic}
                  </CommonHeaderTypography>

                  <Autocomplete
                    value={selectedYear}
                    onChange={handleYearChange}
                    options={years}
                    getOptionLabel={(option) => option.toString()}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Select Year"
                        variant="outlined"
                      />
                    )}
                    style={{ width: 100 }}
                    disableClearable // Disable the clear option
                  />
                </Box>

                <CommonSubHeaderTypography
                  customStyle={pageContent?.customStyle?.subHeaderTextStyle}
                >
                  ({pageContent.changeInPercentage[selectedYear]}){" "}
                  {pageContent.frequency}
                </CommonSubHeaderTypography>

                <Box
                  display="flex"
                  flexDirection="row"
                  justifyContent="flex-start"
                  alignItems="center"
                  gap={2}
                  mt={2}
                >
                  <Box display="flex" flexDirection="column" gap={2}>
                    <Box display="flex" alignItems="center">
                      <FiberManualRecordIcon
                        fontSize="small"
                        sx={{
                          color: pageContent.customStyle?.graphColor[0],
                        }}
                      />
                      <Typography
                        variant="h6"
                        ml={1}
                        fontFamily='"Inter", sans-serif'
                        sx={{
                          fontSize: {
                            xs: "0.9rem",
                            sm: "1.5rem",
                          },
                        }}
                      >
                        {pageContent.seriesName[0]}
                      </Typography>
                    </Box>
                    <Typography
                      variant="h6"
                      ml={1}
                      fontFamily='"Inter", sans-serif'
                      sx={{
                        fontSize: {
                          xs: "0.9rem",
                          sm: "1.5rem",
                        },
                      }}
                    >
                      {pageContent.totalIncome[selectedYear]}
                    </Typography>
                  </Box>
                  <Box display="flex" flexDirection="column" gap={2}>
                    <Box display="flex" alignItems="center">
                      <FiberManualRecordIcon
                        fontSize="small"
                        sx={{
                          color: pageContent.customStyle?.graphColor[1],
                        }}
                      />
                      <Typography
                        variant="h6"
                        ml={1}
                        fontFamily='"Inter", sans-serif'
                        sx={{
                          fontSize: {
                            xs: "0.9rem",
                            sm: "1.5rem",
                          },
                        }}
                      >
                        {pageContent.seriesName[1]}
                      </Typography>
                    </Box>
                    <Typography
                      variant="h6"
                      ml={1}
                      fontFamily='"Inter", sans-serif'
                      sx={{
                        fontSize: {
                          xs: "0.9rem",
                          sm: "1.5rem",
                        },
                      }}
                    >
                      {pageContent.totalExpenses[selectedYear]}
                    </Typography>
                  </Box>
                </Box>
              </Box>
            </Box>
          </Grid>

          <Grid item xs={12}>
            <Box
              sx={{
                height: 300,
                width: "100%",
              }}
            >
              <LineCharts
                seriesName={pageContent.seriesName}
                seriesData={pageContent.seriesData[selectedYear]}
                xAxisCategory={pageContent.monthlyCategories}
                color={pageContent?.customStyle?.graphColor}
                yAxisMin={0}
                yAxisMax={250}
                customHeight="100%"
                customWidth="100%"
              />
            </Box>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

export const ShowSalesOverview = ({ children, ...props }) => {
  const { pageContent } = props;

  return (
    <Card
      sx={{
        position: "relative",
        borderRadius: "24px",
        height: 450,
        m: 2,
      }}
    >
      <CardContent
        sx={{
          height: "100%",
          mt: 2,
        }}
      >
        <Grid container spacing={5}>
          <Grid item xs={12}>
            <CommonHeaderTypography
              customStyle={pageContent?.customStyle?.headerTextStyle}
            >
              {pageContent.topic}
            </CommonHeaderTypography>
          </Grid>

          <Grid item xs={12}>
            <Stack>
              <Box
                display="flex"
                justifyContent="space-between"
                alignItems="center"
              >
                <CommonSubHeaderTypography
                  customStyle={pageContent?.customStyle?.subHeaderTextStyle}
                >
                  {pageContent.TotalProfit.header}
                </CommonSubHeaderTypography>

                <Box display="flex" alignItems="center" justifyContent="center">
                  <CommonSubHeaderTypography
                    customStyle={pageContent?.customStyle?.subHeaderTextStyle}
                  >
                    {pageContent.TotalProfit.currency}
                    {pageContent.TotalProfit.amount}
                  </CommonSubHeaderTypography>

                  <CommonSubHeaderTypography
                    customStyle={pageContent?.customStyle?.subHeaderTextStyle2}
                  >
                    ({pageContent.TotalProfit.changeInPercentage})
                  </CommonSubHeaderTypography>
                </Box>
              </Box>

              <Box width="100%" mt={2}>
                <LinearProgress
                  variant="determinate"
                  value={pageContent.TotalProfit.progressValue} // Assuming progressValue is a number between 0 and 100
                  sx={{
                    height: 10,
                    borderRadius: 5, // Make the stroke rounded
                    "& .MuiLinearProgress-bar": {
                      borderRadius: 5, // Make the stroke rounded
                      backgroundColor: pageContent.TotalProfit.progressColor, // Set the color of the progress bar
                    },
                    backgroundColor: "grey.300", // Set the background color of the progress track
                  }} // Increase the height of the progress bar
                />
              </Box>
            </Stack>
          </Grid>

          <Grid item xs={12}>
            <Stack>
              <Box
                display="flex"
                justifyContent="space-between"
                alignItems="center"
              >
                <CommonSubHeaderTypography
                  customStyle={pageContent?.customStyle?.subHeaderTextStyle}
                >
                  {pageContent.TotalIncome.header}
                </CommonSubHeaderTypography>

                <Box display="flex" alignItems="center" justifyContent="center">
                  <CommonSubHeaderTypography
                    customStyle={pageContent?.customStyle?.subHeaderTextStyle}
                  >
                    {pageContent.TotalIncome.currency}
                    {pageContent.TotalIncome.amount}
                  </CommonSubHeaderTypography>

                  <CommonSubHeaderTypography
                    customStyle={pageContent?.customStyle?.subHeaderTextStyle2}
                  >
                    ({pageContent.TotalIncome.changeInPercentage})
                  </CommonSubHeaderTypography>
                </Box>
              </Box>

              <Box width="100%" mt={2}>
                <LinearProgress
                  variant="determinate"
                  value={pageContent.TotalIncome.progressValue} // Assuming progressValue is a number between 0 and 100
                  sx={{
                    height: 10,
                    borderRadius: 5, // Make the stroke rounded
                    "& .MuiLinearProgress-bar": {
                      borderRadius: 5, // Make the stroke rounded
                      backgroundColor: pageContent.TotalIncome.progressColor, // Set the color of the progress bar
                    },
                    backgroundColor: "grey.300", // Set the background color of the progress track
                  }} // Increase the height of the progress bar
                />
              </Box>
            </Stack>
          </Grid>

          <Grid item xs={12}>
            <Stack>
              <Box
                display="flex"
                justifyContent="space-between"
                alignItems="center"
              >
                <CommonSubHeaderTypography
                  customStyle={pageContent?.customStyle?.subHeaderTextStyle}
                >
                  {pageContent.TotalExpenses.header}
                </CommonSubHeaderTypography>

                <Box display="flex" alignItems="center" justifyContent="center">
                  <CommonSubHeaderTypography
                    customStyle={pageContent?.customStyle?.subHeaderTextStyle}
                  >
                    {pageContent.TotalExpenses.currency}
                    {pageContent.TotalExpenses.amount}
                  </CommonSubHeaderTypography>

                  <CommonSubHeaderTypography
                    customStyle={pageContent?.customStyle?.subHeaderTextStyle2}
                  >
                    ({pageContent.TotalExpenses.changeInPercentage})
                  </CommonSubHeaderTypography>
                </Box>
              </Box>

              <Box width="100%" mt={2}>
                <LinearProgress
                  variant="determinate"
                  value={pageContent.TotalExpenses.progressValue} // Assuming progressValue is a number between 0 and 100
                  sx={{
                    height: 10,
                    borderRadius: 5, // Make the stroke rounded
                    "& .MuiLinearProgress-bar": {
                      borderRadius: 5, // Make the stroke rounded
                      backgroundColor: pageContent.TotalExpenses.progressColor, // Set the color of the progress bar
                    },
                    backgroundColor: "grey.300", // Set the background color of the progress track
                  }} // Increase the height of the progress bar
                />
              </Box>
            </Stack>
          </Grid>

          <Grid item xs={12}></Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

export const ShowBalanceSheet = ({ children, ...props }) => {
  const { pageContent, buttonHandler } = props;

  return (
    <Card
      sx={{
        position: "relative",
        borderRadius: "24px",
        height: 450,
        m: 2,
      }}
    >
      <CardContent
        sx={{
          height: "100%",
          mt: 2,
        }}
      >
        <Grid container spacing={4}>
          <Grid item xs={12}>
            <CommonHeaderTypography
              customStyle={pageContent?.customStyle?.headerTextStyle}
            >
              {pageContent.topic}
            </CommonHeaderTypography>
          </Grid>

          <Grid item xs={12}>
            <CommonHeaderTypography customStyle={{ textAlign: "left" }}>
              {pageContent.currentBalance["currency"]}{" "}
              {pageContent.currentBalance["amount"]}
            </CommonHeaderTypography>
          </Grid>

          <Grid item xs={12}>
            <Stack>
              <Box
                display="flex"
                justifyContent="space-between"
                alignItems="center"
              >
                <CommonSubHeaderTypography
                  customStyle={pageContent?.customStyle?.subHeaderTextStyle}
                >
                  {pageContent.orderTotal.header}
                </CommonSubHeaderTypography>

                <Box display="flex" alignItems="center" justifyContent="center">
                  <CommonSubHeaderTypography
                    customStyle={pageContent?.customStyle?.subHeaderTextStyle}
                  >
                    {pageContent.orderTotal.currency}
                    {pageContent.orderTotal.amount}
                  </CommonSubHeaderTypography>
                </Box>
              </Box>
            </Stack>
          </Grid>

          <Grid item xs={12}>
            <Stack>
              <Box
                display="flex"
                justifyContent="space-between"
                alignItems="center"
              >
                <CommonSubHeaderTypography
                  customStyle={pageContent?.customStyle?.subHeaderTextStyle}
                >
                  {pageContent.earning.header}
                </CommonSubHeaderTypography>

                <Box display="flex" alignItems="center" justifyContent="center">
                  <CommonSubHeaderTypography
                    customStyle={pageContent?.customStyle?.subHeaderTextStyle}
                  >
                    {pageContent.earning.currency}
                    {pageContent.earning.amount}
                  </CommonSubHeaderTypography>
                </Box>
              </Box>
            </Stack>
          </Grid>

          <Grid item xs={12}>
            <Stack>
              <Box
                display="flex"
                justifyContent="space-between"
                alignItems="center"
              >
                <CommonSubHeaderTypography
                  customStyle={pageContent?.customStyle?.subHeaderTextStyle}
                >
                  {pageContent.refunded.header}
                </CommonSubHeaderTypography>

                <Box display="flex" alignItems="center" justifyContent="center">
                  <CommonSubHeaderTypography
                    customStyle={pageContent?.customStyle?.subHeaderTextStyle}
                  >
                    {pageContent.refunded.currency}
                    {pageContent.refunded.amount}
                  </CommonSubHeaderTypography>
                </Box>
              </Box>
            </Stack>
          </Grid>

          <Grid item xs={6}>
            <Stack>
              <CommonButtonV2
                buttonText={"Request"}
                onClick={buttonHandler}
                customStyle={{ fontSize: "1rem" }}
                customClass="button-shoping"
              />
            </Stack>
          </Grid>

          <Grid item xs={6}>
            <Stack>
              <CommonButtonV2
                buttonText={"Transfer"}
                onClick={buttonHandler}
                customStyle={{ fontSize: "1rem" }}
              />
            </Stack>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

export const ShowBestSalesMan = ({ children, ...props }) => {
  const { pageContent } = props;

  const salesManData = pageContent.salesMan;

  return (
    <Card
      sx={{
        position: "relative",
        borderRadius: "24px",
        m: 2,
      }}
    >
      <CardContent
        sx={{
          mt: 2,
        }}
      >
        <CommonHeaderTypography
          customStyle={pageContent?.customStyle?.headerTextStyle}
        >
          {pageContent.topic}
        </CommonHeaderTypography>
      </CardContent>
      <CardContent></CardContent>
      <Box
        sx={{
          overflowX: "auto", // Enable horizontal scrolling
          width: "100%", // Ensure the container takes the full width
        }}
      >
        <TableContainer component={Paper}>
          <Table>
            <TableHead className="white-bg">
              <TableRow>
                <TableCell>
                  <Typography
                    variant="h6"
                    fontFamily='"Inter", sans-serif'
                    fontWeight="bold"
                    className="sub-header"
                  >
                    Seller
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography
                    variant="h6"
                    fontFamily='"Inter", sans-serif'
                    fontWeight="bold"
                    className="sub-header"
                  >
                    Product
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography
                    variant="h6"
                    fontFamily='"Inter", sans-serif'
                    fontWeight="bold"
                    className="sub-header"
                  >
                    Country
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography
                    variant="h6"
                    fontFamily='"Inter", sans-serif'
                    fontWeight="bold"
                    className="sub-header"
                  >
                    Total{" "}
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography
                    variant="h6"
                    fontFamily='"Inter", sans-serif'
                    fontWeight="bold"
                    className="sub-header"
                  >
                    Rank{" "}
                  </Typography>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {salesManData.map((row, index) => (
                <TableRow
                  key={index}
                  sx={{
                    "&:not(:last-child) td, &:not(:last-child) th": {
                      borderBottom: "1px dashed", // Change the default separator to dashed
                    },
                  }}
                >
                  <TableCell>
                    <Box
                      display="flex"
                      alignItems="center"
                      justifyContent="flex-start"
                      gap={2}
                    >
                      <Avatar
                        alt={row.name}
                        src={row.avatar}
                        size="lg"
                        sx={{
                          width: {
                            xs: 50,
                            sm: 75,
                          },
                          height: { xs: 50, sm: 75 },
                          borderRadius: "50%",
                          backgroundColor: "#f7faff",
                        }}
                      />
                      <Typography
                        variant="body1"
                        fontFamily='"Inter", sans-serif'
                        fontWeight="Normal"
                        sx={{
                          fontSize: {
                            xs: "0.8rem",
                            sm: "1.25rem",
                          },
                        }}
                      >
                        {row.name}
                      </Typography>
                    </Box>
                  </TableCell>
                  <TableCell>
                    {" "}
                    <Typography
                      variant="body1"
                      fontFamily='"Inter", sans-serif'
                      fontWeight="Normal"
                      sx={{
                        fontSize: {
                          xs: "0.8rem",
                          sm: "1.25rem",
                        },
                      }}
                    >
                      {row.product}
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Box
                      display="flex"
                      alignItems="center"
                      justifyContent="flex-start"
                      width="100%"
                      ml={2}
                    >
                      <CardMedia
                        component="img"
                        image={row.countryFlag}
                        alt={row.country}
                        sx={{ width: 40, height: 40 }}
                      />
                    </Box>
                  </TableCell>
                  <TableCell>
                    <Typography
                      variant="body1"
                      fontFamily='"Inter", sans-serif'
                      fontWeight="Normal"
                      sx={{
                        fontSize: {
                          xs: "0.8rem",
                          sm: "1.25rem",
                        },
                      }}
                    >
                      {row.sales.currency} {row.sales.amount}
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Chip
                      className={`top-${row.rank}-bg`}
                      label={
                        <Typography
                          variant="body2"
                          className={`top-${row.rank}-text`}
                          fontWeight="bold"
                          fontFamily='"Inter", sans-serif'
                          sx={{
                            fontSize: {
                              xs: "0.8rem",
                              sm: "1.25rem",
                            },
                          }}
                        >
                          {"Top "}
                          {row.rank}
                        </Typography>
                      }
                      sx={{
                        borderRadius: "24px",
                      }}
                    />
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
    </Card>
  );
};

export const ShowLatestProduct = ({ children, ...props }) => {
  const { pageContent } = props;

  const latestProduct = pageContent.items;

  return (
    <Card
      sx={{
        position: "relative",
        borderRadius: "24px",
        m: 2,
      }}
    >
      <CardContent
        sx={{
          mt: 2,
        }}
      >
        <CommonHeaderTypography
          customStyle={pageContent?.customStyle?.headerTextStyle}
        >
          {pageContent.topic}
        </CommonHeaderTypography>
      </CardContent>
      <CardContent>
        <Grid container spacing={2}>
          {latestProduct.map((product, index) => (
            <Grid item xs={12} key={index}>
              <Box
                display="flex"
                justifyContent="space-between"
                alignItems="center"
              >
                <Box
                  display="flex"
                  justifyContent="flex-start"
                  alignItems="center"
                  gap={2}
                >
                  <Card
                    sx={{
                      borderRadius: "24px",
                      height: 75,
                      width: 75,
                      m: 2,
                    }}
                  >
                    <CardMedia
                      component="img"
                      image={product.image}
                      alt="Product"
                      sx={{ width: "100%", height: "100%", objectFit: "cover" }}
                    ></CardMedia>
                  </Card>

                  <Stack gap={2}>
                    <Tooltip arrow>
                      <Box
                        component="span"
                        sx={{
                          cursor: "pointer",
                          textDecoration: "none",
                          "&:hover": {
                            textDecoration: "underline",
                          },
                        }}
                      >
                        <Typography
                          variant="h6"
                          fontFamily='"Inter", sans-serif'
                          sx={{
                            fontSize: {
                              xs: "1rem",
                              sm: "1.25rem",
                            },
                          }}
                        >
                          {product.title}
                        </Typography>
                      </Box>
                    </Tooltip>

                    <Box>
                      {product.price.isDiscountAvailable ? (
                        <Stack direction="row" gap={1}>
                          <Typography
                            variant="h6"
                            fontFamily='"Inter", sans-serif'
                            className="sub-header"
                            sx={{
                              textDecoration: "line-through",
                              fontSize: {
                                xs: "1rem",
                                sm: "1.25rem",
                              },
                            }}
                          >
                            {product.price.currency}
                            {product.price.amount}
                          </Typography>

                          <Typography
                            variant="h6"
                            fontFamily='"Inter", sans-serif'
                            className="sub-header"
                            sx={{
                              fontSize: {
                                xs: "1rem",
                                sm: "1.25rem",
                              },
                            }}
                          >
                            {product.price.currency}
                            {product.price.afterDiscount}
                          </Typography>
                        </Stack>
                      ) : (
                        <Typography
                          variant="h6"
                          fontFamily='"Inter", sans-serif'
                          className="sub-header"
                        >
                          {product.price.currency}
                          {product.price.amount}
                        </Typography>
                      )}
                    </Box>
                  </Stack>
                </Box>

                <Box>
                  <AvatarGroup
                    max={3}
                    sx={{
                      "& .MuiAvatar-root": {
                        width: 20,
                        height: 20,
                        fontSize: 12, // Adjust font size for the "+number" indicator
                      },
                    }}
                  >
                    {Array.from({ length: product.liveUsers }).map(
                      (_, index) => (
                        <Avatar
                          key={index}
                          alt=" "
                          src="/broken-image.jpg"
                          className={`av-${index}-background`}
                          sx={{
                            width: 16,
                            height: 16,
                          }}
                        />
                      )
                    )}
                  </AvatarGroup>
                </Box>
              </Box>
            </Grid>
          ))}
        </Grid>
      </CardContent>
    </Card>
  );
};

export const DotSpinner = () => {
  return (
    <Box
      className="dot-spinner"
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        width: "100%",
        height: "100%",
        "& .dot": {
          width: "14px",
          height: "14px",
          margin: "0 5px",
          borderRadius: "50%",
          animation: "dotSpinner 1.2s infinite ease-in-out both",
        },
        "& .dot:nth-of-type(1)": {
          backgroundColor: "#6056ff", // Primary color
        },
        "& .dot:nth-of-type(2)": {
          backgroundColor: "#ff5656", // Complementary color
        },
        "& .dot:nth-of-type(3)": {
          backgroundColor: "#56aaff", // Analogous color
        },
        "@keyframes dotSpinner": {
          "0%, 80%, 100%": {
            transform: "scale(0)",
          },
          "40%": {
            transform: "scale(1)",
          },
        },
      }}
    >
      <Box className="dot" />
      <Box className="dot" />
      <Box className="dot" />
    </Box>
  );
};

export const RouteToAIPortal = () => {
  window.location.href = `/ai`;
};

export const RouteToAboutUs = () => {
  window.location.href = `/about`;
};

export const RouteToWebPortal = (context = "/web") => {
  window.location.href = `${context}`;
};

export const RouteToCodingPortal = (context = "/") => {
  window.location.href = `${context}`;
};

export const CommonContainer = ({ children, ...props }) => {
  let { key, customStyle } = props;
  return (
    <Box
      m={{
        xs: 2,
        sm: 2.5,
      }}
      key={key || ""}
      sx={{
        ...customStyle,
      }}
    >
      {children}
    </Box>
  );
};

export const DropDownButtonWithDescription = ({ children, ...props }) => {
  const [isHovered, setIsHovered] = useState(false);
  const theme = useTheme();
  const isLargeScreen = useMediaQuery(theme.breakpoints.up("lg"));

  let {
    activeDropdown,
    handleButtonClick,
    buttonName,
    dropDownMenu,
    buttonIndex,
  } = props;

  const handleClick = (context) => {
    window.location.href = context;
  };

  return (
    <Box
      sx={{
        [theme.breakpoints.down("lg")]: { position: "relative" },
      }}
    >
      {isLargeScreen && (
        <Button
          variant="text"
          index={buttonIndex}
          style={{ color: theme.palette.text.primary }}
          onMouseEnter={() => {
            // setIsHovered(true);
            handleButtonClick(buttonIndex);
          }}
          onMouseLeave={() => {
            // setIsHovered(false);
            handleButtonClick(null);
          }}
          onClick={() => {
            // setIsHovered(!isHovered);
            handleButtonClick(buttonIndex);
          }}
        >
          <Box display="flex" alignItems="center">
            <Typography
              variant="h6"
              fontFamily="Inter, sans-serif" // Add your desired font family here
              fontSize="1rem"
            >
              {buttonName}
            </Typography>{" "}
            {!isHovered && (
              <KeyboardArrowDownIcon onClick={() => setIsHovered(!isHovered)} />
            )}
            {isHovered && (
              <KeyboardArrowUp onClick={() => setIsHovered(!isHovered)} />
            )}
          </Box>
        </Button>
      )}

      {!isLargeScreen && (
        <Box display="flex" alignItems="center" justifyContent="space-between">
          <Button
            variant="text"
            index={buttonIndex}
            style={{ color: theme.palette.text.primary }}
            onMouseEnter={() => {
              // setIsHovered(true);
              handleButtonClick(buttonIndex);
            }}
            onMouseLeave={() => {
              // setIsHovered(false);
              handleButtonClick(null);
            }}
            onClick={() => {
              // setIsHovered(!isHovered);
              handleButtonClick(buttonIndex);
            }}
          >
            <Typography
              variant="h6"
              fontFamily="Inter, sans-serif" // Add your desired font family here
              fontSize="1rem"
            >
              {buttonName}
            </Typography>{" "}
          </Button>
          {activeDropdown === buttonIndex ? (
            <KeyboardArrowUp
              onClick={() => {
                // setIsHovered(!isHovered);
                handleButtonClick(null);
              }}
            />
          ) : (
            <KeyboardArrowDownIcon
              onClick={() => {
                // setIsHovered(!isHovered);
                handleButtonClick(buttonIndex);
              }}
            />
          )}{" "}
        </Box>
      )}

      {activeDropdown === buttonIndex && (
        <Card
          sx={{
            // position: "absolute",
            zIndex: 1,
            [theme.breakpoints.down("lg")]: {
              width: "100%",
              position: "relative",
            },
            [theme.breakpoints.up("lg")]: {
              width: "25%",
              position: "absolute",
              zIndex: 100,
            },
          }}
          onMouseEnter={() => setIsHovered(true)}
          onMouseLeave={() => setIsHovered(false)} // Hide the card when the mouse leaves it
        >
          {dropDownMenu.map((menu, index) => (
            <CardContent
              className="customHoverEffect"
              onClick={() => {
                //              handleWebClick("/web");
                handleClick(menu.context);
              }}
              sx={{
                p: 2,
              }}
              key={index}
            >
              <Box
                display="flex"
                flexDirection="column"
                alignItems="flex-start"
                gap={2}
              >
                <Box
                  display="flex"
                  flexDirection="row"
                  alignItems="center"
                  gap={2}
                >
                  {/* <LanguageIcon /> */}
                  {menu.icon}
                  <Box>
                    <Typography variant="h6" className="typography-custom-css">
                      {menu.name}
                    </Typography>
                  </Box>
                </Box>
                <Typography
                  variant="caption"
                  className="typography-custom-css"
                  sx={{
                    ml: "15px",
                    color: theme.palette.text.secondary,
                  }}
                >
                  {menu.description}
                </Typography>
              </Box>
            </CardContent>
          ))}

          {/* <CardContent
            className="customHoverEffect"
            onClick={() => {
              handleAIClick("/ai");
            }}
            sx={{
              p: 2,
            }}
          >
            <Box
              display="flex"
              flexDirection="column"
              alignItems="flex-start"
              gap={2}
            >
              <Box
                display="flex"
                flexDirection="row"
                alignItems="center"
                gap={2}
              >
                <HubIcon />
                <Box sx={{}}>
                  <Typography variant="h6" className="typography-custom-css">
                    AI Rippler
                  </Typography>
                </Box>
              </Box>
              <Typography
                variant="caption"
                className="typography-custom-css"
                sx={{
                  ml: "15px",
                  color: theme.palette.text.secondary,
                }}
              >
                AI-powered solutions to optimize business processes and
                innovation.
              </Typography>
            </Box>
          </CardContent> */}
        </Card>
      )}
    </Box>
  );
};

export const CardWithBackgroundHoverEffect = ({ children, ...props }) => {
  const { advantage } = props;

  return (
    <Card
      sx={{
        position: "relative",
        overflow: "hidden",
        borderRadius: "12px", // Rounded corners

        height: "100%", // Makes all cards same height
    
        "&::before": {
          content: '""',
          position: "absolute",
          top: 0,
          left: 0,
          width: "150%",
          height: "150%",
          backgroundColor: advantage.hoverColor,
          transition: "transform 0.5s ease",
          transform: "scale(0)",
          transformOrigin: "bottom right",
          zIndex: 0,
        },
        "&:hover::before": {
          transform: "scale(1)",
        },
        "&:hover .hoverContent": {
          color: "white",
        },
      }}
    >
      <CardContent sx={{ position: "relative", zIndex: 1 }}>
        <HeroText
          className="hoverContent"
          variant="h5"
          customStyle={{
            textAlign: "left",
            transition: "color 0.5s ease",
          }}
        >
          {advantage.title}
        </HeroText>
      </CardContent>
      <CardContent sx={{ position: "relative", zIndex: 1 }}>
        <HeroSubHeader
          className="hoverContent"
          customStyle={{
            textAlign: "left",
            transition: "color 0.5s ease",
          }}
        >
          {advantage.description}
        </HeroSubHeader>
      </CardContent>
    </Card>
  );
};


export const SectionTitle = styled(Typography)(({ theme }) => ({
  position: 'relative',
  display: 'inline-block',
  marginBottom: theme.spacing(2),
  fontWeight: 600,
  color: '#6056ff',
  textTransform: 'uppercase',
  letterSpacing: '2px',
  '&::after': {
    content: '""',
    position: 'absolute',
    width: '60px',
    height: '3px',
    background: '#6056ff',
    bottom: '-10px',
    left: '0',
    borderRadius: '2px'
  }
}));


export const SectionHeading = styled(Typography)(({ theme }) => ({
  fontWeight: 700,
  marginBottom: theme.spacing(6),
  position: 'relative',
  zIndex: 1
}));


export const ContactUsButton = styled(Button)(({ theme }) => ({
  background: 'linear-gradient(90deg, #6366F1 0%, #8B5CF6 100%)',
  color: 'white',
  fontWeight: 600,
  fontSize: '1rem',
  padding: '10px 28px',
  borderRadius: '30px',
  textTransform: 'none',
  boxShadow: "0 6px 15px rgba(99, 102, 241, 0.4)",
  transition: 'all 0.3s ease',
  '&:hover': {
    boxShadow: '0 6px 20px rgba(95, 130, 255, 0.4)',
    transform: 'translateY(-2px)',
    background: "linear-gradient(90deg, #5661F0 0%, #7048F5 100%)"
  },
}));
