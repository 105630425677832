import { KeyboardArrowUp } from "@mui/icons-material";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import {
  Button,
  Card,
  CardActions,
  CardContent,
  CardMedia,
  Fab,
  Grid,
  Tooltip,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { Box, styled } from "@mui/system";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { truncate } from "./AIUtil/AIUtils";

export const Cards = ({ image, title, description, ...props }) => {
  const { customStyle, minHeight } = props;
  const theme = useTheme();

  return (
    <Card
      sx={{
        borderRadius: "24px",
        m: 2,
        height: 500,
        position: "relative",
        display: "flex",
        flexDirection: "column",
        justifyContent: "flex-start",
        alignItems: "center",
        [theme.breakpoints.up("sm")]: {
          minHeight: minHeight || "400px", // Use minHeight from props or default to "400px"
        },
      }}
      elevation={3}
    >
      <CardMedia
        component="img"
        image={image}
        alt={title}
        sx={{ ...customStyle, height: "60%" }}
      />
      <CardContent height="40%" sx={{ mt: 2 }}>
        <Typography
          variant="h5"
          className="typography-custom-css"
          fontWeight="bold"
        >
          {title}
        </Typography>
        <Box m={2} />
        <Typography
          variant="body1"
          className="sub-header, typography-custom-css"
        >
          {description}
        </Typography>
      </CardContent>
    </Card>
  );
};

export const DashedCardComponent = ({ children, ...props }) => {
  const {
    imageSrc,
    imageAlt,
    title,
    description,
    handleButtonClick,
    buttonText,
    theme,
    maxLength = 100,
  } = props;

  const truncatedDescriptionText = truncate({
    str: description,
    maxLength: maxLength,
  });

  return (
    <Card spacing={2} sx={{ border: "1px dashed", minHeight: "320px" }}>
      <CardContent>
        <Box
          display="flex"
          flexDirection="row"
          justifyContent="flex-start"
          alignItems="center"
        >
          <Box sx={{ marginRight: "20px" }}>
            <CardMedia
              component="img"
              image={imageSrc}
              alt={imageAlt}
              sx={{ 
                    objectFit: "contain",              
                    height: 22.5,
                    width:40
                  }}
            />
          </Box>
          <Typography
            variant="h5"
            className="typography-custom-css"
            sx={{ fontWeight: "bold" }}
          >
            {title}
          </Typography>
        </Box>
      </CardContent>

      <CardContent>
        <Typography variant="h6" className="sub-header, typography-custom-css">
          {truncatedDescriptionText ? (
            <>
              {truncatedDescriptionText}
              <Tooltip title={description}>
                <span style={{ cursor: "pointer" }}>...</span>
              </Tooltip>
            </>
          ) : (
            description
          )}
        </Typography>
      </CardContent>

      <CardActions sx={{ justifyContent: "flex-end" }}>
        <Fab
          variant="extended"
          size="large"
          p={10}
          sx={{
            backgroundColor: theme.palette.background.default,
            mr: 2,
            mb: 2,
            zIndex: 1,
          }}
          onClick={handleButtonClick}
        >
          {buttonText}
        </Fab>
      </CardActions>
    </Card>
  );
};

export const MarqueeContainer = styled(Box)({
  overflow: "hidden",
  whiteSpace: "nowrap",
  width: "100%",
  // maskImage:
  //   "linear-gradient(to right, transparent, black 10px, black 90%, transparent)",
  maskImage:
    "linear-gradient(to right, hsl(0 0% 0%/0), hsl(0 0% 0%/1) 10%, hsl(0 0% 0%/1) 90%, hsl(0 0% 0%/0))",
});

export const MarqueeRow = styled(Box)(({ direction }) => ({
  display: "flex",
  flexDirection: "row",
  justifyContent: "space-between",
  alignItems: "center",
  height: "100%", // Adjust the height as needed
  width: "100%", // Adjust the width as needed
  overflow: "hidden",
  gap: "1rem", // Adjust the gap as needed
  animation: `marquee 60s linear infinite ${
    direction === "reverse" ? "reverse" : "normal"
  }`,
  "@keyframes marquee": {
    "0%": { transform: "translateX(-50%)" },
    "100%": { transform: "translateX(0%)" },
  },
}));

export const PageHeader = ({ children, ...props }) => {
  const { headerText } = props;

  return (
    <Box display="flex" justifyContent="center">
      <Box>{headerText}</Box>
    </Box>
  );
};

export const PageSubHeader = ({ children, ...props }) => {
  const { headerText } = props;

  return (
    <Box display="flex" justifyContent="center">
      {headerText}
    </Box>
  );
};

export const TopicContainer = ({ children, ...props }) => {
  const theme = useTheme();

  const { topicText } = props;

  return (
    <Box display="flex" justifyContent="center">
      <Fab
        variant="extended"
        size="large"
        sx={{ backgroundColor: theme.palette.background.default, zIndex: 1 }}
        p={10}
        pointerEvents="none"
      >
        {/* <AutoAwesomeIcon /> */}
        {children}

        <Typography ml={1} className="typography-custom-css">
          {topicText}
        </Typography>
      </Fab>
    </Box>
  );
};

export const CardWithMediaTitleAndDescription = ({ children, ...props }) => {
  const { data } = props;
  return (
    <Grid container spacing={2}>
      {data.map((data, index) => (
        <Grid item xs={12} md={4} key={index}>
          <Cards
            image={data.image}
            title={data.title}
            description={data.description}
          />
        </Grid>
      ))}
    </Grid>
  );
};

export const InLineMediaContainer = ({ children, ...props }) => {
  const { data } = props;

  return (
    <Grid container>
      <Grid item xs={12}>
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          flexDirection="row"
          maxWidth="100%"
          overflow="hidden"
          sx={{ gap: 2, opacity: 0.4 }}
        >
          {data.map((src, index) => (
            <CardMedia
              key={index}
              component="img"
              image={src}
              alt="Description of the image"
              width="100px"
              height="75px"
              sx={{ objectFit: "contain" }}
            />
          ))}
        </Box>
      </Grid>
    </Grid>
  );
};

export const PercentageContainer = ({ children, ...props }) => {
  const [percentage, setPercentage] = useState(0);
  const percentageRef = useRef(null);
  const intervalRef = useRef(null);

  const {
    className = "typography-custom-css",
    text,
    value,
    prefix,
    suffix,
  } = props;

  const startInterval = useCallback(() => {
    let currentPercentage = 0;
    intervalRef.current = setInterval(() => {
      currentPercentage += 1;
      setPercentage(currentPercentage);
      if (currentPercentage === value) {
        clearInterval(intervalRef.current);
      }
    }, 20); // 20 milliseconds interval time
  }, [value]);

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          startInterval();
        } else {
          clearInterval(intervalRef.current);
        }
      },
      { threshold: 0.1 } // Adjust threshold as needed
    );

    if (percentageRef.current) {
      observer.observe(percentageRef.current);
    }

    return () => {
      observer.disconnect();
      clearInterval(intervalRef.current);
    };
  }, [startInterval]);

  // const startInterval = () => {
  //   let currentPercentage = 0;
  //   intervalRef.current = setInterval(() => {
  //     currentPercentage += 1;
  //     setPercentage(currentPercentage);
  //     if (currentPercentage === value) {
  //       clearInterval(intervalRef.current);
  //     }
  //   }, 20); // 20 milliseconds interval time
  // };

  return (
    <Box ref={percentageRef}>
      <Typography
        className={className}
        fontWeight="bold"
        sx={{
          textAlign: "center",
          typography: {
            xs: "h4",
            md: "h3",
            lg: "h3",
          },
        }}
      >
        <span className="theme-specfic-colored-text">
          {prefix}
          {percentage}
          {suffix}
        </span>
      </Typography>

      <Typography
        variant="h5"
        width="100%"
        className={`sub-header typography-custom-css`}
        fontWeight="normal"
        sx={{
          textAlign: "center",
          typography: {
            xs: "h6",
            md: "h5",
            lg: "h5",
          },
        }}
      >
        {text}{" "}
      </Typography>
    </Box>
  );
};

export const DropDownButton = ({ children, ...props }) => {
  const [isHovered, setIsHovered] = useState(false);
  const theme = useTheme();
  const isLargeScreen = useMediaQuery(theme.breakpoints.up("lg"));

  let {
    activeDropdown,
    handleButtonClick,
    buttonName,
    dropDownMenu,
    buttonIndex,
  } = props;

  const handleClick = (context) => {
    window.location.href = context;
  };

  return (
    <Box
      sx={{
        [theme.breakpoints.down("lg")]: { position: "relative" },
      }}
    >
      {isLargeScreen && (
        <Button
          variant="text"
          index={buttonIndex}
          style={{ color: theme.palette.text.primary }}
          onMouseEnter={() => {
            handleButtonClick(buttonIndex);
          }}
          onMouseLeave={() => {
            handleButtonClick(null);
          }}
          onClick={() => {
            handleButtonClick(buttonIndex);
          }}
        >
          <Box display="flex" alignItems="center">
            <Typography
              variant="h6"
              fontFamily="Inter, sans-serif" // Add your desired font family here
              fontSize="1rem"
            >
              {buttonName}
            </Typography>{" "}
            {!isHovered && (
              <KeyboardArrowDownIcon onClick={() => setIsHovered(!isHovered)} />
            )}
            {isHovered && (
              <KeyboardArrowUp onClick={() => setIsHovered(!isHovered)} />
            )}
          </Box>
        </Button>
      )}

      {!isLargeScreen && (
        <Box display="flex" alignItems="center" justifyContent="space-between">
          <Button
            variant="text"
            index={buttonIndex}
            style={{ color: theme.palette.text.primary }}
            onMouseEnter={() => {
              handleButtonClick(buttonIndex);
            }}
            onMouseLeave={() => {
              handleButtonClick(null);
            }}
            onClick={() => {
              handleButtonClick(buttonIndex);
            }}
          >
            <Typography
              variant="h6"
              fontFamily="Inter, sans-serif" // Add your desired font family here
              fontSize="1rem"
            >
              {buttonName}
            </Typography>{" "}
          </Button>
          {activeDropdown === buttonIndex ? (
            <KeyboardArrowUp
              onClick={() => {
                handleButtonClick(null);
              }}
            />
          ) : (
            <KeyboardArrowDownIcon
              onClick={() => {
                handleButtonClick(buttonIndex);
              }}
            />
          )}{" "}
        </Box>
      )}

      {activeDropdown === buttonIndex && (
        <Card
          sx={{
            position: "absolute",
            [theme.breakpoints.down("lg")]: {
              width: "100%",
              position: "relative",
            },
            [theme.breakpoints.up("lg")]: {
              width: "25%",
              position: "absolute",
            },
            zIndex: 3,
          }}
          onMouseEnter={() => setIsHovered(true)}
          onMouseLeave={() => setIsHovered(false)} // Hide the card when the mouse leaves it
        >
          {dropDownMenu.map((menu, index) => (
            <CardContent
              className="customHoverEffect"
              onClick={() => {
                handleClick(menu.context);
              }}
              key={index}
            >
              <Box display="flex" flexDirection="row" alignItems="center">
                {menu.icon}
                <Box sx={{ ml: "10px" }}>
                  <Typography
                    variant="body2"
                    fontFamily="Inter, sans-serif" // Add your desired font family here
                  >
                    {menu.name}
                  </Typography>
                </Box>
              </Box>
            </CardContent>
          ))}
        </Card>
      )}
    </Box>
  );
};
