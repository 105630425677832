import {
  Box,
  createTheme,
  CssBaseline,
  ThemeProvider,
  Typography,
  useMediaQuery
} from '@mui/material';
import { AnimatePresence, motion } from 'framer-motion';
import React, { useEffect, useState } from 'react';
import { HiChartPie, HiDocumentText, HiFolder } from 'react-icons/hi';


// Create a custom theme
const theme1 = createTheme({
  palette: {
    primary: {
      main: '#6466F1',
      light: '#EEF2FF'
    },
    secondary: {
      main: '#8B5CF6',
    },
    background: {
      default: '#F9FAFB',
      paper: '#FFFFFF'
    },
    text: {
      primary: '#111827',
      secondary: '#4B5563'
    }
  },
  typography: {
    fontFamily: '"Inter", "Roboto", "Helvetica", "Arial", sans-serif',
  },
  shape: {
    borderRadius: 12
  }
});

const DataChatCard = () => {
  const theme = theme1;
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  
  const [activeIndex, setActiveIndex] = useState(0);
  const [animating, setAnimating] = useState(false);

  // Mock data for the conversation bubbles
  const chatSequence = [
    { 
      question: "What was our revenue growth last quarter?", 
      answer: "Revenue grew by 23.5% last quarter compared to the previous one.",
      source: "Q4 Financial Report.pdf"
    },
    { 
      question: "Who are our top 3 clients by spend?", 
      answer: "Your top clients are: 1. Acme Corp ($1.2M) 2. TechGiant ($950K) 3. Innovate Inc ($820K)",
      source: "Client Data.xlsx"
    },
    { 
      question: "Summarize the team feedback from last meeting", 
      answer: "Team wants more clarity on roadmap and improved cross-department communication.",
      source: "Team Survey.doc"
    }
  ];

  // Features list
  const features = [
    "No-Code Setup",
    "Multi-Source Integration",
    "Verifiable Source References"
  ];

  // Document types with icons
  // const docTypes = [
  //   { type: "sheets", icon: "📊", color: "#34A853" },
  //   { type: "pdf", icon: "📄", color: "#EA4335" },
  //   { type: "drive", icon: "📁", color: "#4285F4" }
  // ];

  const docTypes = [
    { type: "sheets", icon: <HiChartPie />, color: "#34A853" },
    { type: "pdf", icon: <HiDocumentText />, color: "#EA4335" },
    { type: "drive", icon: <HiFolder />, color: "#4285F4" }
  ];
  

  useEffect(() => {
    const interval = setInterval(() => {
      if (!animating) {
        setAnimating(true);
        setTimeout(() => {
          setActiveIndex((prev) => (prev + 1) % chatSequence.length);
          setAnimating(false);
        }, 700);
      }
    }, 4000);
    
    return () => clearInterval(interval);
  }, [animating, chatSequence.length]);

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
    <Box
      sx={{
        backgroundColor: 'background.paper',
        borderRadius: { xs: 2, md: 3 },
        p: { xs: 2, md: 3 },
        height: '100%',
        border: '1px solid rgba(0, 0, 0, 0.15)',
        width: '100%',
        overflow: 'hidden',
        position: 'relative'
      }}
    >
      {/* AIStudio Header */}
      <Box sx={{ 
        display: 'flex', 
        alignItems: 'center', 
        mb: 2
      }}>
        <Box sx={{ mr: 1.5 }}>
          <svg width="18" height="18" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect width="24" height="24" rx="4" fill="#000000" />
            <rect x="7" y="7" width="10" height="10" rx="1" fill="white" />
          </svg>
        </Box>
        <Box>AIStudio</Box>
      </Box>
        
      {/* Background decoration elements */}
      <Box 
        sx={{
          position: 'absolute',
          top: -30,
          right: -30,
          width: 120,
          height: 120,
          borderRadius: '50%',
          background: 'radial-gradient(circle, rgba(99,102,241,0.1) 0%, rgba(255,255,255,0) 70%)',
          zIndex: 0
        }}
      />
      
      <Box 
        sx={{
          position: 'absolute',
          bottom: -20,
          left: -20,
          width: 100,
          height: 100,
          borderRadius: '50%',
          background: 'radial-gradient(circle, rgba(79,70,229,0.08) 0%, rgba(255,255,255,0) 70%)',
          zIndex: 0
        }}
      />
      
      <motion.div 
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.5 }}
      >
        {/* Header section with icon */}
        <Box sx={{ 
          display: 'flex', 
          mb: 2.5, 
          position: 'relative', 
          zIndex: 1,
          flexDirection: isMobile ? 'column' : 'row',
          alignItems: isMobile ? 'flex-start' : 'center',
          gap: isMobile ? 1.5 : 0
        }}>
          <Box
            component={motion.div}
            initial={{ scale: 0.8, opacity: 0 }}
            animate={{ scale: 1, opacity: 1 }}
            transition={{ delay: 0.2, type: "spring" }}
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              width: 48,
              height: 48,
              borderRadius: 2,
              backgroundColor: 'primary.light',
              color: 'primary.main',
              mr: isMobile ? 0 : 2
            }}
          >
            <svg width="28" height="28" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M20 2H4C2.9 2 2 2.9 2 4V22L6 18H20C21.1 18 22 17.1 22 16V4C22 2.9 21.1 2 20 2Z" fill="#6366F1"/>
              <path d="M8 9H16M8 13H12" stroke="white" strokeWidth="2" strokeLinecap="round"/>
            </svg>
          </Box>
          
          <Box>
            <Typography variant="h5" fontWeight={700} sx={{ mb: 0.5, fontSize: { xs: '1.25rem', md: '1.5rem' } }}>
              DataChat
            </Typography>
            <Typography variant="body2" color="text.secondary">
              AI-powered data conversations
            </Typography>
          </Box>
        </Box>
        
        {/* Description */}
        <Typography 
          variant="body1" 
          color="text.secondary" 
          sx={{ 
            mb: 3, 
            position: 'relative', 
            zIndex: 1,
            pr: 2,
            fontSize: { xs: '0.875rem', md: '0.95rem' }
          }}
        >
          Turn your Sheets, Drive, and PDFs into an AI-powered Q&A assistant, providing instant answers backed by real-time data validation.
        </Typography>
        
        {/* Document types */}
        <Box 
          sx={{ 
            display: 'flex', 
            mb: 3, 
            position: 'relative', 
            zIndex: 1,
            justifyContent: isMobile ? 'space-between' : 'flex-start',
            width: '100%'
          }}
        >
          {docTypes.map((doc, index) => (
            <motion.div
              key={doc.type}
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ delay: 0.3 + (index * 0.1) }}
            >
              <Box 
                sx={{ 
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  mr: !isMobile && index !== docTypes.length - 1 ? 3 : 0
                }}
              >
                <Box 
                  component={motion.div}
                  whileHover={{ 
                    y: -5, 
                    transition: { duration: 0.2 } 
                  }}
                  sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    width: { xs: 40, md: 48 },
                    height: { xs: 40, md: 48 },
                    borderRadius: 2,
                    backgroundColor: 'white',
                    boxShadow: '0px 2px 8px rgba(0, 0, 0, 0.08)',
                    fontSize: { xs: '20px', md: '24px' },
                    mb: 1
                  }}
                >
                  {doc.icon}
                </Box>
                <Typography 
                  variant="caption" 
                  color="text.secondary"
                  sx={{ 
                    textTransform: 'uppercase', 
                    fontWeight: 600, 
                    fontSize: '0.65rem' 
                  }}
                >
                  {doc.type}
                </Typography>
              </Box>
            </motion.div>
          ))}
        </Box>
        
        {/* Interactive chat example */}
        <Box 
          sx={{ 
            position: 'relative',
            mb: 3,
            height: { xs: 180, sm: 220 },
            backgroundColor: 'rgba(99,102,241,0.04)',
            borderRadius: 3,
            p: { xs: 1.5, md: 2 },
            overflow: 'hidden'
          }}
        >
          <AnimatePresence mode="wait">
            <motion.div
              key={activeIndex}
              initial={{ opacity: 0, x: 50 }}
              animate={{ opacity: 1, x: 0 }}
              exit={{ opacity: 0, x: -50 }}
              transition={{ duration: 0.4 }}
              style={{ height: '100%' }}
            >
              <Box sx={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
                {/* User question */}
                <Box 
                  sx={{ 
                    alignSelf: 'flex-end',
                    backgroundColor: 'primary.main',
                    color: 'white',
                    borderRadius: '12px 12px 0 12px',
                    p: { xs: 1.25, md: 1.5 },
                    maxWidth: '80%',
                    mb: 2
                  }}
                >
                  <Typography variant="body2" sx={{ fontSize: { xs: '0.8125rem', md: '0.875rem' } }}>
                    {chatSequence[activeIndex].question}
                  </Typography>
                </Box>
                
                {/* AI response */}
                <Box 
                  sx={{ 
                    alignSelf: 'flex-start',
                    backgroundColor: 'white',
                    borderRadius: '12px 12px 12px 0',
                    p: { xs: 1.25, md: 1.5 },
                    maxWidth: '80%',
                    boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.05)',
                    position: 'relative'
                  }}
                >
                  <Typography variant="body2" sx={{ mb: 0.5, fontSize: { xs: '0.8125rem', md: '0.875rem' } }}>
                    {chatSequence[activeIndex].answer}
                  </Typography>
                  <Typography 
                    variant="caption" 
                    sx={{ 
                      color: 'primary.main',
                      display: 'flex',
                      alignItems: 'center',
                      fontSize: '0.7rem'
                    }}
                  >
                    <Box component="span" sx={{ mr: 0.5, fontSize: '0.8rem' }}>📎</Box>
                    Source: {chatSequence[activeIndex].source}
                  </Typography>
                </Box>
              </Box>
            </motion.div>
          </AnimatePresence>
        </Box>
        
        {/* Features list */}
        <Box sx={{ 
          position: 'relative', 
          zIndex: 1,
          display: { xs: 'grid', md: 'block' },
          gridTemplateColumns: { xs: '1fr 1fr', md: 'auto' },
          gap: { xs: 1, md: 0 }
        }}>
          {features.map((feature, index) => (
            <Box 
              key={feature}
              component={motion.div}
              initial={{ opacity: 0, x: -20 }}
              animate={{ opacity: 1, x: 0 }}
              transition={{ delay: 0.4 + (index * 0.1) }}
              sx={{ 
                display: 'flex',
                alignItems: 'center',
                mb: !isMobile && index !== features.length - 1 ? 1.5 : 0
              }}
            >
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  width: { xs: 18, md: 20 },
                  height: { xs: 18, md: 20 },
                  borderRadius: '50%',
                  color: 'primary.main',
                  backgroundColor:'primary.light',
                  fontSize: '0.7rem',
                  mr: 1
                }}
              >
                <svg width="12" height="12" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M9 16.2L4.8 12L3.4 13.4L9 19L21 7L19.6 5.6L9 16.2Z" fill="#6366F1"/>
                </svg>
              </Box>
              <Typography variant="body2" fontWeight={500} sx={{ fontSize: { xs: '0.75rem', md: '0.875rem' } }}>
                {feature}
              </Typography>
            </Box>
          ))}
        </Box>
      </motion.div>
    </Box>
    </ThemeProvider>
  );
};

export default DataChatCard;