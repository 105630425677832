import React from 'react';
import { 
  Box, 
  Typography, 
  CssBaseline,
  ThemeProvider,
  createTheme,
  useMediaQuery
} from '@mui/material';
import { motion } from 'framer-motion';

// Create a custom theme to match the design
const theme = createTheme({
  palette: {
    background: {
      default: '#f8f8f8',
      paper: '#ffffff',
    },
    text: {
      primary: '#121212',
      secondary: '#505050',
    },
  },
  typography: {
    fontFamily: '"Inter", "Roboto", "Helvetica", "Arial", sans-serif',
  },
  shape: {
    borderRadius: 8,
  },
});

// Styled motion components using custom components
const MotionBox = motion(Box);

const IntegrationsCard = () => {
  // Check if device is mobile
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  
  // Integration data with icons, colors, and background colors
  const integrations = [
    { 
      name: 'Figma', 
      icon: (
        <svg width="20" height="20" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M8.5 2H12V8H8.5C6.567 8 5 6.433 5 4.5C5 2.567 6.567 1 8.5 1V2Z" fill="#F24E1E"/>
          <path d="M5 9.5C5 7.567 6.567 6 8.5 6H12V13H8.5C6.567 13 5 11.433 5 9.5Z" fill="#FF7262"/>
          <path d="M5 14.5C5 12.567 6.567 11 8.5 11H12V16H8.5C6.567 16 5 14.433 5 12.5V14.5Z" fill="#1ABCFE"/>
          <path d="M12 2H15.5C17.433 2 19 3.567 19 5.5C19 7.433 17.433 9 15.5 9H12V2Z" fill="#F24E1E"/>
          <path d="M15.5 16C17.433 16 19 14.433 19 12.5C19 10.567 17.433 9 15.5 9C13.567 9 12 10.567 12 12.5C12 14.433 13.567 16 15.5 16Z" fill="#A259FF"/>
        </svg>
      ),
      color: '#F24E1E',
      bgColor: 'rgba(242, 78, 30, 0.05)'
    },
    { 
      name: 'Miro', 
      icon: (
        <svg width="20" height="20" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
          <rect width="24" height="24" rx="4" fill="#FFD02F" fillOpacity="0.2"/>
          <path d="M17.5 5H16L12.5 10L10.5 5H9L7 5.5L9.5 12L7 19L9 19.5L12.5 14L16 19.5L18 19L16 12L17.5 5Z" fill="#050038"/>
        </svg>
      ),
      color: '#FFD02F',
      bgColor: 'rgba(255, 208, 47, 0.05)'
    },
    { 
      name: 'Dropbox', 
      icon: (
        <svg width="20" height="20" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M12 6.5L7 9.5L12 12.5L7 15.5L2 12.5L7 9.5L2 6.5L7 3.5L12 6.5Z" fill="#0061FF"/>
          <path d="M12 6.5L17 3.5L22 6.5L17 9.5L22 12.5L17 15.5L12 12.5L17 9.5L12 6.5Z" fill="#0061FF"/>
          <path d="M7 16.5L12 13.5L17 16.5L12 19.5L7 16.5Z" fill="#0061FF"/>
        </svg>
      ),
      color: '#0061FF',
      bgColor: 'rgba(0, 97, 255, 0.05)'
    },
    { 
      name: 'Linear', 
      icon: (
        <svg width="20" height="20" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path fillRule="evenodd" clipRule="evenodd" d="M12 21C16.9706 21 21 16.9706 21 12C21 7.02944 16.9706 3 12 3C7.02944 3 3 7.02944 3 12C3 16.9706 7.02944 21 12 21ZM18 12L12 6V18L18 12Z" fill="#5E6AD2"/>
        </svg>
      ),
      color: '#5E6AD2',
      bgColor: 'rgba(94, 106, 210, 0.05)'
    },
    { 
      name: 'Google Docs', 
      icon: (
        <svg width="20" height="20" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M14 2H6C4.9 2 4 2.9 4 4V20C4 21.1 4.9 22 6 22H18C19.1 22 20 21.1 20 20V8L14 2Z" fill="#4285F4"/>
          <path d="M16 11H8V13H16V11Z" fill="white"/>
          <path d="M16 15H8V17H16V15Z" fill="white"/>
          <path d="M14 2V8H20L14 2Z" fill="#A1C2FA"/>
        </svg>
      ),
      color: '#4285F4',
      bgColor: 'rgba(66, 133, 244, 0.05)'
    },
    { 
      name: 'Coda', 
      icon: (
        <svg width="20" height="20" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
          <rect width="24" height="24" rx="4" fill="#F46A54" fillOpacity="0.2"/>
          <path d="M17 6H7C5.9 6 5 6.9 5 8V16C5 17.1 5.9 18 7 18H17C18.1 18 19 17.1 19 16V8C19 6.9 18.1 6 17 6Z" fill="#F46A54"/>
        </svg>
      ),
      color: '#F46A54',
      bgColor: 'rgba(244, 106, 84, 0.05)'
    },
    { 
      name: 'Asana', 
      icon: (
        <svg width="20" height="20" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M12 15C13.6569 15 15 13.6569 15 12C15 10.3431 13.6569 9 12 9C10.3431 9 9 10.3431 9 12C9 13.6569 10.3431 15 12 15Z" fill="#F06A6A"/>
          <path d="M19 15C20.6569 15 22 13.6569 22 12C22 10.3431 20.6569 9 19 9C17.3431 9 16 10.3431 16 12C16 13.6569 17.3431 15 19 15Z" fill="#F06A6A"/>
          <path d="M5 15C6.65685 15 8 13.6569 8 12C8 10.3431 6.65685 9 5 9C3.34315 9 2 10.3431 2 12C2 13.6569 3.34315 15 5 15Z" fill="#F06A6A"/>
        </svg>
      ),
      color: '#F06A6A',
      bgColor: 'rgba(240, 106, 106, 0.05)'
    },
    { 
      name: 'Trello', 
      icon: (
        <svg width="20" height="20" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
          <rect width="20" height="16" x="2" y="4" rx="2" fill="#0079BF"/>
          <rect width="4" height="7" x="5" y="7" rx="1" fill="white"/>
          <rect width="4" height="4" x="11" y="7" rx="1" fill="white"/>
        </svg>
      ),
      color: '#0079BF',
      bgColor: 'rgba(0, 121, 191, 0.05)'
    },
    { 
      name: 'Salesforce', 
      icon: (
        <svg width="20" height="20" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M12 4C7.582 4 4 7.582 4 12C4 16.418 7.582 20 12 20C16.418 20 20 16.418 20 12C20 7.582 16.418 4 12 4ZM11 14.915C9.93 14.915 9.085 14.07 9.085 13C9.085 11.93 9.93 11.085 11 11.085C12.07 11.085 12.915 11.93 12.915 13C12.915 14.07 12.07 14.915 11 14.915Z" fill="#00A1E0"/>
        </svg>
      ),
      color: '#00A1E0',
      bgColor: 'rgba(0, 161, 224, 0.05)'
    },
    // { 
    //   name: 'Pipedrive', 
    //   icon: (
    //     <svg width="20" height="20" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    //       <path d="M12 2C6.48 2 2 6.48 2 12C2 17.52 6.48 22 12 22C17.52 22 22 17.52 22 12C22 6.48 17.52 2 12 2ZM12 16C9.79 16 8 14.21 8 12C8 9.79 9.79 8 12 8C14.21 8 16 9.79 16 12C16 14.21 14.21 16 12 16Z" fill="#222222"/>
    //     </svg>
    //   ),
    //   color: '#000',
    //   bgColor: 'rgba(0, 0, 0, 0.05)'
    // }
  ];

  // Animation variants for container
  const containerVariants = {
    hidden: { opacity: 0 },
    visible: {
      opacity: 1,
      transition: {
        staggerChildren: 0.07,
        delayChildren: 0.1
      }
    }
  };

  // Animation variants for items
  const itemVariants = {
    hidden: { y: 25, opacity: 0 },
    visible: { 
      y: 0, 
      opacity: 1,
      transition: {
        type: "spring",
        stiffness: 400,
        damping: 25
      }
    }
  };

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Box
        sx={{
          backgroundColor: 'background.paper',
          borderRadius: { xs: 2, md: 3 },
          height: '100%',
          border: '1px solid rgba(0, 0, 0, 0.15)',
          overflow: 'hidden',
          width: '100%'
        }}
      >
        {/* AIStudio Header */}
        <Box sx={{ 
          display: 'flex', 
          alignItems: 'center', 
          p: { xs: 1.25, md: 1.5 }, 
          borderBottom: '1px solid rgba(0, 0, 0, 0.1)' 
        }}>
          <Box sx={{ mr: 1.5 }}>
            <svg width="18" height="18" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <rect width="24" height="24" rx="4" fill="#000000" />
              <rect x="7" y="7" width="10" height="10" rx="1" fill="white" />
            </svg>
          </Box>
          <Box>AIStudio</Box>
        </Box>

        {/* Original Integration Content */}
        <Box sx={{ p: { xs: 2, md: 3 } }}>
          <motion.div
            variants={containerVariants}
            initial="hidden"
            animate="visible"
            style={{ 
              maxHeight: isMobile ? '300px' : '500px', 
              overflowY: 'auto', 
              marginBottom: '16px', 
              paddingRight: '4px'
            }}
          >
            {integrations.map((integration, index) => (
              <MotionBox
                key={integration.name}
                variants={itemVariants}
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  padding: '10px 16px',
                  borderRadius: 2,
                  border: '1px solid rgba(0, 0, 0, 0.08)',
                  marginBottom: 1.2,
                  height: 42,
                  backgroundColor: integration.bgColor,
                  '&:hover': {
                    boxShadow: '0px 2px 8px rgba(0, 0, 0, 0.05)',
                    transform: 'translateY(-2px)'
                  },
                  transition: 'all 0.2s ease'
                }}
              >
                <Box 
                  sx={{ 
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    marginRight: 1.5
                  }}
                >
                  {integration.icon}
                </Box>
                <Typography 
                  variant="subtitle2" 
                  sx={{ 
                    fontWeight: 500,
                    fontSize: '0.9rem'
                  }}
                >
                  {integration.name}
                </Typography>
              </MotionBox>
            ))}
          </motion.div>
          
          <Box sx={{ pt: 1, pb: 1, mt: 1 }}>
            <Typography 
              variant="h5" 
              sx={{ 
                fontSize: { xs: '1.5rem', md: '1.75rem' }, 
                fontWeight: 700, 
                marginBottom: 1,
                fontFamily: '"Inter", "Roboto", "Helvetica", "Arial", sans-serif',
              }}
            >
              Integrations
            </Typography>
            <Typography 
              variant="body1" 
              sx={{ 
                fontSize: { xs: '0.875rem', md: '0.95rem' }, 
                color: 'text.secondary',
                fontFamily: '"Inter", "Roboto", "Helvetica", "Arial", sans-serif',
                lineHeight: 1.4,
              }}
            >
              From Figma to Hubspot, connect 50+ apps to your workspace (coming soon)
            </Typography>
          </Box>
        </Box>
      </Box>
    </ThemeProvider>
  );
};

export default IntegrationsCard;