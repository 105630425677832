// import React from 'react';
// import { 
//   Box, 
//   Typography, 
//   Paper,
//   ThemeProvider, 
//   createTheme,
//   CssBaseline,
// } from '@mui/material';

// // Create a custom theme
// const theme = createTheme({
//   palette: {
//     primary: {
//       main: '#6466F1',
//       light: '#EEF2FF'
//     },
//     secondary: {
//       main: '#8B5CF6',
//     },
//     background: {
//       default: '#F9FAFB',
//       paper: '#FFFFFF'
//     },
//     text: {
//       primary: '#111827',
//       secondary: '#4B5563'
//     }
//   },
//   typography: {
//     fontFamily: '"Inter", "Roboto", "Helvetica", "Arial", sans-serif',
//   },
//   shape: {
//     borderRadius: 12
//   }
// });

// // API Features icons
// const featureIcons = {
//   llm: <svg width="16" height="16" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
//     <circle cx="12" cy="12" r="10" stroke="#6466F1" strokeWidth="2" fill="none"/>
//     <path d="M7 12.5L10 15.5L17 8.5" stroke="#6466F1" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
//   </svg>,
//   graphql: <svg width="16" height="16" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
//     <path d="M12 2L3 7L12 12L21 7L12 2Z" stroke="#6466F1" strokeWidth="2" fill="none"/>
//     <path d="M3 17L12 22L21 17" stroke="#6466F1" strokeWidth="2" fill="none"/>
//     <path d="M3 7V17" stroke="#6466F1" strokeWidth="2" fill="none"/>
//     <path d="M21 7V17" stroke="#6466F1" strokeWidth="2" fill="none"/>
//     <path d="M12 12V22" stroke="#6466F1" strokeWidth="2" fill="none"/>
//   </svg>,
//   security: <svg width="16" height="16" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
//     <path d="M12 1L3 5V11C3 16.55 6.84 21.74 12 23C17.16 21.74 21 16.55 21 11V5L12 1Z" stroke="#6466F1" strokeWidth="2" fill="none"/>
//     <path d="M8 11.5L11 14.5L16 9.5" stroke="#6466F1" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
//   </svg>
// };

// const ApiForgeCard = () => {
//   // Features list
//   const features = [
//     { icon: featureIcons.llm, text: "LLM-Powered AI APIs" },
//     { icon: featureIcons.graphql, text: "REST & GraphQL APIs" },
//     { icon: featureIcons.security, text: "Secure & Scalable Endpoints" }
//   ];

//   return (
//     <ThemeProvider theme={theme}>
//       <CssBaseline />
//       <Paper
//         elevation={0}
//         sx={{
//           borderRadius: 4,
//           overflow: 'hidden',
//           maxWidth: 640,
//           margin: '0 auto',
//           border: '1px solid rgba(0,0,0,0.08)',
//           p: 3,
//           display: 'flex',
//           flexDirection: 'column',
//           gap: 3
//         }}
//       >
//         {/* Header */}
//         <Box sx={{ display: 'flex', alignItems: 'center' }}>
//           <Box 
//             sx={{
//               display: 'flex',
//               justifyContent: 'center',
//               alignItems: 'center',
//               width: 48,
//               height: 48,
//               borderRadius: 2,
//               backgroundColor: 'primary.light',
//               mr: 2,
//               flexShrink: 0
//             }}
//           >
//             <svg width="26" height="26" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
//               <rect width="24" height="24" rx="8" fill="#6466F1" />
//               <path d="M12 5L7 10L12 15L17 10L12 5Z" fill="white" />
//               <path d="M8.5 11.5L12 15L15.5 11.5L12 8L8.5 11.5Z" fill="white" />
//             </svg>
//           </Box>
          
//           <Box>
//             <Typography variant="h4" fontWeight={700} sx={{ mb: 1 }}>
//               ApiForge
//             </Typography>
//             <Typography variant="body1" color="text.secondary">
//               Develop and scale AI-powered APIs with REST and GraphQL, ensuring secure, high-performance integrations for any application.
//             </Typography>
//           </Box>
//         </Box>
        
//         {/* Network Image - Using the provided image from the conversation */}
//         <Box
//           sx={{
//             width: '100%',
//             borderRadius: 3,
//             overflow: 'hidden',
//             backgroundColor: 'white',
//             border: '1px solid rgba(0,0,0,0.05)',
//             display: 'flex',
//             justifyContent: 'center',
//             alignItems: 'center',
//             p: 2
//           }}
//         >
//           <Box 
//             component="img"
//             src="/airippler/api.png"
//             alt="API Network Visualization"
//             sx={{
//               maxWidth: '100%',
//               maxHeight: '300px',
//               objectFit: 'contain'
//             }}
//           />
//         </Box>
        
//         {/* Features */}
//         <Box
//           sx={{ 
//             display: 'flex', 
//             flexDirection: { xs: 'column', sm: 'row' }, 
//             gap: 3, 
//             justifyContent: 'space-between'
//           }}
//         >
//           {features.map((feature, index) => (
//             <Box 
//               key={index}
//               sx={{ 
//                 display: 'flex',
//                 alignItems: 'flex-start',
//                 flex: 1
//               }}
//             >
//               <Box
//                 sx={{
//                   display: 'flex',
//                   justifyContent: 'center',
//                   alignItems: 'center',
//                   width: 32,
//                   height: 32,
//                   borderRadius: '50%',
//                   backgroundColor: 'primary.light',
//                   mr: 1.5,
//                   flexShrink: 0
//                 }}
//               >
//                 {feature.icon}
//               </Box>
//               <Typography variant="body2" fontWeight={500}>
//                 {feature.text}
//               </Typography>
//             </Box>
//           ))}
//         </Box>
        
//         {/* Integrate with Button */}
//         <Box
//           sx={{
//             display: 'flex',
//             justifyContent: 'flex-end',
//             mt: 1
//           }}
//         >
//           <Box
//             sx={{
//               display: 'flex',
//               alignItems: 'center',
//               gap: 1,
//               bgcolor: 'primary.light',
//               color: 'primary.main',
//               py: 1,
//               px: 2,
//               borderRadius: 2,
//               cursor: 'pointer',
//               fontWeight: 500,
//               fontSize: '0.875rem'
//             }}
//           >
//             <Typography variant="body2" fontWeight={600}>
//               Integrate with APIs
//             </Typography>
//             <svg width="14" height="14" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
//               <path d="M12 4L10.59 5.41L16.17 11H4V13H16.17L10.59 18.59L12 20L20 12L12 4Z" fill="#6466F1"/>
//             </svg>
//           </Box>
//         </Box>
//       </Paper>
//     </ThemeProvider>
//   );
// };

// export default ApiForgeCard;

import {
  Box,
  createTheme,
  CssBaseline,
  ThemeProvider,
  Typography,
  useMediaQuery
} from '@mui/material';
import React from 'react';

// Create a custom theme
const theme = createTheme({
  palette: {
    primary: {
      main: '#6466F1',
      light: '#EEF2FF'
    },
    secondary: {
      main: '#8B5CF6',
    },
    background: {
      default: '#F9FAFB',
      paper: '#FFFFFF'
    },
    text: {
      primary: '#111827',
      secondary: '#4B5563'
    }
  },
  typography: {
    fontFamily: '"Inter", "Roboto", "Helvetica", "Arial", sans-serif',
  },
  shape: {
    borderRadius: 12
  }
});

// API Features icons
const featureIcons = {
  llm: <svg width="16" height="16" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle cx="12" cy="12" r="10" stroke="#6466F1" strokeWidth="2" fill="none"/>
    <path d="M7 12.5L10 15.5L17 8.5" stroke="#6466F1" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
  </svg>,
  graphql: <svg width="16" height="16" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M12 2L3 7L12 12L21 7L12 2Z" stroke="#6466F1" strokeWidth="2" fill="none"/>
    <path d="M3 17L12 22L21 17" stroke="#6466F1" strokeWidth="2" fill="none"/>
    <path d="M3 7V17" stroke="#6466F1" strokeWidth="2" fill="none"/>
    <path d="M21 7V17" stroke="#6466F1" strokeWidth="2" fill="none"/>
    <path d="M12 12V22" stroke="#6466F1" strokeWidth="2" fill="none"/>
  </svg>,
  security: <svg width="16" height="16" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M12 1L3 5V11C3 16.55 6.84 21.74 12 23C17.16 21.74 21 16.55 21 11V5L12 1Z" stroke="#6466F1" strokeWidth="2" fill="none"/>
    <path d="M8 11.5L11 14.5L16 9.5" stroke="#6466F1" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
  </svg>
};

const ApiForgeCard = () => {
  // Features list
  const features = [
    { icon: featureIcons.llm, text: "LLM-Powered AI APIs" },
    { icon: featureIcons.graphql, text: "REST & GraphQL APIs" },
    { icon: featureIcons.security, text: "Secure & Scalable Endpoints" }
  ];

  // Media query for mobile
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  // Truncated description for mobile
  const fullDescription = "Develop and scale AI-powered APIs with REST and GraphQL, ensuring secure, high-performance integrations for any application.";
  const mobileDescription = "Develop and scale AI-powered APIs with REST and GraphQL for secure integrations.";

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Box
        sx={{
          backgroundColor: 'background.paper',
          borderRadius: { xs: 2, md: 3 },
          p: { xs: 2, md: 3 },
          height: '100%',
          border: '1px solid rgba(0, 0, 0, 0.15)',
          width: '100%',
          overflow: 'hidden'
        }}
      >
        {/* AIStudio Header */}
        <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
          <Box sx={{ mr: 1.5 }}>
            <svg width="18" height="18" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <rect width="24" height="24" rx="4" fill="#000000" />
              <rect x="7" y="7" width="10" height="10" rx="1" fill="white" />
            </svg>
          </Box>
          <Box>AIStudio</Box>
        </Box>

        {/* ApiForge Content */}
        <Box sx={{ 
          display: 'flex', 
          flexDirection: 'column',
          gap: { xs: 2, sm: 3 }
        }}>
          {/* Header */}
          <Box sx={{ 
            display: 'flex', 
            flexDirection: { xs: 'column', sm: 'row' },
            alignItems: { xs: 'flex-start', sm: 'center' },
            gap: { xs: 1.5, sm: 0 }
          }}>
            <Box 
              sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                width: { xs: 40, sm: 48 },
                height: { xs: 40, sm: 48 },
                borderRadius: 2,
                backgroundColor: 'primary.light',
                mr: { xs: 0, sm: 2 },
                flexShrink: 0,
                mb: { xs: 1, sm: 0 }
              }}
            >
              <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <rect width="24" height="24" rx="8" fill="#6466F1" />
                <path d="M12 5L7 10L12 15L17 10L12 5Z" fill="white" />
                <path d="M8.5 11.5L12 15L15.5 11.5L12 8L8.5 11.5Z" fill="white" />
              </svg>
            </Box>
            
            <Box>
              <Typography 
                variant={isMobile ? "h5" : "h4"} 
                fontWeight={700} 
                sx={{ mb: { xs: 0.5, sm: 1 } }}
              >
                ApiForge
              </Typography>
              <Typography 
                variant="body2" 
                color="text.secondary"
                sx={{ fontSize: { xs: '0.875rem', sm: '1rem' } }}
              >
                {isMobile ? mobileDescription : fullDescription}
              </Typography>
            </Box>
          </Box>
          
          {/* Network Image */}
          <Box
            sx={{
              width: '100%',
              borderRadius: { xs: 2, sm: 3 },
              overflow: 'hidden',
              backgroundColor: 'white',
              border: '1px solid rgba(0,0,0,0.05)',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              p: { xs: 1.5, sm: 2 }
            }}
          >
            <Box 
              component="img"
              src="/airippler/api.png"
              alt="API Network Visualization"
              sx={{
                maxWidth: '100%',
                maxHeight: { xs: '200px', sm: '300px' },
                objectFit: 'contain'
              }}
            />
          </Box>
          
          {/* Features */}
          <Box
            sx={{ 
              display: 'flex', 
              flexDirection: { xs: 'column', sm: 'row' }, 
              gap: { xs: 1.5, sm: 3 }, 
              justifyContent: 'space-between'
            }}
          >
            {features.map((feature, index) => (
              <Box 
                key={index}
                sx={{ 
                  display: 'flex',
                  alignItems: 'center',
                  flex: 1,
                  py: { xs: 0.5, sm: 0 }
                }}
              >
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    width: { xs: 28, sm: 32 },
                    height: { xs: 28, sm: 32 },
                    borderRadius: '50%',
                    backgroundColor: 'primary.light',
                    mr: 1.5,
                    flexShrink: 0
                  }}
                >
                  {feature.icon}
                </Box>
                <Typography 
                  variant="body2" 
                  fontWeight={500}
                  sx={{ fontSize: { xs: '0.8125rem', sm: '0.875rem' } }}
                >
                  {feature.text}
                </Typography>
              </Box>
            ))}
          </Box>
          
          {/* Integrate with Button */}
          <Box
            sx={{
              display: 'flex',
              justifyContent: { xs: 'center', sm: 'flex-end' },
              mt: { xs: 0.5, sm: 1 }
            }}
          >
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                gap: 1,
                bgcolor: 'primary.light',
                color: 'primary.main',
                py: 1,
                px: 2,
                borderRadius: 2,
                //  cursor: 'pointer',
                fontWeight: 500,
                fontSize: '0.875rem',
                width: { xs: '100%', sm: 'auto' },
                justifyContent: { xs: 'center', sm: 'flex-start' }
              }}
            >
              <Typography 
                variant="body2" 
                fontWeight={600}
              >
                Integrate with APIs
              </Typography>
            </Box>
          </Box>
        </Box>
      </Box>
    </ThemeProvider>
  );
};

export default ApiForgeCard;